import { makeRequest } from "../../utils/request";
import { RestList } from "../../components/shared/RestList";
import { User } from "../../redux/reducers/userSlice";
import { View } from "../../components/base/View";
import { useSearchParams } from "react-router-dom";

type AdminUsersPageProps = {
  className?: string;
};

export const AdminUsersPage = ({ className }: AdminUsersPageProps) => {
  const [searchParams] = useSearchParams();
  const isPanelOpen = searchParams.has("id");

  const search = async (query: string) => {
    try {
      const { data } = await makeRequest<{ data: User[] }>(`/v1/users/search?q=${query}`);
      return data.map((user) => user.id);
    } catch (error) {
      console.error("Error searching users:", error);
    }
    return [];
  };

  return (
    <View className="h-full bg-gray-50 dark:bg-gray-900">
      <div className={`h-full relative ${isPanelOpen ? "pr-[400px]" : ""} transition-all duration-300`}>
        <RestList
          className={`h-full ${className || ""}`}
          entityName="user"
          search={search}
          fields={[
            {
              name: "id",
              label: "ID",
              readOnly: true,
            },
            {
              name: "organizationId",
              label: "Organization Id",
              readOnly: true,
            },
            {
              name: "name",
              label: "Name",
            },
            {
              name: "email",
              label: "Email",
            },
            {
              name: "isAdmin",
              label: "Admin",
              type: "checkbox",
            },
          ]}
        />
      </div>
    </View>
  );
};
