import { motion } from "framer-motion";
import clsx from "clsx";

import { View } from "../base/View";
import { Text } from "../base/Text";
import { Icon } from "../base/Icon";

type RecipeListItemProps = {
  id: string;
  name: string;
  description: string;
  onClick?: () => void;
  variant?: "grid" | "list";
  isEmpty?: boolean;
  isCreatingRecipe?: boolean;
};

export const RecipeListItem = ({ name, description, onClick, variant = "grid", isEmpty = false, isCreatingRecipe = false }: RecipeListItemProps) => {
  const containerClasses = clsx(
    "relative overflow-hidden rounded-xl border transition-all duration-200",
    "bg-white dark:bg-gray-800",
    "border-gray-200 dark:border-gray-700",
    "hover:border-indigo-500 dark:hover:border-indigo-400",
    "hover:shadow-md dark:hover:shadow-indigo-500/10",
    "cursor-pointer",
    isEmpty && "border-dashed",
    isEmpty && "hover:border-indigo-500/50 dark:hover:border-indigo-400/50",
    isEmpty && "hover:shadow-none dark:hover:shadow-none",
    isCreatingRecipe && "opacity-70 cursor-not-allowed",
    variant === "list" && "flex-row items-center gap-4 p-4",
    variant === "grid" && "flex-col p-6",
  );

  const contentClasses = clsx("flex flex-col gap-2", variant === "list" && "flex-1");

  return (
    <motion.div
      whileHover={{ scale: isEmpty || isCreatingRecipe ? 1 : 1.02 }}
      whileTap={{ scale: isEmpty || isCreatingRecipe ? 1 : 0.98 }}
      onClick={!isCreatingRecipe ? onClick : undefined}
      className={containerClasses}
    >
      <View className={contentClasses}>
        <View className="flex items-center gap-2">
          <Icon
            name="recipe"
            size={24}
            className="text-indigo-500 dark:text-indigo-400"
          />
          <Text className="text-lg font-semibold text-gray-900 dark:text-gray-100">{isEmpty ? "Create New Recipe" : name}</Text>
        </View>
        <Text className="text-sm text-gray-500 dark:text-gray-400 line-clamp-2">
          {isEmpty ? "Create a new recipe to define reusable workflows for your agents" : description}
        </Text>
      </View>
    </motion.div>
  );
};
