import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { makeRequest } from "../../utils/request";
import { User } from "./userSlice";

type Song = {
  id: string;
  title: string;
  audioUrl: string;
};

type ResetPasswordPayload = {
  email: string;
};

type SignInPayload = {
  email?: string;
  password?: string;
};

type SignUpPayload = {
  email?: string;
  password?: string;
};

export type SessionState = {
  user: User | null;
  error: string | null;
  isLoading: boolean;
  isLoadingSession: boolean;
  languageCode?: string;
  invite?: {
    id: string;
    email?: string;
  };
};

const initialState: SessionState = {
  user: null,
  error: null,
  isLoading: false,
  isLoadingSession: true,
  languageCode: "",
};

const getSession = createAsyncThunk("session/getSession", async () => {
  const response = await makeRequest<{ data: User }>("/v1/auth/session");

  return response.data;
});

const signIn = createAsyncThunk("session/signin", async (payload: SignInPayload, { dispatch }) => {
  await makeRequest("/v1/auth/signin", {
    method: "POST",
    body: payload,
  });

  await dispatch(getSession());
});

const signUp = createAsyncThunk("session/signup", async (payload: SignUpPayload) => {
  await makeRequest("/v1/auth/signup", {
    method: "POST",
    body: payload,
  });
});

const signOut = createAsyncThunk("session/signout", async () => {
  await makeRequest("/v1/auth/signout", {
    method: "POST",
  });
});

const resetPassword = createAsyncThunk("session/resetPassword", async (payload: ResetPasswordPayload) => {
  await makeRequest("/v1/auth/reset", {
    method: "POST",
    body: payload,
  });
});

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setInvite: (state, action) => {
      state.invite = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSession.fulfilled, (state, action) => {
      state.isLoadingSession = false;
      state.user = action.payload;
    });

    builder.addCase(getSession.rejected, (state) => {
      state.isLoadingSession = false;
      state.user = null;
    });

    builder.addCase(signIn.rejected, (state) => {
      state.error = "Invalid email or password";
    });

    builder.addCase(signOut.fulfilled, (state) => {
      state.isLoading = false;
      state.user = null;
    });

    builder.addCase(signUp.rejected, (state, action) => {
      state.error = action.error.message || null;
    });

    builder.addMatcher(
      (action) => action.type.startsWith("session/") && action.type.endsWith("/pending"),
      (state) => {
        state.isLoading = true;
        state.error = null;
      },
    );

    builder.addMatcher(
      (action) => action.type.startsWith("session/") && action.type.endsWith("/rejected"),
      (state) => {
        state.isLoading = false;
      },
    );

    builder.addMatcher(
      (action) => action.type.startsWith("session/") && action.type.endsWith("/fulfilled"),
      (state) => {
        state.isLoading = false;
      },
    );
  },
});

export const sessionReducer = sessionSlice.reducer;

export const sessionActions = {
  ...sessionSlice.actions,
  getSession,
  resetPassword,
  signIn,
  signUp,
  signOut,
};
