import { createRestSlice } from "./restSlice";

export type OrganizationVariable = {
  id: string;
  organizationId: string;
  name: string;
  value: string;
  masked: boolean;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
};

const organizationVariablesSlice = createRestSlice<OrganizationVariable>("organizationVariables", "/v1/organizations/:organizationId/variables");

export const organizationVariablesReducer = organizationVariablesSlice.reducer;
export const organizationVariablesActions = organizationVariablesSlice.actions;
