import React from "react";
import { BaseVoiceCard, type BaseVoiceCardProps } from ".";

export const LargeFeatureVoiceCard: React.FC<BaseVoiceCardProps> = (props) => {
  return (
    <BaseVoiceCard
      {...props}
      titleClassName="text-2xl"
      descriptionClassName="text-base"
      playButtonClassName="!w-12 !h-12"
      hideVerifiedBadge={true}
      hidePublicBadge={true}
      hideMetadata={true}
      hideTags={true}
      descriptionLineClamp={3}
    />
  );
};
