import { Link } from "react-router-dom";

import { Text } from "./base/Text";
import { Icon } from "./base/Icon";

type BaseHeaderButtonProps = {
  to: string;
  icon: string;
  label: string;
};

export const BaseHeaderButton = ({ to, icon, label }: BaseHeaderButtonProps) => {
  return (
    <Link
      to={to}
      className="flex items-center justify-center px-0 md:px-3 mr-0 h-9 w-9 md:w-auto text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 hover:text-indigo-600 dark:hover:text-indigo-400 hover:bg-gray-50 dark:hover:bg-gray-800"
    >
      <Icon
        name={icon}
        size={16}
      />
      <Text className="ml-2 hidden md:block">{label}</Text>
    </Link>
  );
};
