import { Button } from "./base/Button";
import { Icon } from "./base/Icon";
import { InputField } from "./base/InputField";
import { View } from "./base/View";
import { EmptyMessage } from "./EmptyMessage";
import { useEffect, useState } from "react";
import { useAppSelector } from "../redux";
import { SelectField } from "./base/SelectField";
import { AgentEnvironmentVariable } from "../redux/reducers/agentEnvironmentVariableSlice";

type VariablesFormProps = {
  variables?: Partial<AgentEnvironmentVariable>[];
  onChange: (newVariable: Partial<AgentEnvironmentVariable>) => void;
  onClickAdd: () => void;
  onClickDelete: (id: string) => Promise<void>;
};

export const VariablesForm = ({ variables = [], onChange, onClickAdd, onClickDelete }: VariablesFormProps) => {
  const [internalVariables, setInternalVariables] = useState<Partial<AgentEnvironmentVariable>[]>(variables);
  const [isLoadingDeleteId, setIsLoadingDeleteId] = useState<string | null>(null);
  const isLoadingCreate = useAppSelector((state) => state.agentEnvironmentVariable.isLoadingCreate);

  const onChangeHandler = (id: string, key: string) => (value: string) => {
    if (key === "masked") {
      const isMasked = value === "masked";

      onChange({ id, [key]: isMasked });
      setInternalVariables((prev) => prev.map((v) => (v.id === id ? { ...v, [key]: isMasked, value: isMasked ? "********" : v.value } : v)));
    } else {
      onChange({ id, [key]: value });
      setInternalVariables((prev) => prev.map((v) => (v.id === id ? { ...v, [key]: value } : v)));
    }
  };

  const onClickDeleteHandler = (id: string) => async () => {
    setIsLoadingDeleteId(id);

    await onClickDelete(id);

    setIsLoadingDeleteId(null);
  };

  useEffect(() => {
    if (variables.length !== internalVariables.length) {
      setInternalVariables(variables);
    }
  }, [variables]);

  if (!internalVariables.length) {
    return (
      <EmptyMessage
        title="No Agent Variables Found"
        description="Add your first variable to get started. Variables help you store information for use with your agents."
        iconName="list"
        buttonText="Add Your First Variable"
        onClickAdd={onClickAdd}
        className="flex flex-col flex-1 !py-0 w-full"
      />
    );
  }

  return (
    <View className="flex flex-col flex-1 w-full gap-2">
      {internalVariables
        .sort((a, b) => {
          const aDate = new Date(a.createdAt || 0).getTime();
          const bDate = new Date(b.createdAt || 0).getTime();

          return aDate - bDate;
        })
        .map((variable, index) => (
          <View
            key={index}
            className="flex w-full gap-2"
          >
            <InputField
              value={variable.name}
              onChange={onChangeHandler(variable.id || "", "name")}
              placeholder="Variable name"
            />
            <InputField
              type={variable.masked ? "password" : "text"}
              value={variable.value}
              onChange={onChangeHandler(variable.id || "", "value")}
              placeholder="Variable value"
            />
            <SelectField
              value={variable.masked ? "masked" : "visible"}
              onChange={onChangeHandler(variable.id || "", "masked")}
              className="!w-36"
              labelClassName="!w-36"
              options={[
                { label: "Visible", value: "visible" },
                { label: "Masked", value: "masked" },
              ]}
            />
            <Button
              type="basic"
              variant="ghost"
              className="self-end mb-2 p-2 !rounded-full"
              onClick={onClickDeleteHandler(variable.id || "")}
              isDisabled={isLoadingDeleteId === variable.id}
              isLoading={isLoadingDeleteId === variable.id}
            >
              <Icon
                name="trash"
                size={20}
                className={isLoadingDeleteId === variable.id ? "opacity-0" : "opacity-100"}
              />
            </Button>
          </View>
        ))}

      <Button
        variant="secondary"
        className="self-end mt-2"
        onClick={onClickAdd}
        isLoading={isLoadingCreate}
      >
        <Icon
          name="plus"
          size={20}
          className="mr-2 mt-[1px]"
        />
        Add Variable
      </Button>
    </View>
  );
};
