import { RestList } from "../../components/shared/RestList";
import { View } from "../../components/base/View";
import { useSearchParams } from "react-router-dom";

type AdminTrainingJobsPageProps = {
  className?: string;
};

export const AdminTrainingJobsPage = ({ className }: AdminTrainingJobsPageProps) => {
  const [searchParams] = useSearchParams();
  const isPanelOpen = searchParams.has("id");

  return (
    <View className="h-full bg-gray-50 dark:bg-gray-900">
      <div className={`h-full relative ${isPanelOpen ? "pr-[400px]" : ""} transition-all duration-300`}>
        <RestList
          className={`h-full ${className || ""}`}
          entityName="trainingJob"
          fields={[
            {
              name: "id",
              label: "ID",
              readOnly: true,
            },
            {
              name: "jobName",
              label: "Job Name",
            },
            {
              name: "datasetName",
              label: "Dataset Name",
              visible: false,
            },
            {
              name: "baseModelCheckpoint",
              label: "Base Model Checkpoint",
              visible: false,
            },
            {
              name: "status",
              label: "Status",
              readOnly: true,
            },
            {
              name: "epoch",
              label: "Epoch",
              readOnly: true,
              visible: false,
            },
            {
              name: "latestCheckpoint",
              label: "Latest Checkpoint",
              readOnly: true,
            },
            {
              name: "createdAt",
              label: "Created At",
              readOnly: true,
              visible: false,
            },
            {
              name: "completedAt",
              label: "Completed At",
              readOnly: true,
              visible: false,
            },
          ]}
        />
      </div>
    </View>
  );
};
