import clsx from "clsx";
import { useState, useEffect, useRef, ReactNode } from "react";

import { FieldLabel } from "./FieldLabel";
import { View } from "./View";
import { Icon } from "./Icon";
import { Text } from "./Text";

type DropdownFieldProps = {
  name?: string;
  options: Array<{
    label: string | ReactNode;
    value: string;
  }>;
  value?: string;
  onChange?: (value: string) => void;
  className?: string;
  label?: string;
  placeholder?: string;
  error?: string;
};

export const DropdownField = ({ name, options = [], value, onChange = () => {}, className, label, placeholder, error }: DropdownFieldProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const dropdownFieldRef = useRef<HTMLDivElement>(null);

  const filteredOptions = options.filter((option) => (typeof option.label === "string" ? option.label.toLowerCase().includes(search.toLowerCase()) : true));

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownFieldRef.current && !dropdownFieldRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setIsOpen(true);
  };

  const onClickOptionHandler = (value: string) => {
    onChange(value);
    setIsOpen(false);
    setSearch("");
  };

  const selectedOption = options.find((opt) => opt.value === value);

  return (
    <FieldLabel
      label={label}
      className={clsx("w-full", className)}
      error={error}
    >
      <View
        className={clsx("relative w-full", error && "animate-shake")}
        ref={dropdownFieldRef}
      >
        <View
          className={clsx(
            "flex items-center px-4 h-10 w-full border rounded-lg",
            "text-gray-900 dark:text-gray-100 bg-white dark:bg-gray-800",
            "focus-within:outline-none focus-within:ring-2 focus-within:ring-brand focus-within:ring-offset-2 dark:focus-within:ring-offset-gray-900",
            "cursor-pointer",
            {
              "border-gray-300 dark:border-gray-600": !error,
              "border-red-500 dark:border-red-400": error,
            },
          )}
          onClick={() => setIsOpen(!isOpen)}
        >
          <View className="flex-1 flex items-center">
            {isOpen ? (
              <input
                name={name}
                type="text"
                className="w-full h-8 border-none bg-transparent outline-none text-sm"
                placeholder={placeholder}
                autoFocus
                value={search}
                onChange={onChangeInput}
                onClick={(e) => e.stopPropagation()}
              />
            ) : (
              <View className={clsx("text-sm", !selectedOption && "text-gray-400 dark:text-gray-500")}>
                {selectedOption?.label || placeholder || "Select an option"}
              </View>
            )}
          </View>
          <Icon
            name={isOpen ? "chevron-up" : "chevron-down"}
            size={16}
            className="text-gray-500 dark:text-gray-400"
          />
        </View>

        {isOpen && (
          <View
            className={clsx(
              "absolute z-10 w-full mt-1 bg-white dark:bg-gray-800 border rounded-lg shadow-lg overflow-y-auto max-h-60",
              error ? "border-red-500 dark:border-red-400" : "border-gray-200 dark:border-gray-700",
            )}
          >
            {filteredOptions.length === 0 ? (
              <View className="p-3 text-sm text-gray-500 dark:text-gray-400">No options found</View>
            ) : (
              filteredOptions.map((option) => (
                <View
                  key={option.value}
                  onClick={() => onClickOptionHandler(option.value)}
                  className={clsx(
                    "w-full p-3",
                    "cursor-pointer transition-colors duration-150",
                    "hover:bg-gray-50 dark:hover:bg-gray-800/50",
                    value === option.value && "bg-gray-100 dark:bg-gray-800",
                  )}
                >
                  {option.label}
                </View>
              ))
            )}
          </View>
        )}
      </View>
    </FieldLabel>
  );
};
