import { useNavigation } from "../../hooks/useNavigation";
import { ProfileBaseCard } from "./ProfileBaseCard";
import { View } from "../base/View";
import { Text } from "../base/Text";

type AccountCardProps = {
  email: string | undefined;
};

export const AccountCard = ({ email }: AccountCardProps) => {
  const navigation = useNavigation();

  return (
    <ProfileBaseCard
      title="Account Details"
      actionButton={{
        label: "Sign out",
        onClick: () => navigation.navigate("/signout"),
        variant: "outline",
        className: "!text-red-600 !border-red-600 hover:!bg-red-50 dark:hover:!bg-red-950",
      }}
    >
      <View className="flex flex-col gap-1">
        <Text
          variant="muted"
          className="text-sm"
        >
          Email Address
        </Text>
        <Text className="text-lg">{email}</Text>
      </View>
    </ProfileBaseCard>
  );
};
