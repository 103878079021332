import { createRestSlice } from "./restSlice";

export type DatasetBase = {
  id: string;
  userId: string;
  name: string;
  description: string;
  baseVoice: string;
  lineDirection: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
};

const datasetBaseSlice = createRestSlice<DatasetBase>("datasetBase", "/v1/datasetBases");

export const datasetBaseReducer = datasetBaseSlice.reducer;
export const datasetBaseActions = datasetBaseSlice.actions;
