import clsx from "clsx";
import { View } from "../base/View";
import { Button } from "../base/Button";
import { Icon } from "../base/Icon";
import { GenerationList } from "../profile/GenerationList";
import { Generation } from "../../redux/reducers/generationSlice";

// Import or define the correct type that GenerationList expects
interface GenerationWithDisplay extends Omit<Generation, "createdAt"> {
  createdAt: string;
  // Add any other properties that might be in GenerationWithDisplay but not in Generation
}

interface GenerationHistorySidebarProps {
  showGenerationList: boolean;
  setShowGenerationList: (show: boolean) => void;
  onDownload: (audioUrl: string) => void;
  onGenerateWithConfig: (generation: Generation) => void;
  genHistoryRef: React.RefObject<HTMLDivElement>;
}

export const GenerationHistorySidebar = ({
  showGenerationList,
  setShowGenerationList,
  onDownload,
  onGenerateWithConfig,
  genHistoryRef,
}: GenerationHistorySidebarProps) => {
  return (
    <View
      className={clsx(
        "h-full bg-white dark:bg-gray-800 shadow-lg border-l border-gray-200 dark:border-gray-700 transition-all duration-300 ease-in-out hidden md:flex flex-col",
        showGenerationList ? "w-72" : "w-12",
      )}
      ref={genHistoryRef}
    >
      <Button
        type="basic"
        variant="ghost"
        className="p-3 my-2 self-start hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full w-10 h-10 flex items-center justify-center ml-1"
        onClick={() => setShowGenerationList(!showGenerationList)}
        aria-label={showGenerationList ? "Collapse history sidebar" : "Expand history sidebar"}
      >
        <View className="flex items-center justify-center">
          <Icon
            name={showGenerationList ? "chevron-right" : "chevron-left"}
            size={20}
          />
        </View>
      </Button>

      <View className={clsx("flex-1 transition-opacity duration-300 overflow-y-auto", showGenerationList ? "opacity-100" : "opacity-0 pointer-events-none")}>
        {showGenerationList && (
          <GenerationList
            onCreateNew={() => {}}
            onDownload={onDownload}
            onGenerateWithConfig={(generationWithDisplay: GenerationWithDisplay) => {
              // Convert GenerationWithDisplay to Generation if needed
              const generation: Generation = {
                ...generationWithDisplay,
                createdAt: new Date(generationWithDisplay.createdAt),
              };
              onGenerateWithConfig(generation);
            }}
            maxDisplayCount={20}
            variant="list"
            title="History"
            className="border-none shadow-none rounded-none !p-4 !pt-0"
          />
        )}
      </View>
    </View>
  );
};
