import React from "react";
import { motion } from "framer-motion";
import { View } from "../base/View";
import { Text } from "../base/Text";
import { Button } from "../base/Button";
import { Icon } from "../base/Icon";
import { PlayButton } from "../base/PlayButton";
import { Voice } from "../../types";
import { Tooltip } from "../base/Tooltip";
import clsx from "clsx";
import { ReactComponent as MaleAdultSvg } from "../../assets/svg/male-adult.svg";
import { ReactComponent as FemaleAdultSvg } from "../../assets/svg/female-adult.svg";
import { ReactComponent as FemaleYoungAdultSvg } from "../../assets/svg/female-young-adult.svg";
import { ReactComponent as FemaleChildSvg } from "../../assets/svg/female-child.svg";
import { ReactComponent as MaleChildSvg } from "../../assets/svg/male-child.svg";
import { ReactComponent as MaleYoungAdultSvg } from "../../assets/svg/male-young-adult.svg";
import { ReactComponent as NongenderedSvg } from "../../assets/svg/nongendered.svg";
import { ReactComponent as HiddenSvg } from "../../assets/svg/hidden.svg";
import { useNavigate } from "react-router-dom";

const gradients = [
  "linear-gradient(45deg, #4B5563, #6B7280)", // Subtle gray gradient
  "linear-gradient(45deg, #7C3AED, #C084FC)", // Purple gradient
  "linear-gradient(45deg, #2563EB, #60A5FA)", // Blue gradient
  "linear-gradient(45deg, #059669, #34D399)", // Green gradient
  "linear-gradient(45deg, #DC2626, #FB7185)", // Red gradient
  "linear-gradient(45deg, #D97706, #FBBF24)", // Yellow gradient
  "linear-gradient(45deg, #0891B2, #22D3EE)", // Cyan gradient
  "linear-gradient(45deg, #7C3AED, #F472B6)", // Purple-Pink gradient
  "linear-gradient(45deg, #4F46E5, #818CF8)", // Indigo gradient
];

const getGradientColors = (gradient: string) => {
  const match = gradient.match(/linear-gradient\(45deg,\s*([^,]+),\s*([^)]+)\)/);
  return match ? [match[1].trim(), match[2].trim()] : ["#4B5563", "#6B7280"];
};

const StyledFaceIcon = ({ gradient, gender, age }: { gradient: string; gender?: string; age?: string }) => {
  const [startColor, endColor] = getGradientColors(gradient);
  const lowerGender = gender?.toLowerCase() || "";
  const lowerAge = age?.toLowerCase() || "";

  // Function to determine which icon to use based on gender and age
  const getFaceIcon = () => {
    // Male scenarios
    if (lowerGender.includes("male") && !lowerGender.includes("female")) {
      if (lowerAge.includes("mature") || lowerAge.includes("elder")) {
        return MaleAdultSvg; // Male - Mature Adult
      }
      if (lowerAge.includes("adult") && !lowerAge.includes("young")) {
        return MaleAdultSvg; // Male - Adult
      }
      if (lowerAge.includes("young")) {
        return MaleYoungAdultSvg; // Male - Young Adult
      }
      if (lowerAge.includes("child")) {
        return MaleChildSvg; // Male - Child
      }
      return MaleAdultSvg; // Default male
    }

    // Female scenarios
    if (lowerGender.includes("female")) {
      if (lowerAge.includes("mature") || lowerAge.includes("elder")) {
        return FemaleAdultSvg; // Female - Mature Adult
      }
      if (lowerAge.includes("adult") && !lowerAge.includes("young")) {
        return FemaleAdultSvg; // Female - Adult
      }
      if (lowerAge.includes("young")) {
        return FemaleYoungAdultSvg; // Female - Young Adult
      }
      if (lowerAge.includes("child")) {
        return FemaleChildSvg; // Female - Child
      }
      return FemaleYoungAdultSvg; // Default female
    }

    // Non-binary/Other scenario
    if (lowerGender.includes("non")) {
      return NongenderedSvg; // Gender: Non
    }

    // Default case
    return HiddenSvg;
  };

  const FaceIcon = getFaceIcon();
  const gradientId = `face-gradient-${startColor.replace("#", "")}`; // Create unique ID for gradient

  return (
    <View className="relative w-full h-full">
      <svg
        width="0"
        height="0"
      >
        <defs>
          <linearGradient
            id={gradientId}
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%"
          >
            <stop
              offset="20%"
              style={{ stopColor: startColor }}
            />
            <stop
              offset="80%"
              style={{ stopColor: endColor }}
            />
          </linearGradient>
        </defs>
      </svg>
      <FaceIcon
        width="100%"
        height="100%"
        style={{
          fill: `url(#${gradientId})`,
        }}
      />
    </View>
  );
};

export interface BaseVoiceCardProps {
  voice: Voice;
  onClickPlay: () => void;
  isPlaying: boolean;
  className?: string;
  index?: number;
  audioElement?: HTMLAudioElement | null;
  hideVerifiedBadge?: boolean;
  hidePublicBadge?: boolean;
  hideMetadata?: boolean;
  hideTags?: boolean;
  titleClassName?: string;
  descriptionClassName?: string;
  playButtonClassName?: string;
  descriptionLineClamp?: number;
}

export const BaseVoiceCard: React.FC<BaseVoiceCardProps> = ({
  voice,
  onClickPlay,
  isPlaying,
  className,
  index = 0,
  audioElement,
  hideVerifiedBadge = false,
  hidePublicBadge = false,
  hideMetadata = false,
  hideTags = false,
  titleClassName = "text-3xl",
  descriptionClassName = "text-lg",
  playButtonClassName = "!w-14 !h-14",
  descriptionLineClamp = 2,
}) => {
  const navigate = useNavigate();

  // Safely destructure voice properties with defaults
  const {
    name = "",
    userDescription = "",
    userGenderDescription = "",
    userAgeDescription = "",
    userImageUpload = "",
    meanf0pitch,
    tags = "",
    isHighQuality = false,
    verified = false,
    public: isPublic = false,
  } = voice;

  // Split tags into array
  const tagList = typeof tags === "string" ? tags.split(",").map((t) => t.trim()) : tags;

  // Get gradient for the avatar
  const gradient = gradients[index % gradients.length];

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className={clsx("relative h-full rounded-2xl overflow-hidden", "transition-transform duration-300 hover:scale-[1.02]", className)}
    >
      {/* Background Image with Gradient Overlay */}
      <View className="absolute inset-0">
        {userImageUpload ? (
          <img
            src={userImageUpload}
            alt=""
            className="w-full h-full object-cover"
            loading="lazy"
          />
        ) : (
          <View className="w-full h-full">
            <StyledFaceIcon
              gradient={gradient}
              gender={userGenderDescription}
              age={userAgeDescription}
            />
          </View>
        )}
        <View
          className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/50 to-transparent"
          aria-hidden="true"
        />
        <View
          className="absolute inset-0 bg-gradient-to-r from-black/70 to-transparent"
          aria-hidden="true"
        />
      </View>

      {/* Content */}
      <View className="relative z-10 h-full flex flex-col p-8">
        {/* Header with badges */}
        <View className="flex items-center gap-2 mb-2">
          {isHighQuality && (
            <View className="px-1.5 py-0.5 rounded-full bg-brand/20 backdrop-blur">
              <Text className="text-[10px] font-medium text-brand-light">High Quality</Text>
            </View>
          )}
          {verified && !hideVerifiedBadge && (
            <View className="px-1.5 py-0.5 rounded-full bg-emerald-500/20 backdrop-blur">
              <Text className="text-[10px] font-medium text-emerald-400">Verified</Text>
            </View>
          )}
          {isPublic && !hidePublicBadge && (
            <View className="px-1.5 py-0.5 rounded-full bg-blue-500/20 backdrop-blur">
              <Text className="text-[10px] font-medium text-blue-400">Public</Text>
            </View>
          )}
        </View>

        {/* Spacer to push content to bottom */}
        <View className="flex-1" />

        {/* Bottom content group */}
        <View>
          <div
            role="heading"
            aria-level={2}
          >
            <Text className={clsx("font-bold text-white mb-1", titleClassName)}>{name}</Text>
          </div>
          {userDescription &&
            (userDescription.length > 100 ? (
              <Tooltip content={userDescription}>
                <Text className={clsx("text-white/80 mb-3", `line-clamp-${descriptionLineClamp}`, descriptionClassName)}>{userDescription}</Text>
              </Tooltip>
            ) : (
              <Text className={clsx("text-white/80 mb-3", `line-clamp-${descriptionLineClamp}`, descriptionClassName)}>{userDescription}</Text>
            ))}

          {/* Voice characteristics */}
          {!hideMetadata && (
            <View className="flex flex-wrap gap-1.5 mb-3">
              {userGenderDescription && (
                <Tooltip content="Gender">
                  <View className="px-2 py-0.5 rounded-full bg-white/10 backdrop-blur flex items-center gap-1.5">
                    <Icon
                      name={
                        userGenderDescription.toLowerCase().includes("male")
                          ? "male"
                          : userGenderDescription.toLowerCase().includes("female")
                            ? "female"
                            : userGenderDescription.toLowerCase().includes("non-binary")
                              ? "transgender"
                              : "person"
                      }
                      className="w-3 h-3 text-white/80"
                    />
                    <Text className="text-[10px] text-white">{userGenderDescription}</Text>
                  </View>
                </Tooltip>
              )}
              {userAgeDescription && (
                <Tooltip content="Age Group">
                  <View className="px-2 py-0.5 rounded-full bg-white/10 backdrop-blur flex items-center gap-1.5">
                    <Icon
                      name={
                        userAgeDescription.toLowerCase().includes("child")
                          ? "childcare"
                          : userAgeDescription.toLowerCase().includes("elderly")
                            ? "elderly"
                            : userAgeDescription.toLowerCase().includes("young")
                              ? "emoji_people"
                              : "person"
                      }
                      className="w-3 h-3 text-white/80"
                    />
                    <Text className="text-[10px] text-white">{userAgeDescription}</Text>
                  </View>
                </Tooltip>
              )}
              {meanf0pitch && (
                <Tooltip content="Voice Pitch">
                  <View className="px-2 py-0.5 rounded-full bg-white/10 backdrop-blur flex items-center gap-1.5">
                    <Icon
                      name="music"
                      className="w-3 h-3 text-white/80"
                    />
                    <Text className="text-[10px] text-white">{Math.round(meanf0pitch)}Hz</Text>
                  </View>
                </Tooltip>
              )}
            </View>
          )}

          {/* Tags */}
          {!hideTags && tagList.length > 0 && (
            <View className="flex flex-wrap gap-1.5 mb-3">
              {tagList.map((tag, index) => (
                <View
                  key={index}
                  className="px-1.5 py-0.5 rounded-full bg-white/5 backdrop-blur"
                >
                  <Text className="text-[10px] text-white/70">#{tag}</Text>
                </View>
              ))}
            </View>
          )}

          {/* Play Button and Create Speech */}
          <View className="flex justify-start">
            <View className="flex items-center gap-2">
              <View className="flex items-center gap-1.5">
                <PlayButton
                  isPlaying={isPlaying}
                  onPlayPause={onClickPlay}
                  className={clsx("max-h-8 max-w-8", playButtonClassName)}
                  audioElement={audioElement}
                  audioUrl={voice.preferredPreviewUrl}
                />
                <Text className={clsx("font-medium text-white", descriptionClassName)}>{isPlaying ? "Pause" : "Play"}</Text>
              </View>
              <Button
                onClick={() => navigate(`/create?voiceId=${voice.id}`)}
                type="basic"
                variant="ghost"
                className={clsx("flex items-center gap-1 text-white hover:bg-white/10", descriptionClassName === "text-xs" ? "text-xs px-1.5 py-0.5" : "")}
              >
                <Icon
                  name="voice-over"
                  className={clsx("text-brand-light", descriptionClassName === "text-xs" ? "w-3 h-3" : "w-5 h-5")}
                />
                <span>Create</span>
              </Button>
            </View>
          </View>
        </View>
      </View>
    </motion.div>
  );
};
