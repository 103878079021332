import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Agent, agentActions } from "../../redux/reducers/agentSlice";
import { AgentListItem } from "../../components/agents/AgentListItem";
import { SearchableList } from "../../components/SearchableList";
import { useAppDispatch, useAppSelector } from "../../redux";
import { View } from "../../components/base/View";
import { voiceActions } from "../../redux/reducers/voiceSlice";

export const AgentsPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const agentEntities = useAppSelector((state) => state.agent.entities);
  const voiceEntities = useAppSelector((state) => state.voice.entities);
  const isLoading = useAppSelector((state) => state.agent.isLoading);
  const agents = Object.values(agentEntities);

  const onClickItem = (id: string) => {
    navigate(`/agents/${id}/details`);
  };

  const onClickCreate = async () => {
    try {
      // Dispatch the create action and wait for it to complete
      const resultAction = await dispatch(agentActions.create({})).unwrap();

      // Navigate to the newly created agent's details page
      if (resultAction?.id) {
        navigate(`/agents/${resultAction.id}/details`);
      }
    } catch (error) {
      console.error("Failed to create new agent:", error);
    }
  };

  const filterAgents = (searchQuery: string, item: Record<string, unknown>) => {
    const agent = item as Agent;
    const agentName = agent.name?.toLowerCase() || "";
    const agentDescription = agent.description?.toLowerCase() || "";

    return agentName.includes(searchQuery.toLowerCase()) || agentDescription.includes(searchQuery.toLowerCase());
  };

  const sortAgents = (a: Record<string, unknown>, b: Record<string, unknown>) => {
    const agentA = a as Agent;
    const agentB = b as Agent;

    return new Date(agentA.createdAt).getTime() - new Date(agentB.createdAt).getTime();
  };

  useEffect(() => {
    dispatch(agentActions.get({}));
    dispatch(voiceActions.get({}));
  }, [dispatch]);

  return (
    <View className="bg-white dark:bg-gray-900">
      <SearchableList
        entityName="agent"
        emptyMessage={`Create your first AI agent to get started.\nAgents can have their own voice and personality.`}
        filter={filterAgents}
        sort={sortAgents}
        isLoading={isLoading}
        onClickCreate={onClickCreate}
        items={agents}
        itemComponent={({ item, variant, isEmpty }) => {
          const agent = item as Agent;

          const onClick = () => {
            if (isEmpty) {
              onClickCreate();
            } else {
              onClickItem(agent.id);
            }
          };

          return (
            <AgentListItem
              id={agent.id}
              name={agent.name}
              description={agent.description}
              voiceName={voiceEntities[agent?.defaultVoiceId]?.name}
              onClick={onClick}
              variant={variant}
              isEmpty={isEmpty}
            />
          );
        }}
      />
    </View>
  );
};
