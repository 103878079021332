let apiUrl = "";
let googleOauthUrl = "";
let stripePublishableKey = "";
let ttsWsUrl = "";

switch (window.origin) {
  case "http://localhost:3000":
    apiUrl = "http://localhost:8080";
    googleOauthUrl = "https://test.stytch.com/v1/public/oauth/google/start?public_token=public-token-test-e6e70c31-a728-4408-b66a-72d4a3b24492";
    stripePublishableKey = "pk_test_51PgpsAKxRAxzz8mLFvOqk2t6ycadJk63nCztY7LYCz18P80O7THnvYN3gABT9pWfaaKBu3AcuQ2SB6HHXeVFICIr006KuTqjoG";
    ttsWsUrl = "ws://localhost:7860/ws";
    break;
  case "http://local.primvoices.com:3000":
    apiUrl = "https://labs.primstories.com";
    googleOauthUrl = "https://test.stytch.com/v1/public/oauth/google/start?public_token=public-token-test-e6e70c31-a728-4408-b66a-72d4a3b24492";
    stripePublishableKey = "pk_test_51PgpsAKxRAxzz8mLFvOqk2t6ycadJk63nCztY7LYCz18P80O7THnvYN3gABT9pWfaaKBu3AcuQ2SB6HHXeVFICIr006KuTqjoG";
    ttsWsUrl = "ws://localhost:7860/ws";
    break;
  case "https://app.primvoices.com":
    apiUrl = "https://api.primvoices.com";
    googleOauthUrl = "https://api.stytch.primvoices.com/v1/public/oauth/google/start?public_token=public-token-live-6127898b-3b79-4918-b74e-7e66c51e51c6";
    stripePublishableKey = "pk_live_51QPUkmHI5hVmfN3zMwf5rmWaqoFHnlqb0gqtLcrX1iGiunNHnuonQfiMxV2gpsb3ZmnANBrr1whFhJsCLklt26zS00VEKPYwwb";
    ttsWsUrl = "wss://tts.primvoices.com/ws";
    break;
  default:
    apiUrl = window.origin.replace("3000", "8080");
    break;
}

export const API_URL = apiUrl;
export const GOOGLE_OAUTH_URL = googleOauthUrl;
export const STRIPE_PUBLISHABLE_KEY = stripePublishableKey;
export const TTS_WS_URL = ttsWsUrl;
export const WS_URL = `${apiUrl.replace("http", "ws")}/ws`;
