import { useEffect } from "react";

export const useKeyboardShortcut = (shortcut: string, callback: () => void) => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      const keys = shortcut.split("+");

      const isMatch = keys.every((key) => {
        if (key.toLowerCase() === "ctrl") return e.ctrlKey;
        if (key.toLowerCase() === "meta") return e.metaKey;
        if (key.toLowerCase() === "cmd") return e.metaKey;
        if (key.toLowerCase() === "shift") return e.shiftKey;
        if (key.toLowerCase() === "alt") return e.altKey;

        return key.toLowerCase() === e.key.toLowerCase();
      });

      if (isMatch) {
        e.preventDefault();
        callback();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [shortcut, callback]);
};
