import React from "react";
import { clsx } from "clsx";
import { useLocation } from "react-router-dom";

import { Button } from "./base/Button";
import { Icon } from "./base/Icon";
import { Link } from "./base/Link";
import { View } from "./base/View";
import { Text } from "./base/Text";
import { ReactComponent as LogoSvg } from "../assets/svg/logo.svg";
import { useAuth } from "../hooks/useAuth";
import { useTheme } from "../contexts/ThemeContext";
import { BaseHeaderButton } from "./BaseHeaderButton";

export type HeaderLink = {
  label: string;
  to: string;
};

type BaseHeaderProps = {
  className?: string;
  links?: HeaderLink[];
  title?: string;
  showBrand?: boolean;
  rightContent?: React.ReactNode;
};

export const BaseHeader = ({ className, links = [], title, showBrand = true, rightContent }: BaseHeaderProps) => {
  const { theme, toggleTheme } = useTheme();
  const { pathname } = useLocation();
  const { user, isAdmin } = useAuth();

  const isLinkActive = (linkPath: string) => {
    // Exact match for root path
    if (linkPath === "/" && pathname === "/") return true;
    // For other paths, check if the current path starts with the link path
    return linkPath !== "/" && pathname === linkPath;
  };

  return (
    <View className={clsx("sticky top-0 z-50 bg-white/80 dark:bg-gray-900/80 backdrop-blur-sm border-b border-gray-200 dark:border-gray-800", className)}>
      <View className="px-4 h-[72px] grid grid-cols-3">
        <View className="flex items-center justify-start">
          {showBrand && (
            <Link
              to="/"
              className="flex items-center gap-2"
            >
              <div className="flex items-center justify-center w-8 h-8 [--logo-fill:theme(colors.indigo.600)] [--logo-stroke:theme(colors.gray.900)] dark:[--logo-stroke:theme(colors.gray.600)] [--logo-stroke-width:5]">
                <LogoSvg className="w-full h-full" />
              </div>
              <span className="items-center hidden sm:flex text-xl md:text-2xl font-bold text-brand dark:text-brand-light hover:text-brand dark:hover:text-brand-light">
                Prim Voices
              </span>
            </Link>
          )}
          {title && (
            <Text className="flex items-center mt-[2px] ml-2 text-lg font-semibold bg-gradient-to-b from-black to-gray-500 dark:from-white dark:to-gray-500 bg-clip-text text-transparent">
              {title}
            </Text>
          )}
        </View>

        {links.length > 0 && (
          <View className="flex items-center justify-center">
            <View className="flex items-center space-x-4 md:space-x-8">
              {links.map((link) => (
                <Link
                  key={link.to}
                  to={link.to}
                  className={clsx(
                    "transition-all duration-200 pb-1 border-b-2 text-sm md:text-base text-nowrap",
                    isLinkActive(link.to)
                      ? "text-brand dark:text-brand-light font-medium border-brand dark:border-brand-light"
                      : "text-gray-600 dark:text-gray-300 hover:text-brand dark:hover:text-brand-light border-transparent hover:border-brand/30 dark:hover:border-brand-light/30",
                  )}
                >
                  {link.label}
                </Link>
              ))}
            </View>
          </View>
        )}

        <View className="flex items-center justify-end">
          {rightContent}
          {user && (
            <BaseHeaderButton
              to="/profile"
              icon="person"
              label={user.name || "Profile"}
            />
          )}
          {isAdmin && (
            <Link to="/admin">
              <Button
                type="basic"
                variant="rainbowicon"
                className="ml-4 !h-9 !w-9 min-w-9 !shadow-none"
              >
                <Icon
                  name="admin_panel_settings"
                  size={20}
                  className="text-white"
                />
              </Button>
            </Link>
          )}
          <Button
            type="basic"
            variant="ghost"
            onClick={toggleTheme}
            className="ml-4 !h-9 !w-9 min-w-9 bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
          >
            <Icon
              name={theme === "light" ? "moon" : "sun"}
              size={20}
              className="text-current"
            />
          </Button>
        </View>
      </View>
    </View>
  );
};
