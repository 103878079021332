import clsx from "clsx";
import { useState, useEffect, useRef } from "react";

import { FieldLabel } from "./FieldLabel";
import { Style } from "../../utils/types";
import { View } from "./View";
import { Icon } from "./Icon";
import { Text } from "./Text";
import { PlayButton } from "./PlayButton";
import { ReactComponent as MaleAdultSvg } from "../../assets/svg/male-adult.svg";
import { ReactComponent as FemaleAdultSvg } from "../../assets/svg/female-adult.svg";
import { ReactComponent as FemaleYoungAdultSvg } from "../../assets/svg/female-young-adult.svg";
import { ReactComponent as FemaleChildSvg } from "../../assets/svg/female-child.svg";
import { ReactComponent as MaleChildSvg } from "../../assets/svg/male-child.svg";
import { ReactComponent as MaleYoungAdultSvg } from "../../assets/svg/male-young-adult.svg";
import { ReactComponent as NongenderedSvg } from "../../assets/svg/nongendered.svg";
import { ReactComponent as HiddenSvg } from "../../assets/svg/hidden.svg";

type SearchFieldProps = {
  name?: string;
  options: Array<{
    label: string;
    value: string;
    color?: string;
    description?: string;
    gradient?: string;
    gender?: string;
    age?: string;
    isOwner?: boolean;
    isBase?: boolean;
    isCommunity?: boolean;
    preferredPreviewUrl?: string;
    userImageUploadThumbnail?: string;
  }>;
  value?: string;
  onChange?: (value: string) => void;
  onClickAddOption?: () => void;
  className?: string;
  label?: string;
  placeholder?: string;
  style?: Style;
  showAddOption?: boolean;
  onChangeSearchQuery?: (value: string) => void;
  error?: string;
};

const getGradientColors = (gradient: string) => {
  const match = gradient.match(/linear-gradient\(45deg,\s*([^,]+),\s*([^)]+)\)/);
  return match ? [match[1].trim(), match[2].trim()] : ["#4B5563", "#6B7280"];
};

const StyledFaceIcon = ({ gradient, gender, age }: { gradient: string; gender?: string; age?: string }) => {
  const [startColor, endColor] = getGradientColors(gradient);
  const lowerGender = gender?.toLowerCase() || "";
  const lowerAge = age?.toLowerCase() || "";

  const getFaceIcon = () => {
    if (lowerGender.includes("male") && !lowerGender.includes("female")) {
      if (lowerAge.includes("mature") || lowerAge.includes("elder")) {
        return MaleAdultSvg;
      }
      if (lowerAge.includes("adult") && !lowerAge.includes("young")) {
        return MaleAdultSvg;
      }
      if (lowerAge.includes("young")) {
        return MaleYoungAdultSvg;
      }
      if (lowerAge.includes("child")) {
        return MaleChildSvg;
      }
      return MaleAdultSvg;
    }

    if (lowerGender.includes("female")) {
      if (lowerAge.includes("mature") || lowerAge.includes("elder")) {
        return FemaleAdultSvg;
      }
      if (lowerAge.includes("adult") && !lowerAge.includes("young")) {
        return FemaleAdultSvg;
      }
      if (lowerAge.includes("young")) {
        return FemaleYoungAdultSvg;
      }
      if (lowerAge.includes("child")) {
        return FemaleChildSvg;
      }
      return FemaleYoungAdultSvg;
    }

    if (lowerGender.includes("non")) {
      return NongenderedSvg;
    }

    return HiddenSvg;
  };

  const FaceIcon = getFaceIcon();
  const gradientId = `face-gradient-${startColor.replace("#", "")}-${endColor.replace("#", "")}`;

  return (
    <View className="relative w-full h-full">
      <svg
        width="0"
        height="0"
      >
        <defs>
          <linearGradient
            id={gradientId}
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%"
          >
            <stop
              offset="20%"
              style={{ stopColor: startColor }}
            />
            <stop
              offset="80%"
              style={{ stopColor: endColor }}
            />
          </linearGradient>
        </defs>
      </svg>
      <FaceIcon
        width="100%"
        height="100%"
        style={{
          fill: `url(#${gradientId})`,
        }}
      />
    </View>
  );
};

export const SearchField = ({
  name,
  options = [],
  value,
  onChange = () => {},
  onClickAddOption,
  showAddOption,
  className,
  label,
  placeholder,
  style,
  onChangeSearchQuery = () => {},
  error,
}: SearchFieldProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [playingUrl, setPlayingUrl] = useState<string | null>(null);
  const searchFieldRef = useRef<HTMLDivElement>(null);

  const filteredOptions = options.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()));

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchFieldRef.current && !searchFieldRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const onClickAddOptionInternal = () => {
    setIsOpen(false);
    onClickAddOption?.();
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setIsOpen(true);
    onChangeSearchQuery(e.target.value);
  };

  const onClickOptionHandler = (value: string) => {
    onChange(value);
    setIsOpen(false);
    setSearch("");
  };

  const onClickPreviewHandler = (url?: string, e?: React.MouseEvent) => {
    e?.stopPropagation();
    if (!url) return;

    if (audio && playingUrl === url) {
      audio.pause();
      setAudio(null);
      setPlayingUrl(null);
      return;
    }

    if (audio) {
      audio.pause();
    }

    const newAudio = new Audio(url);
    newAudio.play();
    newAudio.addEventListener("ended", () => {
      setPlayingUrl(null);
      setAudio(null);
    });
    setAudio(newAudio);
    setPlayingUrl(url);
  };

  const selectedOption = options.find((opt) => opt.value === value);

  const groupedOptions = filteredOptions.reduce(
    (acc, option) => {
      if (option.isOwner) {
        acc.myVoices.push(option);
      } else if (option.isBase) {
        acc.baseVoices.push(option);
      } else {
        acc.communityVoices.push(option);
      }
      return acc;
    },
    { myVoices: [], baseVoices: [], communityVoices: [] } as {
      myVoices: typeof filteredOptions;
      baseVoices: typeof filteredOptions;
      communityVoices: typeof filteredOptions;
    },
  );

  const renderVoiceGroup = (voices: typeof filteredOptions, title: string) => {
    if (voices.length === 0) return null;

    return (
      <>
        <View className="px-3 py-2 bg-gray-50 dark:bg-gray-800 border-t border-b border-gray-200 dark:border-gray-700">
          <Text className="text-xs font-medium text-gray-500 dark:text-gray-400">{title}</Text>
        </View>
        {voices.map((option, index) => (
          <View
            key={option.value}
            onClick={() => onClickOptionHandler(option.value)}
            className={clsx(
              "w-full p-3",
              "cursor-pointer transition-colors duration-150",
              "hover:bg-gray-50 dark:hover:bg-gray-800/50",
              value === option.value && "bg-gray-100 dark:bg-gray-800",
              index !== 0 && "border-t",
              error ? "border-red-500 dark:border-red-400" : "border-gray-200 dark:border-gray-700",
            )}
          >
            <View className="flex items-center gap-3">
              <View className="w-10 h-10 rounded-lg flex-shrink-0 overflow-hidden">
                {option.userImageUploadThumbnail ? (
                  <img
                    src={option.userImageUploadThumbnail}
                    alt={`${option.label} Thumbnail`}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <StyledFaceIcon
                    gradient={option.gradient || "linear-gradient(45deg, #4B5563, #6B7280)"}
                    gender={option.gender}
                    age={option.age}
                  />
                )}
              </View>
              <View className="flex-1 min-w-0">
                <Text className="font-medium text-gray-900 dark:text-gray-100">{option.label}</Text>
                {option.description && <Text className="text-sm text-gray-500 dark:text-gray-400 line-clamp-1">{option.description}</Text>}
              </View>
              {option.preferredPreviewUrl && (
                <View onClick={(e) => e.stopPropagation()}>
                  <PlayButton
                    isPlaying={playingUrl === option.preferredPreviewUrl}
                    onPlayPause={() => onClickPreviewHandler(option.preferredPreviewUrl)}
                    audioElement={audio}
                    audioUrl={option.preferredPreviewUrl}
                    className="flex-shrink-0"
                  />
                </View>
              )}
            </View>
          </View>
        ))}
      </>
    );
  };

  return (
    <FieldLabel
      label={label}
      error={error}
      className={clsx("relative", label ? "max-h-[86px]" : "max-h-[64px]", "overflow-visible z-30")}
    >
      <View
        ref={searchFieldRef}
        className="relative w-full"
      >
        <input
          value={isOpen ? search : selectedOption?.label || ""}
          onChange={onChangeInput}
          onFocus={() => setIsOpen(true)}
          placeholder={placeholder}
          style={style}
          autoComplete="off"
          data-form-type="other"
          className={clsx(
            "w-full px-4 pr-10 py-2 rounded-lg",
            "transition-colors duration-200",
            "border",
            {
              "border-gray-300 dark:border-gray-700": !error,
              "border-red-500 dark:border-red-400": error,
            },
            "text-gray-900 dark:text-gray-100",
            "bg-white dark:bg-gray-800",
            "placeholder:text-gray-400 dark:placeholder:text-gray-500",
            "focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2 dark:focus:ring-offset-gray-900",
            !error && "hover:border-brand/50 dark:hover:border-brand/50",
            error && "hover:border-red-400 dark:hover:border-red-300",
            className,
          )}
        />
        <View className="absolute right-3 top-1/2 -translate-y-1/2 pointer-events-none">
          <Icon
            name={isOpen ? "chevron-up" : "chevron-down"}
            size={20}
            className={clsx("transition-colors duration-200", error ? "text-red-500 dark:text-red-400" : "text-gray-400 dark:text-gray-500")}
          />
        </View>

        {options.length > 0 && isOpen && (
          <View
            className={clsx(
              "absolute z-30 w-full max-h-[350px] overflow-y-auto",
              "bg-white dark:bg-gray-800",
              "border rounded-lg",
              error ? "border-red-500 dark:border-red-400" : "border-brand/50 dark:border-brand/50",
            )}
          >
            {showAddOption && (
              <View
                onClick={onClickAddOptionInternal}
                className={clsx(
                  "w-full p-3",
                  "hover:bg-gray-50 dark:hover:bg-gray-700/50",
                  "border-b cursor-pointer",
                  error ? "border-red-500 dark:border-red-400" : "border-gray-200 dark:border-gray-700",
                )}
              >
                <View className="flex items-center gap-3">
                  <View className="w-10 h-10 rounded-lg overflow-hidden">
                    <StyledFaceIcon
                      gradient="linear-gradient(45deg, #4B5563, #6B7280)"
                      gender="hidden"
                      age="hidden"
                    />
                  </View>
                  <View className="flex-1 min-w-0">
                    <Text className="text-gray-900 dark:text-gray-100">Add New Voice</Text>
                  </View>
                  <View className={clsx("w-8 h-8 rounded-lg flex items-center justify-center", "hover:bg-gray-100 dark:hover:bg-gray-700")}>
                    <Icon
                      name="add"
                      size={20}
                      className="text-brand dark:text-brand-light"
                    />
                  </View>
                </View>
              </View>
            )}
            {renderVoiceGroup(groupedOptions.myVoices, "My Voices")}
            {renderVoiceGroup(groupedOptions.baseVoices, "Base Voices")}
            {renderVoiceGroup(groupedOptions.communityVoices, "Community Voices")}
          </View>
        )}
      </View>
    </FieldLabel>
  );
};
