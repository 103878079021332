import { PrimVoicesProvider, usePrimVoices } from "primvoices-react";
import { useEffect, useState } from "react";
import clsx from "clsx";

import { AgentMonitoring } from "./AgentMonitoring";
import { Button } from "../base/Button";
import { Icon } from "../base/Icon";
import { InputField } from "../base/InputField";
import { Text } from "../base/Text";
import { TTS_WS_URL } from "../../config";
import { View } from "../base/View";
import { useAppSelector } from "../../redux";
import { DropdownField } from "../base/DropdownField";
import { getGradientForName } from "../../utils/gradients";

type AgentDebuggerInternalProps = {
  environmentId?: string;
  isOpen: boolean;
  onClick: () => void;
  onChangeEnvironment: (environment: string) => void;
};

export const AgentDebuggerInternal = ({ environmentId, isOpen, onClick, onChangeEnvironment }: AgentDebuggerInternalProps) => {
  const [isKeyboardActive, setIsKeyboardActive] = useState(false);
  const [inputMessage, setInputMessage] = useState("");
  const agentEnvironments = useAppSelector((state) => Object.values(state.agentEnvironment.entities));
  const { connect, disconnect, startListening, stopListening, sendTextEvent, isConnected, isListening } = usePrimVoices();

  const onClickStart = async () => {
    try {
      await connect();
    } catch (err) {
      console.error("Failed to start conversation:", err);
    }
  };

  const onClickStop = async () => {
    try {
      await stopListening();
      await disconnect();

      setIsKeyboardActive(false);
      setInputMessage("");
    } catch (err) {
      console.error("Failed to stop conversation:", err);
    }
  };

  const onClickMic = async () => {
    try {
      if (isListening) {
        await stopListening();
      } else {
        await startListening();
      }
    } catch (err) {
      console.error("Failed to toggle microphone:", err);
    }
  };

  const onClickKeyboard = () => {
    setIsKeyboardActive(!isKeyboardActive);
  };

  const onClickSendMessage = async () => {
    if (!inputMessage.trim()) return;

    try {
      // Send message to agent
      const userMessage = inputMessage.trim();
      await sendTextEvent(userMessage);

      // Clear input field
      setInputMessage("");
    } catch (err) {
      console.error("Failed to send message:", err);
    }
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      onClickSendMessage();
    }
  };

  useEffect(() => {
    if (!environmentId && agentEnvironments.length > 0) {
      onChangeEnvironment(agentEnvironments[0].id);
    }
  }, [environmentId, agentEnvironments]);

  return (
    <View
      className={clsx(
        "relative h-full bg-white dark:bg-gray-800 shadow-lg border-l border-gray-200 dark:border-gray-700 transition-all duration-300 ease-in-out hidden md:flex flex-col",
        isOpen ? "w-[50vw]" : "w-12",
      )}
    >
      <Button
        type="basic"
        variant="ghost"
        className="!absolute p-3 mt-1 !dark:focus:ring-0 !focus:ring-0 self-start hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full w-10 h-10 flex items-center justify-center ml-1"
        onClick={onClick}
        aria-label={isOpen ? "Collapse debug sidebar" : "Expand debug sidebar"}
      >
        <View className="flex items-center justify-center">
          <Icon
            name={isOpen ? "chevron-right" : "chevron-left"}
            size={20}
          />
        </View>
      </Button>
      <Text className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-2 text-sm font-medium text-gray-500 dark:text-gray-400 origin-center -rotate-90 transition-opacity duration-300 ease-in-out">
        Debugger
      </Text>
      <View className="ml-12 h-full border-l border-gray-200 dark:border-gray-700 w-[calc(50vw-48px)] min-w-[calc(50vw-48px)]">
        <View className="flex flex-col h-full">
          <View className="flex items-center justify-between py-2 px-4 border-b border-gray-200 dark:border-gray-700">
            <View className="flex items-center gap-2">
              <View className={`w-3 h-3 min-w-3 min-h-3 rounded-full ${isConnected ? "bg-green-500 animate-pulse" : "bg-gray-400"}`} />
              <Text className="text-sm font-medium text-gray-700 dark:text-gray-300">{isConnected ? "Conversation Active" : "Conversation Inactive"}</Text>
            </View>
            <View className="flex items-center gap-2">
              <DropdownField
                options={agentEnvironments?.map((agentEnvironment) => {
                  const gradient = getGradientForName(agentEnvironment.name);
                  return {
                    value: agentEnvironment.id,
                    label: (
                      <View className="flex items-center gap-2">
                        <View
                          key={agentEnvironment.id}
                          className={`h-5 w-5 rounded-full bg-gradient-to-br ${gradient.from} ${gradient.to} ${gradient.darkFrom} ${gradient.darkTo} flex items-center justify-center`}
                          title={agentEnvironment.name}
                        >
                          <Text className="text-xs font-bold text-white">{agentEnvironment.name.substring(0, 1).toUpperCase()}</Text>
                        </View>
                        <Text className="text-sm font-medium text-gray-700 dark:text-gray-300">{agentEnvironment.name}</Text>
                      </View>
                    ),
                  };
                })}
                value={environmentId}
                onChange={onChangeEnvironment}
                className="w-48 min-w-48 max-w-48"
              />
              <View className="flex items-center mx-2 h-8 w-[1px] bg-gray-200 dark:bg-gray-700" />
              <Button
                onClick={onClickMic}
                className={clsx(
                  "flex items-center h-10 w-10 bg-indigo-600 hover:bg-indigo-700 text-white rounded-md transition-colors shadow-none",
                  isListening ? "bg-red-600 hover:bg-red-700" : "bg-indigo-600 hover:bg-indigo-700",
                )}
                isDisabled={!isConnected}
              >
                <Icon
                  name={isListening ? "mic-off" : "mic"}
                  size={20}
                />
              </Button>
              <Button
                onClick={onClickKeyboard}
                className={clsx(
                  "flex items-center h-10 w-10 bg-indigo-600 hover:bg-indigo-700 text-white rounded-md transition-colors shadow-none",
                  isKeyboardActive ? "bg-red-600 hover:bg-red-700" : "bg-indigo-600 hover:bg-indigo-700",
                )}
                isDisabled={!isConnected}
              >
                <Icon
                  name={isKeyboardActive ? "keyboard-off" : "keyboard"}
                  size={20}
                />
              </Button>
              <View className="flex items-center mx-2 h-8 w-[1px] bg-gray-200 dark:bg-gray-700" />
              <Button
                onClick={isConnected ? onClickStop : onClickStart}
                className={clsx(
                  "flex items-center gap-2 text-white h-10 px-4 py-2 rounded-md transition-colors w-24 shadow-none",
                  isConnected ? "bg-red-600 hover:bg-red-700" : "bg-indigo-600 hover:bg-indigo-700",
                )}
              >
                <Icon
                  name={isConnected ? "stop" : "play"}
                  size={16}
                />
                {isConnected ? "Stop" : "Start"}
              </Button>
            </View>
          </View>
          <AgentMonitoring />
          <View
            className={clsx(
              "mt-auto p-4 border-t border-gray-200 dark:border-gray-700 transition-all duration-300 ease-in-out",
              !isKeyboardActive ? "-mb-24" : "mb-0",
            )}
          >
            <View className="relative">
              <InputField
                value={inputMessage}
                onChange={setInputMessage}
                placeholder={isConnected ? "Type your message..." : "Start conversation to enable..."}
                className="w-full pr-12 h-14"
                onKeyDown={onKeyDown}
                readOnly={!isConnected}
              />
              <View className="absolute right-2 top-1/2 transform -translate-y-1/2">
                <Button
                  onClick={isConnected ? onClickSendMessage : undefined}
                  type="basic"
                  variant="icon"
                  className={`
                    text-white !w-10 !h-10
                    ${
                      !isConnected
                        ? "bg-gray-300 dark:bg-gray-700 cursor-not-allowed"
                        : !inputMessage.trim()
                          ? "bg-indigo-400 cursor-not-allowed"
                          : "bg-indigo-600 hover:bg-indigo-700"
                    }
                  `}
                  isDisabled={!isConnected || !inputMessage.trim()}
                >
                  <Icon
                    name="send"
                    size={18}
                  />
                </Button>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

type AgentDebuggerProps = {
  agentId: string;
  functionId: string;
  isOpen: boolean;
  onClick: () => void;
};

export const AgentDebugger = ({ agentId, functionId, isOpen, onClick }: AgentDebuggerProps) => {
  const [environmentId, setEnvironmentId] = useState("");
  const agentEnvironment = useAppSelector((state) => (environmentId ? state.agentEnvironment.entities[environmentId] : null));

  const onChangeEnvironment = (id: string) => {
    setEnvironmentId(id);
  };

  return (
    <PrimVoicesProvider
      config={{
        serverUrl: TTS_WS_URL,
        agentId,
        functionId,
        environment: agentEnvironment?.name,
        logLevel: "INFO",
      }}
    >
      <AgentDebuggerInternal
        environmentId={environmentId}
        isOpen={isOpen}
        onClick={onClick}
        onChangeEnvironment={onChangeEnvironment}
      />
    </PrimVoicesProvider>
  );
};
