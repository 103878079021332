import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { View } from "../base/View";
import { LargeFeatureVoiceCard } from "./LargeFeatureVoiceCard";
import { CarouselProps } from "./types";
import { Icon } from "../base/Icon";

export const LargeFeatureVoiceCarousel: React.FC<CarouselProps> = ({ voices, onClickPlay, isPlayingVoiceId, audioElement }) => {
  const [startIndex, setStartIndex] = useState(0);
  const [visibleCount, setVisibleCount] = useState(3);

  // Update visible count based on window width
  useEffect(() => {
    const updateVisibleCount = () => {
      if (window.innerWidth < 640) {
        // sm
        setVisibleCount(1);
      } else if (window.innerWidth < 1024) {
        // lg
        setVisibleCount(2);
      } else {
        setVisibleCount(3);
      }
    };

    // Initial update
    updateVisibleCount();

    // Add resize listener
    window.addEventListener("resize", updateVisibleCount);
    return () => window.removeEventListener("resize", updateVisibleCount);
  }, []);

  // Safety check - if no voices, don't render
  if (!voices?.length) return null;

  const maxStartIndex = Math.max(0, voices.length - visibleCount);
  const safeStartIndex = Math.min(startIndex, maxStartIndex);
  const hasOverflow = voices.length > visibleCount;

  const visibleVoices = voices.slice(safeStartIndex, safeStartIndex + visibleCount);

  const handlePrevious = () => {
    setStartIndex((prev) => Math.max(0, prev - 1));
  };

  const handleNext = () => {
    setStartIndex((prev) => Math.min(maxStartIndex, prev + 1));
  };

  // Calculate card width based on visible count
  const getCardWidthClass = () => {
    switch (visibleCount) {
      case 1:
        return "w-full";
      case 2:
        return "w-[calc(50%-0.75rem)]";
      default:
        return "w-[calc(33.333%-1rem)]";
    }
  };

  return (
    <View className="relative w-full overflow-hidden">
      <View className="flex gap-6 justify-between items-stretch relative min-h-[400px] max-w-full">
        {visibleVoices.map((voice, idx) => (
          <motion.div
            key={voice.id}
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            className={`${getCardWidthClass()} min-w-0 flex-shrink-0`}
          >
            <LargeFeatureVoiceCard
              voice={voice}
              onClickPlay={onClickPlay(voice.id)}
              isPlaying={isPlayingVoiceId === voice.id}
              audioElement={audioElement}
              index={safeStartIndex + idx}
            />
          </motion.div>
        ))}
      </View>

      {/* Navigation Arrows - Only show when there's overflow */}
      {hasOverflow && (
        <>
          {startIndex > 0 && (
            <button
              onClick={handlePrevious}
              className="absolute left-4 top-1/2 -translate-y-1/2 bg-black/50 hover:bg-black/70 p-3 rounded-full backdrop-blur-sm transition-all z-10"
              aria-label="Previous voices"
            >
              <Icon
                name="chevron-left"
                className="text-white w-6 h-6"
              />
            </button>
          )}

          {startIndex < maxStartIndex && (
            <button
              onClick={handleNext}
              className="absolute right-4 top-1/2 -translate-y-1/2 bg-black/50 hover:bg-black/70 p-3 rounded-full backdrop-blur-sm transition-all z-10"
              aria-label="Next voices"
            >
              <Icon
                name="chevron-right"
                className="text-white w-6 h-6"
              />
            </button>
          )}
        </>
      )}
    </View>
  );
};
