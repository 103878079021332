import { createRestSlice } from "./restSlice";

export type AgentRecipe = {
  id: string;
  userId: string;
  organizationId: string;
  name: string;
  description: string;
  code: string;
  isPublic: boolean;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
};

const agentRecipeSlice = createRestSlice<AgentRecipe>("agentRecipe", "/v1/agentRecipes");

export const agentRecipeReducer = agentRecipeSlice.reducer;
export const agentRecipeActions = agentRecipeSlice.actions;
