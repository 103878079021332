import { View } from "../../components/base/View";
import { Text } from "../../components/base/Text";
import { BaseHeader } from "../../components/BaseHeader";

type FaqPageProps = {
  className?: string;
};

export const FaqPage = ({ className }: FaqPageProps) => {
  const links = [
    { label: "Getting Started", to: "/docs/getting-started" },
    { label: "API Reference", to: "/docs/api-reference" },
    { label: "Features", to: "/docs/features" },
    { label: "FAQ", to: "/docs/faq" },
  ];

  return (
    <View className={`min-h-screen bg-white dark:bg-gray-900 ${className}`}>
      <BaseHeader
        title="Documentation"
        links={links}
        showBrand={true}
      />
      <View className="w-full max-w-5xl mx-auto p-8">
        <View className="space-y-8">
          <Text
            variant="brand"
            className="text-3xl font-bold mb-4"
          >
            Frequently Asked Questions
          </Text>
          <Text
            variant="body"
            className="text-lg mb-4"
          >
            Find answers to common questions about PrimVoices.
          </Text>
          {/* Add FAQ content here */}
        </View>
      </View>
    </View>
  );
};
