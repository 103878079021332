import { useState, useEffect } from "react";
import { useAppSelector } from "../redux";

export const useAuth = () => {
  const [user, setUser] = useState<any>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const sessionState = useAppSelector((state) => state.session);

  useEffect(() => {
    if (sessionState.user) {
      setUser(sessionState.user);
      setIsAdmin(sessionState.user.isAdmin);
    } else {
      setUser(null);
      setIsAdmin(false);
    }
  }, [sessionState.user]);

  return { user, isAdmin };
};
