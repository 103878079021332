import React, { ReactNode } from "react";
import { Button } from "../base/Button";
import { Text } from "../base/Text";
import { View } from "../base/View";

type ProfileBaseCardProps = {
  title?: string;
  subtitle?: string;
  actionButton?: {
    label: string;
    onClick: () => void;
    variant?: "default" | "outline" | "ghost";
    className?: string;
  };
  children: ReactNode;
  className?: string;
};

export const ProfileBaseCard = ({ title, subtitle, actionButton, children, className }: ProfileBaseCardProps) => {
  return (
    <View className={`bg-white dark:bg-gray-800 rounded-lg p-4 border border-gray-200 dark:border-gray-700 shadow-sm ${className || ""}`}>
      {(title || actionButton) && (
        <View className="flex flex-col sm:flex-row items-start sm:items-top justify-between mb-4">
          {title && (
            <View className="space-y-1 mb-3 sm:mb-0">
              <Text
                variant="brand"
                className="text-lg font-semibold"
              >
                {title}
              </Text>
              {subtitle && (
                <Text
                  variant="muted"
                  className="text-sm"
                >
                  {subtitle}
                </Text>
              )}
            </View>
          )}
          {actionButton && (
            <Button
              onClick={actionButton.onClick}
              variant={actionButton.variant || "default"}
              className={`w-full sm:w-auto min-w-[120px] justify-center ${actionButton.className || ""}`}
            >
              {actionButton.label}
            </Button>
          )}
        </View>
      )}
      {children}
    </View>
  );
};
