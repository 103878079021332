import clsx from "clsx";
import React, { useEffect, useState } from "react";

import { View } from "./View";
import { Style } from "../../utils/types";

export const useForm = <T extends {}>(initialData: Partial<T> = {}) => {
  const [formData, setFormData] = useState<Partial<T>>(initialData as Partial<T>);

  const onChange = (newFormData: Partial<T>) => {
    setFormData(newFormData);
  };

  return { onChange, formData };
};

type FormProps<T extends Record<string, unknown>> = {
  className?: string;
  onChange?: (formData: T) => void;
  children: React.ReactNode;
  data: T;
  style?: Style;
  gap?: "none" | "sm" | "md" | "lg";
};

export const Form = <T extends Record<string, unknown>>({ className, onChange, children, data, style, gap = "md" }: FormProps<T>) => {
  const [formData, setFormData] = useState<T>(data);

  const onChangeHandler = (key: keyof T) => (value: unknown) => {
    const newFormData = {
      ...formData,
      [key]: value,
    };

    setFormData(newFormData);
    onChange?.(newFormData);
  };

  useEffect(() => {
    setFormData(data);
  }, [data]);

  const recursivelyMapChildren = (children: React.ReactNode): React.ReactNode => {
    return React.Children.map(children, (child) => {
      if (!React.isValidElement(child)) return child;

      const childProps = child.props as { name?: keyof T; children?: React.ReactNode };

      // If the child has children, recursively map them
      const mappedChildren = childProps.children ? recursivelyMapChildren(childProps.children) : childProps.children;

      // If this element has a name prop, add the onChange and value props
      if (childProps.name) {
        console.log("childProps.name", childProps.name);
        return React.cloneElement(child, {
          ...child.props,
          onChange: onChangeHandler(childProps.name),
          value: formData[childProps.name],
          children: mappedChildren,
        });
      }

      // Otherwise just pass through the mapped children
      if (mappedChildren !== childProps.children) {
        return React.cloneElement(child, {
          ...child.props,
          children: mappedChildren,
        });
      }

      return child;
    });
  };

  return (
    <View
      className={clsx(
        "w-full flex flex-col",
        {
          "gap-2": gap === "sm",
          "gap-4": gap === "md",
          "gap-6": gap === "lg",
        },
        className,
      )}
      style={style}
    >
      {recursivelyMapChildren(children)}
    </View>
  );
};
