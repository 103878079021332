import clsx from "clsx";
import { View } from "../base/View";
import { Button } from "../base/Button";
import { Generation } from "../../redux/reducers/generationSlice";
import { AudioPlayer } from "../AudioPlayer";
import { Icon } from "../base/Icon";

interface StickyFooterProps {
  onClickSubmit: () => void;
  isLoadingGeneration: boolean;
  formData: Partial<Generation>;
  validateForm: () => boolean;
  showGenerationList: boolean;
  generationPreview: Generation | null;
  onDownload?: (audioUrl: string) => void;
  onClearPreview?: () => void;
}

export const StickyFooter = ({
  onClickSubmit,
  isLoadingGeneration,
  formData,
  validateForm,
  showGenerationList,
  generationPreview,
  onDownload,
  onClearPreview,
}: StickyFooterProps) => {
  return (
    <View
      className={clsx(
        "fixed bottom-0 z-20 border-t border-gray-200 dark:border-gray-700 py-4 px-6 transition-all duration-300",
        "bg-gray-50 dark:bg-transparent backdrop-blur-sm",
        "shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.1)]",
        // On mobile, take full width
        "left-0 right-0",
        // On desktop, adjust based on left sidebar
        "md:left-72",
        // Adjust right side based on GenerationHistorySidebar
        showGenerationList ? "md:right-72" : "md:right-12",
      )}
      style={{ height: "var(--footer-height)" }}
    >
      <View className="flex flex-row justify-center gap-4 w-full h-full items-center max-w-4xl mx-auto">
        {generationPreview && generationPreview.audioUrl && (
          <View className="flex-1 flex flex-row items-center gap-2">
            <AudioPlayer
              audioUrl={generationPreview.audioUrl}
              className="flex-1 w-full"
            />
            {onClearPreview && (
              <Button
                onClick={onClearPreview}
                variant="ghost"
                className="!w-8 !h-8 !p-0 !shadow-none hover:bg-gray-100 dark:hover:bg-gray-700 !rounded-full flex-shrink-0"
              >
                <Icon
                  name="close"
                  size={18}
                  className="text-gray-500 dark:text-gray-400"
                />
              </Button>
            )}
          </View>
        )}
        <Button
          onClick={onClickSubmit}
          isDisabled={!validateForm() || isLoadingGeneration}
          className={clsx("flex-1", generationPreview ? "max-w-xs" : "max-w-md")}
          isLoading={isLoadingGeneration}
          type="primary"
        >
          {formData.quality === "high" || formData.quality === "flash" ? "Generate Speech" : "Convert Speech"}
        </Button>
      </View>
    </View>
  );
};
