import { useState, useEffect, useRef } from "react";

import { Button } from "./base/Button";
import { Dialog } from "./base/Dialog";
import { fileActions } from "../redux/reducers/fileSlice";
import { FileField } from "./base/FileField";
import { ImageField } from "./ImageField";
import { InputField } from "./base/InputField";
import { SearchField } from "./base/SearchField";
import { SelectField } from "./base/SelectField";
import { Text } from "./base/Text";
import { useAppDispatch, useAppSelector } from "../redux";
import { View } from "./base/View";
import { voiceActions } from "../redux/reducers/voiceSlice";
import { Icon } from "./base/Icon";
import { BasicVoiceCard } from "./CardsAndCarousels";
import { AudioPlayer } from "./AudioPlayer";
import { CheckboxField } from "./base/CheckboxField";
import clsx from "clsx";

const TAG_OPTIONS = ["Characters", "Social Media", "Customer Service", "Narration", "Advertising", "Corporate", "Education", "News"] as const;

const gradients = [
  "linear-gradient(45deg, #4B5563, #6B7280)", // Subtle gray gradient
  "linear-gradient(45deg, #7C3AED, #C084FC)", // Purple gradient
  "linear-gradient(45deg, #2563EB, #60A5FA)", // Blue gradient
  "linear-gradient(45deg, #059669, #34D399)", // Green gradient
  "linear-gradient(45deg, #DC2626, #FB7185)", // Red gradient
  "linear-gradient(45deg, #D97706, #FBBF24)", // Yellow gradient
  "linear-gradient(45deg, #0891B2, #22D3EE)", // Cyan gradient
  "linear-gradient(45deg, #7C3AED, #F472B6)", // Purple-Pink gradient
  "linear-gradient(45deg, #4F46E5, #818CF8)", // Indigo gradient
];

type AddVoiceDialogProps = {
  isOpen: boolean;
  onClose: (newVoiceId?: string) => void;
  remixedFromVoiceId?: string;
  editVoiceId?: string;
  isEditMode?: boolean;
};

// Creation mode type to determine what flow to show
type CreationMode = "clone" | "design" | null;

// Debounce utility
const debounce = (fn: Function, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export const AddVoiceDialog = ({ isOpen, onClose, remixedFromVoiceId, editVoiceId, isEditMode }: AddVoiceDialogProps) => {
  const dispatch = useAppDispatch();
  const dialogRef = useRef<HTMLDivElement>(null);
  const voices = Object.values(useAppSelector((state) => state.voice.entities));
  const combinedVoices = [...voices].sort((a, b) => a.name.localeCompare(b.name));
  const editingVoice = useAppSelector((state) => (editVoiceId ? state.voice.entities[editVoiceId] : null));

  // Creation mode state
  const [creationMode, setCreationMode] = useState<CreationMode>(null);

  // Page 1 state (basic info & acoustic sample)
  const [newVoiceName, setNewVoiceName] = useState("");
  const [description, setDescription] = useState("");
  const [voiceSampleFile, setVoiceSampleFile] = useState<FileList>();
  const [isDragging, setIsDragging] = useState(false);
  const dragCounter = useRef(0);

  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [gender, setGender] = useState<"Male" | "Female" | "Neutral">("Neutral");
  const [age, setAge] = useState<"Child" | "Young Adult" | "Adult" | "Mature Adult">("Young Adult");
  const [language, setLanguage] = useState("English");
  const [dialectOrAccent, setDialectOrAccent] = useState("Neutral");
  const [isLoading, setIsLoading] = useState(false);

  // Acoustic method state:
  const [acousticSource, setAcousticSource] = useState<"remix" | "upload">(remixedFromVoiceId ? "remix" : "upload");
  const [selectedRemixedFromVoiceId, setSelectedRemixedFromVoiceId] = useState<string>(remixedFromVoiceId || "");

  // Wizard page number (0: mode selection, 1: basic info, 2: variant selection, 3: publish decision, 4: publish details)
  const [currentPage, setCurrentPage] = useState(isEditMode ? 4 : 0);

  // Page 4 state: publication details
  const [publicDescription, setPublicDescription] = useState("");
  const [isVerified, setIsVerified] = useState(false);

  // When the voice is first created, we save its details here.
  const [createdVoice, setCreatedVoice] = useState<any>(null);

  // Page 2 state: variant selection
  const [selectedVariant, setSelectedVariant] = useState<string>("");

  // Add loading messages state
  const loadingMessages = [
    "Yodeling in the Alps...",
    "Untangling vocal cords...",
    "Honey for the voice box...",
    "Voice charm school...",
    "Pronouncing supercalif...",
    "Overcoming stage fright...",
    "High notes vs low notes...",
    "Voice pep talk...",
    "No dad jokes, please...",
    "Auto-tune is not the answer...",
  ];
  const [currentLoadingMessage, setCurrentLoadingMessage] = useState(loadingMessages[0]);

  // Add useEffect for cycling through messages
  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isLoading) {
      let index = 0;
      interval = setInterval(() => {
        index = (index + 1) % loadingMessages.length;
        setCurrentLoadingMessage(loadingMessages[index]);
      }, 3000); // Change message every 2 seconds
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isLoading]);

  // Initialize state from editing voice if in edit mode
  useEffect(() => {
    if (editingVoice && isEditMode) {
      setCurrentPage(4); // Jump straight to edit page
      setPublicDescription(editingVoice.userDescription || "");
      setGender((editingVoice.userGenderDescription as "Male" | "Female" | "Neutral") || "");
      setAge((editingVoice.userAgeDescription as "Child" | "Young Adult" | "Adult" | "Mature Adult") || "");
      setLanguage(editingVoice.userLanguageDescription || "");
      setDialectOrAccent(editingVoice.userAccentDescription || "");
      setSelectedTags(editingVoice.tags ? editingVoice.tags.split(",").map((t) => t.trim()) : []);
      setCreatedVoice(editingVoice);
    }
    // If remixedFromVoiceId is provided, initialize remix mode
    if (remixedFromVoiceId) {
      setCreationMode("design");
      setAcousticSource("remix");
      setSelectedRemixedFromVoiceId(remixedFromVoiceId);
    }
  }, [editingVoice, isEditMode, remixedFromVoiceId]);

  // Reset state when dialog closes
  useEffect(() => {
    if (!isOpen) {
      resetState();
    }
  }, [isOpen]);

  // Ensure proper initialization when dialog opens with remix option
  useEffect(() => {
    if (isOpen && remixedFromVoiceId && !isEditMode) {
      setCreationMode("design");
      setAcousticSource("remix");
      setSelectedRemixedFromVoiceId(remixedFromVoiceId);
    }
  }, [isOpen, remixedFromVoiceId, isEditMode]);

  useEffect(() => {
    // Handle ResizeObserver errors
    const handleResizeError = (error: ErrorEvent) => {
      if (error.message === "ResizeObserver loop completed with undelivered notifications.") {
        error.stopImmediatePropagation();
      }
    };

    window.addEventListener("error", handleResizeError);

    // Debounced resize handler
    const debouncedResize = debounce(() => {
      if (dialogRef.current) {
        dialogRef.current.style.display = "none";
        void dialogRef.current.offsetHeight; // force reflow
        dialogRef.current.style.display = "";
      }
    }, 100);

    const resizeObserver = new ResizeObserver(debouncedResize);
    if (dialogRef.current) {
      resizeObserver.observe(dialogRef.current);
    }

    return () => {
      window.removeEventListener("error", handleResizeError);
      resizeObserver.disconnect();
    };
  }, [isOpen]);

  const canProceedToNext = () => {
    switch (currentPage) {
      case 0:
        return creationMode !== null; // Can proceed if a creation mode is selected
      case 1:
        if (creationMode === "clone") {
          return newVoiceName && voiceSampleFile && voiceSampleFile.length > 0;
        } else if (creationMode === "design") {
          return newVoiceName && description && selectedRemixedFromVoiceId !== "";
        }
        return (
          newVoiceName &&
          description &&
          (acousticSource === "upload" ? voiceSampleFile && voiceSampleFile.length > 0 : acousticSource === "remix" && selectedRemixedFromVoiceId)
        );
      case 2:
        return true;
      case 3:
        return true; // no input here
      case 4:
        return publicDescription.trim() !== "" && (isEditMode || isVerified);
      default:
        return false;
    }
  };

  const resetState = () => {
    setCreationMode(null);
    setNewVoiceName("");
    setDescription("");
    setVoiceSampleFile(undefined);
    setSelectedTags([]);
    setGender("Neutral");
    setAge("Young Adult");
    setLanguage("English");
    setDialectOrAccent("Neutral");
    setAcousticSource("upload");
    setSelectedRemixedFromVoiceId("");
    setIsLoading(false);
    setPublicDescription("");
    setIsVerified(false);
    setCreatedVoice(null);
    setSelectedVariant("");
    setCurrentPage(0);
  };

  // -------------------------
  // Render the current page's content
  const renderPageContent = () => {
    switch (currentPage) {
      case 0: // New initial page with mode selection
        return (
          <View className="space-y-4 text-center max-w-3xl mx-auto my-6">
            <View className="space-y-4">
              <Text
                variant="muted"
                className="font-semibold text-2xl"
              >
                How would you like to create your voice?
              </Text>
            </View>

            <View className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <View
                className={`p-8 border-2 rounded-xl space-y-5 cursor-pointer transition-all ${
                  creationMode === "clone"
                    ? "border-indigo-500 bg-indigo-50 dark:bg-indigo-900/20"
                    : "border-gray-200 dark:border-gray-700 hover:border-indigo-300 hover:shadow-md"
                }`}
                onClick={() => setCreationMode("clone")}
              >
                <View className="flex items-center space-x-4">
                  <View className="bg-indigo-100 dark:bg-indigo-800/40 w-14 h-14 rounded-full flex items-center justify-center">
                    <Icon
                      name="copy"
                      size={28}
                      className="text-indigo-600 dark:text-indigo-400"
                    />
                  </View>
                  <Text className="font-semibold text-xl">Clone a Voice</Text>
                </View>
                <View>
                  <Text
                    variant="muted"
                    className="text-base leading-relaxed"
                  >
                    Upload your own voice sample to create a voice that sounds just like the sample.
                  </Text>
                </View>
              </View>

              <View
                className={`p-8 border-2 rounded-xl space-y-5 cursor-pointer transition-all ${
                  creationMode === "design"
                    ? "border-indigo-500 bg-indigo-50 dark:bg-indigo-900/20"
                    : "border-gray-200 dark:border-gray-700 hover:border-indigo-300 hover:shadow-md"
                }`}
                onClick={() => setCreationMode("design")}
              >
                <View className="flex items-center space-x-4">
                  <View className="bg-indigo-100 dark:bg-indigo-800/40 w-14 h-14 rounded-full flex items-center justify-center">
                    <Icon
                      name="brush"
                      size={28}
                      className="text-indigo-600 dark:text-indigo-400"
                    />
                  </View>
                  <Text className="font-semibold text-xl">Design a Voice</Text>
                </View>
                <View>
                  <Text
                    variant="muted"
                    className="text-base leading-relaxed"
                  >
                    Create a customized voice by selecting a base voice and describing how it should speak.
                  </Text>
                </View>
              </View>
            </View>
          </View>
        );
      case 1:
        return (
          <View className="space-y-8 py-2">
            {/* Basic Information Section */}
            <View className="grid grid-cols-1 gap-8">
              <View className="space-y-6">
                <View className="space-y-2">
                  <Text
                    variant="muted"
                    className="font-semibold text-lg"
                  >
                    Name
                  </Text>
                  <InputField
                    value={newVoiceName}
                    onChange={setNewVoiceName}
                    placeholder="Enter a unique and descriptive name..."
                    className="w-full"
                  />
                </View>

                {creationMode === "design" && (
                  <View className="space-y-2">
                    <Text
                      variant="muted"
                      className="font-semibold text-lg"
                    >
                      Style: How should {newVoiceName ? <span className="text-indigo-500">{newVoiceName}</span> : "this voice"} speak?
                    </Text>
                    <InputField
                      value={description}
                      onChange={setDescription}
                      placeholder="Describe the voice how you might to a casting agent. Include accent details, tones, age, or anything else relevant."
                      className="w-full min-h-[80px]"
                      type="textarea"
                    />
                  </View>
                )}
              </View>

              {/* Voice Selection Section - Now a separate section */}
              <View className="space-y-0">
                <Text
                  variant="muted"
                  className="font-semibold text-lg"
                >
                  {creationMode === "clone" ? "Voice Sample" : "Voice Reference"}
                </Text>

                <View className="space-y-0">
                  <View className="flex flex-col md:flex-row gap-6 min-h-[320px]">
                    {creationMode === "design" && (
                      <View className="rounded-xl transition-all flex-1">
                        <View className="flex items-start gap-4">
                          <View className="space-y-3 flex-1">
                            <View>
                              <Text
                                variant="muted"
                                className="text-sm leading-relaxed"
                              >
                                Choose a voice to remix and create your variation. The AI will use this voice as a starting point for your custom creation.
                              </Text>
                            </View>
                            <View className="mt-4">
                              <SearchField
                                name="remixedFromVoiceId"
                                label="Select Base Voice"
                                options={combinedVoices.map((v, index) => ({
                                  value: v.id,
                                  label: v.name,
                                  preferredPreviewUrl: v.preferredPreviewUrl,
                                  description: v.description,
                                  gradient: gradients[index % gradients.length],
                                  gender: v.userGenderDescription,
                                  age: v.userAgeDescription,
                                  isOwner: v.isOwner,
                                  isBase: v.isBase,
                                  isCommunity: v.isCommunity,
                                  userImageUploadThumbnail: v.userImageUploadThumbnail,
                                }))}
                                placeholder="Choose a voice to remix"
                                className="w-full"
                                onChange={(value) => setSelectedRemixedFromVoiceId(value)}
                                value={selectedRemixedFromVoiceId}
                              />
                              {selectedRemixedFromVoiceId && (
                                <View className="hidden sm:block mt-6 h-[250px]">
                                  <BasicVoiceCard
                                    voice={combinedVoices.find((v) => v.id === selectedRemixedFromVoiceId)!}
                                    index={combinedVoices.findIndex((v) => v.id === selectedRemixedFromVoiceId)}
                                    onClickPlay={() => {}}
                                    isPlaying={false}
                                  />
                                </View>
                              )}
                            </View>
                          </View>
                        </View>
                      </View>
                    )}

                    {creationMode === "clone" && (
                      <View className=" rounded-xl transition-all flex-1">
                        <View className="flex items-start gap-4">
                          <View className="space-y-3 min-w-0 flex-1">
                            <View>
                              <Text
                                variant="muted"
                                className="text-sm leading-relaxed"
                              >
                                Upload the voice sample (10-20 seconds). For best results, use a clear recording with minimal background noise.
                              </Text>
                            </View>
                            <View
                              className="relative cursor-pointer w-full h-52 border-2 border-dashed rounded-xl flex items-start justify-center transition-colors hover:border-indigo-500 mt-4"
                              onDragEnter={handleDragIn}
                              onDragLeave={handleDragOut}
                              onDragOver={handleDrag}
                              onDrop={handleDrop}
                            >
                              <input
                                type="file"
                                accept="audio/*"
                                onChange={(e) => {
                                  const files = e.target.files;
                                  if (files && files[0]) {
                                    setVoiceSampleFile(files);
                                  }
                                }}
                                className="hidden"
                                id="voice-upload"
                              />
                              <label
                                htmlFor="voice-upload"
                                className="w-full h-full flex flex-col items-center justify-center"
                              >
                                <View className="bg-indigo-100 dark:bg-indigo-800/40 w-16 h-16 rounded-full flex items-center justify-center mb-4">
                                  <Icon
                                    name="upload"
                                    size={32}
                                    className="text-indigo-600 dark:text-indigo-400"
                                  />
                                </View>
                                <Text className="p-2 text-gray-500 dark:text-gray-400 text-center max-w-sm">
                                  {voiceSampleFile && voiceSampleFile.length > 0
                                    ? voiceSampleFile[0].name
                                    : isDragging
                                      ? "Drop audio file here"
                                      : "Click to upload or drag and drop audio file"}
                                </Text>
                                <Text className="text-xs text-gray-400 dark:text-gray-500">MP3, WAV, or M4A files accepted</Text>
                              </label>
                              {voiceSampleFile && (
                                <Button
                                  onClick={() => setVoiceSampleFile(undefined)}
                                  variant="ghost"
                                  className="!absolute flex !p-2 top-2 right-2 text-indigo-700 dark:text-indigo-300 hover:text-indigo-800 dark:hover:text-indigo-200"
                                >
                                  <Icon
                                    name="x"
                                    size={16}
                                  />
                                </Button>
                              )}
                            </View>
                          </View>
                        </View>
                      </View>
                    )}

                    {!creationMode && (
                      // Original content for non-creationMode (keeping it for backward compatibility)
                      // ... existing code for default acoustic options ...
                      <>
                        <View
                          className={`p-4 border-2 rounded-xl cursor-pointer transition-all flex-1 ${
                            acousticSource === "remix" ? "border-indigo-500 bg-indigo-50 dark:bg-indigo-900/20" : "border-gray-200 dark:border-gray-700"
                          }`}
                          onClick={() => setAcousticSource("remix")}
                        >
                          <View className="flex items-start gap-3">
                            <View className="space-y-1 flex-1">
                              <Text className="font-medium">Voice remix</Text>
                              <View className="space-y-1">
                                <Text
                                  variant="muted"
                                  className="text-sm"
                                >
                                  Choose a voice to remix and create your variation
                                </Text>
                              </View>
                              {acousticSource === "remix" && (
                                <View className="mt-2">
                                  <SearchField
                                    name="remixedFromVoiceId"
                                    label="Select Base Voice"
                                    options={combinedVoices.map((v, index) => ({
                                      value: v.id,
                                      label: v.name,
                                      preferredPreviewUrl: v.preferredPreviewUrl,
                                      description: v.description,
                                      gradient: gradients[index % gradients.length],
                                      gender: v.userGenderDescription,
                                      age: v.userAgeDescription,
                                      isOwner: v.isOwner,
                                      isBase: v.isBase,
                                      isCommunity: v.isCommunity,
                                      userImageUploadThumbnail: v.userImageUploadThumbnail,
                                    }))}
                                    placeholder="Choose a voice to remix"
                                    className="w-full"
                                    onChange={(value) => setSelectedRemixedFromVoiceId(value)}
                                    value={selectedRemixedFromVoiceId}
                                  />
                                  {selectedRemixedFromVoiceId && (
                                    <View className="hidden sm:block mt-4 h-[250px]">
                                      <BasicVoiceCard
                                        voice={combinedVoices.find((v) => v.id === selectedRemixedFromVoiceId)!}
                                        index={combinedVoices.findIndex((v) => v.id === selectedRemixedFromVoiceId)}
                                        onClickPlay={() => {}}
                                        isPlaying={false}
                                      />
                                    </View>
                                  )}
                                </View>
                              )}
                            </View>
                          </View>
                        </View>

                        <View
                          className={`p-4 border-2 rounded-xl cursor-pointer transition-all flex-1 ${
                            acousticSource === "upload" ? "border-indigo-500 bg-indigo-50 dark:bg-indigo-900/20" : "border-gray-200 dark:border-gray-700"
                          }`}
                          onClick={() => setAcousticSource("upload")}
                        >
                          <View className="flex items-start gap-2 sm:gap-3">
                            <View className="space-y-1 min-w-0 flex-1">
                              <Text className="font-medium">Upload sample</Text>
                              <View className="space-y-1">
                                <Text
                                  variant="muted"
                                  className="text-sm"
                                >
                                  Upload your own voice sample (10-20 seconds)
                                </Text>
                              </View>
                              {acousticSource === "upload" && (
                                <div
                                  className="relative cursor-pointer w-full sm:w-[350px] h-48 border-2 border-dashed rounded-xl flex items-center justify-center transition-colors hover:border-indigo-500"
                                  onDragEnter={handleDragIn}
                                  onDragLeave={handleDragOut}
                                  onDragOver={handleDrag}
                                  onDrop={handleDrop}
                                >
                                  <input
                                    type="file"
                                    accept="audio/*"
                                    onChange={(e) => {
                                      const files = e.target.files;
                                      if (files && files[0]) {
                                        setVoiceSampleFile(files);
                                      }
                                    }}
                                    className="hidden"
                                    id="voice-upload"
                                  />
                                  <label
                                    htmlFor="voice-upload"
                                    className="w-full h-full flex flex-col items-center justify-center"
                                  >
                                    <Icon
                                      name="upload"
                                      size={48}
                                      className="text-indigo-600 dark:text-indigo-400 mb-4"
                                    />
                                    <Text className="p-2 text-gray-400 dark:text-gray-500 text-center">
                                      {voiceSampleFile && voiceSampleFile.length > 0
                                        ? voiceSampleFile[0].name
                                        : isDragging
                                          ? "Drop audio file here"
                                          : "Click to upload or drag and drop audio file"}
                                    </Text>
                                  </label>
                                  {voiceSampleFile && (
                                    <Button
                                      onClick={() => setVoiceSampleFile(undefined)}
                                      variant="ghost"
                                      className="absolute flex !p-2 top-2 right-2 text-indigo-700 dark:text-indigo-300 hover:text-indigo-800 dark:hover:text-indigo-200"
                                    >
                                      <Icon
                                        name="x"
                                        size={16}
                                      />
                                    </Button>
                                  )}
                                </div>
                              )}
                            </View>
                          </View>
                        </View>
                      </>
                    )}
                  </View>
                </View>
              </View>
            </View>
          </View>
        );
      case 2:
        console.log("PreviewURL", createdVoice.previewUrl);
        console.log("NormalizedPreviewURL", createdVoice.normalizedPreviewUrl);
        return (
          <View className="space-y-10 my-6">
            <View className="text-center">
              <Text
                variant="muted"
                className="font-semibold text-3xl mb-3"
              >
                Which voice sounds better?
              </Text>
            </View>
            <View className="space-y-8 bg-gray-50 dark:bg-gray-800/50 rounded-xl p-8">
              <View className="space-y-6">
                {(() => {
                  if (createdVoice?.previewUrl && createdVoice?.normalizedPreviewUrl) {
                    return (
                      <>
                        <View className="grid grid-cols-1 md:grid-cols-2 gap-8">
                          {[
                            {
                              url: createdVoice.previewUrl,
                              label: "Natural",
                              description: "The original voice with natural characteristics",
                              color: "indigo",
                            },
                            {
                              url: createdVoice.normalizedPreviewUrl,
                              label: "Enhanced",
                              description: "Optimized version with balanced audio levels",
                              color: "indigo",
                            },
                          ].map(({ url, label, description, color }) => (
                            <View
                              key={url}
                              className={`p-6 rounded-xl border-2 transition-all flex flex-col h-full cursor-pointer ${
                                selectedVariant === url
                                  ? `border-${color}-500 bg-${color}-50 dark:bg-${color}-900/20`
                                  : "border-gray-200 dark:border-gray-700 hover:border-gray-300 dark:hover:border-gray-600"
                              }`}
                              onClick={() => setSelectedVariant(url)}
                            >
                              <View className="flex items-start gap-4 mb-6">
                                <View
                                  className={`mt-1 bg-${color}-100 dark:bg-${color}-800 w-5 h-5 rounded-full flex items-center justify-center shrink-0 ${selectedVariant !== url && "opacity-0"}`}
                                >
                                  <View className={`bg-${color}-500 dark:bg-${color}-400 w-3 h-3 rounded-full`}></View>
                                </View>
                                <View>
                                  <Text className="font-semibold text-lg">{label}</Text>
                                  <View>
                                    <Text
                                      variant="muted"
                                      className="text-sm leading-relaxed"
                                    >
                                      {description}
                                    </Text>
                                  </View>
                                </View>
                              </View>
                              <View className="mt-auto">
                                <AudioPlayer audioUrl={url} />
                              </View>
                            </View>
                          ))}
                        </View>
                        <View className="text-center mt-6">
                          <Text
                            variant="muted"
                            className="text-sm italic"
                          >
                            Tip: Choose the option that you feel sounds clearest and most natural
                          </Text>
                        </View>
                      </>
                    );
                  }
                  return (
                    <>
                      <Text
                        variant="muted"
                        className="text-lg font-medium text-center"
                      >
                        Here's a preview of your new voice
                      </Text>
                      <View className="bg-white dark:bg-gray-800 rounded-xl p-6 border border-gray-200 dark:border-gray-700 max-w-md mx-auto">
                        <AudioPlayer audioUrl={createdVoice?.preferredPreviewUrl} />
                      </View>
                    </>
                  );
                })()}
              </View>
            </View>
          </View>
        );
      case 3:
        return (
          <View className="space-y-4 text-center max-w-3xl mx-auto my-6">
            <View className="space-y-4">
              <Text
                variant="muted"
                className="font-semibold text-2xl"
              >
                Share your designed voice with the community?
              </Text>
              <View>
                <Text
                  variant="muted"
                  className="text-lg"
                >
                  Keep it private for your own use or share it with the community.
                </Text>
              </View>
            </View>

            <View className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <View
                className={`p-8 border-2 rounded-xl space-y-5 cursor-pointer transition-all border-gray-200 dark:border-gray-700 hover:border-indigo-300 hover:shadow-md`}
                onClick={onKeepPrivate}
              >
                <View className="flex items-center space-x-4">
                  <View className="bg-gray-100 dark:bg-gray-800 w-14 h-14 rounded-full flex items-center justify-center">
                    <Icon
                      name="lock"
                      size={28}
                      className="text-gray-600 dark:text-gray-400"
                    />
                  </View>
                  <Text className="font-semibold text-xl">Keep Private</Text>
                </View>
                <View>
                  <Text
                    variant="muted"
                    className="text-base leading-relaxed"
                  >
                    Only you will be able to use this voice. You can always publish it later.
                  </Text>
                </View>
                <Button
                  onClick={onKeepPrivate}
                  variant="ghost"
                  className="w-full mt-4 border-2 border-gray-200 hover:border-gray-300 dark:border-gray-700 dark:hover:border-gray-600 rounded-xl"
                >
                  Keep Private
                </Button>
              </View>

              <View
                className={`p-8 border-2 rounded-xl space-y-5 cursor-pointer transition-all border-indigo-500 bg-indigo-50 dark:bg-indigo-900/20`}
                onClick={() => setCurrentPage(4)}
              >
                <View className="flex items-center space-x-4">
                  <View className="bg-indigo-100 dark:bg-indigo-800/40 w-14 h-14 rounded-full flex items-center justify-center">
                    <Icon
                      name="group"
                      size={28}
                      className="text-indigo-600 dark:text-indigo-400"
                    />
                  </View>
                  <Text className="font-semibold text-xl">Publish to Community</Text>
                </View>
                <View>
                  <Text
                    variant="muted"
                    className="text-base leading-relaxed"
                  >
                    Share your voice with others and join our growing library of community voices.
                  </Text>
                </View>
                <Button
                  onClick={() => setCurrentPage(4)}
                  variant="brand"
                  className="w-full mt-4 rounded-xl"
                >
                  Continue to Publish
                </Button>
              </View>
            </View>
          </View>
        );
      case 4:
        return (
          <View className="space-y-6 py-4">
            <View className="text-center mb-8">
              <Text
                variant="muted"
                className="font-semibold text-2xl"
              >
                Complete Your Voice Details
              </Text>
              <View>
                <Text
                  variant="muted"
                  className="text-lg mt-2"
                >
                  Add metadata to help others discover and use your voice
                </Text>
              </View>
            </View>

            {/* Top section: Cover Image and Metadata */}
            <View className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
              {/* Left column: Cover Image */}
              <View className="space-y-4">
                <Text
                  variant="muted"
                  className="font-semibold text-lg"
                >
                  Add a profile image
                </Text>
                <View className="bg-gray-50 dark:bg-gray-800/50 rounded-xl p-6 border border-gray-200 dark:border-gray-700 shadow-sm">
                  <ImageField
                    voiceId={createdVoice?.id}
                    className="!aspect-square !rounded-lg !border-2 !border-dashed !border-gray-300 dark:!border-gray-600"
                  />
                  <Text className="text-xs text-gray-500 dark:text-gray-400 text-center mt-3">Recommended: A simple portrait or icon (square format)</Text>
                </View>
              </View>

              {/* Right column: Metadata Fields */}
              <View className="space-y-5">
                <Text
                  variant="muted"
                  className="font-semibold text-lg"
                >
                  Voice characteristics
                </Text>
                <View className="bg-white dark:bg-gray-800 p-6 rounded-xl border border-gray-200 dark:border-gray-700 shadow-sm">
                  <View className="grid grid-cols-2 gap-4">
                    <SelectField
                      label="Gender"
                      value={gender}
                      onChange={(value) => setGender(value as "Male" | "Female" | "Neutral")}
                      options={[
                        { value: "Male", label: "Male" },
                        { value: "Female", label: "Female" },
                        { value: "Neutral", label: "Neutral" },
                      ]}
                      className="w-full"
                    />
                    <SelectField
                      label="Age Range"
                      value={age}
                      onChange={(value) => setAge(value as "Child" | "Young Adult" | "Adult" | "Mature Adult")}
                      options={[
                        { value: "Child", label: "Child" },
                        { value: "Young Adult", label: "Young Adult" },
                        { value: "Adult", label: "Adult" },
                        { value: "Mature Adult", label: "Mature Adult" },
                      ]}
                      className="w-full"
                    />
                    <SelectField
                      label="Language"
                      value={language}
                      onChange={setLanguage}
                      options={[{ value: "English", label: "English" }]}
                      className="w-full"
                    />
                    <View className="relative">
                      <InputField
                        label="Accent/Dialect"
                        value={dialectOrAccent}
                        onChange={(value) => setDialectOrAccent(value.slice(0, 25))}
                        placeholder="e.g. 'British', 'Southern', etc."
                        className="w-full"
                      />
                      <Text
                        variant="muted"
                        className="absolute bottom-2 right-2 text-xs"
                      >
                        {dialectOrAccent.length}/25
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>

            {/* Description section - full width */}
            <View className="space-y-4 bg-blue-50/50 dark:bg-blue-900/10 p-6 rounded-xl border border-blue-100 dark:border-blue-900/20 shadow-sm mb-8">
              <Text
                variant="muted"
                className="font-semibold text-lg text-blue-800 dark:text-blue-300"
              >
                Voice description
              </Text>
              <View className="relative">
                <InputField
                  value={publicDescription}
                  onChange={(value) => setPublicDescription(value.slice(0, 200))}
                  placeholder="Describe your voice's unique qualities, character, and potential uses..."
                  className="w-full !min-h-[120px]"
                  type="textarea"
                />
                <Text
                  variant="muted"
                  className={`absolute bottom-2 right-2 text-xs ${publicDescription.length > 180 ? "text-amber-500" : ""}`}
                >
                  {publicDescription.length}/200
                </Text>
              </View>
            </View>

            {/* Tags section - full width */}
            <View className="space-y-4 bg-purple-50/50 dark:bg-purple-900/10 p-6 rounded-xl border border-purple-100 dark:border-purple-900/20 shadow-sm mb-8">
              <Text
                variant="muted"
                className="font-semibold text-lg text-purple-800 dark:text-purple-300"
              >
                Tags (select up to 3)
              </Text>
              <View className="flex flex-wrap gap-3">
                {TAG_OPTIONS.map((tag) => (
                  <button
                    key={tag}
                    type="button"
                    onClick={() => {
                      if (selectedTags.includes(tag)) {
                        setSelectedTags(selectedTags.filter((t) => t !== tag));
                      } else if (selectedTags.length < 3) {
                        setSelectedTags([...selectedTags, tag]);
                      }
                    }}
                    disabled={!selectedTags.includes(tag) && selectedTags.length >= 3}
                    className={`
                      px-5 py-2.5 rounded-full text-sm font-medium transition-all
                      ${
                        selectedTags.includes(tag)
                          ? "bg-purple-100 text-purple-700 border-2 border-purple-500 dark:bg-purple-900/40 dark:text-purple-300 dark:border-purple-700"
                          : selectedTags.length >= 3
                            ? "bg-gray-100 text-gray-400 border-2 border-transparent cursor-not-allowed dark:bg-gray-800 dark:text-gray-500"
                            : "bg-gray-100 text-gray-700 border-2 border-transparent hover:border-gray-300 dark:bg-gray-800 dark:text-gray-300 dark:hover:border-gray-600"
                      }
                    `}
                  >
                    {tag}
                  </button>
                ))}
              </View>
              <Text className="text-xs text-gray-500 dark:text-gray-400 mt-1">Tags help users discover voices for specific use cases</Text>
            </View>

            {/* Verification Section */}
            <View className="space-y-4 bg-amber-50 dark:bg-amber-900/10 border border-amber-200 dark:border-amber-800/30 rounded-xl p-6 shadow-sm">
              <Text
                variant="muted"
                className="font-semibold text-lg text-amber-800 dark:text-amber-300"
              >
                Verification
              </Text>
              <View className="flex items-start">
                <View className="shrink-0 pr-3">
                  <CheckboxField
                    value={createdVoice?.verified || isVerified}
                    onChange={setIsVerified}
                    className={clsx("mt-1", createdVoice?.verified && "opacity-50 cursor-not-allowed")}
                  />
                </View>
                <Text
                  variant="muted"
                  className="text-sm leading-relaxed"
                >
                  I verify that I have the necessary rights and permissions to publish this voice for generation purposes, and I accept responsibility for any
                  misuse. By checking this box, I acknowledge that I have obtained proper consent from the voice owner or have the legal right to use this
                  voice.
                </Text>
              </View>
            </View>
          </View>
        );
      default:
        return null;
    }
  };

  const handleDrag = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setIsDragging(true);
    }
  };

  const handleDragOut = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setIsDragging(false);
    }
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current = 0;
    setIsDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      if (file.type.startsWith("audio/")) {
        const dt = new DataTransfer();
        dt.items.add(file);
        setVoiceSampleFile(dt.files);
      }
    }
  };

  // -------------------------
  // Page 1: Create voice immediately
  const onClickCreateVoice = async () => {
    if (!canProceedToNext()) return;
    setIsLoading(true);
    try {
      let sampleUrl = "";
      if ((creationMode === "clone" || acousticSource === "upload") && voiceSampleFile) {
        sampleUrl = await dispatch(
          fileActions.uploadFile({
            type: "audio",
            file: voiceSampleFile[0],
          }),
        ).unwrap();
      }
      // Create the voice with public = false and verified = true.
      const newVoice = await dispatch(
        voiceActions.create({
          name: newVoiceName,
          description,
          fullOriginalSampleUrl: sampleUrl || undefined,
          baseVoiceId: creationMode === "design" || acousticSource === "remix" ? selectedRemixedFromVoiceId : undefined,
          tags: "", // will update later if publishing
          public: false,
          userDescription: "",
          userGenderDescription: "",
          userAgeDescription: "",
          userLanguageDescription: "",
          userAccentDescription: "",
        }),
      ).unwrap();
      setCreatedVoice(newVoice);
      setIsLoading(false);
      setCurrentPage(2);
    } catch (error) {
      setIsLoading(false);
      console.error("Failed to create voice:", error);
    }
  };

  // -------------------------
  // Page 2: Variant selection (original page 4)
  const onVariantSelectionNext = async () => {
    if (!createdVoice) return;

    try {
      await dispatch(
        voiceActions.updateById({
          id: createdVoice.id,
          preferredPreviewUrl: selectedVariant === createdVoice.normalizedPreviewUrl ? createdVoice.previewUrl : createdVoice.normalizedPreviewUrl,
          preferredSampleUrl: selectedVariant === createdVoice.normalizedSampleUrl ? createdVoice.normalizedSampleUrl : createdVoice.sampleUrl,
        }),
      ).unwrap();
    } catch (error) {
      console.error("Failed to update voice URLs:", error);
      return;
    }
    setCurrentPage(3);
  };

  // -------------------------
  // Page 3: Publish decision
  const onKeepPrivate = () => {
    // Exit the flow (voice remains private)
    onClose(createdVoice?.id);
    resetState();
  };

  // -------------------------
  // Page 4: Final publish details (original page 2, minus the toggle)
  const onFinishPublish = async () => {
    try {
      await dispatch(
        voiceActions.updateById({
          id: createdVoice.id,
          public: true,
          userDescription: publicDescription,
          userGenderDescription: gender,
          userAgeDescription: age,
          userLanguageDescription: language,
          userAccentDescription: dialectOrAccent,
          tags: selectedTags.join(", "),
        }),
      ).unwrap();
    } catch (error) {
      console.error("Failed to update voice with publish details:", error);
      return;
    }
    onClose(createdVoice?.id);
    resetState();
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => {
        onClose(undefined);
        resetState();
      }}
      title={
        isEditMode
          ? "Edit Voice Details"
          : currentPage === 0
            ? "Step 1 of 3: Voice Type"
            : currentPage === 1
              ? "Step 2 of 3: Basic Information"
              : currentPage === 2
                ? "Step 3 of 3: Voice Preview"
                : currentPage === 3
                  ? "Publication Decision"
                  : "Publish Your Voice"
      }
      className="max-w-[1050px] w-full max-h-[calc(100vh-64px)] flex flex-col"
      size="flex"
    >
      <View
        ref={dialogRef}
        className="flex flex-col h-full min-h-[500px]"
      >
        {/* Progress Bar - Only show if not in edit mode and not in publication pages */}
        {!isEditMode && currentPage >= 0 && currentPage <= 2 && (
          <View className="shrink-0 px-8 pt-4 pb-3">
            <View className="flex justify-between mb-2">
              <Text className="text-sm font-medium">
                {currentPage === 0 ? <span className="text-indigo-600 dark:text-indigo-400">Voice Type</span> : <span>Voice Type</span>}
              </Text>
              <Text className="text-sm font-medium">
                {currentPage === 1 ? <span className="text-indigo-600 dark:text-indigo-400">Basic Info</span> : <span>Basic Info</span>}
              </Text>
              <Text className="text-sm font-medium">
                {currentPage === 2 ? <span className="text-indigo-600 dark:text-indigo-400">Voice Preview</span> : <span>Voice Preview</span>}
              </Text>
            </View>
            <View className="relative w-full h-2 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
              <View
                className="h-full bg-indigo-500 transition-all duration-500 ease-in-out"
                style={{ width: currentPage === 0 ? "0%" : currentPage === 1 ? "50%" : "100%" }}
              />
              {[0, 1, 2].map((step) => {
                const stepPage = step; // Map step index to page number
                return (
                  <View
                    key={step}
                    className={`absolute h-4 w-4 rounded-full top-1/2 -translate-y-1/2 transition-all ${
                      currentPage >= stepPage ? "bg-indigo-500 border-2 border-white dark:border-gray-800" : "bg-gray-300 dark:bg-gray-600"
                    }`}
                    style={{
                      left: step === 0 ? "0%" : step === 1 ? "50%" : "100%",
                      transform: `translate(${step === 0 ? "0" : step === 1 ? "-50%" : "-100%"}, -50%)`,
                    }}
                  />
                );
              })}
            </View>
          </View>
        )}

        {/* Scrollable Content */}
        <View className="flex-1 overflow-y-auto px-8 min-h-0">
          <View className="space-y-6 py-3 max-w-[950px] mx-auto">{renderPageContent()}</View>
        </View>

        {/* Navigation Buttons */}
        <View className="shrink-0 px-8 py-5 border-t border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 mt-auto">
          <View className="flex flex-col sm:flex-row justify-between gap-4 max-w-[950px] mx-auto">
            {currentPage > 0 && currentPage !== 3 && !isEditMode && (
              <Button
                onClick={() => setCurrentPage(currentPage - 1)}
                variant="ghost"
                className="px-8 border border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700"
              >
                <View className="flex items-center gap-2">
                  <Icon
                    name="arrow-left"
                    size={18}
                    className="text-gray-600 dark:text-gray-300"
                  />
                  <Text>Back</Text>
                </View>
              </Button>
            )}
            {currentPage === 0 && (
              <Button
                onClick={() => setCurrentPage(1)}
                variant="brand"
                className="flex-1 py-2.5 sm:max-w-xs sm:ml-auto rounded-xl"
                isDisabled={!canProceedToNext()}
              >
                <View className="flex items-center justify-center gap-2">
                  <Text>Continue</Text>
                  <Icon
                    name="arrow-right"
                    size={18}
                  />
                </View>
              </Button>
            )}
            {currentPage === 1 && (
              <Button
                onClick={onClickCreateVoice}
                variant="brand"
                className="flex-1 py-2.5 sm:max-w-xs sm:ml-auto rounded-xl"
                isDisabled={!canProceedToNext()}
                isLoading={isLoading}
                loadingText={currentLoadingMessage}
              >
                <View className="flex items-center justify-center gap-2">
                  <Text>Create Voice</Text>
                  {!isLoading && (
                    <Icon
                      name="arrow-right"
                      size={18}
                    />
                  )}
                </View>
              </Button>
            )}
            {currentPage === 2 && (
              <Button
                onClick={onVariantSelectionNext}
                variant="brand"
                className="flex-1 py-2.5 sm:max-w-xs sm:ml-auto rounded-xl"
                isDisabled={!canProceedToNext() || !selectedVariant}
                isLoading={isLoading}
              >
                <View className="flex items-center justify-center gap-2">
                  <Text>Continue</Text>
                  {!isLoading && (
                    <Icon
                      name="arrow-right"
                      size={18}
                    />
                  )}
                </View>
              </Button>
            )}
            {currentPage === 3 && (
              // The publish decision buttons are in page content.
              <></>
            )}
            {currentPage === 4 && (
              <View className="flex flex-row gap-4 flex-1">
                <Button
                  onClick={async () => {
                    try {
                      await dispatch(
                        voiceActions.updateById({
                          id: createdVoice.id,
                          userDescription: publicDescription,
                          userGenderDescription: gender,
                          userAgeDescription: age,
                          userLanguageDescription: language,
                          userAccentDescription: dialectOrAccent,
                          tags: selectedTags.join(", "),
                        }),
                      ).unwrap();
                      onClose(createdVoice?.id);
                      resetState();
                    } catch (error) {
                      console.error("Failed to update voice:", error);
                    }
                  }}
                  variant="ghost"
                  className="flex-1 border border-gray-200 dark:border-gray-700 py-2.5 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-xl"
                  isLoading={isLoading}
                >
                  <View className="flex items-center justify-center gap-2">
                    <Icon
                      name="lock"
                      size={16}
                      className="text-gray-600 dark:text-gray-300"
                    />
                    <Text>Save as Private</Text>
                  </View>
                </Button>
                <Button
                  onClick={async () => {
                    try {
                      await dispatch(
                        voiceActions.updateById({
                          id: createdVoice.id,
                          public: true,
                          userDescription: publicDescription,
                          userGenderDescription: gender,
                          userAgeDescription: age,
                          userLanguageDescription: language,
                          userAccentDescription: dialectOrAccent,
                          tags: selectedTags.join(", "),
                        }),
                      ).unwrap();
                      onClose(createdVoice?.id);
                      resetState();
                    } catch (error) {
                      console.error("Failed to update voice:", error);
                    }
                  }}
                  variant="brand"
                  className="flex-1 py-2.5 rounded-xl"
                  isDisabled={!canProceedToNext() || createdVoice?.public || !isVerified}
                  isLoading={isLoading}
                >
                  <View className="flex items-center justify-center gap-2">
                    <Icon
                      name="group"
                      size={16}
                    />
                    <Text>{createdVoice?.public ? "Published" : "Publish to Community"}</Text>
                  </View>
                </Button>
              </View>
            )}
          </View>
        </View>
      </View>
    </Dialog>
  );
};
