import { CircularProgress } from "@mui/material";
import clsx from "clsx";

import { View } from "./View";
import { forwardRef } from "react";

type ButtonProps = {
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseDown?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseMove?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseUp?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
  isLoading?: boolean;
  loadingText?: string;
  isDisabled?: boolean;
  variant?: "default" | "secondary" | "outline" | "ghost" | "brand" | "icon" | "rainbowicon";
  type?: "default" | "basic" | "primary";
  href?: string;
  style?: React.CSSProperties;
  iconLeft?: React.ReactNode;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      variant = "default",
      isDisabled,
      isLoading,
      loadingText,
      onClick = () => {},
      onMouseDown = () => {},
      onMouseMove = () => {},
      onMouseUp = () => {},
      style,
      type = "default",
      iconLeft,
    },
    ref,
  ) => {
    return (
      <button
        ref={ref}
        className={clsx(
          "relative flex items-center justify-center rounded-lg transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2 dark:focus:ring-offset-gray-900",
          type !== "basic" && variant !== "icon" && variant !== "rainbowicon" && "px-6 py-3 font-semibold",
          variant === "default" && "bg-brand text-white hover:bg-brand-dark shadow-lg dark:shadow-gray-900",
          variant === "secondary" && "bg-brand/10 text-brand hover:bg-brand/20 dark:bg-brand/20 dark:text-brand-light dark:hover:bg-brand/30",
          variant === "outline" && "border border-brand/20 text-brand hover:bg-brand/10 dark:border-brand/30 dark:text-brand-light dark:hover:bg-brand/20",
          variant === "ghost" && "text-brand hover:bg-brand/10 dark:text-brand-light dark:hover:bg-brand/20",
          variant === "brand" && "bg-brand text-white hover:bg-brand-dark shadow-lg dark:shadow-gray-900",
          variant === "icon" && "w-10 h-10 rounded-full bg-brand text-white hover:bg-brand-dark shadow-lg dark:shadow-gray-900 p-0",
          variant === "rainbowicon" &&
            "w-10 h-10 rounded-full bg-gradient-to-r from-red-500 via-yellow-500 to-blue-500 hover:from-red-600 hover:via-yellow-600 hover:to-blue-600 text-white shadow-lg dark:shadow-gray-900 p-0",
          isDisabled && "opacity-50 cursor-not-allowed hover:bg-brand dark:hover:bg-brand",
          className,
        )}
        style={style}
        onClick={!isLoading ? onClick : undefined}
        onMouseDown={!isLoading ? onMouseDown : undefined}
        onMouseMove={!isLoading ? onMouseMove : undefined}
        onMouseUp={!isLoading ? onMouseUp : undefined}
        disabled={isDisabled}
      >
        {type === "basic" || variant === "icon" || variant === "rainbowicon" ? (
          children
        ) : (
          <>
            {iconLeft && <span className="mr-2">{iconLeft}</span>}
            <View className={clsx("flex items-center justify-center select-none", isLoading ? "opacity-0" : "opacity-100")}>{children}</View>
          </>
        )}
        {isLoading && (
          <View className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <View className="flex items-center gap-2">
              <CircularProgress
                className={clsx(
                  "!w-5 !h-5",
                  variant === "default" && "!text-white",
                  variant === "secondary" && "!text-brand",
                  variant === "outline" && "!text-brand",
                  variant === "ghost" && "!text-brand",
                  variant === "brand" && "!text-white",
                  variant === "icon" && "!text-white",
                  variant === "rainbowicon" && "!text-white",
                )}
                size="small"
              />
              {loadingText && (
                <span
                  className={clsx(
                    "text-sm whitespace-nowrap transition-opacity duration-200",
                    variant === "default" && "text-white",
                    variant === "secondary" && "text-brand",
                    variant === "outline" && "text-brand",
                    variant === "ghost" && "text-brand",
                    variant === "brand" && "text-white",
                    variant === "icon" && "text-white",
                    variant === "rainbowicon" && "text-white",
                  )}
                >
                  {loadingText}
                </span>
              )}
            </View>
          </View>
        )}
      </button>
    );
  },
);
