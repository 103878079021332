import { Button } from "../base/Button";
import { Text } from "../base/Text";
import { View } from "../base/View";
import { Icon } from "../base/Icon";
import { Tooltip } from "../base/Tooltip";
import { useAppDispatch, useAppSelector } from "../../redux";
import { voiceActions } from "../../redux/reducers/voiceSlice";
import { Voice } from "../../types";
import clsx from "clsx";
import { ReactComponent as MaleAdultSvg } from "../../assets/svg/male-adult.svg";
import { ReactComponent as FemaleAdultSvg } from "../../assets/svg/female-adult.svg";
import { ReactComponent as FemaleYoungAdultSvg } from "../../assets/svg/female-young-adult.svg";
import { ReactComponent as FemaleChildSvg } from "../../assets/svg/female-child.svg";
import { ReactComponent as MaleChildSvg } from "../../assets/svg/male-child.svg";
import { ReactComponent as MaleYoungAdultSvg } from "../../assets/svg/male-young-adult.svg";
import { ReactComponent as NongenderedSvg } from "../../assets/svg/nongendered.svg";
import { ReactComponent as HiddenSvg } from "../../assets/svg/hidden.svg";
import { useState, useEffect, useRef, FC } from "react";
import { AddVoiceDialog } from "../AddVoiceDialog";
import { Dialog } from "../base/Dialog";
import { createPortal } from "react-dom";
import { PlayButton } from "../base/PlayButton";

type BasicVoiceCardProps = {
  voice: Voice;
  index: number;
  onClickPlay: () => void;
  isPlaying: boolean;
  showDelete?: boolean;
  audioElement?: HTMLAudioElement | null;
};

const gradients = [
  "linear-gradient(45deg, #4B5563, #6B7280)", // Subtle gray gradient
  "linear-gradient(45deg, #7C3AED, #C084FC)", // Purple gradient
  "linear-gradient(45deg, #2563EB, #60A5FA)", // Blue gradient
  "linear-gradient(45deg, #059669, #34D399)", // Green gradient
  "linear-gradient(45deg, #DC2626, #FB7185)", // Red gradient
  "linear-gradient(45deg, #D97706, #FBBF24)", // Yellow gradient
  "linear-gradient(45deg, #0891B2, #22D3EE)", // Cyan gradient
  "linear-gradient(45deg, #7C3AED, #F472B6)", // Purple-Pink gradient
  "linear-gradient(45deg, #4F46E5, #818CF8)", // Indigo gradient
];

const getGradientColors = (gradient: string) => {
  const match = gradient.match(/linear-gradient\(45deg,\s*([^,]+),\s*([^)]+)\)/);
  return match ? [match[1].trim(), match[2].trim()] : ["#4B5563", "#6B7280"];
};

const StyledFaceIcon = ({ gradient, gender, age }: { gradient: string; gender?: string; age?: string }) => {
  const [startColor, endColor] = getGradientColors(gradient);
  const lowerGender = gender?.toLowerCase() || "";
  const lowerAge = age?.toLowerCase() || "";

  // Function to determine which icon to use based on gender and age
  const getFaceIcon = () => {
    // Male scenarios
    if (lowerGender.includes("male") && !lowerGender.includes("female")) {
      if (lowerAge.includes("mature") || lowerAge.includes("elder")) {
        return MaleAdultSvg; // Male - Mature Adult
      }
      if (lowerAge.includes("adult") && !lowerAge.includes("young")) {
        return MaleAdultSvg; // Male - Adult
      }
      if (lowerAge.includes("young")) {
        return MaleYoungAdultSvg; // Male - Young Adult
      }
      if (lowerAge.includes("child")) {
        return MaleChildSvg; // Male - Child
      }
      return MaleAdultSvg; // Default male
    }

    // Female scenarios
    if (lowerGender.includes("female")) {
      if (lowerAge.includes("mature") || lowerAge.includes("elder")) {
        return FemaleAdultSvg; // Female - Mature Adult
      }
      if (lowerAge.includes("adult") && !lowerAge.includes("young")) {
        return FemaleAdultSvg; // Female - Adult
      }
      if (lowerAge.includes("young")) {
        return FemaleYoungAdultSvg; // Female - Young Adult
      }
      if (lowerAge.includes("child")) {
        return FemaleChildSvg; // Female - Child
      }
      return FemaleYoungAdultSvg; // Default female
    }

    // Non-binary/Other scenario
    if (lowerGender.includes("non")) {
      return NongenderedSvg; // Gender: Non
    }

    // Default case
    return HiddenSvg;
  };

  const FaceIcon = getFaceIcon();
  const gradientId = `face-gradient-${startColor.replace("#", "")}`; // Create unique ID for gradient

  return (
    <View className="relative w-full h-full">
      <svg
        width="0"
        height="0"
      >
        <defs>
          <linearGradient
            id={gradientId}
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%"
          >
            <stop
              offset="20%"
              style={{ stopColor: startColor }}
            />
            <stop
              offset="80%"
              style={{ stopColor: endColor }}
            />
          </linearGradient>
        </defs>
      </svg>
      <FaceIcon
        width="100%"
        height="100%"
        style={{
          fill: `url(#${gradientId})`,
        }}
      />
    </View>
  );
};

const Badge = ({ children, color }: { children: React.ReactNode; color: string }) => (
  <View
    className={clsx(
      "flex items-center justify-center px-2 rounded-full",
      `bg-${color}-100 dark:bg-${color}-900/20`,
      `border border-${color}-200 dark:border-${color}-700/30`,
      `text-${color}-700 dark:text-${color}-300`,
    )}
  >
    <Text className="text-xs font-medium">{children}</Text>
  </View>
);

const GradientBadge = ({ children, gradient }: { children: React.ReactNode; gradient: string }) => (
  <View className={clsx("px-2 py-0.5 rounded-full", "bg-gradient-to-r", gradient, "flex items-center justify-center")}>
    <Text className="text-xs font-medium mt-1 mb-1">{children}</Text>
  </View>
);

const getAgeIcon = (ageDescription: string | undefined) => {
  if (!ageDescription) return null;
  const lowerAge = ageDescription.toLowerCase();
  if (lowerAge.includes("child")) return "childcare";
  if (lowerAge.includes("young")) return "emoji_people";
  if (lowerAge.includes("mature") || lowerAge.includes("elder")) return "elderly";
  return "man";
};

const getGenderIcon = (genderDescription: string | undefined) => {
  if (!genderDescription) return null;
  const lowerGender = genderDescription.toLowerCase();
  if (lowerGender.includes("female")) return "female";
  if (lowerGender.includes("male")) return "male";
  if (lowerGender.includes("non")) return "transgender";
  return null;
};

// Add this at the top level, outside of the component
const debounce = (fn: Function, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

const BaseVoicePreview: FC<{ voice: Voice }> = ({ voice }) => {
  return (
    <div className="flex flex-col gap-2">
      <Text className="px-4 pt-2 text-sm font-medium text-gray-600 dark:text-gray-300">This voice was remixed from:</Text>
      <BasicVoiceCard
        voice={voice}
        index={0}
        onClickPlay={() => {}}
        isPlaying={false}
      />
    </div>
  );
};

interface BaseVoiceTooltipProps {
  children: React.ReactNode;
  baseVoice: Voice | undefined;
}

const BaseVoiceTooltip: FC<BaseVoiceTooltipProps> = ({ children, baseVoice }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const triggerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (triggerRef.current && isVisible) {
      const rect = triggerRef.current.getBoundingClientRect();
      setTooltipPosition({
        top: rect.bottom + 10,
        left: rect.left + rect.width / 2,
      });
    }
  }, [isVisible]);

  if (!baseVoice) return <>{children}</>;

  return (
    <>
      <div
        ref={triggerRef}
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
      </div>
      {isVisible &&
        createPortal(
          <div
            className={clsx(
              "fixed z-[9999] w-96 transform -translate-x-1/2",
              "bg-white dark:bg-gray-800 rounded-lg shadow-lg",
              "border border-gray-200 dark:border-gray-600",
              "transition-all duration-200",
              isVisible ? "opacity-100 scale-100" : "opacity-0 scale-95",
            )}
            style={{
              top: tooltipPosition.top,
              left: tooltipPosition.left,
            }}
          >
            <BaseVoicePreview voice={baseVoice} />
          </div>,
          document.body,
        )}
    </>
  );
};

interface DeleteVoiceDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const DeleteVoiceDialog: FC<DeleteVoiceDialogProps> = ({ isOpen, onClose, onDelete }) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title="Delete Voice"
    >
      <View className="p-4 space-y-4">
        <Text className="text-gray-900 dark:text-white">Are you sure you want to delete this voice? This action cannot be undone.</Text>
        <View className="flex justify-end gap-4">
          <Button
            onClick={onClose}
            variant="ghost"
          >
            Cancel
          </Button>
          <Button
            onClick={onDelete}
            variant="default"
            className="!bg-red-500 hover:!bg-red-600"
          >
            Delete
          </Button>
        </View>
      </View>
    </Dialog>
  );
};

export const BasicVoiceCard = ({ voice, index, onClickPlay, isPlaying, showDelete, audioElement }: BasicVoiceCardProps) => {
  // Move all hooks to the top and ensure they are called unconditionally
  const dispatch = useAppDispatch();
  const baseVoice = useAppSelector((state) => (voice?.baseVoiceId ? state.voice.entities[voice.baseVoiceId] : undefined));
  const [showAddVoiceDialog, setShowAddVoiceDialog] = useState(false);
  const [dialogMode, setDialogMode] = useState<"remix" | "edit" | null>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const menuButtonRef = useRef<HTMLButtonElement>(null);

  // Remove local audio management - we'll rely on parent component
  const handlePlayPause = () => {
    onClickPlay();
  };

  // Add early return after all hooks are declared
  if (!voice) {
    return null;
  }

  useEffect(() => {
    // Handle ResizeObserver error
    const handleResizeError = (error: ErrorEvent) => {
      if (error.message === "ResizeObserver loop completed with undelivered notifications.") {
        error.stopImmediatePropagation();
      }
    };

    window.addEventListener("error", handleResizeError);

    // Cleanup
    return () => {
      window.removeEventListener("error", handleResizeError);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        menuButtonRef.current &&
        !menuButtonRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };

    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

  // Safely access properties with optional chaining and default values
  const {
    name = "",
    description = "",
    userDescription = "",
    userGenderDescription = "",
    userAgeDescription = "",
    tags = "",
    isOwner = false,
    meanf0pitch,
    meanf0pitchAutoDescription,
    flashCheckpointUrl,
    userImageUploadThumbnail,
    baseVoiceId,
    isCloned = false,
    isBase = false,
  } = voice;

  const gradient = gradients[index % gradients.length];

  return (
    <View
      ref={cardRef}
      className={clsx(
        "relative w-full h-full rounded-lg bg-gray-50 dark:bg-gray-700/50",
        "border border-gray-200 dark:border-gray-600",
        "hover:border-brand dark:hover:border-brand-light",
        "transition-all duration-200 shadow-sm hover:shadow-md",
        "flex flex-col",
      )}
    >
      <View className="p-4 flex flex-col h-full">
        {/* Header with avatar and title */}
        <View className="flex items-center space-x-3">
          <View className="w-10 h-10 rounded-lg flex-shrink-0 overflow-hidden">
            {userImageUploadThumbnail ? (
              <img
                src={userImageUploadThumbnail}
                alt={`${name} thumbnail`}
                className="w-full h-full object-cover"
              />
            ) : (
              <StyledFaceIcon
                gradient={gradient}
                gender={userGenderDescription}
                age={userAgeDescription}
              />
            )}
          </View>
          <View className="flex-1 min-w-0 overflow-hidden">
            <Tooltip content={name}>
              <View className="overflow-hidden">
                <Text
                  variant="body"
                  className="font-semibold text-lg truncate block"
                >
                  {name}
                </Text>
              </View>
            </Tooltip>
          </View>
          <View className="flex gap-2 flex-shrink-0 items-center">
            {!isBase && (
              <>
                {isCloned ? (
                  <GradientBadge gradient="from-blue-500/50 to-indigo-800/50">Cloned</GradientBadge>
                ) : (
                  <GradientBadge gradient="from-pink-500/50 to-purple-800/50">Designed</GradientBadge>
                )}
              </>
            )}
            {flashCheckpointUrl && <GradientBadge gradient="from-orange-600/50 to-yellow-400/50">Flash</GradientBadge>}
            {isOwner && (
              <div className="relative">
                <button
                  ref={menuButtonRef}
                  onClick={() => setShowDropdown((prev) => !prev)}
                  className="p-2 text-2xl font-black text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors focus:outline-none"
                >
                  ⋮
                </button>
                {showDropdown && (
                  <div
                    ref={dropdownRef}
                    className="absolute top-full right-0 mt-1 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-600 rounded-md shadow-md z-20"
                  >
                    <button
                      onClick={() => {
                        setShowDropdown(false);
                        setDialogMode("edit");
                        setShowAddVoiceDialog(true);
                      }}
                      className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 min-w-[150px] text-left"
                    >
                      Edit Details
                    </button>
                    {showDelete && (
                      <button
                        onClick={() => {
                          setShowDropdown(false);
                          setShowDeleteDialog(true);
                        }}
                        className="block w-full px-4 py-2 text-sm text-red-500 hover:bg-gray-100 dark:hover:bg-gray-700 min-w-[150px] text-left"
                      >
                        Delete Voice
                      </button>
                    )}
                  </div>
                )}
              </div>
            )}
          </View>
        </View>

        {/* Description */}
        {userDescription && (
          <Text
            variant="muted"
            className="mt-3 line-clamp-3 text-sm"
          >
            {userDescription}
          </Text>
        )}
        {/* Tags */}
        {tags && (
          <View className="mt-3 flex flex-wrap gap-1.5">
            {tags.split(",").map((tag: string) => (
              <Badge
                key={tag}
                color="brand"
              >
                {tag.trim()}
              </Badge>
            ))}
          </View>
        )}
      </View>

      {/* Actions Footer */}
      <View className="flex flex-row items-center gap-2 mt-auto p-4 justify-between border-t border-gray-200 dark:border-gray-600">
        {/* Voice Type Icons */}
        <View className="flex items-center gap-3">
          {/* Public / Private Icon*/}
          {isOwner && (
            <Tooltip content={voice.public ? "Public" : "Private"}>
              <View className="flex items-center justify-center text-gray-500">
                <Icon
                  name={voice.public ? "group" : "lock"}
                  size={20}
                />
              </View>
            </Tooltip>
          )}
          {/* Base Voice Icon */}
          {baseVoiceId && (
            <BaseVoiceTooltip baseVoice={baseVoice}>
              <View className="flex items-center justify-center text-gray-500">
                <Icon
                  name="remix"
                  size={20}
                />
              </View>
            </BaseVoiceTooltip>
          )}
          {/* Voice Direction */}
          {description && (
            <Tooltip content={`Voice Direction: ${description}`}>
              <View className="flex items-center justify-center text-gray-500">
                <Icon
                  name="campaign"
                  size={20}
                />
              </View>
            </Tooltip>
          )}
          {/* Mean f0 Pitch */}
          {meanf0pitch && (
            <Tooltip content={`Mean f0 Pitch: ${Math.round(Number(meanf0pitch))} Hz${meanf0pitchAutoDescription ? `\n(${meanf0pitchAutoDescription})` : ""}`}>
              <View className="flex items-center justify-start text-gray-500 min-w-[20px] h-[20px]">
                <Text className="text-sm font-medium text-gray-500 dark:text-gray-500">{Math.round(Number(meanf0pitch))}Hz</Text>
              </View>
            </Tooltip>
          )}
        </View>

        {/* Right-aligned actions */}
        <View className="flex flex-row items-center gap-2">
          <PlayButton
            isPlaying={isPlaying}
            onPlayPause={handlePlayPause}
            audioUrl={voice.preferredPreviewUrl}
            audioElement={audioElement}
            className={clsx()}
          />
          <Tooltip content="Remix to create a new voice">
            <Button
              type="basic"
              variant="ghost"
              onClick={() => {
                setShowDropdown(false);
                setDialogMode("remix");
                setShowAddVoiceDialog(true);
              }}
              className="!w-8 !h-8 !rounded-full flex items-center justify-center hover:bg-brand/10"
            >
              <Icon
                name="remix"
                size={16}
              />
            </Button>
          </Tooltip>
        </View>
      </View>

      <AddVoiceDialog
        key={`${dialogMode}-${dialogMode === "remix" ? voice.id : "edit"}`}
        isOpen={showAddVoiceDialog}
        onClose={() => {
          setShowAddVoiceDialog(false);
          setDialogMode(null);
        }}
        remixedFromVoiceId={dialogMode === "remix" ? voice.id : undefined}
        editVoiceId={dialogMode === "edit" ? voice.id : undefined}
        isEditMode={dialogMode === "edit"}
      />

      <DeleteVoiceDialog
        isOpen={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        onDelete={async () => {
          try {
            await dispatch(voiceActions.deleteById({ id: voice.id })).unwrap();
            setShowDeleteDialog(false);
          } catch (error) {
            console.error("Failed to delete voice:", error);
          }
        }}
      />
    </View>
  );
};
