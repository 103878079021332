import clsx from "clsx";

import { FieldLabel } from "./FieldLabel";
import { View } from "./View";

type SwitchOption = {
  label: string;
  value: string;
  isCategory?: boolean;
  category?: string;
};

type SwitchProps = {
  className?: string;
  label?: string;
  labelClassName?: string;
  options: SwitchOption[];
  value?: string;
  onChange?: (value: string) => void;
  error?: string;
  isDisabled?: boolean;
};

export const Switch = ({ className, label, options, value, onChange = () => {}, labelClassName, error, isDisabled = false }: SwitchProps) => {
  const onChangeInternal = (newValue: string) => {
    if (isDisabled) return;

    onChange(newValue);
  };

  return (
    <FieldLabel
      label={label}
      className={labelClassName}
      error={error}
    >
      <View
        className={clsx(
          "flex rounded-lg overflow-hidden",
          {
            "border border-gray-300 dark:border-gray-700": !error,
            "border border-red-500": error,
          },
          className,
        )}
      >
        {options.map((option, index) => (
          <button
            key={option.value}
            className={clsx(
              "flex-1 flex items-center justify-center py-2 px-4 text-sm font-medium transition-colors duration-200",
              "border-r last:border-r-0",
              {
                "border-gray-300 dark:border-gray-700": !error,
                "border-red-500": error,
                "bg-brand text-white hover:bg-brand-dark": value === option.value,
                "bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-300 hover:text-brand dark:hover:text-brand-light hover:bg-brand/5 dark:hover:bg-brand/10":
                  value !== option.value,
                "opacity-50 cursor-not-allowed": isDisabled,
              },
            )}
            onClick={() => onChangeInternal(option.value)}
          >
            {option.label}
          </button>
        ))}
      </View>
    </FieldLabel>
  );
};
