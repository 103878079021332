import { motion } from "framer-motion";
import { Text } from "../base/Text";
import { View } from "../base/View";
import { Button } from "../base/Button";
import { Icon } from "../base/Icon";

// Predefined gradients for agent cards
const GRADIENTS = [
  "linear-gradient(45deg, #7C3AED, #C084FC)", // Purple gradient
  "linear-gradient(45deg, #2563EB, #60A5FA)", // Blue gradient
  "linear-gradient(45deg, #059669, #34D399)", // Green gradient
  "linear-gradient(45deg, #DC2626, #FB7185)", // Red gradient
  "linear-gradient(45deg, #D97706, #FBBF24)", // Yellow gradient
  "linear-gradient(45deg, #0891B2, #22D3EE)", // Cyan gradient
  "linear-gradient(45deg, #7C3AED, #F472B6)", // Purple-Pink gradient
  "linear-gradient(45deg, #4F46E5, #818CF8)", // Indigo gradient
];

// Ghost agent card gradient
const GHOST_GRADIENT = "linear-gradient(45deg, #4B5563, #6B7280)";

// Simple hash function that converts a string to a number
const hashString = (str: string): number => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32-bit integer
  }
  return Math.abs(hash);
};

// Function to get a consistent gradient based on agent name
const getGradientForAgent = (agentName: string): string => {
  const hash = hashString(agentName);
  const index = hash % GRADIENTS.length;
  return GRADIENTS[index];
};

type AgentListItemProps = {
  id: string;
  name: string;
  description: string;
  voiceName?: string;
  phoneNumber?: string;
  onClick?: () => void;
  variant?: "grid" | "list";
  isEmpty?: boolean;
  isCreatingAgent?: boolean;
};

export const AgentListItem = ({
  id,
  name = "Untitled Agent",
  description = "No description",
  phoneNumber = "",
  voiceName,
  onClick = () => {},
  variant = "grid",
  isEmpty = false,
  isCreatingAgent = false,
}: AgentListItemProps) => {
  const gradient = isEmpty ? GHOST_GRADIENT : getGradientForAgent(id);

  // Common hover animation
  const hoverAnimation = {
    scale: isEmpty && isCreatingAgent ? 1 : 1.02,
    boxShadow: isEmpty && isCreatingAgent ? "none" : "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    transition: { duration: 0.2 },
  };

  if (variant === "list") {
    return (
      <motion.div
        whileHover={hoverAnimation}
        className={`rounded-lg overflow-hidden cursor-pointer border ${
          isEmpty
            ? "border-2 border-dashed border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-800/30"
            : "border-gray-100 dark:border-gray-700 bg-white dark:bg-gray-800"
        } ${isCreatingAgent ? "opacity-70 cursor-not-allowed" : ""}`}
        onClick={onClick}
      >
        <View className="flex items-center p-4">
          {/* Agent color indicator/avatar */}
          <View
            className="w-10 h-10 rounded-full mr-4 flex-shrink-0 flex items-center justify-center"
            style={{ background: gradient }}
          >
            {isEmpty ? (
              isCreatingAgent ? (
                <Icon
                  name="loader"
                  size={16}
                  className="text-white/90 animate-spin"
                />
              ) : (
                <Icon
                  name="plus"
                  size={16}
                  className="text-white/90"
                />
              )
            ) : (
              <Icon
                name="user"
                size={16}
                className="text-white/90"
              />
            )}
          </View>

          <View className="flex-1 min-w-0">
            <Text className="font-medium text-gray-900 dark:text-white truncate">
              {isEmpty ? (isCreatingAgent ? "Creating New Agent..." : "Create New Agent") : name}
            </Text>
            <Text className="text-sm text-gray-500 dark:text-gray-400 line-clamp-1">
              {isEmpty ? (isCreatingAgent ? "Please wait" : "Add a new AI agent with custom voice and personality") : description}
            </Text>
          </View>

          {/* Voice info or decorative elements */}
          {!isEmpty && (
            <View className="ml-4 flex items-center bg-gray-100 dark:bg-gray-700 px-3 py-1 rounded-full">
              <Icon
                name="mic"
                size={14}
                className="text-gray-500 dark:text-gray-400"
              />
              {voiceName && <Text className="text-xs text-gray-600 dark:text-gray-300 ml-2">{voiceName}</Text>}
            </View>
          )}

          {/* Action button */}
          <View className="ml-4">
            <Icon
              name="chevron-right"
              size={20}
              className="text-gray-400 dark:text-gray-500"
            />
          </View>
        </View>
      </motion.div>
    );
  }

  // Grid variant
  return (
    <motion.div
      whileHover={hoverAnimation}
      className={`relative flex flex-col rounded-xl overflow-hidden cursor-pointer border ${
        isEmpty ? "border border-dashed border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-800/30" : "border-gray-200 dark:border-gray-700"
      } h-full ${isCreatingAgent ? "opacity-70 cursor-not-allowed" : ""}`}
      onClick={onClick}
      style={{ background: gradient }}
    >
      <View className="absolute top-0 right-0 w-24 h-24 bg-white/10 rounded-bl-full -mt-8 -mr-8 backdrop-blur-sm" />

      {isEmpty && (
        <>
          <View className="absolute bottom-0 left-0 w-20 h-20 bg-white/10 rounded-tr-full -mb-6 -ml-6 backdrop-blur-sm" />
          <View className="absolute top-1/2 left-0 w-12 h-12 bg-white/5 rounded-full -ml-6 transform -translate-y-1/2" />
          <View className="absolute top-1/4 right-1/4 w-8 h-8 bg-white/5 rounded-full" />
        </>
      )}

      <View className="flex flex-col justify-between items-start p-6 z-10">
        <View className="flex-1">
          <View className="flex flex-row items-center justify-between">
            <Text className="text-xl font-bold text-white">{isEmpty ? "Create New Agent" : name}</Text>
          </View>
          <Text className="text-white text-sm mb-4 line-clamp-2 min-h-[40px]">
            {isEmpty ? "Add a new AI agent with custom voice and personality" : description}
          </Text>
        </View>

        {!isEmpty && (
          <View className="flex items-center justify-between w-full mt-4 bg-black/20 p-3 rounded-lg border border-white/10">
            <View className="flex items-center gap-2">
              <View className="flex items-center bg-white/20 rounded-full p-1.5">
                <Icon
                  name="mic"
                  size={14}
                  className="text-white"
                />
              </View>
              <View className="flex items-center gap-0.5 h-5 justify-center">
                {Array.from({ length: 10 }).map((_, i) => (
                  <View
                    key={i}
                    className="w-1 bg-white/70 rounded-full"
                    style={{
                      height: `${4 + Math.random() * 3 + Math.sin(i / 3) * 4 + Math.cos(i) * 2}px`,
                      animation: `soundWave ${0.8 + Math.random() * 0.4}s ease-in-out infinite alternate`,
                      animationDelay: `${i * 0.07}s`,
                    }}
                  />
                ))}
              </View>
            </View>
            <Text className="text-sm font-medium text-white/90">{voiceName}</Text>
          </View>
        )}
      </View>

      {isEmpty ? (
        <View className="p-5 flex flex-col justify-between flex-grow">
          <View className="flex items-center justify-center flex-grow">
            <View className="w-16 h-16 flex items-center justify-center rounded-full bg-white/20">
              {isCreatingAgent ? (
                <Icon
                  name="loader"
                  size={28}
                  className="text-white animate-spin"
                />
              ) : (
                <Icon
                  name="plus"
                  size={28}
                  className="text-white"
                />
              )}
            </View>
          </View>
          <View className="mt-4 pt-4 border-t border-gray-100 dark:border-gray-700">
            <Button
              onClick={onClick}
              type="basic"
              variant="ghost"
              className="w-full p-2 hover:bg-gray-500 dark:hover:bg-gray-700"
            >
              <Text className="text-sm font-medium mr-2 text-white">Create Agent</Text>
              <Icon
                name="chevron-right"
                size={16}
                className="text-white"
              />
            </Button>
          </View>
        </View>
      ) : (
        <View className="p-5 bg-white dark:bg-gray-800 flex-1 flex flex-col justify-between">
          {phoneNumber ? (
            <View className="flex items-center">
              <View className="bg-indigo-100 dark:bg-indigo-900/30 rounded-full p-1.5 mr-3">
                <Icon
                  name="phone"
                  size={14}
                  className="text-indigo-600 dark:text-indigo-400"
                />
              </View>
              <Text className="text-sm text-gray-700 dark:text-gray-300 font-medium">{phoneNumber}</Text>
            </View>
          ) : (
            <View className="flex items-center justify-center h-full opacity-50 py-4">
              <Text className="text-sm text-gray-500 dark:text-gray-400 italic">No additional information</Text>
            </View>
          )}
          <View className="mt-4 pt-4 border-t border-gray-100 dark:border-gray-700">
            <Button
              onClick={onClick}
              type="basic"
              variant="ghost"
              className="w-full p-2 hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              <Text className="text-sm font-medium mr-2">View Agent Details</Text>
              <Icon
                name="chevron-right"
                size={16}
                className="text-gray-800 dark:text-white"
              />
            </Button>
          </View>
        </View>
      )}
    </motion.div>
  );
};
