import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

import { agentActions, agentReducer } from "./reducers/agentSlice";
import { agentEnvironmentActions, agentEnvironmentReducer } from "./reducers/agentEnvironmentSlice";
import { agentFunctionActions, agentFunctionReducer } from "./reducers/agentFunctionSlice";
import { agentInteractionActions, agentInteractionReducer } from "./reducers/agentInteractionSlice";
import { agentRecipeActions, agentRecipeReducer } from "./reducers/agentRecipeSlice";
import { datasetActions, datasetReducer } from "./reducers/datasetSlice";
import { datasetBaseActions, datasetBaseReducer } from "./reducers/datasetBaseSlice";
import { fileReducer } from "./reducers/fileSlice";
import { generationActions, generationReducer } from "./reducers/generationSlice";
import { organizationReducer, organizationRestActions, organizationRestReducer } from "./reducers/organizationSlice";
import { organizationVariablesActions, organizationVariablesReducer } from "./reducers/organizationVariablesSlice";
import { sessionReducer } from "./reducers/sessionSlice";
import { trainingJobActions, trainingJobReducer } from "./reducers/trainingJobSlice";
import { userActions, userReducer } from "./reducers/userSlice";
import { voiceActions, voiceReducer } from "./reducers/voiceSlice";
import { featureReducer } from "./reducers/featureSlice";
import { agentEnvironmentVariableReducer } from "./reducers/agentEnvironmentVariableSlice";

export type RootState = ReturnType<typeof store.getState>;
export type EntityState = Omit<RootState, "session" | "file" | "feature">;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();

export const store = configureStore({
  reducer: {
    agent: agentReducer,
    agentEnvironment: agentEnvironmentReducer,
    agentEnvironmentVariable: agentEnvironmentVariableReducer,
    agentFunction: agentFunctionReducer,
    agentInteraction: agentInteractionReducer,
    agentRecipe: agentRecipeReducer,
    datasetBase: datasetBaseReducer,
    dataset: datasetReducer,
    feature: featureReducer,
    generation: generationReducer,
    organization: organizationReducer,
    organizationRest: organizationRestReducer,
    organizationVariables: organizationVariablesReducer,
    session: sessionReducer,
    user: userReducer,
    voice: voiceReducer,
    file: fileReducer,
    trainingJob: trainingJobReducer,
  },
});

export const getActionsByName = (entityName: keyof EntityState) => {
  switch (entityName) {
    case "agent":
      return agentActions;
    case "agentEnvironment":
      return agentEnvironmentActions;
    case "agentFunction":
      return agentFunctionActions;
    case "agentInteraction":
      return agentInteractionActions;
    case "agentRecipe":
      return agentRecipeActions;
    case "datasetBase":
      return datasetBaseActions;
    case "dataset":
      return datasetActions;
    case "generation":
      return generationActions;
    case "organizationRest":
      return organizationRestActions;
    case "organizationVariables":
      return organizationVariablesActions;
    case "user":
      return userActions;
    case "voice":
      return voiceActions;
    case "trainingJob":
      return trainingJobActions;
    default:
      throw new Error(`Unknown entity name: ${entityName}`);
  }
};
