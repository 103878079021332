import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux";
import { AgentEnvironment, agentEnvironmentActions } from "../../redux/reducers/agentEnvironmentSlice";
import { View } from "../base/View";
import { Dialog } from "../base/Dialog";
import { InputField } from "../base/InputField";
import { Button } from "../base/Button";
import { SelectField } from "../base/SelectField";

type CreateAgentEnvironmentDialogProps = {
  agentId: string;
  isOpen?: boolean;
  onClose?: () => void;
};

export const CreateAgentEnvironmentDialog = ({ agentId, isOpen = false, onClose = () => {} }: CreateAgentEnvironmentDialogProps) => {
  const dispatch = useAppDispatch();
  const [newEnvironment, setNewEnvironment] = useState<Partial<AgentEnvironment>>({});
  const isLoading = useAppSelector((state) => state.agentEnvironment.isLoadingCreate);
  const agentFunctions = useAppSelector((state) => Object.values(state.agentFunction.entities).filter((env) => env.agentId === agentId));

  const onClickCreateEnvironment = async () => {
    await dispatch(
      agentEnvironmentActions.create({
        agentId,
        name: newEnvironment.name,
        functionId: newEnvironment.functionId || "",
      }),
    );

    onClose();
    setNewEnvironment({ name: "", functionId: "" });
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title="Create New Environment"
      className="w-full max-w-2xl max-h-[90vh] overflow-y-auto"
    >
      <View className="space-y-4 p-4">
        <InputField
          label="Name"
          value={newEnvironment.name}
          onChange={(value) => setNewEnvironment({ ...newEnvironment, name: value })}
          placeholder="Enter environment name"
        />
        <SelectField
          label="Function"
          value={newEnvironment.functionId}
          placeholder="Select function"
          onChange={(value) => setNewEnvironment({ ...newEnvironment, functionId: value })}
          options={agentFunctions.map((agentFunction) => ({
            label: agentFunction.name,
            value: agentFunction.id,
          }))}
        />
        <View className="flex justify-end space-x-3">
          <Button
            variant="ghost"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="default"
            onClick={onClickCreateEnvironment}
            isLoading={isLoading}
            isDisabled={isLoading || !newEnvironment.name}
          >
            Create Environment
          </Button>
        </View>
      </View>
    </Dialog>
  );
};
