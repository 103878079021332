import { Button } from "../base/Button";
import { Text } from "../base/Text";
import { View } from "../base/View";
import { BasicVoiceCard } from "../CardsAndCarousels";
import { ProfileBaseCard } from "./ProfileBaseCard";
import { Voice } from "../../types";

type ProfileVoiceListProps = {
  voices: Voice[];
  onAddVoice: () => void;
  onClickPlay: (voiceId: string, preferredPreviewUrl: string) => void;
  isPlayingVoiceId: string;
  audioElement?: HTMLAudioElement | null;
};

export const ProfileVoiceList = ({ voices, onAddVoice, onClickPlay, isPlayingVoiceId, audioElement }: ProfileVoiceListProps) => {
  return (
    <ProfileBaseCard
      title="Voices"
      actionButton={{
        label: "Create New Voice",
        onClick: onAddVoice,
      }}
    >
      <View className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {voices.map((voice, index) => (
          <BasicVoiceCard
            key={voice.id}
            voice={voice}
            index={index}
            onClickPlay={() => onClickPlay(voice.id, voice.preferredPreviewUrl)}
            isPlaying={isPlayingVoiceId === voice.id}
            showDelete={true}
            audioElement={audioElement}
          />
        ))}
      </View>
    </ProfileBaseCard>
  );
};
