import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { fileActions } from "../redux/reducers/fileSlice";
import { voiceActions } from "../redux/reducers/voiceSlice";
import { Spinner } from "./base/Spinner";
import { View } from "./base/View";

// Add upload icon component
const UploadIcon = () => (
  <svg
    className="w-8 h-8 mb-4 text-gray-400"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
    />
  </svg>
);

export type ImageFieldProps = {
  className?: string;
  onChange?: (fileUrl: string) => void;
  children?: React.ReactNode;
  name?: string;
  voiceId?: string;
};

export const ImageField: React.FC<ImageFieldProps> = ({ className, onChange = () => {}, children, voiceId }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch<any>();
  const isLoading = useSelector((state: any) => state.file.isLoading);
  const voice = useSelector((state: any) => (voiceId ? state.voice.entities[voiceId] : null));
  const [dragActive, setDragActive] = useState(false);
  const [uploadedImage, setUploadedImage] = useState<string | null>(voice?.userImageUpload || null);

  const onClickUpload = () => {
    fileInputRef.current?.click();
  };

  const handleFile = async (file: File) => {
    try {
      const response = await dispatch(fileActions.uploadFile({ type: "images", file })).unwrap();
      onChange(response);
      setUploadedImage(response);
      if (voiceId) {
        dispatch(voiceActions.updateById({ id: voiceId, userImageUpload: response }));
      }
    } catch (error) {
      console.error("Error uploading file", error as Error);
    }
  };

  const onChangeInternal = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) await handleFile(file);
  };

  const onDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragActive(true);
  };

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragActive(false);
  };

  const onDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragActive(false);
    const file = event.dataTransfer.files?.[0];
    if (file) await handleFile(file);
  };

  const dismissImage = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setUploadedImage(null);
    onChange("");
  };

  if (uploadedImage) {
    return (
      <div className="relative inline-block">
        <img
          src={uploadedImage}
          alt="Uploaded"
          className="w-full h-auto rounded-xl"
        />
        <button
          onClick={dismissImage}
          className="absolute top-2 right-2 bg-white rounded-full p-1 shadow-md"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-gray-700"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    );
  }

  return (
    <View
      className={clsx(
        "relative group transition-all duration-300 ease-in-out",
        "min-h-[200px] p-6 rounded-xl",
        "flex flex-col items-center justify-center",
        "border-2 border-dashed",
        dragActive ? "border-blue-500 bg-blue-50 dark:bg-blue-900/20" : "border-gray-300 dark:border-gray-600 hover:border-gray-400 dark:hover:border-gray-500",
        "cursor-pointer",
      )}
      onClick={onClickUpload}
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <input
        type="file"
        accept="image/*"
        className="hidden"
        onChange={onChangeInternal}
        ref={fileInputRef}
      />

      <div className={clsx("transition-opacity duration-300", isLoading ? "opacity-0" : "opacity-100", "flex flex-col items-center")}>
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child as React.ReactElement<any>, {
              onClick: onClickUpload,
              isLoading: isLoading,
            });
          }
          return child;
        })}

        <UploadIcon />

        <div className="space-y-2 text-center">
          <p className="text-sm font-medium text-gray-700 dark:text-gray-300">{dragActive ? "Drop your image here" : "Upload an image"}</p>
          <p className="text-xs text-gray-500 dark:text-gray-400">Drag and drop or click to select</p>
          <p className="text-xs text-gray-400 dark:text-gray-500">PNG, JPG up to 10MB</p>
        </div>
      </div>

      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-white/80 dark:bg-gray-800/80 rounded-xl">
          <Spinner className="w-8 h-8 text-blue-500" />
        </div>
      )}
    </View>
  );
};
