import { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { FC } from "react";
import clsx from "clsx";

import { Button } from "../../components/base/Button";
import { Generation, generationActions } from "../../redux/reducers/generationSlice";
import { Icon } from "../../components/base/Icon";
import { Text } from "../../components/base/Text";
import { useAppDispatch, useAppSelector } from "../../redux";
import { View } from "../../components/base/View";
import { Voice } from "../../types";
import { voiceActions } from "../../redux/reducers/voiceSlice";
import { SearchField } from "../../components/base/SearchField";
import { AddVoiceDialog } from "../../components/AddVoiceDialog";
import { Dialog } from "../../components/base/Dialog";
import { useNavigation } from "../../hooks/useNavigation";
import { TutorialOverlay } from "../../components/TutorialOverlay";
import { useSearchParam } from "../../hooks/useSearchParam";
import { MinimalVoiceCard } from "../../components/CardsAndCarousels";

// Import our new components
import { ModelSelector, SpeechGenerationOptions, VoiceConversionOptions, StickyFooter, GenerationHistorySidebar } from "../../components/CreatePageComponents";

type GenerationWithModel = Generation & {
  voiceName: string;
  model: string;
};

const gradients = [
  "linear-gradient(45deg, #4B5563, #6B7280)", // Subtle gray gradient
  "linear-gradient(45deg, #7C3AED, #C084FC)", // Purple gradient
  "linear-gradient(45deg, #2563EB, #60A5FA)", // Blue gradient
  "linear-gradient(45deg, #059669, #34D399)", // Green gradient
  "linear-gradient(45deg, #DC2626, #FB7185)", // Red gradient
  "linear-gradient(45deg, #D97706, #FBBF24)", // Yellow gradient
  "linear-gradient(45deg, #0891B2, #22D3EE)", // Cyan gradient
  "linear-gradient(45deg, #7C3AED, #F472B6)", // Purple-Pink gradient
  "linear-gradient(45deg, #4F46E5, #818CF8)", // Indigo gradient
];

const BaseVoicePreview: FC<{ voice: Voice }> = ({ voice }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const handlePlayPause = () => {
    if (!audioRef.current) {
      audioRef.current = new Audio(voice.preferredPreviewUrl);
      audioRef.current.addEventListener("ended", () => {
        setIsPlaying(false);
      });
    }

    if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
      }
    };
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <Text className="px-4 pt-2 text-sm font-medium text-gray-600 dark:text-gray-300">This voice was remixed from:</Text>
      <MinimalVoiceCard
        voice={voice}
        index={0}
        onClickPlay={handlePlayPause}
        isPlaying={isPlaying}
        audioElement={audioRef.current}
      />
    </div>
  );
};

export const CreatePage = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const voiceId = useSearchParam("voiceId");
  const text = useSearchParam("text");
  const notes = useSearchParam("notes");
  const isLoadingGeneration = useAppSelector((state) => state.generation.isLoadingCreate);
  const voices = Object.values(useAppSelector((state) => state.voice.entities));
  const generations = Object.values(useAppSelector((state) => state.generation.entities));
  const combinedVoices = [...voices].sort((a, b) => a.name.localeCompare(b.name)) as Voice[];
  const [generationPreview, setGenerationPreview] = useState<Generation | null>(null);
  const [formData, setFormData] = useState<Partial<Generation>>({
    quality: "high",
    voiceId,
    text: text || "",
    notes: notes || undefined,
  });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [showAddVoiceDialog, setShowAddVoiceDialog] = useState(false);
  const [showInsufficientBalanceDialog, setShowInsufficientBalanceDialog] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);
  const [currentTutorialStep, setCurrentTutorialStep] = useState(0);
  const [searchParams] = useSearchParams();
  const [showGenerationList, setShowGenerationList] = useState(false);
  const [useVoiceStyle, setUseVoiceStyle] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [selectedVoiceIsPlaying, setSelectedVoiceIsPlaying] = useState(false);

  const directableRef = useRef<HTMLDivElement>(null);
  const basicRef = useRef<HTMLDivElement>(null);
  const voiceConversionRef = useRef<HTMLDivElement>(null);
  const voiceStyleRef = useRef<HTMLDivElement>(null);
  const voiceGenerationRef = useRef<HTMLDivElement>(null);
  const genHistoryRef = useRef<HTMLDivElement>(null);

  // Load voices when component mounts
  useEffect(() => {
    const loadVoices = async () => {
      try {
        await dispatch(voiceActions.get({})).unwrap();
      } catch (error) {
        console.error("Failed to load voices:", error);
      }
    };
    loadVoices();
  }, [dispatch]);

  const tutorialSteps = [
    {
      voiceId: "f96d4606-da6e-4fe2-ac61-c0e3977b8893",
      message:
        "Howdy, y'all! Welcome to Prim Voices! Me and my pals are fixin' to give ya a little tour on how to wrangle this here platform, so you can rustle up some mighty fine speech, just like me!",
      audioUrl: "https://api.primvoices.com/v1/files/audio/4807e2ce-293e-42a7-8489-2b2b3ff76f40.wav",
    },
    {
      targetRef: directableRef,
      voiceId: "292acc0d-d896-46d3-a245-0434a091ea8e",
      message:
        "Let's be quiet… This… is our Studio model. It's special because it's not saying words… It knows. It understands context. And… in the advanced settings… you can even give it line direction. Sometimes… it messes up… but… it's one-of-a-kind…",
      audioUrl: "https://api.primvoices.com/v1/files/audio/bab057c1-c24c-40aa-9e7e-729e6e44fa74.wav",
    },
    {
      targetRef: basicRef,
      voiceId: "7fa5041e-e0f1-4b43-8572-aef86181e8d5",
      message:
        "Ah-ha-ha! Behold! Our Flash model—it's crazy fast! Not every voice is ready yet, but oh-ho-ho, just you wait! Our collection of Flash voices will grow—oh yes, it will! And the best part? You get crystal-clear audio, lightning-fast, and for a price so low, it's practically madness! MWAHAHA!",
      audioUrl: "https://api.primvoices.com/v1/files/audio/35e80abf-9b0e-46b0-9acb-f1a2f275aba3.wav",
    },
    {
      targetRef: voiceConversionRef,
      voiceId: "f5556b34-436f-4314-bbe5-4b636741c067",
      message:
        "Ah, yes! The Voice Changer—a marvel of modern technology! Simply provide an audio clip, and watch in astonishment as it transforms to match the voice of your choosing. Quite extraordinary, wouldn't you say?",
      audioUrl: "https://api.primvoices.com/v1/files/audio/2ba7fb5a-868c-401e-bbde-7d30135ac202.wav",
    },
    {
      targetRef: voiceGenerationRef,
      voiceId: "025e8d11-6bd5-44a3-b73c-2ef5730afb24",
      message:
        "This… this is where you set up your generation. Pick a voice. Tell it what to say. Simple, right? In fact… that's exactly how this very speech came to be. Ain't that somethin'?",
      audioUrl: "https://api.primvoices.com/v1/files/audio/9cb14825-ece8-4ca7-8171-14ad0dc4019f.wav",
    },
    {
      targetRef: genHistoryRef,
      voiceId: "85e381fc-3af9-4e62-8cf0-740d592f34f6",
      audioUrl: "https://api.primvoices.com/v1/files/audio/e4e25cb8-789b-4a53-8c16-0f74da44f133.wav",
      message:
        "Yay~! Over heeere, you can find all your past generations! If you ever wanna go back and listen to one again—no worries! It's all saved just for you! Teehee~!",
    },
    {
      voiceId: "3575ae4c-e60f-4695-b05d-881a6ed8cb82",
      audioUrl: "https://api.primvoices.com/v1/files/audio/ae27e35c-cbb3-446d-8065-5cb4db17f0fc.wav",
      message:
        "And at last, my dear… go forth and explore our voice library. As you master the craft, you may even begin to conjure voices of your very own. Hehehe… the magic is in your hands now!",
    },
  ];

  const validateForm = () => {
    if (formData.quality === "voice" || formData.quality === "voicestyle") {
      return !!(formData.voiceId && formData.sourceUrl);
    }

    if (formData.quality === "high") {
      return !!(formData.voiceId && formData.text);
    }

    return !!(formData.voiceId && formData.text);
  };

  const onClickAddVoice = () => {
    setShowAddVoiceDialog(true);
  };

  const onClickSubmit = async () => {
    setGenerationPreview(null);

    try {
      let dataToSubmit: Partial<Generation> = {};

      if (formData.quality === "voice" || formData.quality === "voicestyle") {
        dataToSubmit = {
          quality: formData.quality,
          voiceId: formData.voiceId,
          sourceUrl: formData.sourceUrl,
        };
      } else if (formData.quality === "high") {
        dataToSubmit = {
          quality: formData.quality,
          voiceId: formData.voiceId,
          text: formData.text,
          notes: formData.notes,
        };
      } else if (formData.quality === "echo") {
        dataToSubmit = {
          quality: formData.quality,
          voiceId: formData.voiceId,
          text: formData.text,
        };
      } else {
        // For "flash" quality
        dataToSubmit = {
          quality: formData.quality,
          voiceId: formData.voiceId,
          text: formData.text,
        };
      }

      const response = await dispatch(generationActions.create(dataToSubmit)).unwrap();

      if (response) {
        setGenerationPreview(response);
      }
    } catch (error: any) {
      if (error?.message?.includes("Insufficient balance")) {
        setShowInsufficientBalanceDialog(true);
      }
    }
  };

  const onCloseVoiceDialog = (newVoiceId?: string) => {
    setShowAddVoiceDialog(false);

    if (newVoiceId) {
      setFormData({ ...formData, voiceId: newVoiceId });
    }
  };

  const onClickGoToProfile = () => {
    navigation.navigate("/profile");
    setShowInsufficientBalanceDialog(false);
  };

  const onClickDownloadHandler = (audioUrl: string) => {
    window.open(audioUrl, "_blank");
  };

  const handleVoicePlayPause = () => {
    if (!audioRef.current) {
      const selectedVoice = combinedVoices.find((v) => v.id === formData.voiceId);
      if (selectedVoice?.preferredPreviewUrl) {
        audioRef.current = new Audio(selectedVoice.preferredPreviewUrl);
        audioRef.current.addEventListener("ended", () => {
          setSelectedVoiceIsPlaying(false);
        });
      }
    }

    if (selectedVoiceIsPlaying) {
      audioRef.current?.pause();
      setSelectedVoiceIsPlaying(false);
    } else {
      audioRef.current?.play();
      setSelectedVoiceIsPlaying(true);
    }
  };

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
      }
    };
  }, []);

  useEffect(() => {
    if (formData.voiceId && !combinedVoices.find((v) => v.id === formData.voiceId)) {
      setFormData((prev) => ({ ...prev, voiceId: undefined }));
    }
  }, [formData.voiceId, combinedVoices]);

  useEffect(() => {
    const hasSeenTutorial = localStorage.getItem("hasSeenTutorial");
    if (hasSeenTutorial !== "true") {
      // Only show tutorial on desktop screens
      if (window.innerWidth >= 768) {
        navigation.navigate("/create", { tutorial: "true" });
      } else {
        // Mark tutorial as seen on mobile to prevent it from showing later
        localStorage.setItem("hasSeenTutorial", "true");
      }
    }
  }, []);

  useEffect(() => {
    const isTutorial = searchParams.get("tutorial") === "true";
    // Only show tutorial on desktop screens
    setShowTutorial(isTutorial && window.innerWidth >= 768);
    if (isTutorial && window.innerWidth >= 768) {
      localStorage.setItem("hasSeenTutorial", "true");
      setCurrentTutorialStep(0);
      setFormData((prev) => ({ ...prev, quality: "high" }));
      // Load voices if they haven't been loaded yet
      dispatch(voiceActions.get({}));
    }
  }, [searchParams]);

  // Update formData quality when Voice Style checkbox changes
  useEffect(() => {
    if (formData.quality === "voice" || formData.quality === "voicestyle") {
      setFormData((prev) => ({
        ...prev,
        quality: useVoiceStyle ? "voicestyle" : "voice",
      }));
    }
  }, [useVoiceStyle]);

  return (
    <View
      className="w-screen h-screen relative overflow-hidden"
      style={{ "--footer-height": "calc(2rem + 56px)" } as React.CSSProperties}
    >
      {/* Left sidebar with model selection - fixed position */}
      <View className="fixed pt-[4.5rem] top-0 left-0 h-full hidden md:block z-10">
        <ModelSelector
          directableRef={directableRef}
          basicRef={basicRef}
          voiceConversionRef={voiceConversionRef}
          onChange={(value) => {
            // If selecting voice mode, respect the current useVoiceStyle state
            if (value === "voice") {
              setFormData({ ...formData, quality: useVoiceStyle ? "voicestyle" : "voice" });
            } else {
              setFormData({ ...formData, quality: value });
            }
          }}
          activeMode={formData.quality}
        />
      </View>

      {/* Main content area - scrollable with padding for fixed sidebars */}
      <View className={clsx("h-screen overflow-y-auto md:pl-72", showGenerationList ? "md:pr-72" : "pr-3 md:pr-12")}>
        <View className="p-8 pb-[calc(var(--footer-height)+1rem)] bg-gray-50 dark:bg-transparent min-h-screen">
          <View className="flex flex-col max-w-4xl mx-auto">
            {/* Mobile model selector */}
            <ModelSelector
              directableRef={directableRef}
              basicRef={basicRef}
              voiceConversionRef={voiceConversionRef}
              onChange={(value) => {
                // If selecting voice mode, respect the current useVoiceStyle state
                if (value === "voice") {
                  setFormData({ ...formData, quality: useVoiceStyle ? "voicestyle" : "voice" });
                } else {
                  setFormData({ ...formData, quality: value });
                }
              }}
              activeMode={formData.quality}
              isMobile
            />

            {/* Main generation panel */}
            <View
              ref={voiceGenerationRef}
              className="flex flex-1 flex-col bg-white dark:bg-slate-800 bg-opacity-95 dark:bg-opacity-50 backdrop-blur-lg rounded-xl p-6 shadow-lg relative z-0"
            >
              {/* Voice selection */}
              <View className="flex flex-col gap-2 mb-4">
                <SearchField
                  name="voiceId"
                  label="Select Voice"
                  options={combinedVoices
                    .filter((voice) => {
                      if (formData.quality === "flash") return voice.isFlashEnabled;
                      return true;
                    })
                    .map((v, index) => ({
                      value: v.id,
                      label: v.name,
                      preferredPreviewUrl: v.preferredPreviewUrl,
                      description: v.userDescription,
                      gradient: gradients[index % gradients.length],
                      gender: v.userGenderDescription,
                      age: v.userAgeDescription,
                      isOwner: v.isOwner,
                      isBase: v.isBase,
                      isCommunity: v.isCommunity,
                      userImageUploadThumbnail: v.userImageUploadThumbnail,
                    }))}
                  placeholder="Choose a voice"
                  className="flex-1 flex-grow"
                  onClickAddOption={onClickAddVoice}
                  onChange={(value) => setFormData({ ...formData, voiceId: value })}
                  value={formData.voiceId}
                  showAddOption
                />
                {formData.voiceId && (
                  <View className="w-full">
                    <audio
                      ref={audioRef}
                      src={combinedVoices.find((v) => v.id === formData.voiceId)?.preferredPreviewUrl}
                    />
                    {combinedVoices.find((v) => v.id === formData.voiceId) ? (
                      <MinimalVoiceCard
                        voice={combinedVoices.find((v) => v.id === formData.voiceId)!}
                        index={combinedVoices.findIndex((v) => v.id === formData.voiceId)}
                        onClickPlay={handleVoicePlayPause}
                        isPlaying={selectedVoiceIsPlaying}
                        audioElement={audioRef.current}
                      />
                    ) : (
                      <View className="w-full h-full flex items-center justify-center border border-gray-200 dark:border-gray-700 rounded-lg">
                        <Text className="text-gray-500 dark:text-gray-400">Selected voice no longer exists</Text>
                      </View>
                    )}
                  </View>
                )}
              </View>

              {/* Conditional rendering based on selected mode */}
              {formData.quality === "voice" || formData.quality === "voicestyle" ? (
                <VoiceConversionOptions
                  formData={formData}
                  setFormData={setFormData}
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  useVoiceStyle={useVoiceStyle}
                  setUseVoiceStyle={setUseVoiceStyle}
                  voiceConversionRef={voiceConversionRef}
                />
              ) : (
                <SpeechGenerationOptions
                  formData={formData}
                  setFormData={setFormData}
                  selectedVoice={combinedVoices.find((v) => v.id === formData.voiceId)}
                  selectedVoiceIsPlaying={selectedVoiceIsPlaying}
                  handleVoicePlayPause={handleVoicePlayPause}
                  audioRef={audioRef}
                  voiceGenerationRef={voiceGenerationRef}
                />
              )}
            </View>
          </View>
        </View>
      </View>

      {/* Right sidebar with generation history - fixed position */}
      <View className="pt-[4.5rem] fixed top-0 right-0 h-full z-10 hidden md:block">
        <GenerationHistorySidebar
          showGenerationList={showGenerationList}
          setShowGenerationList={setShowGenerationList}
          onDownload={onClickDownloadHandler}
          onGenerateWithConfig={(generation) => {
            setFormData({
              quality: generation.quality,
              voiceId: generation.voiceId,
              text: generation.text,
              notes: generation.notes,
            });
          }}
          genHistoryRef={genHistoryRef}
        />
      </View>

      {/* Sticky footer */}
      <StickyFooter
        onClickSubmit={onClickSubmit}
        isLoadingGeneration={isLoadingGeneration}
        formData={formData}
        validateForm={validateForm}
        showGenerationList={showGenerationList}
        generationPreview={generationPreview}
        onDownload={onClickDownloadHandler}
        onClearPreview={() => setGenerationPreview(null)}
      />

      {/* Dialogs and overlays */}
      {showAddVoiceDialog && (
        <AddVoiceDialog
          isOpen={showAddVoiceDialog}
          onClose={onCloseVoiceDialog}
        />
      )}

      {showTutorial && (
        <TutorialOverlay
          isVisible={showTutorial}
          steps={tutorialSteps}
          currentStep={currentTutorialStep}
          onNextStep={() => setCurrentTutorialStep((prev) => Math.min(prev + 1, tutorialSteps.length - 1))}
          onPreviousStep={() => setCurrentTutorialStep((prev) => Math.max(prev - 1, 0))}
          onClose={() => {
            setShowTutorial(false);
            navigation.navigate("/create");
          }}
        />
      )}

      {showInsufficientBalanceDialog && (
        <Dialog
          isOpen={showInsufficientBalanceDialog}
          title="Insufficient Balance"
          onClose={() => setShowInsufficientBalanceDialog(false)}
        >
          <Text>You don't have enough credits to create this generation. Please top up your balance.</Text>
          <View className="flex flex-row justify-end gap-2 mt-4">
            <Button
              type="basic"
              onClick={() => setShowInsufficientBalanceDialog(false)}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={onClickGoToProfile}
            >
              Top Up
            </Button>
          </View>
        </Dialog>
      )}
    </View>
  );
};
