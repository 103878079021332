import { createRestSlice } from "./restSlice";

export type AgentInteractionEvent = {
  name: string;
  data: Record<string, unknown>;
};

export type AgentInteraction = {
  id: string;
  agentId: string;
  events: AgentInteractionEvent[];
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
};

const agentInteractionSlice = createRestSlice<AgentInteraction>("agentInteractions", "/v1/agents/:agentId/interactions");

export const agentInteractionReducer = agentInteractionSlice.reducer;
export const agentInteractionActions = agentInteractionSlice.actions;
