import { SvgIconTypeMap } from "@mui/material";
import {
  Add,
  ArrowBackRounded,
  ArrowDownwardRounded,
  ArrowUpwardRounded,
  Boy,
  Man,
  Campaign,
  CheckCircle,
  CheckOutlined,
  CheckRounded,
  ChevronRightRounded,
  ChevronLeftRounded,
  CloseOutlined,
  CloseRounded,
  ContentCopyOutlined,
  Create,
  DeleteOutlined,
  DeleteRounded,
  Download,
  Edit,
  ErrorOutlined,
  Folder,
  ImageOutlined,
  InfoOutlined,
  Lock,
  Logout,
  MenuRounded,
  Mic,
  MusicNote,
  PauseRounded,
  Person,
  PlayArrowRounded,
  QuestionMarkOutlined,
  SearchOutlined,
  Stop,
  Upload,
  VisibilityOffOutlined,
  VisibilityOutlined,
  DarkMode,
  LightMode,
  AutoFixHigh,
  KeyboardVoice,
  ReplayRounded,
  RecordVoiceOver,
  Groups,
  ViewInAr,
  AdminPanelSettings,
  Male,
  Female,
  Transgender,
  Elderly,
  EmojiPeople,
  ChildCare,
  SwitchAccessShortcutAdd,
  Group,
  Star,
  StarOutline,
  ArrowRightRounded,
  ArrowLeftRounded,
  Brush,
  ContentCopy,
  WarningAmber,
  EditNote,
  Phone,
  MicOff,
  MailOutlined,
  BarChart,
  MessageOutlined,
  AccessTimeOutlined,
  TrendingUpOutlined,
  Inbox,
  BuildOutlined,
  ShowChartOutlined,
  HelpOutlineOutlined,
  ErrorOutlineOutlined,
  VolumeUpOutlined,
  WifiOutlined,
  SendOutlined,
  EditOutlined,
  Storage,
  AutoAwesome,
  Refresh,
  Bolt,
  Psychology,
  SmartToy,
  GridView,
  List,
  AccountTreeOutlined,
  RocketLaunchOutlined,
  Home,
  Key,
  Mail,
  SoupKitchen,
  Keyboard,
  KeyboardHide,
  VolumeMute,
  MoreVertOutlined,
} from "@mui/icons-material";

import { memoize } from "../../utils/performance";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Style } from "../../utils/types";

const icons: Record<string, OverridableComponent<SvgIconTypeMap<{}, "svg">>> = {
  "account-tree": AccountTreeOutlined,
  "add-voice": KeyboardVoice,
  "alert-circle": ErrorOutlineOutlined,
  "arrow-back": ArrowBackRounded,
  "arrow-down": ArrowDownwardRounded,
  "arrow-left": ArrowLeftRounded,
  "arrow-right": ArrowRightRounded,
  "arrow-up": ArrowUpwardRounded,
  "bar-chart-2": BarChart,
  "check-circle": CheckCircle,
  "checkmark-outline": CheckOutlined,
  "chevron-down": ChevronRightRounded,
  "chevron-left": ChevronLeftRounded,
  "chevron-right": ChevronRightRounded,
  "chevron-up": ChevronLeftRounded,
  "content-copy-outline": ContentCopyOutlined,
  "delete-outline": DeleteOutlined,
  "edit-2": EditOutlined,
  "edit-note": EditNote,
  "error-outline": ErrorOutlined,
  "eye-outline": VisibilityOutlined,
  "eye-slash-outline": VisibilityOffOutlined,
  "help-circle": HelpOutlineOutlined,
  "info-outline": InfoOutlined,
  "keyboard-off": KeyboardHide,
  "magic-wand": AutoFixHigh,
  "message-circle": MessageOutlined,
  "message-square": MessageOutlined,
  "mic-off": MicOff,
  "play-arrow": PlayArrowRounded,
  "question-mark": QuestionMarkOutlined,
  "trending-up": TrendingUpOutlined,
  "voice-over": RecordVoiceOver,
  "volume-up": VolumeUpOutlined,
  "warning-amber": WarningAmber,
  activity: ShowChartOutlined,
  add: Add,
  admin_panel_settings: AdminPanelSettings,
  base: ViewInAr,
  bot: SmartToy,
  boy: Boy,
  brain: Psychology,
  brush: Brush,
  campaign: Campaign,
  check: CheckRounded,
  childcare: ChildCare,
  clock: AccessTimeOutlined,
  close: CloseOutlined,
  community: Groups,
  copy: ContentCopy,
  create: Create,
  download: Download,
  edit: Edit,
  elderly: Elderly,
  emoji_people: EmojiPeople,
  female: Female,
  folder: Folder,
  grid: GridView,
  group: Group,
  home: Home,
  image: ImageOutlined,
  inbox: Inbox,
  info: InfoOutlined,
  invite: Mail,
  key: Key,
  keyboard: Keyboard,
  list: List,
  loader: Refresh,
  lock: Lock,
  logout: Logout,
  mail: MailOutlined,
  male: Male,
  man: Man,
  menu: MenuRounded,
  mic: Mic,
  moon: DarkMode,
  music: MusicNote,
  mute: VolumeMute,
  pause: PauseRounded,
  person: Person,
  phone: Phone,
  play: PlayArrowRounded,
  plus: Add,
  recipe: SoupKitchen,
  refresh: ReplayRounded,
  remix: SwitchAccessShortcutAdd,
  rocket: RocketLaunchOutlined,
  search: SearchOutlined,
  send: SendOutlined,
  server: Storage,
  sparkles: AutoAwesome,
  star: StarOutline,
  stop: Stop,
  sun: LightMode,
  tool: BuildOutlined,
  transgender: Transgender,
  trash: DeleteRounded,
  "trash-outline": DeleteOutlined,
  "more-vertical": MoreVertOutlined,
  upload: Upload,
  user: Person,
  users: Groups,
  wifi: WifiOutlined,
  x: CloseRounded,
  zap: Bolt,
};

const styles: Record<string, Style> = {
  "chevron-down": { transform: "rotate(90deg)" },
  "chevron-up": { transform: "rotate(90deg)" },
  admin_panel_settings: {
    background: "linear-gradient(45deg, #ff0000, #ff8000, #ffff00, #00ff00, #00ffff, #0000ff, #8000ff)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    color: "white",
  },
};

interface IconProps {
  name: string;
  size?: number;
  className?: string;
}

export const Icon = memoize(({ name, size = 24, className }: IconProps) => {
  const Component = icons[name] || QuestionMarkOutlined;

  return (
    <Component
      className={className}
      style={{
        fontSize: size,
        ...styles[name],
      }}
    />
  );
});
