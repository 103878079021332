import { motion } from "framer-motion";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

import { Agent } from "../../redux/reducers/agentSlice";
import { useAppDispatch, useAppSelector } from "../../redux";
import { View } from "../../components/base/View";
import { Form } from "../../components/base/Form";
import { InputField } from "../../components/base/InputField";
import { SearchField } from "../../components/base/SearchField";
import { voiceActions } from "../../redux/reducers/voiceSlice";
import { featureActions } from "../../redux/reducers/featureSlice";

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.4 },
  },
};

export const AgentDetailsPage = () => {
  const dispatch = useAppDispatch();
  const { agentId } = useParams<{ agentId: string }>();
  const voices = useAppSelector((state) => Object.values(state.voice.entities));
  const agent = useAppSelector((state) => (agentId ? state.agent.entities[agentId] : null));
  const formData = useAppSelector((state) => state.feature.agentDetailsFormData);

  const onChange = (formData: Partial<Agent>) => {
    dispatch(featureActions.setAgentDetailsFormData(formData));
  };

  useEffect(() => {
    if (agent) {
      dispatch(featureActions.setAgentDetailsFormData(agent));
    }
  }, [agent]);

  useEffect(() => {
    dispatch(voiceActions.get({}));
  }, [agentId]);

  return (
    <View className="relative flex flex-row h-full overflow-hidden">
      <View className="flex flex-col flex-1 px-4 h-full overflow-hidden">
        <motion.div
          className="flex flex-col flex-1"
          variants={itemVariants}
        >
          <Form<Partial<Agent>>
            data={formData}
            onChange={onChange}
            className="flex-1 flex-col justify-between p-6"
          >
            <View className="flex flex-col gap-6">
              <InputField
                name="name"
                label="Name"
                placeholder="Enter agent name"
              />
              <InputField
                name="description"
                label="Description"
                placeholder="Enter agent description"
                type="textarea"
              />
              <SearchField
                name="defaultVoiceId"
                label="Default Voice"
                placeholder="Select default voice"
                options={voices.map((voice) => ({
                  label: voice.name,
                  value: voice.id,
                }))}
              />
            </View>
          </Form>
        </motion.div>
      </View>
    </View>
  );
};
