import { useState } from "react";
import GoogleButton from "react-google-button";

import { Button } from "../components/base/Button";
import { Text } from "../components/base/Text";
import { sessionActions } from "../redux/reducers/sessionSlice";
import { useAppDispatch, useAppSelector } from "../redux";
import { useNavigation } from "../hooks/useNavigation";
import { useApiRequest } from "../hooks/useApiRequest";
import { AuthLayout } from "../components/auth/AuthLayout";
import { SignUpForm } from "../components/auth/SignUpForm";
import { encodeState } from "../utils/parse";
import { View } from "../components/base/View";
import { GOOGLE_OAUTH_URL } from "../config";

export const SignUpPage = () => {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const createAccountApiRequest = useApiRequest();
  const [showEmailForm, setShowEmailForm] = useState(false);
  const invite = useAppSelector((state) => state.session.invite);

  const onSubmit = async (formData: { email?: string; password?: string; name?: string }) => {
    const { error } = await createAccountApiRequest.fetch("/v1/auth/signup", {
      method: "POST",
      body: {
        email: formData.email,
        password: formData.password,
        name: formData.name,
      },
    });

    if (!error) {
      await dispatch(sessionActions.signIn({ email: formData.email, password: formData.password }));

      // If there's an invite ID, redirect to the invite acceptance page
      if (invite?.id) {
        navigation.navigate(`/invites/${invite.id}/accept`);
      } else {
        navigation.navigate("/");
      }
    }
  };

  const openUrl = (url: string) => {
    window.open(url, "_blank");
  };

  // Modify Google OAuth URL to include invite ID if present
  const googleOAuthUrl = invite?.id ? `${GOOGLE_OAUTH_URL}&state=${encodeState({ inviteId: invite.id })}` : GOOGLE_OAUTH_URL;

  return (
    <AuthLayout
      title={invite?.id ? "Accept Invitation" : "Create Account"}
      subtitle={invite?.id ? "Create an account to join the organization" : "Join Prim Voices and start creating"}
      footer={
        !invite?.id ? (
          <View className="text-center space-x-1">
            <Text variant="muted">Already have an account?</Text>
            <Button
              variant="ghost"
              type="basic"
              className="!p-0 font-medium w-full"
              onClick={() => navigation.navigate(invite?.id ? `/invites/${invite.id}/accept` : "/signin")}
            >
              Sign in
            </Button>
          </View>
        ) : null
      }
    >
      {!showEmailForm ? (
        <View className="space-y-6">
          <View>
            <View className="transition-transform duration-200 hover:scale-[1.02] active:scale-[0.98]">
              <GoogleButton
                type="light"
                label="Sign up with Google&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                style={{
                  width: "100%",
                  borderRadius: "0.5rem",
                  fontFamily: "inherit",
                }}
                onClick={() => (window.location.href = googleOAuthUrl)}
              />
            </View>
            <View className="flex flex-col items-start gap-1 ml-4 mt-2">
              <Text
                variant="muted"
                className="flex text-sm w-full"
              >
                By creating an account you agree to our
              </Text>
              <View className="flex flex-row items-center gap-1">
                <Button
                  type="basic"
                  variant="ghost"
                  className="!p-0 text-brand dark:text-brand-light hover:underline inline-block text-sm"
                  onClick={() => openUrl(`${window.location.origin}/terms.pdf`)}
                >
                  Terms of Service
                </Button>
                <Text
                  variant="muted"
                  className="flex text-sm"
                >
                  and{" "}
                </Text>
                <Button
                  type="basic"
                  variant="ghost"
                  className="!p-0 text-brand dark:text-brand-light hover:underline inline-block text-sm"
                  onClick={() => openUrl(`${window.location.origin}/privacy.pdf`)}
                >
                  Privacy Policy
                </Button>
              </View>
            </View>
          </View>

          <View className="relative">
            <View className="absolute inset-0 flex items-center">
              <View className="w-full border-t border-gray-200 dark:border-gray-800" />
            </View>
            <View className="relative flex justify-center text-sm">
              <Text
                variant="muted"
                className="px-2 bg-white dark:bg-gray-800"
              >
                Or
              </Text>
            </View>
          </View>

          <Button
            onClick={() => setShowEmailForm(true)}
            className="w-full h-[50px] text-base"
          >
            Sign up with Email
          </Button>
        </View>
      ) : (
        <SignUpForm
          onSubmit={onSubmit}
          isLoading={createAccountApiRequest.isLoading}
          error={createAccountApiRequest.error?.message}
          email={invite?.email}
        />
      )}
    </AuthLayout>
  );
};
