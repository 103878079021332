import React, { useState } from "react";
import { motion } from "framer-motion";

import { Text } from "../components/base/Text";
import { View } from "../components/base/View";
import { InputField } from "../components/base/InputField";
import { Icon } from "../components/base/Icon";
import { EmptyMessage } from "../components/EmptyMessage";

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.08,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
      ease: "easeOut",
    },
  },
};

type SearchableListProps = {
  emptyMessage?: string;
  entityName?: string;
  filter?: (searchQuery: string, item: Record<string, unknown>) => boolean;
  sort?: (a: Record<string, unknown>, b: Record<string, unknown>) => number;
  isLoading?: boolean;
  onClickCreate?: (data: Record<string, unknown>) => void;
  items?: Record<string, unknown>[];
  itemComponent?: React.ComponentType<{ item: Record<string, unknown>; variant: "grid" | "list"; isEmpty: boolean }>;
};

export const SearchableList = ({
  entityName = "item",
  emptyMessage = `Create your first ${entityName} to get started.`,
  filter = () => true,
  sort = () => 0,
  isLoading = false,
  itemComponent = () => null,
  items = [],
  onClickCreate = () => {},
}: SearchableListProps) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [viewMode, setViewMode] = useState<"grid" | "list">("grid");
  const filteredItems = items.filter((item) => filter(searchQuery, item)).sort((a, b) => sort(a, b));

  const onClickViewModeHandler = (mode: "grid" | "list") => () => {
    setViewMode(mode);
  };

  return (
    <View className="relative bg-white dark:bg-gray-900 container mx-auto px-4 sm:px-6 lg:px-8 py-12 z-10">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.1 }}
        className="mb-8 bg-gray-50 dark:bg-gray-800/50 p-4 rounded-xl border border-gray-100 dark:border-gray-700 shadow-sm"
      >
        <View className="flex items-center gap-4">
          <View className="relative flex-1">
            <InputField
              name="search"
              placeholder={`Search ${entityName}s by name or description...`}
              value={searchQuery}
              onChange={setSearchQuery}
              type="text"
              className="pl-10 w-full"
            />
            <Icon
              name="search"
              size={20}
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
            />
          </View>
          <View className="flex bg-white dark:bg-gray-700 rounded-lg border border-gray-200 dark:border-gray-600 overflow-hidden">
            <button
              onClick={onClickViewModeHandler("grid")}
              className={`h-10 w-10 flex items-center justify-center transition-colors ${
                viewMode === "grid"
                  ? "bg-indigo-500 text-white"
                  : "bg-white dark:bg-gray-700 text-gray-500 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600"
              }`}
              aria-label="Grid View"
              title="Grid View"
            >
              <Icon
                name="grid"
                size={20}
              />
            </button>
            <button
              onClick={onClickViewModeHandler("list")}
              className={`h-10 w-10 flex items-center justify-center transition-colors ${
                viewMode === "list"
                  ? "bg-indigo-500 text-white"
                  : "bg-white dark:bg-gray-700 text-gray-500 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600"
              }`}
              aria-label="List View"
              title="List View"
            >
              <Icon
                name="list"
                size={20}
              />
            </button>
          </View>
        </View>
      </motion.div>

      {isLoading ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="flex justify-center items-center py-20"
        >
          <View className="flex flex-col items-center gap-4">
            <motion.div
              animate={{
                rotate: 360,
                transition: { duration: 1.5, repeat: Infinity, ease: "linear" },
              }}
            >
              <Icon
                name="loader"
                size={36}
                className="text-indigo-500"
              />
            </motion.div>
            <Text className="text-gray-500 dark:text-gray-400 mt-4 animate-pulse">Loading your {entityName}s...</Text>
          </View>
        </motion.div>
      ) : filteredItems.length === 0 ? (
        <EmptyMessage
          title={`No ${capitalize(entityName)}s Found`}
          description={searchQuery === "" ? emptyMessage : `No ${entityName} match your search "${searchQuery}".\nTry a different search term.`}
          iconName="bot"
          buttonText={`Create ${capitalize(entityName)}`}
          onClickAdd={onClickCreate}
        />
      ) : (
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className={viewMode === "grid" ? "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 pb-6" : "flex flex-col gap-3 pb-6"}
        >
          {filteredItems?.map((item, index) => (
            <motion.div
              key={index}
              variants={itemVariants}
              className="h-full"
              initial="hidden"
              animate="visible"
            >
              {React.createElement(itemComponent, { item, variant: viewMode, isEmpty: false })}
            </motion.div>
          ))}
          <motion.div
            key="create"
            variants={itemVariants}
            className="h-full"
            initial="hidden"
            animate="visible"
          >
            {React.createElement(itemComponent, { item: {}, variant: viewMode, isEmpty: true })}
          </motion.div>
        </motion.div>
      )}
    </View>
  );
};
