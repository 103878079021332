import { createRestSlice } from "./restSlice";

export type Voice = {
  id: string;
  userId: string;
  name: string;
  description: string;
  tags: string;
  underlyingVoice: string;
  baseVoiceId: string;
  fullOriginalSampleUrl: string;
  preferredSampleUrl: string;
  preferredPreviewUrl: string;
  sampleUrl: string;
  normalizedSampleUrl: string;
  previewUrl: string;
  normalizedPreviewUrl: string;
  verified: boolean;
  public: boolean;
  isCloned: boolean;
  isFlashEnabled: boolean;
  flashCheckpointUrl: string;
  // user description
  userDescription: string;
  userGenderDescription: string;
  userAgeDescription: string;
  userLanguageDescription: string;
  userAccentDescription: string;
  userImageUpload: string;
  // library categories
  isOwner: boolean;
  isBase: boolean;
  isCommunity: boolean;
  isHighQuality: boolean;
  // audio features
  meanf0pitch: number;
  spectralCentroidMean: number;
  spectralContrastMean: number;
  spectralFlatnessMean: number;
  onsetStrengthMean: number;
  jitter: number;
  zeroCrossingRateMean: number;
  //timestamps
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  // auto description
  userImageUploadThumbnail: string;
  meanf0pitchAutoDescription: string;
  spectralCentroidMeanAutoDescription: string;
  spectralContrastMeanAutoDescription: string;
  spectralFlatnessMeanAutoDescription: string;
  onsetStrengthMeanAutoDescription: string;
  jitterMeanAutoDescription: string;
  zeroCrossingRateMeanAutoDescription: string;
};

const voiceSlice = createRestSlice<Voice>("voice", "/v1/voices");

export const { actions: voiceActions, reducer: voiceReducer } = voiceSlice;
