import { Voice } from "../types";

export type ThemeTemplate = "HeroVoiceCarousel" | "HorizontalCarousel" | "LargeFeatureVoiceCarousel" | "SmallFeatureVoiceCarousel";

export interface ThemeGroup {
  color: string;
  gradient: string;
  template: ThemeTemplate;
  description: string;
  // Function to determine if a voice belongs in this group
  rule: (voice: Voice) => boolean;
}

// Helper function to normalize tags for consistent comparison
const normalizeTags = (voice: Voice): string[] => {
  return voice.tags.split(",").map((t: string) => t.trim());
};

// Generate a stable random value for a string
const getStableRandomValue = (str: string): number => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  // Convert to a value between 0 and 1
  return Math.abs(Math.sin(hash));
};

// Stable sort function that uses voice ID for consistent randomization
const stableRandomSort = (voices: Voice[]): Voice[] => {
  return [...voices].sort((a, b) => {
    return getStableRandomValue(a.id) - getStableRandomValue(b.id);
  });
};

export const THEME_GROUPS: Record<string, ThemeGroup> = {
  Featured: {
    color: "#4F46E5",
    gradient: "from-indigo-500 to-indigo-700",
    template: "HeroVoiceCarousel",
    description: "Featured voices",
    rule: (voice) => voice.verified === true && voice.isCommunity === true && voice.isBase === false,
  },
  MyVoices: {
    color: "#059669",
    gradient: "from-emerald-500 to-emerald-700",
    template: "HorizontalCarousel",
    description: "Your voices",
    rule: (voice) => voice.isOwner === true && voice.isBase === false,
  },
  BaseAmerican: {
    color: "#059669",
    gradient: "from-emerald-500 to-emerald-700",
    template: "SmallFeatureVoiceCarousel",
    description: "Platform American Voices",
    rule: (voice) => voice.isBase === true && !(voice.underlyingVoice === "fable" || voice.underlyingVoice === "ballad"),
  },
  BaseBritish: {
    color: "#059669",
    gradient: "from-emerald-500 to-emerald-700",
    template: "SmallFeatureVoiceCarousel",
    description: "Platform British Voices",
    rule: (voice) => voice.isBase === true && (voice.underlyingVoice === "fable" || voice.underlyingVoice === "ballad"),
  },
  Narration: {
    color: "#7C3AED",
    gradient: "from-purple-500 to-purple-700",
    template: "LargeFeatureVoiceCarousel",
    description: "Narrators and Storytellers",
    rule: (voice) => {
      const tags = normalizeTags(voice);
      return tags.includes("Narration") && voice.isBase === false;
    },
  },
  Characters: {
    color: "#059669",
    gradient: "from-emerald-500 to-emerald-700",
    template: "SmallFeatureVoiceCarousel",
    description: "Animation and gaming",
    rule: (voice) => {
      const tags = normalizeTags(voice);
      return tags.includes("Characters") && voice.isBase === false;
    },
  },
  "Flash Eligible": {
    color: "#DC2626",
    gradient: "from-red-500 to-red-700",
    template: "LargeFeatureVoiceCarousel",
    description: "Flash Voices",
    rule: (voice) => {
      return voice.flashCheckpointUrl != null;
    },
  },
  "Social Media": {
    color: "#DC2626",
    gradient: "from-red-500 to-red-700",
    template: "SmallFeatureVoiceCarousel",
    description: "Social Media",
    rule: (voice) => {
      const tags = normalizeTags(voice);
      return tags.includes("Social Media") && voice.isBase === false;
    },
  },
  Advertising: {
    color: "#D97706",
    gradient: "from-amber-500 to-amber-700",
    template: "SmallFeatureVoiceCarousel",
    description: "Commercials and Advertising",
    rule: (voice) => {
      const tags = normalizeTags(voice);
      return tags.includes("Advertising") && voice.isBase === false;
    },
  },
  Corporate: {
    color: "#2563EB",
    gradient: "from-blue-500 to-blue-700",
    template: "SmallFeatureVoiceCarousel",
    description: "Explainers and News",
    rule: (voice) => {
      const tags = normalizeTags(voice);
      return (
        (tags.includes("Corporate") ||
          tags.includes("Customer Service") ||
          tags.includes("Advertising") ||
          tags.includes("Education") ||
          tags.includes("News")) &&
        voice.isBase === false
      );
    },
  },
};

export interface VoiceSection {
  type: ThemeTemplate;
  voices: Voice[];
  title?: string;
  description?: string;
  gradient?: string;
}

export const groupVoicesByTheme = (voices: Voice[]) => {
  const groups: Record<string, Voice[]> = {};

  // Initialize groups with empty arrays
  Object.keys(THEME_GROUPS).forEach((theme) => {
    groups[theme] = [];
  });

  // For each voice, check each group's rule
  voices.forEach((voice) => {
    Object.entries(THEME_GROUPS).forEach(([theme, group]) => {
      if (group.rule(voice)) {
        groups[theme].push(voice);
      }
    });
  });

  // Sort each group using stable random sort
  Object.keys(groups).forEach((theme) => {
    groups[theme] = stableRandomSort(groups[theme]);
  });

  return groups;
};

export const organizeVoicesIntoSections = (voices: Voice[]): VoiceSection[] => {
  const groups = groupVoicesByTheme(voices);
  const sections: VoiceSection[] = [];
  const usedVoiceIds = new Set<string>();

  // Helper function to prioritize unused voices without additional shuffling
  const prioritizeUnusedVoices = (voiceGroup: Voice[]): Voice[] => {
    const unused = voiceGroup.filter((voice) => !usedVoiceIds.has(voice.id));
    const used = voiceGroup.filter((voice) => usedVoiceIds.has(voice.id));
    return [...unused, ...used];
  };

  // First section is Featured, only add if there are voices
  const prioritizedFeaturedVoices = prioritizeUnusedVoices(groups["Featured"] || []);
  if (prioritizedFeaturedVoices.length > 0) {
    sections.push({
      type: THEME_GROUPS["Featured"].template,
      voices: prioritizedFeaturedVoices,
      title: "Featured Voices",
      description: THEME_GROUPS["Featured"].description,
      gradient: THEME_GROUPS["Featured"].gradient,
    });
    prioritizedFeaturedVoices.forEach((voice) => usedVoiceIds.add(voice.id));
  }

  // Filter themes to only include those with voices
  const remainingThemes = Object.keys(THEME_GROUPS)
    .filter((theme) => theme !== "Featured")
    .filter((theme) => (groups[theme] || []).length > 0);

  let currentIndex = 0;

  while (currentIndex < remainingThemes.length) {
    // Add up to 2 horizontal carousels
    for (let i = 0; i < 2 && currentIndex < remainingThemes.length; i++) {
      const theme = remainingThemes[currentIndex];
      const prioritizedVoices = prioritizeUnusedVoices(groups[theme] || []);
      if (prioritizedVoices.length > 0) {
        sections.push({
          type: THEME_GROUPS[theme].template,
          voices: prioritizedVoices,
          title: theme,
          description: THEME_GROUPS[theme].description,
          gradient: THEME_GROUPS[theme].gradient,
        });
        prioritizedVoices.forEach((voice) => usedVoiceIds.add(voice.id));
      }
      currentIndex++;
    }
  }
  return sections;
};
