import { makeRequest } from "../../utils/request";
import { RestList } from "../../components/shared/RestList";
import { View } from "../../components/base/View";
import { useSearchParams } from "react-router-dom";
import { Button } from "../../components/base/Button";
import { SyncOutlined, Refresh } from "@mui/icons-material";
import { Voice } from "../../types";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { voiceActions } from "../../redux/reducers/voiceSlice";
import { useAppSelector, useAppDispatch } from "../../redux";
import { ImageField } from "../../components/ImageField";

type UpdateButtonProps = {
  id: string;
  formData: Voice;
  value: string;
  onChange: (value: string) => void;
};

const UpdateButton = ({ id, formData }: UpdateButtonProps) => {
  const dispatch = useAppDispatch();
  const updateDescriptions = async () => {
    try {
      await makeRequest(`/v1/voice-analysis/analyze/${formData.id}`, {
        method: "POST",
      });
      dispatch(voiceActions.getById({ id }));
    } catch (error) {
      console.error("Error updating descriptions:", error);
    }
  };

  return (
    <Button
      onClick={updateDescriptions}
      variant="ghost"
      className="!shadow-none flex items-center gap-2"
    >
      <SyncOutlined className="text-brand group-hover:text-brand-dark dark:text-brand-light dark:group-hover:text-brand" />
      <span className="text-brand group-hover:text-brand-dark dark:text-brand-light dark:group-hover:text-brand">Analyze Descriptions</span>
    </Button>
  );
};

const RegeneratePreviewButton = ({ id, formData }: UpdateButtonProps) => {
  const dispatch = useAppDispatch();
  const regeneratePreview = async () => {
    try {
      await makeRequest(`/v1/voices/${formData.id}/regenerate-preview`, {
        method: "POST",
      });
      dispatch(voiceActions.getById({ id }));
    } catch (error) {
      console.error("Error regenerating preview:", error);
    }
  };

  return (
    <Button
      onClick={regeneratePreview}
      variant="ghost"
      className="!shadow-none flex items-center gap-2"
    >
      <Refresh className="text-brand group-hover:text-brand-dark dark:text-brand-light dark:group-hover:text-brand" />
      <span className="text-brand group-hover:text-brand-dark dark:text-brand-light dark:group-hover:text-brand">Regenerate Preview</span>
    </Button>
  );
};

type AdminPublicVoicesPageProps = {
  className?: string;
};

export const AdminPublicVoicesPage = ({ className }: AdminPublicVoicesPageProps) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const isPanelOpen = searchParams.has("id");
  const [filters, setFilters] = useState({
    isBase: true,
    isCommunity: true,
    isOwner: true,
  });

  const toggleFilter = async (filterName: keyof typeof filters) => {
    const newFilters = {
      ...filters,
      [filterName]: !filters[filterName],
    };
    setFilters(newFilters);
  };

  const search = async (query: string = "", searchFilters: Record<string, any> = filters) => {
    try {
      const filterParams = [];
      if (searchFilters.isBase) {
        filterParams.push("isBase=true");
      }
      if (searchFilters.isCommunity) {
        filterParams.push("isCommunity=true");
      }
      if (searchFilters.isOwner) {
        filterParams.push("isOwner=true");
      }

      // Build the URL with or without the search query
      const searchParam = query ? `q=${query}` : "";
      const filterString = filterParams.length ? filterParams.join("&") : "";
      const separator = searchParam && filterString ? "&" : "";

      const url = `/v1/voices/search?${searchParam}${separator}${filterString}`.replace(/\?$/, "");
      const { data } = await makeRequest<{ data: Voice[] }>(url);

      // Store the full Voice objects in Redux by dispatching a fulfilled action
      dispatch({
        type: "voice/get/fulfilled",
        payload: data,
      });

      // Return just the IDs for RestList to use
      return data.map((voice) => voice.id);
    } catch (error) {
      console.error("Error searching voices:", error);
      return [];
    }
  };

  // Initial search when component mounts and when filters change
  useEffect(() => {
    search("", filters);
  }, [filters]);

  return (
    <View className="h-full bg-gray-50 dark:bg-gray-900">
      <div className={`h-full relative ${isPanelOpen ? "pr-[400px]" : ""} transition-all duration-300`}>
        <div className="p-4 flex gap-2">
          <Button
            onClick={() => toggleFilter("isBase")}
            variant={filters.isBase ? "brand" : "ghost"}
            className="!shadow-none"
          >
            Base Voices
          </Button>
          <Button
            onClick={() => toggleFilter("isCommunity")}
            variant={filters.isCommunity ? "brand" : "ghost"}
            className="!shadow-none"
          >
            Community Voices
          </Button>
          <Button
            onClick={() => toggleFilter("isOwner")}
            variant={filters.isOwner ? "brand" : "ghost"}
            className="!shadow-none"
          >
            Owner Voices
          </Button>
        </div>
        <RestList
          key={JSON.stringify(filters)}
          className={`h-full ${className || ""}`}
          entityName="voice"
          search={search}
          filters={filters}
          skipInitialFetch={true}
          fields={[
            {
              name: "id",
              label: "ID",
              readOnly: true,
            },
            {
              name: "name",
              label: "Name",
            },
            {
              name: "underlyingVoice",
              label: "Underlying Voice",
              readOnly: false,
            },
            {
              name: "description",
              label: "Description",
              type: "textarea",
              visible: false,
            },
            {
              name: "verified",
              label: "Verified",
              type: "checkbox",
              visible: true,
            },
            {
              name: "tags",
              label: "Tags",
              visible: false,
            },
            {
              name: "sampleUrl",
              label: "Sample URL",
              type: "audio",
              visible: false,
            },
            {
              name: "preferredPreviewUrl",
              label: "PreferredPreview URL",
              type: "audio",
              visible: false,
            },
            {
              name: "regeneratePreview",
              label: "Regenerate Preview",
              component: RegeneratePreviewButton,
              visible: true,
            },
            {
              name: "flashCheckpointUrl",
              label: "Flash Checkpoint URL",
              visible: false,
            },
            {
              name: "userDescription",
              label: "User Description",
              type: "textarea",
              visible: false,
            },
            {
              name: "userGenderDescription",
              label: "Gender Description",
              visible: false,
            },
            {
              name: "userAgeDescription",
              label: "Age Description",
              visible: false,
            },
            {
              name: "meanf0pitch",
              label: "Mean F0 Pitch",
              type: "number",
              readOnly: true,
              visible: false,
            },
            {
              name: "spectralCentroidMean",
              label: "Spectral Centroid Mean",
              type: "number",
              readOnly: true,
              visible: false,
            },
            {
              name: "spectralContrastMean",
              label: "Spectral Contrast Mean",
              type: "number",
              readOnly: true,
              visible: false,
            },
            {
              name: "spectralFlatnessMean",
              label: "Spectral Flatness Mean",
              type: "number",
              readOnly: true,
              visible: false,
            },
            {
              name: "onsetStrengthMean",
              label: "Onset Strength Mean",
              type: "number",
              readOnly: true,
              visible: false,
            },
            {
              name: "jitter",
              label: "Jitter",
              type: "number",
              readOnly: true,
              visible: false,
            },
            {
              name: "zeroCrossingRateMean",
              label: "Zero Crossing Rate Mean",
              type: "number",
              readOnly: true,
              visible: false,
            },
            {
              name: "meanf0pitchAutoDescription",
              label: "Mean F0 Pitch Description (Auto)",
              type: "textarea",
              readOnly: true,
              visible: false,
            },
            {
              name: "spectralCentroidMeanAutoDescription",
              label: "Spectral Centroid Description (Auto)",
              type: "textarea",
              readOnly: true,
              visible: false,
            },
            {
              name: "spectralContrastMeanAutoDescription",
              label: "Spectral Contrast Description (Auto)",
              type: "textarea",
              readOnly: true,
              visible: false,
            },
            {
              name: "spectralFlatnessMeanAutoDescription",
              label: "Spectral Flatness Description (Auto)",
              type: "textarea",
              readOnly: true,
              visible: false,
            },
            {
              name: "onsetStrengthMeanAutoDescription",
              label: "Onset Strength Description (Auto)",
              type: "textarea",
              readOnly: true,
              visible: false,
            },
            {
              name: "jitterMeanAutoDescription",
              label: "Jitter Description (Auto)",
              type: "textarea",
              readOnly: true,
              visible: false,
            },
            {
              name: "zeroCrossingRateMeanAutoDescription",
              label: "Zero Crossing Rate Description (Auto)",
              type: "textarea",
              readOnly: true,
              visible: false,
            },
            {
              name: "analyze",
              label: "Analyze",
              component: UpdateButton,
              visible: false,
            },
            {
              name: "isBase",
              label: "Base?",
              type: "checkbox",
              readOnly: true,
              visible: false,
            },
            {
              name: "isCommunity",
              label: "Community?",
              type: "checkbox",
              readOnly: true,
              visible: false,
            },
            {
              name: "isOwner",
              label: "Owner?",
              type: "checkbox",
              readOnly: true,
              visible: false,
            },
            {
              name: "userImageUpload",
              label: "User Image Upload",
              component: ImageField,
              visible: true,
            },
          ]}
        />
      </div>
    </View>
  );
};
