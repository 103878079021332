import { Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { View } from "../components/base/View";
import { BaseHeader } from "../components/BaseHeader";
import { BaseHeaderButton } from "../components/BaseHeaderButton";
import { RequireAuth } from "../components/auth/RequireAuth";
import { useAuth } from "../hooks/useAuth";
import { useAppSelector } from "../redux";

export const AgentLayout = () => {
  const { isAdmin } = useAuth();
  const navigate = useNavigate();
  const organization = useAppSelector((state) => state.organization.currentOrganization);

  useEffect(() => {
    if (organization && !organization?.agentsEnabled) {
      navigate("/");
    }
  }, [organization]);

  return (
    <RequireAuth>
      <View className="relative flex flex-col w-full h-screen overflow-hidden bg-white dark:bg-gray-900">
        <BaseHeader
          title="Agents"
          links={
            isAdmin
              ? [
                  { label: "Agents", to: "/agents" },
                  { label: "Recipes", to: "/agents/recipes" },
                ]
              : []
          }
          rightContent={
            <BaseHeaderButton
              to="/create"
              icon="sparkles"
              label="Creator Portal"
            />
          }
        />
        <View className="flex-1 overflow-y-auto">
          <Outlet />
        </View>
      </View>
    </RequireAuth>
  );
};
