import { useState, useRef } from "react";
import clsx from "clsx";
import { View } from "../base/View";
import { Text } from "../base/Text";
import { Button } from "../base/Button";
import { Icon } from "../base/Icon";
import { FieldLabel } from "../base/FieldLabel";
import { Switch } from "../base/Switch";
import { Generation } from "../../redux/reducers/generationSlice";
import { fileActions } from "../../redux/reducers/fileSlice";
import { useAppDispatch } from "../../redux";

interface VoiceConversionOptionsProps {
  formData: Partial<Generation>;
  setFormData: (data: Partial<Generation>) => void;
  selectedFile: File | null;
  setSelectedFile: (file: File | null) => void;
  useVoiceStyle: boolean;
  setUseVoiceStyle: (useStyle: boolean) => void;
  voiceConversionRef: React.RefObject<HTMLDivElement>;
}

export const VoiceConversionOptions = ({
  formData,
  setFormData,
  selectedFile,
  setSelectedFile,
  useVoiceStyle,
  setUseVoiceStyle,
  voiceConversionRef,
}: VoiceConversionOptionsProps) => {
  const dispatch = useAppDispatch();
  const [isDragging, setIsDragging] = useState(false);
  const dragCounter = useRef(0);

  const handleDrag = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setIsDragging(true);
    }
  };

  const handleDragOut = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setIsDragging(false);
    }
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current = 0;
    setIsDragging(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      if (file.type.startsWith("audio/")) {
        setSelectedFile(file);
        dispatch(fileActions.uploadFile({ type: "audio", file }))
          .unwrap()
          .then((response) => {
            setFormData({ ...formData, sourceUrl: response });
          });
      }
    }
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);

      const response = await dispatch(fileActions.uploadFile({ type: "audio", file: e.target.files[0] })).unwrap();

      setFormData({ ...formData, sourceUrl: response });
    }
  };

  const clearSelectedFile = () => {
    setSelectedFile(null);
    setFormData({ ...formData, sourceUrl: undefined });
  };

  return (
    <View className="flex flex-col gap-4">
      <FieldLabel
        label="Upload Audio File"
        className="flex flex-1 flex-grow"
      >
        <View
          className={clsx(
            "cursor-pointer w-full h-48 border border-dashed rounded-lg flex items-center justify-center backdrop-blur-lg",
            "flex-grow transition-colors duration-200 relative border-gray-300 dark:border-gray-700 hover:border-brand/50 dark:hover:border-brand/50",
            "bg-white dark:bg-gray-800",
          )}
          onDragEnter={handleDragIn}
          onDragLeave={handleDragOut}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          {selectedFile && (
            <Button
              onClick={clearSelectedFile}
              type="basic"
              variant="ghost"
              className="!absolute !p-2 top-2 right-2 text-gray-400 hover:text-brand dark:text-gray-500 dark:hover:text-brand-light transition-colors"
            >
              <Icon
                name="x"
                size={20}
              />
            </Button>
          )}
          <input
            type="file"
            accept="audio/*"
            onChange={onFileChange}
            className="hidden"
            id="audio-upload"
          />
          <label
            htmlFor="audio-upload"
            onDragEnter={handleDragIn}
            onDragLeave={handleDragOut}
            onDragOver={handleDrag}
            onDrop={handleDrop}
            className="cursor-pointer flex flex-col items-center"
          >
            <Icon
              name="upload"
              size={48}
              className="text-brand dark:text-brand-light mb-4"
            />
            <Text className="p-2 text-gray-400 dark:text-gray-500 text-center">
              {selectedFile ? selectedFile.name : isDragging ? "Drop audio file here" : "Click to upload or drag and drop audio file"}
            </Text>
          </label>
        </View>
      </FieldLabel>
      <FieldLabel
        label="Voice Style Transfer"
        className="flex flex-1 flex-grow"
      >
        <View className="w-full p-4 bg-gray-50 dark:bg-gray-700/50 rounded-lg">
          <View className="flex flex-row items-center justify-between mb-2">
            <Switch
              options={[
                { label: "Off", value: "false" },
                { label: "On", value: "true" },
              ]}
              value={useVoiceStyle ? "true" : "false"}
              onChange={(value) => setUseVoiceStyle(value === "true")}
            />
            <View className="flex flex-row items-center gap-2">
              <View className="flex px-2 py-0.5 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-full">
                <Text className="py-1 text-xs font-medium text-white text-center">Experimental</Text>
              </View>
            </View>
          </View>
          <Text className="text-xs text-gray-500 dark:text-gray-400 mb-2">
            {useVoiceStyle
              ? "Converts your audio to the voices timbre and speaking style. This is a new feature and may not work as expected. If it doesn't work, try using a new uploaded audio file."
              : "Converts the uploaded audio file to the timbre of the selected voice, maintaining the original content."}
          </Text>
          {useVoiceStyle && (
            <View className="mt-2 p-3 bg-amber-50 dark:bg-amber-900/20 border border-amber-200 dark:border-amber-700/50 rounded-md flex items-center gap-2">
              <Icon
                name="info"
                size={16}
                className="text-amber-500 dark:text-amber-400 flex-shrink-0"
              />
              <Text className="text-xs text-amber-700 dark:text-amber-300">
                This feature costs <span className="font-semibold">an additional $0.025 per minute</span> of processed audio. Total is $0.05 per minute.
              </Text>
            </View>
          )}
        </View>
      </FieldLabel>
    </View>
  );
};
