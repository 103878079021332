import clsx from "clsx";
import React, { ReactNode } from "react";

import { FieldLabel } from "./FieldLabel";

type CheckboxFieldProps = {
  labelClassName?: string;
  className?: string;
  label?: ReactNode;
  name?: string;
  onChange?: (value: boolean) => void;
  value?: boolean;
  readOnly?: boolean;
  error?: string;
};

export const CheckboxField = ({ labelClassName, className, label, name, onChange = () => {}, value, readOnly, error }: CheckboxFieldProps) => {
  const onChangeInternal = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <FieldLabel
      label={label}
      className={clsx("flex", labelClassName)}
      error={error}
    >
      <input
        type="checkbox"
        className={clsx(
          "!w-3 !h-3 rounded",
          "transition-all duration-200",
          "outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2 dark:focus:ring-offset-gray-900",
          "border-2",
          {
            "cursor-not-allowed opacity-50": readOnly,
            "cursor-pointer": !readOnly,
            "border-gray-300 dark:border-gray-600": !error && !value,
            "border-red-500 dark:border-red-400": error,
            "border-brand bg-brand": value && !error,
            "border-red-500 bg-red-500": value && error,
          },
          "hover:border-brand dark:hover:border-brand-light",
          className,
        )}
        onChange={onChangeInternal}
        name={name}
        checked={value}
        disabled={readOnly}
      />
    </FieldLabel>
  );
};
