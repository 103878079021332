import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";

import { store } from "./redux";
import { ThemeProvider } from "./contexts/ThemeContext";

import { AdminLayout } from "./layouts/AdminLayout";
import { AgentDetailLayout } from "./layouts/AgentDetailLayout";
import { AgentLayout } from "./layouts/AgentLayout";
import { AppLayout } from "./layouts/AppLayout";
import { CreatorLayout } from "./layouts/CreatorLayout";

import { AdminDatasetBasePage } from "./pages/admin/AdminDatasetBasePage";
import { AdminDatasetPage } from "./pages/admin/AdminDatasetPage";
import { AdminOrganizationsPage } from "./pages/admin/AdminOrganizationsPage";
import { AdminPublicVoicesPage } from "./pages/admin/AdminPublicVoicesPage";
import { AdminTrainingJobsPage } from "./pages/admin/AdminTrainingJobsPage";
import { AdminUsersPage } from "./pages/admin/AdminUsersPage";
import { AgentDetailsPage } from "./pages/agent/AgentDetailsPage";
import { AgentFunctionPage } from "./pages/agent/AgentFunctionPage";
import { AgentRecipesPage } from "./pages/agent/AgentRecipesPage";
import { AgentsPage } from "./pages/agent/AgentsPage";
import { ApiReferencePage } from "./pages/docs/ApiReferencePage";
import { CreatePage } from "./pages/creator/CreatePage";
import { DocsPage } from "./pages/docs/DocsPage";
import { FaqPage } from "./pages/docs/FaqPage";
import { FeaturesPage } from "./pages/docs/FeaturesPage";
import { ForgotPasswordPage } from "./pages/ForgotPassword";
import { GettingStartedPage } from "./pages/docs/GettingStartedPage";
import { InviteAcceptPage } from "./pages/InviteAcceptPage";
import { NotFoundPage } from "./pages/NotFoundPage";
import { ProfilePage } from "./pages/ProfilePage";
import { RedirectPage } from "./pages/RedirectPage";
import { SignInPage } from "./pages/SignInPage";
import { SignOutPage } from "./pages/SignOutPage";
import { SignUpPage } from "./pages/SignUpPage";
import { VoiceMixerPage } from "./pages/VoiceMixerPage";
import { VoicesPage } from "./pages/creator/VoicesPage";
import { AgentEnvironmentsPage } from "./pages/agent/AgentEnvironmentsPage";

export const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={<AppLayout />}
            >
              <Route
                path="/signin"
                element={<SignInPage />}
              />
              <Route
                path="/signup"
                element={<SignUpPage />}
              />
              <Route
                path="/forgot-password"
                element={<ForgotPasswordPage />}
              />
              <Route
                path="/invites/:inviteId/accept"
                element={<InviteAcceptPage />}
              />
              <Route
                path="/"
                element={<CreatorLayout />}
              >
                <Route
                  path="/create"
                  element={<CreatePage />}
                />
                <Route
                  path="/mixer"
                  element={<VoiceMixerPage />}
                />
                <Route
                  path="/profile"
                  element={<ProfilePage />}
                />
                <Route
                  path="/voices"
                  element={<VoicesPage />}
                />
                <Route
                  path="/signout"
                  element={<SignOutPage />}
                />
                <Route
                  path="/"
                  element={<RedirectPage path="/create" />}
                />
              </Route>

              <Route
                path="/agents"
                element={<AgentLayout />}
              >
                <Route
                  index
                  element={<AgentsPage />}
                />
                <Route
                  path="/agents/recipes"
                  element={<AgentRecipesPage />}
                />
                <Route
                  path="/agents/:agentId"
                  element={<AgentDetailLayout />}
                >
                  <Route
                    path="/agents/:agentId"
                    element={<RedirectPage path="/agents/:agentId/details" />}
                  />
                  <Route
                    path="/agents/:agentId/details"
                    element={<AgentDetailsPage />}
                  />
                  <Route
                    path="/agents/:agentId/environments"
                    element={<AgentEnvironmentsPage />}
                  />
                  <Route
                    path="/agents/:agentId/function"
                    element={<AgentFunctionPage />}
                  />
                </Route>
              </Route>

              <Route
                path="/admin"
                element={<AdminLayout />}
              >
                <Route
                  path="/admin"
                  element={<RedirectPage path="/admin/users" />}
                />
                <Route
                  path="/admin/users"
                  element={<AdminUsersPage />}
                />
                <Route
                  path="/admin/organizations"
                  element={<AdminOrganizationsPage />}
                />
                <Route
                  path="/admin/publicVoices"
                  element={<AdminPublicVoicesPage />}
                />
                <Route
                  path="/admin/datasetBases"
                  element={<AdminDatasetBasePage />}
                />
                <Route
                  path="/admin/datasets"
                  element={<AdminDatasetPage />}
                />
                <Route
                  path="/admin/trainingJobs"
                  element={<AdminTrainingJobsPage />}
                />
              </Route>

              <Route
                path="/docs"
                element={<DocsPage />}
              />
              <Route
                path="/docs/getting-started"
                element={<GettingStartedPage />}
              />
              <Route
                path="/docs/api-reference"
                element={<ApiReferencePage />}
              />
              <Route
                path="/docs/features"
                element={<FeaturesPage />}
              />
              <Route
                path="/docs/faq"
                element={<FaqPage />}
              />
              <Route
                path="*"
                element={<NotFoundPage />}
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
};
