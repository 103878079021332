import { View } from "../../components/base/View";
import { Text } from "../../components/base/Text";
import { BaseHeader } from "../../components/BaseHeader";
import { DocsSidebar } from "../../components/docs/DocsSidebar";

type ApiReferencePageProps = {
  className?: string;
};

export const ApiReferencePage = ({ className }: ApiReferencePageProps) => {
  const links = [
    { label: "Getting Started", to: "/docs/getting-started" },
    { label: "API Reference", to: "/docs/api-reference" },
    { label: "Features", to: "/docs/features" },
    { label: "FAQ", to: "/docs/faq" },
  ];

  return (
    <View className={`min-h-screen bg-white dark:bg-gray-900 ${className}`}>
      <BaseHeader
        title="Documentation"
        links={links}
      />
      <View className="flex w-full max-w-7xl mx-auto p-8">
        <DocsSidebar />
        <View className="flex-1">
          <View className="space-y-12">
            <View>
              <Text
                variant="brand"
                className="text-3xl font-bold mb-4"
              >
                API Reference
              </Text>
              <Text
                variant="body"
                className="text-lg mb-4"
              >
                Our API allows you to integrate PrimVoices into your applications. Get started with our comprehensive API documentation.
              </Text>
            </View>

            {/* Events Section */}
            <View>
              <Text
                variant="brand"
                className="text-2xl font-bold mb-6"
              >
                Events
              </Text>
              <Text
                variant="body"
                className="text-lg mb-8"
              >
                Events are the core building blocks of PrimVoices. They are divided into two categories: input events that your handler receives, and output
                events that your handler can yield to control the conversation flow.
              </Text>

              {/* Input Events Section */}
              <View className="mb-12">
                <Text className="text-xl font-semibold mb-6">Input Events</Text>
                <Text className="mb-6">These events are sent to your handler when certain conditions are met or when user input is received.</Text>

                {/* Text Event */}
                <View
                  id="text-event"
                  className="scroll-mt-20 mb-8"
                >
                  <Text className="text-lg font-semibold mb-2">Text Event</Text>
                  <Text className="mb-4">Received when the user provides text input.</Text>
                  <View className="mt-4">
                    <Text className="font-medium mb-2">Inputs:</Text>
                    <Text className="ml-4">• text (string): The text content from the user</Text>
                  </View>
                </View>

                {/* Timeout Event */}
                <View
                  id="timeout-event"
                  className="scroll-mt-20 mb-8"
                >
                  <Text className="text-lg font-semibold mb-2">Timeout Event</Text>
                  <Text className="mb-4">Received when a specified timeout period has elapsed.</Text>
                  <View className="mt-4">
                    <Text className="font-medium mb-2">Inputs:</Text>
                    <Text className="ml-4">No inputs required</Text>
                  </View>
                </View>

                {/* Interrupt Event */}
                <View
                  id="interrupt-event"
                  className="scroll-mt-20 mb-8"
                >
                  <Text className="text-lg font-semibold mb-2">Interrupt Event</Text>
                  <Text className="mb-4">Received when the user interrupts the current voice output.</Text>
                  <View className="mt-4">
                    <Text className="font-medium mb-2">Inputs:</Text>
                    <Text className="ml-4">No inputs required</Text>
                  </View>
                </View>
              </View>

              {/* Output Events Section */}
              <View>
                <Text className="text-xl font-semibold mb-6">Output Events</Text>
                <Text className="mb-6">These events can be yielded from your handler to control the conversation flow and generate responses.</Text>

                {/* Start Event */}
                <View
                  id="start-event"
                  className="scroll-mt-20 mb-8"
                >
                  <Text className="text-lg font-semibold mb-2">Start Event</Text>
                  <Text className="mb-4">Initiates a new voice interaction or session.</Text>
                  <View className="mt-4">
                    <Text className="font-medium mb-2">Inputs:</Text>
                    <Text className="ml-4">No inputs required</Text>
                  </View>
                </View>

                {/* Stop Event */}
                <View
                  id="stop-event"
                  className="scroll-mt-20 mb-8"
                >
                  <Text className="text-lg font-semibold mb-2">Stop Event</Text>
                  <Text className="mb-4">Ends the current voice interaction or session.</Text>
                  <View className="mt-4">
                    <Text className="font-medium mb-2">Inputs:</Text>
                    <Text className="ml-4">No inputs required</Text>
                  </View>
                </View>

                {/* End Call Event */}
                <View
                  id="end-call-event"
                  className="scroll-mt-20 mb-8"
                >
                  <Text className="text-lg font-semibold mb-2">End Call Event</Text>
                  <Text className="mb-4">Ends the current call session.</Text>
                  <View className="mt-4">
                    <Text className="font-medium mb-2">Inputs:</Text>
                    <Text className="ml-4">No inputs required</Text>
                  </View>
                </View>

                {/* Text to Speech Event */}
                <View
                  id="text-to-speech-event"
                  className="scroll-mt-20 mb-8"
                >
                  <Text className="text-lg font-semibold mb-2">Text to Speech Event</Text>
                  <Text className="mb-4">Converts text to speech using specified voice settings.</Text>
                  <View className="mt-4">
                    <Text className="font-medium mb-2">Inputs:</Text>
                    <Text className="ml-4">• text (string): The text to convert to speech</Text>
                    <Text className="ml-4">• voice (string): The voice to use (default: "lyric")</Text>
                    <Text className="ml-4">• cache (boolean): Whether to cache the audio (default: true)</Text>
                  </View>
                </View>

                {/* Audio Event */}
                <View
                  id="audio-event"
                  className="scroll-mt-20 mb-8"
                >
                  <Text className="text-lg font-semibold mb-2">Audio Event</Text>
                  <Text className="mb-4">Plays an audio file from the specified path.</Text>
                  <View className="mt-4">
                    <Text className="font-medium mb-2">Inputs:</Text>
                    <Text className="ml-4">• path (string): The path to the audio file</Text>
                  </View>
                </View>

                {/* Silence Event */}
                <View
                  id="silence-event"
                  className="scroll-mt-20 mb-8"
                >
                  <Text className="text-lg font-semibold mb-2">Silence Event</Text>
                  <Text className="mb-4">Inserts a period of silence into the audio stream.</Text>
                  <View className="mt-4">
                    <Text className="font-medium mb-2">Inputs:</Text>
                    <Text className="ml-4">• duration (number): The duration of silence in milliseconds</Text>
                  </View>
                </View>

                {/* Transfer Call Event */}
                <View
                  id="transfer-call-event"
                  className="scroll-mt-20 mb-8"
                >
                  <Text className="text-lg font-semibold mb-2">Transfer Call Event</Text>
                  <Text className="mb-4">Transfers the call to another phone number.</Text>
                  <View className="mt-4">
                    <Text className="font-medium mb-2">Inputs:</Text>
                    <Text className="ml-4">• phone_number (string): The phone number to transfer the call to</Text>
                  </View>
                </View>

                {/* Merge Call Event */}
                <View
                  id="merge-call-event"
                  className="scroll-mt-20 mb-8"
                >
                  <Text className="text-lg font-semibold mb-2">Merge Call Event</Text>
                  <Text className="mb-4">Merges the current call with another call.</Text>
                  <View className="mt-4">
                    <Text className="font-medium mb-2">Inputs:</Text>
                    <Text className="ml-4">• phone_number (string): The phone number of the call to merge with</Text>
                  </View>
                </View>

                {/* Context Update Event */}
                <View
                  id="context-update-event"
                  className="scroll-mt-20 mb-8"
                >
                  <Text className="text-lg font-semibold mb-2">Context Update Event</Text>
                  <Text className="mb-4">Updates the context of the current conversation.</Text>
                  <View className="mt-4">
                    <Text className="font-medium mb-2">Inputs:</Text>
                    <Text className="ml-4">• context (object): The new context data to update</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};
