import { View } from "./View";
import { Text } from "./Text";
import { Button } from "./Button";
import { Slider } from "./Slider";
import { SearchField } from "./SearchField";
import { motion } from "framer-motion";
import { useState } from "react";

// Update types to match the actual data format from the API
export type Gender = string; // Allow any string since userGenderDescription is a string
export type AgeGroup = string; // Allow any string since userAgeDescription is a string
export type Tag = string;

const TAG_OPTIONS = ["Characters", "Social Media", "Customer Service", "Narration", "Advertising", "Corporate", "Education", "News"] as const;

interface VoiceFiltersProps {
  selectedGenders: Gender[];
  selectedAges: AgeGroup[];
  selectedTags: Tag[];
  pitchRange: [number, number];
  searchQuery: string;
  onGenderChange: (genders: Gender[]) => void;
  onAgeChange: (ages: AgeGroup[]) => void;
  onTagChange: (tags: Tag[]) => void;
  onPitchChange: (range: [number, number]) => void;
  onSearchChange: (value: string) => void;
}

export const VoiceFilters = ({
  selectedGenders,
  selectedAges,
  selectedTags,
  pitchRange,
  searchQuery,
  onGenderChange,
  onAgeChange,
  onTagChange,
  onPitchChange,
  onSearchChange,
}: VoiceFiltersProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const genderOptions: { value: string; label: string }[] = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "nongendered", label: "Non-Gendered" },
  ];

  const ageOptions: { value: string; label: string }[] = [
    { value: "child", label: "Child" },
    { value: "young adult", label: "Young Adult" },
    { value: "adult", label: "Adult" },
    { value: "mature adult", label: "Mature Adult" },
  ];

  const getButtonStyle = (isSelected: boolean, areAllUnselected: boolean) => {
    if (isSelected) {
      return "bg-indigo-600 text-white border-2 border-indigo-500";
    }
    if (areAllUnselected) {
      return "bg-indigo-100 text-indigo-700 dark:bg-indigo-900/30 dark:text-indigo-300 border-2 border-transparent hover:bg-indigo-200 dark:hover:bg-indigo-800/40";
    }
    return "bg-gray-50 dark:bg-gray-800 text-gray-700 dark:text-gray-300 border-2 border-transparent hover:bg-gray-100 dark:hover:bg-gray-700";
  };

  const toggleGender = (gender: string) => {
    if (selectedGenders.includes(gender)) {
      onGenderChange(selectedGenders.filter((g) => g !== gender));
    } else {
      onGenderChange([...selectedGenders, gender]);
    }
  };

  const toggleAge = (age: string) => {
    if (selectedAges.includes(age)) {
      onAgeChange(selectedAges.filter((a) => a !== age));
    } else {
      onAgeChange([...selectedAges, age]);
    }
  };

  const toggleTag = (tag: string) => {
    if (selectedTags.includes(tag)) {
      onTagChange(selectedTags.filter((t) => t !== tag));
    } else {
      onTagChange([...selectedTags, tag]);
    }
  };

  return (
    <View className="bg-white dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-700 mb-6">
      {/* Header with Search */}
      <View className="p-4 flex items-center gap-4">
        <Button
          onClick={() => setIsExpanded(!isExpanded)}
          variant="ghost"
          className="flex items-center gap-2"
        >
          <Text className="font-medium">Filters</Text>
          <motion.div
            initial={false}
            animate={{ rotate: isExpanded ? 180 : 0 }}
            className="text-gray-500 ml-1"
          >
            ▼
          </motion.div>
        </Button>
        <View className="flex-1">
          <SearchField
            placeholder="Search voices by name or description..."
            value={searchQuery}
            onChangeSearchQuery={onSearchChange}
            className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-sm"
            options={[]}
          />
        </View>
      </View>

      {/* Collapsible Content */}
      {isExpanded && (
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: "auto", opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <View className="px-4 pb-4">
            <View className="flex flex-wrap gap-4 h-fit">
              {/* Demographics Filter */}
              <View className="w-full">
                <View className="flex gap-4">
                  {/* Titles Column */}
                  <View className="w-32 text-right pr-2">
                    <Text className="font-medium text-right">Demographics:</Text>
                  </View>

                  {/* Options Column */}
                  <View className="flex-1">
                    <View className="flex items-center">
                      {/* Gender Options */}
                      <View className="flex flex-wrap gap-2">
                        {genderOptions.map((option) => (
                          <button
                            key={option.value}
                            onClick={() => toggleGender(option.value)}
                            className={`px-3 py-1 rounded-full text-sm transition-colors ${getButtonStyle(
                              selectedGenders.includes(option.value),
                              selectedGenders.length === 0,
                            )}`}
                          >
                            {option.label}
                          </button>
                        ))}
                      </View>

                      {/* Vertical Divider */}
                      <View className="w-px h-8 bg-gray-200 dark:bg-gray-700 mx-4" />

                      {/* Age Options */}
                      <View className="flex flex-wrap gap-2">
                        {ageOptions.map((option) => (
                          <button
                            key={option.value}
                            onClick={() => toggleAge(option.value)}
                            className={`px-3 py-1 rounded-full text-sm transition-colors ${getButtonStyle(
                              selectedAges.includes(option.value),
                              selectedAges.length === 0,
                            )}`}
                          >
                            {option.label}
                          </button>
                        ))}
                      </View>
                    </View>
                  </View>
                </View>
              </View>

              {/* Tags Filter */}
              <View className="w-full">
                <View className="flex gap-4">
                  {/* Titles Column */}
                  <View className="w-32 text-right pr-2">
                    <Text className="font-medium text-right">Tags:</Text>
                  </View>

                  {/* Options Column */}
                  <View className="flex-1">
                    <View className="flex flex-wrap gap-2">
                      {TAG_OPTIONS.map((tag) => (
                        <button
                          key={tag}
                          onClick={() => toggleTag(tag)}
                          className={`px-3 py-1 rounded-full text-sm transition-colors ${getButtonStyle(
                            selectedTags.includes(tag),
                            selectedTags.length === 0,
                          )}`}
                        >
                          {tag}
                        </button>
                      ))}
                    </View>
                  </View>
                </View>
              </View>

              {/* Pitch Filter */}
              <View className="w-full flex gap-4 h-fit">
                <View className="w-32 text-right pr-2">
                  <Text className="font-medium whitespace-nowrap">Pitch:</Text>
                </View>
                <View className="flex-1 p-2">
                  <Slider
                    min={0}
                    max={500}
                    step={1}
                    value={pitchRange}
                    onChange={onPitchChange}
                  />
                  <View className="flex justify-between mt-1">
                    <Text className="text-sm text-gray-500">{pitchRange[0]} Hz</Text>
                    <Text className="text-sm text-gray-500">{pitchRange[1]} Hz</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </motion.div>
      )}
    </View>
  );
};
