import React, { useState, useRef } from "react";
import { motion } from "framer-motion";
import { View } from "../base/View";
import { HeroVoiceCard } from "./HeroVoiceCard";
import { CarouselProps } from "./types";
import { Icon } from "../base/Icon";

export const HeroVoiceCarousel: React.FC<CarouselProps> = ({ voices, onClickPlay, isPlayingVoiceId, audioElement }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  // Safety check - if no voices, don't render
  if (!voices?.length) return null;

  // Ensure activeIndex is within bounds
  const safeActiveIndex = Math.min(activeIndex, voices.length - 1);
  const activeVoice = voices[safeActiveIndex];

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };

  return (
    <View className="relative">
      <View className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Featured voice */}
        {activeVoice && (
          <motion.div
            key={activeVoice.id}
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            className="md:col-span-2 h-[400px]"
          >
            <HeroVoiceCard
              voice={activeVoice}
              onClickPlay={onClickPlay(activeVoice.id)}
              isPlaying={isPlayingVoiceId === activeVoice.id}
              audioElement={audioElement}
              index={safeActiveIndex}
            />
          </motion.div>
        )}
        {/* Thumbnails */}
        <View className="relative md:col-span-2">
          <View
            className="flex gap-6 overflow-x-auto p-2 pb-4 scroll-smooth"
            ref={scrollContainerRef}
          >
            {voices.map((voice, idx) => (
              <div
                key={voice.id}
                className="flex-shrink-0"
              >
                <button
                  onClick={() => setActiveIndex(idx)}
                  className={`w-40 h-40 rounded-xl overflow-hidden transition-all ${
                    idx === safeActiveIndex ? "ring-2 ring-brand" : "opacity-30 hover:opacity-50"
                  }`}
                >
                  {voice.userImageUpload ? (
                    <img
                      src={voice.userImageUpload}
                      alt={voice.name}
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <View className="w-full h-full bg-gradient-to-br from-indigo-500 to-purple-600" />
                  )}
                </button>
                <p className="mt-2 text-sm text-center text-gray-900 dark:text-gray-200 truncate max-w-[160px]">{voice.name}</p>
              </div>
            ))}
          </View>

          {/* Navigation Arrows */}
          <button
            onClick={scrollLeft}
            className="absolute left-0 top-[96px] -translate-y-1/2 bg-black/50 hover:bg-black/70 p-2 rounded-full backdrop-blur-sm transition-all"
            aria-label="Scroll left"
          >
            <Icon
              name="chevron-left"
              className="text-white"
            />
          </button>
          <button
            onClick={scrollRight}
            className="absolute right-0 top-[96px] -translate-y-1/2 bg-black/50 hover:bg-black/70 p-2 rounded-full backdrop-blur-sm transition-all"
            aria-label="Scroll right"
          >
            <Icon
              name="chevron-right"
              className="text-white"
            />
          </button>
        </View>
      </View>
    </View>
  );
};
