import React, { useState, useEffect, useRef, useCallback } from "react";
import { motion } from "framer-motion";

import { View } from "../../components/base/View";
import { BasicVoiceCard } from "../../components/CardsAndCarousels";
import { voiceActions } from "../../redux/reducers/voiceSlice";
import { useAppDispatch, useAppSelector } from "../../redux";
import { Text } from "../../components/base/Text";
import { Button } from "../../components/base/Button";
import { VoiceFilters, Gender, AgeGroup } from "../../components/base/VoiceFilters";
import { HeroVoiceCarousel, HorizontalCarousel, LargeFeatureVoiceCarousel, SmallFeatureVoiceCarousel } from "../../components/CardsAndCarousels";
import { organizeVoicesIntoSections } from "../../config/voiceGroups";
import { Icon } from "../../components/base/Icon";

export const VoicesPage = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isPlayingVoiceId, setIsPlayingVoiceId] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [viewMode, setViewMode] = useState<"grid" | "carousel">("carousel");

  // Group demographic filters together
  const [demographicFilters, setDemographicFilters] = useState({
    genders: [] as Gender[],
    ages: [] as AgeGroup[],
  });
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [pitchRange, setPitchRange] = useState<[number, number]>([0, 500]); // Default to full range

  const audioRef = useRef<HTMLAudioElement | null>(null);
  const loadMoreRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const voices = useAppSelector((state) => Object.values(state.voice.entities) || []);

  useEffect(() => {
    const loadInitialData = async () => {
      if (!isInitialLoading) return;

      try {
        await dispatch(voiceActions.get({})).unwrap();
      } catch (error) {
        console.error("Failed to load initial voices:", error);
      } finally {
        setIsInitialLoading(false);
      }
    };

    loadInitialData();
  }, [dispatch, isInitialLoading]);

  const loadMoreVoices = useCallback(async () => {
    if (isLoadingMore || !hasMore) return;

    setIsLoadingMore(true);
    try {
      const result = await dispatch(
        voiceActions.get({
          page: String(page + 1),
          limit: "20",
        }),
      ).unwrap();

      setPage((prev) => prev + 1);
      setHasMore(result.length === 20);
    } catch (error) {
      console.error("Failed to load more voices:", error);
    } finally {
      setIsLoadingMore(false);
    }
  }, [dispatch, hasMore, isLoadingMore, page]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !isLoadingMore) {
          clearTimeout(timeoutId);
          timeoutId = setTimeout(() => {
            loadMoreVoices();
          }, 500);
        }
      },
      { threshold: 0.5 },
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
      clearTimeout(timeoutId);
    };
  }, [hasMore, isLoadingMore, loadMoreVoices]);

  const onChangeSearchQuery = (value: string) => {
    setSearchQuery(value);
  };

  // Manage audio event listeners
  useEffect(() => {
    if (!audioRef.current || !isPlayingVoiceId) return;

    const audio = audioRef.current;
    const handleEnded = () => setIsPlayingVoiceId(null);
    const handlePause = () => setIsPlayingVoiceId(null);
    const handleError = () => {
      setIsPlayingVoiceId(null);
      audioRef.current = null;
    };

    audio.addEventListener("ended", handleEnded);
    audio.addEventListener("pause", handlePause);
    audio.addEventListener("error", handleError);

    return () => {
      audio.removeEventListener("ended", handleEnded);
      audio.removeEventListener("pause", handlePause);
      audio.removeEventListener("error", handleError);
    };
  }, [isPlayingVoiceId]);

  // Cleanup audio on unmount
  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
        setIsPlayingVoiceId(null);
      }
    };
  }, []);

  const onClickPlayHandler = (voiceId: string) => () => {
    const voice = voices.find((voice) => voice.id === voiceId);

    if (voice) {
      if (isPlayingVoiceId === voiceId) {
        // If clicking the same voice, just pause it
        audioRef.current?.pause();
        return;
      }

      // Stop any currently playing audio
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }

      // Create and set up new audio element
      audioRef.current = new Audio(voice.preferredPreviewUrl);
      setIsPlayingVoiceId(voiceId);

      // Start playing
      audioRef.current.play().catch(() => {
        setIsPlayingVoiceId(null);
        audioRef.current = null;
      });
    }
  };

  // Update the filter handlers to use the new demographic state
  const handleGenderChange = (genders: Gender[]) => {
    setDemographicFilters((prev) => ({ ...prev, genders }));
  };

  const handleAgeChange = (ages: AgeGroup[]) => {
    setDemographicFilters((prev) => ({ ...prev, ages }));
  };

  const handleTagChange = (tags: string[]) => {
    setSelectedTags(tags);
  };

  const filteredVoices = voices
    .filter((voice) => {
      const matchesSearch = searchQuery
        ? voice.name.toLowerCase().includes(searchQuery.toLowerCase()) || voice.userDescription?.toLowerCase().includes(searchQuery.toLowerCase())
        : true;

      const matchesDemographics =
        (demographicFilters.genders.length === 0 ||
          (voice.userGenderDescription && demographicFilters.genders.some((g) => voice.userGenderDescription?.toLowerCase() === g.toLowerCase()))) &&
        (demographicFilters.ages.length === 0 ||
          (voice.userAgeDescription && demographicFilters.ages.some((a) => voice.userAgeDescription?.toLowerCase() === a.toLowerCase())));

      const matchesTags = selectedTags.length === 0 || (voice.tags && selectedTags.some((tag) => voice.tags?.includes(tag)));
      const matchesPitch = !pitchRange || (voice.meanf0pitch >= pitchRange[0] && voice.meanf0pitch <= pitchRange[1]);

      return matchesSearch && matchesDemographics && matchesTags && matchesPitch;
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  const renderCarouselSection = (section: ReturnType<typeof organizeVoicesIntoSections>[0]) => {
    const commonProps = {
      voices: section.voices,
      onClickPlay: onClickPlayHandler,
      isPlayingVoiceId,
      audioElement: audioRef.current,
    };

    return (
      <motion.div
        key={section.title}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mb-12"
      >
        <View className="mb-4">
          <Text className="text-2xl font-bold mb-2">{section.description}</Text>
          <Text
            variant="body"
            className="text-gray-600 dark:text-gray-400"
          ></Text>
        </View>

        {(() => {
          switch (section.type) {
            case "HeroVoiceCarousel":
              return <HeroVoiceCarousel {...commonProps} />;
            case "HorizontalCarousel":
              return <HorizontalCarousel {...commonProps} />;
            case "SmallFeatureVoiceCarousel":
              return <SmallFeatureVoiceCarousel {...commonProps} />;
            case "LargeFeatureVoiceCarousel":
              return <LargeFeatureVoiceCarousel {...commonProps} />;
            default:
              return null;
          }
        })()}
      </motion.div>
    );
  };

  return (
    <View className="flex-1 bg-white dark:bg-gray-900">
      <View className="container mx-auto px-4 sm:px-6 lg:px-16 py-6 max-w-[1440px]">
        {/* Main Content */}
        <View className="w-full">
          {/* Voice Filters */}
          {viewMode === "grid" && (
            <>
              <Button
                onClick={() => setViewMode("carousel")}
                variant="ghost"
                className="text-brand hover:text-brand-dark whitespace-nowrap flex items-center gap-2 mb-2 pl-0"
              >
                <Icon
                  name="arrow-left"
                  size={20}
                />
                Back to Explore
              </Button>
              <View className="w-full">
                <VoiceFilters
                  selectedGenders={demographicFilters.genders}
                  selectedAges={demographicFilters.ages}
                  selectedTags={selectedTags}
                  pitchRange={pitchRange}
                  searchQuery={searchQuery}
                  onGenderChange={handleGenderChange}
                  onAgeChange={handleAgeChange}
                  onTagChange={handleTagChange}
                  onPitchChange={setPitchRange}
                  onSearchChange={onChangeSearchQuery}
                />
              </View>
            </>
          )}

          {!isInitialLoading && viewMode === "grid" && (
            <View className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-8 w-full">
              {filteredVoices.length === 0 ? (
                <View className="text-center py-12 col-span-full">
                  <Text
                    variant="muted"
                    className="text-lg"
                  >
                    No voices found matching your search
                  </Text>
                </View>
              ) : (
                <>
                  {filteredVoices.map((voice, index) => (
                    <motion.div
                      key={voice.id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.05 }}
                      className="h-full"
                    >
                      <BasicVoiceCard
                        voice={voice}
                        index={index}
                        onClickPlay={onClickPlayHandler(voice.id)}
                        isPlaying={isPlayingVoiceId === voice.id}
                        audioElement={audioRef.current}
                      />
                    </motion.div>
                  ))}
                </>
              )}
            </View>
          )}

          {!isInitialLoading && viewMode === "carousel" && (
            <View className="w-full overflow-hidden">
              {organizeVoicesIntoSections(filteredVoices).map((section, index) => (
                <motion.div
                  key={section.title}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="mb-12"
                >
                  <View className="mb-4 flex justify-between items-center">
                    <Text className="text-2xl font-bold">{section.description}</Text>
                    {index === 0 && (
                      <Button
                        onClick={() => setViewMode(viewMode === "carousel" ? "grid" : "carousel")}
                        variant="ghost"
                        className="text-brand hover:text-brand-dark flex items-center gap-2"
                      >
                        <Icon
                          name="search"
                          size={20}
                        />
                        <span className="hidden sm:inline">Search</span>
                      </Button>
                    )}
                  </View>
                  {(() => {
                    switch (section.type) {
                      case "HeroVoiceCarousel":
                        return (
                          <HeroVoiceCarousel
                            voices={section.voices}
                            onClickPlay={onClickPlayHandler}
                            isPlayingVoiceId={isPlayingVoiceId}
                            audioElement={audioRef.current}
                          />
                        );
                      case "HorizontalCarousel":
                        return (
                          <HorizontalCarousel
                            voices={section.voices}
                            onClickPlay={onClickPlayHandler}
                            isPlayingVoiceId={isPlayingVoiceId}
                            audioElement={audioRef.current}
                          />
                        );
                      case "SmallFeatureVoiceCarousel":
                        return (
                          <SmallFeatureVoiceCarousel
                            voices={section.voices}
                            onClickPlay={onClickPlayHandler}
                            isPlayingVoiceId={isPlayingVoiceId}
                            audioElement={audioRef.current}
                          />
                        );
                      case "LargeFeatureVoiceCarousel":
                        return (
                          <LargeFeatureVoiceCarousel
                            voices={section.voices}
                            onClickPlay={onClickPlayHandler}
                            isPlayingVoiceId={isPlayingVoiceId}
                            audioElement={audioRef.current}
                          />
                        );
                      default:
                        return null;
                    }
                  })()}
                </motion.div>
              ))}
            </View>
          )}

          {hasMore && (
            <View
              ref={loadMoreRef}
              className="flex justify-center py-8"
            >
              {isLoadingMore && <Text variant="muted">Loading more voices...</Text>}
            </View>
          )}
        </View>
      </View>
    </View>
  );
};
