import { API_URL } from "../config";
import { store } from "../redux";
import { sessionActions } from "../redux/reducers/sessionSlice";

export type MakeRequestError = {
  error: string;
};

export type MakeRequestOptions = {
  method?: string;
  headers?: Record<string, string>;
  body?: Record<string, unknown>;
  file?: File;
  files?: FileList;
  blob?: boolean;
};

export const makeRequest = async <T>(url: string, options?: MakeRequestOptions): Promise<T> => {
  let headers = options?.headers || {};
  let bodyFormData = new FormData();
  let body: FormData | string | undefined = undefined;

  if (options?.body) {
    headers["Content-Type"] = "application/json";
    body = JSON.stringify(options?.body);
  }

  if (options?.file) {
    body = bodyFormData;
    bodyFormData.append("file", options.file);
  }

  if (options?.files) {
    body = bodyFormData;
    for (let i = 0; i < options.files.length; i++) {
      bodyFormData.append("file", options.files[i]);
    }
  }

  const response = await fetch(`${API_URL}${url}`, {
    method: options?.method || "GET",
    credentials: "include",
    headers,
    body,
  });

  if (response.status === 204) {
    return null as T;
  }

  if ((response.status === 401 || response.status === 403) && url !== "/v1/auth/session") {
    store.dispatch(sessionActions.getSession());
  }

  if (options?.blob) {
    return response.blob() as T;
  }

  const json = await response.json();
  if (response.ok) {
    return json as T;
  }

  const error = json as MakeRequestError;

  throw new Error(error.error);
};
