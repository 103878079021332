import { Button } from "./Button";
import { Icon } from "./Icon";
import { useEffect, useState } from "react";

interface PlayButtonProps {
  isPlaying: boolean;
  onPlayPause: () => void;
  audioElement?: HTMLAudioElement | null;
  className?: string;
  audioUrl?: string;
}

export const PlayButton = ({ isPlaying, onPlayPause, audioElement, className = "", audioUrl }: PlayButtonProps) => {
  const [progress, setProgress] = useState(0);

  // Compare URLs by creating URL objects to handle different formats
  const isCurrentAudio =
    audioUrl && audioElement?.src
      ? new URL(audioElement.src).pathname.endsWith(new URL(audioUrl).pathname) || new URL(audioElement.src).pathname === new URL(audioUrl).pathname
      : false;

  useEffect(() => {
    if (!audioElement) return;

    const handleTimeUpdate = () => {
      if (!isCurrentAudio) return;
      if (!audioElement.duration || audioElement.duration === Infinity) return;
      const currentProgress = (audioElement.currentTime / audioElement.duration) * 100;
      setProgress(Math.min(currentProgress, 100));
    };

    audioElement.addEventListener("timeupdate", handleTimeUpdate);
    return () => {
      audioElement.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, [audioElement, isCurrentAudio]);

  // Reset progress when playback stops
  useEffect(() => {
    if (!isPlaying) {
      setProgress(0);
    }
  }, [isPlaying]);

  const circumference = 2 * Math.PI * 11; // radius of 11 for a 24px circle

  return (
    <Button
      onClick={onPlayPause}
      type="basic"
      variant="ghost"
      className={`!w-8 !h-8 !rounded-full relative ${className}`}
    >
      <svg
        className="absolute inset-0 -rotate-90 w-full h-full"
        viewBox="0 0 24 24"
      >
        <circle
          cx="12"
          cy="12"
          r="11"
          strokeWidth="2"
          fill="none"
          className="stroke-gray-200 dark:stroke-gray-700"
        />
        <circle
          cx="12"
          cy="12"
          r="11"
          strokeWidth="2"
          fill="none"
          className="stroke-brand dark:stroke-brand-light transition-all duration-200"
          strokeDasharray={circumference}
          strokeDashoffset={isCurrentAudio ? circumference - (progress / 100) * circumference : circumference}
          strokeLinecap="round"
        />
      </svg>
      <Icon
        name={isPlaying ? "pause" : "play-arrow"}
        size={18}
        className="relative z-10"
      />
    </Button>
  );
};
