import Editor from "@monaco-editor/react";
import { useCallback, useEffect, useRef, useState } from "react";
import * as monaco from "monaco-editor";

import { View } from "../base/View";
import { useTheme } from "../../contexts/ThemeContext";
import clsx from "clsx";

type AgentFunctionEditorProps = {
  value: string;
  onChange: (value: string) => void;
  readOnly?: boolean;
};

export const AgentFunctionEditor = ({ value, onChange, readOnly = false }: AgentFunctionEditorProps) => {
  const { theme } = useTheme();
  const [showEditor, setShowEditor] = useState(true);
  const resizeTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor>();

  const handleEditorChange = useCallback(
    (value: string | undefined) => {
      if (value !== undefined) {
        onChange(value);
      }
    },
    [onChange],
  );

  useEffect(() => {
    setShowEditor(showEditor);
  }, [showEditor]);

  useEffect(() => {
    const handleResize = () => {
      setShowEditor(false);

      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }

      resizeTimeoutRef.current = setTimeout(() => {
        setShowEditor(true);
      }, 300);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <View className="relative flex flex-col flex-grow border rounded-md overflow-hidden h-full">
      <View className={clsx("absolute top-0 left-0 right-0 bottom-0", showEditor ? "z-0" : "z-10")}>
        <View
          className={clsx(
            "w-full h-full bg-black dark:bg-[#1e1e1e] transition-all duration-100 dark:duration-200",
            showEditor ? "opacity-0" : "opacity-50 dark:opacity-100",
          )}
        />
      </View>
      {showEditor && (
        <View className="flex-grow z-0">
          <Editor
            height="100%"
            defaultLanguage="python"
            value={value}
            onChange={handleEditorChange}
            theme={theme === "dark" ? "vs-dark" : "light"}
            onMount={(editor, monaco) => {
              editorRef.current = editor;

              // Add Python-specific completions
              monaco.languages.registerCompletionItemProvider("python", {
                provideCompletionItems: (model, position) => {
                  const word = model.getWordUntilPosition(position);
                  const range = {
                    startLineNumber: position.lineNumber,
                    endLineNumber: position.lineNumber,
                    startColumn: word.startColumn,
                    endColumn: word.endColumn,
                  };

                  const suggestions = [
                    {
                      label: "def",
                      kind: monaco.languages.CompletionItemKind.Keyword,
                      insertText: "def ${1:function_name}(${2:parameters}):\n\t${3:pass}",
                      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                      range,
                    },
                    {
                      label: "class",
                      kind: monaco.languages.CompletionItemKind.Keyword,
                      insertText: "class ${1:ClassName}:\n\tdef __init__(self${2:, parameters}):\n\t\t${3:pass}",
                      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                      range,
                    },
                    {
                      label: "if",
                      kind: monaco.languages.CompletionItemKind.Keyword,
                      insertText: "if ${1:condition}:\n\t${2:pass}",
                      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                      range,
                    },
                    {
                      label: "for",
                      kind: monaco.languages.CompletionItemKind.Keyword,
                      insertText: "for ${1:item} in ${2:iterable}:\n\t${3:pass}",
                      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                      range,
                    },
                    {
                      label: "while",
                      kind: monaco.languages.CompletionItemKind.Keyword,
                      insertText: "while ${1:condition}:\n\t${2:pass}",
                      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                      range,
                    },
                    {
                      label: "try",
                      kind: monaco.languages.CompletionItemKind.Keyword,
                      insertText: "try:\n\t${1:pass}\nexcept ${2:Exception} as ${3:e}:\n\t${4:pass}",
                      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                      range,
                    },
                    {
                      label: "return",
                      kind: monaco.languages.CompletionItemKind.Keyword,
                      insertText: "return ${1:value}",
                      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                      range,
                    },
                    {
                      label: "print",
                      kind: monaco.languages.CompletionItemKind.Function,
                      insertText: "print(${1:value})",
                      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                      range,
                    },
                    {
                      label: "import",
                      kind: monaco.languages.CompletionItemKind.Keyword,
                      insertText: "import ${1:module}",
                      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                      range,
                    },
                    {
                      label: "from",
                      kind: monaco.languages.CompletionItemKind.Keyword,
                      insertText: "from ${1:module} import ${2:name}",
                      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                      range,
                    },
                  ];
                  return { suggestions };
                },
              });
            }}
            options={{
              minimap: { enabled: true },
              fontSize: 14,
              lineNumbers: "on",
              roundedSelection: false,
              scrollBeyondLastLine: false,
              readOnly,
              readOnlyMessage: { value: "This function is deployed and cannot be edited.\n\nCreate a new version from the menu to make changes." },
              automaticLayout: showEditor, // Disable automatic layout since we're handling it manually
              tabSize: 4,
              wordWrap: "on",
              suggestOnTriggerCharacters: true,
              quickSuggestions: true,
              parameterHints: { enabled: true },
              snippetSuggestions: "top",
              formatOnPaste: true,
              formatOnType: true,
              scrollbar: {
                vertical: "visible",
                horizontal: "visible",
                useShadows: false,
                verticalScrollbarSize: 10,
                horizontalScrollbarSize: 10,
                verticalSliderSize: 10,
                horizontalSliderSize: 10,
                arrowSize: 30,
              },
            }}
          />
        </View>
      )}
    </View>
  );
};
