import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useApiRequest } from "../hooks/useApiRequest";
import { sessionActions } from "../redux/reducers/sessionSlice";
import { useAppDispatch } from "../redux";
import { View } from "../components/base/View";
import { Text } from "../components/base/Text";

export const InviteAcceptPage = () => {
  const { inviteId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const acceptInviteRequest = useApiRequest<{ data: { status: string; email?: string } }>();

  const acceptInvite = async () => {
    const { data, error } = await acceptInviteRequest.fetch(`/v1/organizations/invites/${inviteId}/accept`, {
      method: "POST",
    });

    if (!error) {
      if (data?.data.status === "accepted") {
        navigate("/create");
      } else if (data?.data.status === "signup") {
        dispatch(sessionActions.setInvite({ id: inviteId, email: data?.data.email }));
        navigate("/signup");
      } else if (data?.data.status === "signin") {
        dispatch(sessionActions.setInvite({ id: inviteId, email: data?.data.email }));
        navigate("/signin");
      }
    } else {
      setTimeout(() => {
        navigate("/");
      }, 3000); // Redirect after 3 seconds
    }
  };

  useEffect(() => {
    acceptInvite();
  }, []);

  if (acceptInviteRequest.error) {
    return (
      <View className="flex flex-col items-center justify-center min-h-screen p-4 space-y-4">
        <Text variant="error">{acceptInviteRequest.error.message}</Text>
        <Text
          variant="muted"
          className="animate-pulse"
        >
          Redirecting to home...
        </Text>
      </View>
    );
  }

  return (
    <View className="flex flex-col items-center justify-center min-h-screen p-4">
      <Text
        variant="muted"
        className="animate-pulse text-lg"
      >
        Accepting invitation...
      </Text>
    </View>
  );
};
