import { createRestSlice } from "./restSlice";

export type Agent = {
  id: string;
  userId: string;
  name: string;
  description: string;
  defaultVoiceId: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
};

const agentSlice = createRestSlice<Agent>("agent", "/v1/agents");

export const agentActions = agentSlice.actions;
export const agentReducer = agentSlice.reducer;
