import React, { useState, useEffect } from "react";
import { View } from "../base/View";
import { InputField } from "../base/InputField";
import { Text } from "../base/Text";
import { Button } from "../base/Button";
import { Icon } from "../base/Icon";
import { Tooltip } from "../base/Tooltip";
import { Switch } from "../base/Switch";
import { Voice } from "../../types";
import { Generation } from "../../redux/reducers/generationSlice";

interface SpeechGenerationOptionsProps {
  formData: Partial<Generation>;
  setFormData: (data: Partial<Generation>) => void;
  selectedVoice?: Voice;
  selectedVoiceIsPlaying: boolean;
  handleVoicePlayPause: () => void;
  audioRef: React.RefObject<HTMLAudioElement>;
  voiceGenerationRef: React.RefObject<HTMLDivElement>;
}

export const SpeechGenerationOptions = ({
  formData,
  setFormData,
  selectedVoice,
  selectedVoiceIsPlaying,
  handleVoicePlayPause,
  audioRef,
  voiceGenerationRef,
}: SpeechGenerationOptionsProps) => {
  const [styleMode, setStyleMode] = useState<"describe" | "match">(formData.quality === "echo" ? "match" : "describe");
  const [styleDescription, setStyleDescription] = useState<string>(selectedVoice?.description || "");

  // Update just the notes without changing quality
  const updateDescriptionOnly = (description: string) => {
    setFormData({
      ...formData,
      notes: `Style Description: ${description}`,
    });
  };

  // Update the style description when selected Voice changes
  useEffect(() => {
    if (selectedVoice?.description) {
      setStyleDescription(selectedVoice.description);
      if (styleMode === "describe") {
        // Only update the description without changing quality
        updateDescriptionOnly(selectedVoice.description);
      }
    }
  }, [selectedVoice]);

  // Update styleMode when formData.quality changes
  useEffect(() => {
    setStyleMode(formData.quality === "echo" ? "match" : "describe");
  }, [formData.quality]);

  // Function to handle style selection
  const handleStyleSelect = (mode: "describe" | "match") => {
    setStyleMode(mode);
    setFormData({
      ...formData,
      notes: mode === "describe" ? `Style Description: ${styleDescription}` : `Style: Match voice style`,
      quality: mode === "describe" ? "high" : "echo",
    });
  };

  const styleOptions = [
    {
      label: "Describe Style",
      value: "describe",
    },
    {
      label: "Match Sample",
      value: "match",
    },
  ];

  return (
    <View className="flex flex-col flex-grow gap-4 min-h-[200px]">
      <View className="flex-grow">
        <InputField
          name="text"
          label="Your Text"
          placeholder="Enter the text you want to convert to speech..."
          type="textarea"
          className="bg-opacity-90 w-full h-full overflow-y-auto !min-h-[200px]"
          labelClassName="w-full flex flex-col h-full min-h-[200px]"
          onChange={(value) => {
            if (value.length <= 5000) {
              setFormData({ ...formData, text: value });
            }
          }}
          value={formData.text}
          suffix={<Text className="text-gray-400 dark:text-gray-500 text-sm">{`${formData.text?.length || 0}/5000`}</Text>}
          autoGrow={false}
        />
      </View>
      {(formData.quality === "high" || formData.quality === "echo") && (
        <View className="w-full">
          <View className="w-full mb-2 flex flex-row items-center">
            <Text className="text-gray-600 dark:text-gray-300 text-sm font-medium">Style</Text>
            <Tooltip content='Our Studio model will not create explicit content during "Use Description"'>
              <Button
                type="basic"
                variant="ghost"
                className="!p-1 !bg-transparent hover:!bg-transparent text-gray-400 hover:text-gray-600 dark:hover:text-white transition-colors ml-1"
              >
                <Icon
                  name="info"
                  size={16}
                />
              </Button>
            </Tooltip>
          </View>

          {/* Style Selection */}
          <View className="w-full mb-4">
            <Switch
              options={styleOptions}
              value={styleMode}
              onChange={(value) => handleStyleSelect(value as "describe" | "match")}
              className="bg-gray-100 dark:bg-gray-700 w-full"
            />
          </View>

          {/* Warning Messages */}
          {selectedVoice && !selectedVoice.isCloned && styleMode === "match" && (
            <View className="w-full mb-4 p-3 bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg">
              <View className="flex flex-row items-start gap-2">
                <Icon
                  name="info"
                  size={16}
                  className="text-gray-500 dark:text-gray-400 mt-0.5 shrink-0"
                />
                <Text className="text-sm text-gray-600 dark:text-gray-300">
                  Designed voices perform better with the "Describe Style" option. Consider switching for optimal results.
                </Text>
              </View>
            </View>
          )}

          {selectedVoice && selectedVoice.isCloned && styleMode === "describe" && (
            <View className="w-full mb-4 p-3 bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg">
              <View className="flex flex-row items-start gap-2">
                <Icon
                  name="info"
                  size={16}
                  className="text-gray-500 dark:text-gray-400 mt-0.5 shrink-0"
                />
                <Text className="text-sm text-gray-600 dark:text-gray-300">
                  The generated voice will only preserve the pitch and tone of your cloned voice.
                </Text>
              </View>
            </View>
          )}

          {styleMode === "describe" ? (
            <View className="w-full">
              <Text className="text-sm text-gray-500 dark:text-gray-400 mb-1">Description</Text>
              <View className="relative">
                <InputField
                  name="styleDescription"
                  type="textarea"
                  className="w-full !min-h-[120px] border border-gray-200 dark:border-gray-700"
                  labelClassName="w-full flex flex-col !min-h-[120px]"
                  onChange={(value) => {
                    if (value.length <= 150) {
                      setStyleDescription(value);
                      // Only update the notes without changing the model quality
                      updateDescriptionOnly(value);
                    }
                  }}
                  value={styleDescription}
                  suffix={<Text className="text-gray-400 dark:text-gray-500 text-sm">{`${styleDescription.length}/150`}</Text>}
                  placeholder="Describe the speaking style, accent, or emotion you want..."
                />
              </View>
            </View>
          ) : (
            <View className="w-full">
              <Text className="text-sm text-gray-500 dark:text-gray-400 mb-1">Sample-based Style</Text>
              <View className="w-full p-3 border border-gray-200 dark:border-gray-700 rounded-lg">
                <Text className="text-sm text-gray-600 dark:text-gray-300">
                  The model will attempt to match the style (accent and pacing) of the input voice sample.
                </Text>
              </View>
            </View>
          )}
        </View>
      )}
    </View>
  );
};
