import { useEffect, useRef, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import clsx from "clsx";

import { AccountCard } from "../components/profile/AccountCard";
import { AddVoiceDialog } from "../components/AddVoiceDialog";
import { ApiKey } from "../redux/reducers/userSlice";
import { ApiKeyList } from "../components/profile/ApiKeyList";
import { BalanceCard } from "../components/profile/BalanceCard";
import { Button } from "../components/base/Button";
import { CreateInvitePayload } from "../redux/reducers/organizationSlice";
import { Dialog } from "../components/base/Dialog";
import { GenerationList } from "../components/profile/GenerationList";
import { Icon } from "../components/base/Icon";
import { InputField } from "../components/base/InputField";
import { organizationActions } from "../redux/reducers/organizationSlice";
import { OrganizationCard } from "../components/profile/OrganizationCard";
import { ProfileVoiceList } from "../components/profile/ProfileVoiceList";
import { STRIPE_PUBLISHABLE_KEY, API_URL } from "../config";
import { Text } from "../components/base/Text";
import { useApiRequest } from "../hooks/useApiRequest";
import { useAppDispatch, useAppSelector } from "../redux";
import { useNavigation } from "../hooks/useNavigation";
import { View } from "../components/base/View";
import { VariablesForm } from "../components/VariablesForm";
import { OrganizationVariable, organizationVariablesActions } from "../redux/reducers/organizationVariablesSlice";

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

type Tab = {
  id: string;
  label: string;
  icon: string;
};

const tabs: Tab[] = [
  { id: "overview", label: "Overview", icon: "home" },
  { id: "organization", label: "Organization", icon: "users" },
  { id: "api-keys", label: "API Keys", icon: "key" },
  { id: "voices", label: "Voices", icon: "mic" },
  { id: "generations", label: "Generations", icon: "volume-up" },
  { id: "variables", label: "Agent Variables", icon: "list" },
];

export const ProfilePage = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const user = useAppSelector((state) => state.session.user);
  const currentOrganization = useAppSelector((state) => state.organization.currentOrganization);
  const organizationMembers = useAppSelector((state) => state.organization.members);
  const organizationInvites = useAppSelector((state) => state.organization.invites);
  const [activeTab, setActiveTab] = useState(() => {
    // Initialize from URL hash or default to 'overview'
    const hash = window.location.hash.slice(1);
    return tabs.some((tab) => tab.id === hash) ? hash : "overview";
  });
  const [showDescriptionDialog, setShowDescriptionDialog] = useState(false);
  const [description, setDescription] = useState("");
  const [showApiKeyDialog, setShowApiKeyDialog] = useState(false);
  const [showPurchaseDialog, setShowPurchaseDialog] = useState(false);
  const [newApiKey, setNewApiKey] = useState("");
  const [showCopySuccess, setShowCopySuccess] = useState(false);
  const [amount, setAmount] = useState<number>(10);
  const [isCustomAmount, setIsCustomAmount] = useState(false);
  const [customAmount, setCustomAmount] = useState<string>("");
  const [customAmountError, setCustomAmountError] = useState<string>("");
  const [showAddVoiceDialog, setShowAddVoiceDialog] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const createApiKeyRequest = useApiRequest<{ data: ApiKey }>();
  const getApiKeysRequest = useApiRequest<{ data: ApiKey[] }>();
  const revokeApiKeyRequest = useApiRequest();
  const createPaymentRequest = useApiRequest<{ sessionId: string }>();
  const [isPlayingVoiceId, setIsPlayingVoiceId] = useState("");
  const currentPlayingVoice = useRef<HTMLAudioElement | null>(null);
  const voices = Object.values(useAppSelector((state) => state.voice.entities)).filter((voice) => voice.isOwner);
  const organizationVariables = useAppSelector((state) => Object.values(state.organizationVariables.entities));

  // Update URL hash when active tab changes
  useEffect(() => {
    window.location.hash = activeTab;
  }, [activeTab]);

  // Listen for hash changes
  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.slice(1);
      if (tabs.some((tab) => tab.id === hash)) {
        setActiveTab(hash);
      }
    };

    window.addEventListener("hashchange", handleHashChange);
    return () => window.removeEventListener("hashchange", handleHashChange);
  }, []);

  // Calculate volume discount based on amount
  const calculateDiscount = (amount: number) => {
    if (amount >= 100) return 0.15; // 15% off for $100+
    if (amount >= 50) return 0.1; // 10% off for $50+
    return 0; // No discount
  };

  // Organization handlers
  const handleInviteUser = (email: string, role: "admin" | "member") => {
    if (currentOrganization) {
      const payload: CreateInvitePayload = { email, role };
      dispatch(
        organizationActions.createInvite({
          organizationId: currentOrganization.id,
          payload,
        }),
      );
    }
  };

  const handleCancelInvite = (inviteId: string) => {
    if (currentOrganization) {
      dispatch(
        organizationActions.cancelInvite({
          organizationId: currentOrganization.id,
          inviteId,
        }),
      );
    }
  };

  const handleUpdateMemberRole = (memberId: string, role: "admin" | "member") => {
    if (currentOrganization) {
      dispatch(
        organizationActions.updateMemberRole({
          organizationId: currentOrganization.id,
          memberId,
          role,
        }),
      );
    }
  };

  const handleRemoveMember = (memberId: string) => {
    if (currentOrganization) {
      dispatch(
        organizationActions.removeMember({
          organizationId: currentOrganization.id,
          memberId,
        }),
      );
    }
  };

  const onClickPurchaseAmount = async () => {
    try {
      const stripe = await stripePromise;
      if (!stripe) throw new Error("Stripe failed to load");

      const response = await createPaymentRequest.fetch("/v1/payments/create-session", {
        method: "POST",
        body: { amount },
      });

      const result = await stripe.redirectToCheckout({
        sessionId: response.data?.sessionId || "",
      });

      if (result.error) {
        console.error(result.error);
      }
    } catch (err) {
      console.error("Payment error:", err);
    }
  };

  const onClickCreateApiKey = async () => {
    const response = await createApiKeyRequest.fetch("/v1/users/apiKeys", {
      method: "POST",
      body: { description },
    });

    if (response?.data) {
      setNewApiKey(response.data?.data?.token || "");
      setShowApiKeyDialog(true);
      setShowDescriptionDialog(false);
      setDescription("");
      getApiKeysRequest.fetch("/v1/users/apiKeys");
    }
  };

  const onClickDeleteApiKey = async (keyId: string) => {
    await revokeApiKeyRequest.fetch(`/v1/users/apiKeys/${keyId}`, {
      method: "DELETE",
    });
    getApiKeysRequest.fetch("/v1/users/apiKeys");
  };

  const onClickCopyApiKey = async () => {
    await navigator.clipboard.writeText(newApiKey);
    setShowCopySuccess(true);
    setTimeout(() => setShowCopySuccess(false), 1500);
  };

  // Manage voice audio event listeners
  useEffect(() => {
    if (!currentPlayingVoice.current || !isPlayingVoiceId) return;

    const audio = currentPlayingVoice.current;
    const handleEnded = () => setIsPlayingVoiceId("");
    const handlePause = () => setIsPlayingVoiceId("");
    const handleError = () => {
      setIsPlayingVoiceId("");
      currentPlayingVoice.current = null;
    };

    audio.addEventListener("ended", handleEnded);
    audio.addEventListener("pause", handlePause);
    audio.addEventListener("error", handleError);

    return () => {
      audio.removeEventListener("ended", handleEnded);
      audio.removeEventListener("pause", handlePause);
      audio.removeEventListener("error", handleError);
    };
  }, [isPlayingVoiceId]);

  // Cleanup audio on unmount
  useEffect(() => {
    return () => {
      if (currentPlayingVoice.current) {
        currentPlayingVoice.current.pause();
        currentPlayingVoice.current = null;
        setIsPlayingVoiceId("");
      }
    };
  }, []);

  const onClickPlayVoiceHandler = (voiceId: string, preferredPreviewUrl: string) => {
    if (isPlayingVoiceId === voiceId) {
      // If clicking the same voice, just pause it
      currentPlayingVoice.current?.pause();
      return;
    }

    // Stop any currently playing audio
    if (currentPlayingVoice.current) {
      currentPlayingVoice.current.pause();
      currentPlayingVoice.current = null;
    }

    // Create and set up new audio element
    currentPlayingVoice.current = new Audio(preferredPreviewUrl);
    setIsPlayingVoiceId(voiceId);

    // Start playing
    currentPlayingVoice.current.play().catch(() => {
      setIsPlayingVoiceId("");
      currentPlayingVoice.current = null;
    });
  };

  const onClickDownloadHandler = (audioUrl: string) => {
    if (audioUrl) {
      const link = document.createElement("a");
      const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
      link.download = `audio-${timestamp}.wav`;
      link.href = audioUrl;
      link.click();
      link.remove();
    }
  };

  const onChangeOrganizationVariables = (updatedVariable: Partial<OrganizationVariable>) => {
    dispatch(organizationVariablesActions.updateById({ ...updatedVariable, organizationId: currentOrganization?.id }));
  };

  const onClickAddOrganizationVariable = () => {
    dispatch(
      organizationVariablesActions.create({
        name: "",
        value: "",
        masked: false,
        organizationId: currentOrganization?.id,
      }),
    );
  };

  const onClickDeleteOrganizationVariable = async (id: string) => {
    await dispatch(
      organizationVariablesActions.deleteById({
        id,
        organizationId: currentOrganization?.id,
      }),
    );
  };

  useEffect(() => {
    if (currentOrganization) {
      dispatch(
        organizationVariablesActions.get({
          organizationId: currentOrganization.id,
        }),
      );
    }
  }, [currentOrganization]);

  const renderTabContent = () => {
    switch (activeTab) {
      case "overview":
        return (
          <div className="space-y-6">
            <AccountCard email={user?.email} />
            <BalanceCard
              balance={user?.balance || 0}
              onClickAddFunds={() => setShowPurchaseDialog(true)}
            />
          </div>
        );
      case "organization":
        return (
          currentOrganization && (
            <OrganizationCard
              members={organizationMembers}
              invites={organizationInvites}
              onInviteUser={handleInviteUser}
              onCancelInvite={handleCancelInvite}
              onUpdateMemberRole={handleUpdateMemberRole}
              onRemoveMember={handleRemoveMember}
            />
          )
        );
      case "api-keys":
        return (
          <ApiKeyList
            apiKeys={getApiKeysRequest.data?.data || []}
            onCreateKey={() => setShowDescriptionDialog(true)}
            onDeleteKey={onClickDeleteApiKey}
            isLoading={getApiKeysRequest.isLoading}
          />
        );
      case "voices":
        return (
          <ProfileVoiceList
            voices={voices}
            onAddVoice={() => setShowAddVoiceDialog(true)}
            onClickPlay={onClickPlayVoiceHandler}
            isPlayingVoiceId={isPlayingVoiceId}
            audioElement={currentPlayingVoice.current}
          />
        );
      case "generations":
        return (
          <GenerationList
            onCreateNew={() => navigation.navigate("/create")}
            onDownload={onClickDownloadHandler}
            onGenerateWithConfig={(generation) =>
              navigation.navigate("/create", {
                voiceId: generation.voiceId,
                text: encodeURIComponent(generation.text),
                notes: generation.notes ? encodeURIComponent(generation.notes) : undefined,
              })
            }
            variant="grid"
          />
        );
      case "variables":
        return (
          <VariablesForm
            variables={organizationVariables}
            onChange={onChangeOrganizationVariables}
            onClickAdd={onClickAddOrganizationVariable}
            onClickDelete={onClickDeleteOrganizationVariable}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    getApiKeysRequest.fetch("/v1/users/apiKeys");

    dispatch(organizationActions.getCurrentOrganization());

    if (currentOrganization) {
      dispatch(organizationActions.getOrganizationMembers(currentOrganization.id));
      dispatch(organizationActions.getOrganizationInvites(currentOrganization.id));
    }
  }, [currentOrganization?.id]);

  return (
    <View className="flex-grow bg-white dark:bg-gray-900">
      {/* Mobile Header */}
      <div className="lg:hidden bg-white dark:bg-gray-800 border-b dark:border-gray-700 sticky top-0 z-10">
        <div className="px-4 py-3 flex items-center justify-between">
          <h1 className="text-xl font-semibold text-gray-900 dark:text-white">Profile</h1>
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="p-2 rounded-md text-gray-500 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300"
          >
            <Icon
              name={isMobileMenuOpen ? "x" : "menu"}
              size={24}
            />
          </button>
        </div>
        {isMobileMenuOpen && (
          <div className="border-t dark:border-gray-700">
            <nav className="px-2 py-3">
              {tabs.map((tab) => (
                <button
                  key={tab.id}
                  onClick={() => {
                    setActiveTab(tab.id);
                    setIsMobileMenuOpen(false);
                  }}
                  className={clsx(
                    "w-full flex items-center px-3 py-2 text-sm font-medium rounded-md mb-1",
                    activeTab === tab.id ? "bg-brand text-white" : "text-gray-600 hover:bg-gray-50 dark:text-gray-300 dark:hover:bg-gray-800",
                  )}
                >
                  <Icon
                    name={tab.icon}
                    size={20}
                    className="mr-3"
                  />
                  {tab.label}
                </button>
              ))}
            </nav>
          </div>
        )}
      </div>

      <div className="flex h-full">
        {/* Desktop Sidebar */}
        <div className="hidden lg:flex lg:flex-col lg:w-64 lg:h-full lg:inset-y-0 border-r dark:border-gray-700">
          <div className="flex flex-col flex-grow py-4 bg-white dark:bg-gray-800 overflow-y-auto">
            <nav className="flex-1 px-4 space-y-1">
              {tabs.map((tab) => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={clsx(
                    "w-full flex items-center px-3 py-2 text-sm font-medium rounded-md",
                    activeTab === tab.id ? "bg-brand text-white" : "text-gray-600 hover:bg-gray-50 dark:text-gray-300 dark:hover:bg-gray-800",
                  )}
                >
                  <Icon
                    name={tab.icon}
                    size={20}
                    className="mr-3"
                  />
                  {tab.label}
                </button>
              ))}
            </nav>
          </div>
        </div>

        {/* Main Content */}
        <main className="flex flex-1">
          <View className="flex flex-col flex-1 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">{renderTabContent()}</View>
        </main>
      </div>

      {/* Existing Dialogs */}
      <AddVoiceDialog
        isOpen={showAddVoiceDialog}
        onClose={() => setShowAddVoiceDialog(false)}
      />

      <Dialog
        isOpen={showDescriptionDialog}
        onClose={() => setShowDescriptionDialog(false)}
        title="New API Key"
      >
        <View className="p-4 space-y-4">
          <Text variant="body">Enter a description for your new API key:</Text>
          <InputField
            value={description}
            onChange={(description) => setDescription(description)}
            placeholder="Enter key description..."
            className="w-full text-slate-700"
          />
          <Button
            onClick={onClickCreateApiKey}
            isDisabled={!description || createApiKeyRequest.isLoading}
            className="bg-brand hover:bg-brand-dark disabled:bg-brand/50 text-white px-4 py-2 w-full"
          >
            {createApiKeyRequest.isLoading ? "Generating..." : "Generate API Key"}
          </Button>
        </View>
      </Dialog>

      <Dialog
        isOpen={showApiKeyDialog}
        onClose={() => setShowApiKeyDialog(false)}
        title="Your New API Key"
      >
        <View className="flex flex-col p-4">
          <Text
            variant="body"
            className="mb-4"
          >
            Please save this API key - you won't be able to see it again!
          </Text>
          <Text
            variant="body"
            className="mb-4"
          >
            You can view documentation for the API{" "}
            <a
              href={`${API_URL}/docs`}
              target="_blank"
              className="text-brand hover:text-brand-light transition-colors"
            >
              here
            </a>
            .
          </Text>
          <View className="flex items-center space-x-2">
            <InputField
              value={newApiKey}
              readOnly
              className="flex-1"
            />
            <Button
              onClick={onClickCopyApiKey}
              variant="default"
              className="whitespace-nowrap"
            >
              {showCopySuccess ? "Copied!" : "Copy Key"}
            </Button>
          </View>
        </View>
      </Dialog>

      <Dialog
        isOpen={showPurchaseDialog}
        onClose={() => setShowPurchaseDialog(false)}
        title="Add Funds"
      >
        <View className="p-4 space-y-4">
          <Text>Select Amount:</Text>
          <View className="grid grid-cols-2 gap-2">
            <Button
              onClick={() => {
                setAmount(5);
                setIsCustomAmount(false);
                setCustomAmount("");
              }}
              variant="outline"
              className={`w-full ${amount === 5 && !isCustomAmount ? "bg-brand text-white" : ""}`}
            >
              $5
            </Button>
            <Button
              onClick={() => {
                setAmount(10);
                setIsCustomAmount(false);
                setCustomAmount("");
              }}
              variant="outline"
              className={`w-full ${amount === 10 && !isCustomAmount ? "bg-brand text-white" : ""}`}
            >
              $10
            </Button>
            <Button
              onClick={() => {
                setAmount(25);
                setIsCustomAmount(false);
                setCustomAmount("");
              }}
              variant="outline"
              className={`w-full ${amount === 25 && !isCustomAmount ? "bg-brand text-white" : ""}`}
            >
              $25
            </Button>
            <Button
              onClick={() => {
                setAmount(50);
                setIsCustomAmount(false);
                setCustomAmount("");
              }}
              variant="outline"
              className={`w-full ${amount === 50 && !isCustomAmount ? "bg-brand text-white" : ""}`}
            >
              $50
            </Button>
          </View>

          <Button
            onClick={() => {
              setIsCustomAmount(true);
              setAmount(0);
            }}
            variant="outline"
            className={`w-full ${isCustomAmount ? "bg-brand text-white" : ""}`}
          >
            Enter Custom Amount
          </Button>

          {isCustomAmount && (
            <View className="space-y-2">
              <Text>Enter custom amount (USD):</Text>
              <View className="relative">
                <Text className="absolute left-3 top-1/2 -translate-y-1/2">$</Text>
                <InputField
                  type="number"
                  value={customAmount}
                  onChange={(value) => {
                    setCustomAmount(value);
                    const numValue = Number(value) || 0;
                    if (numValue < 5 && value !== "") {
                      setCustomAmountError("Minimum amount is $5");
                      setAmount(0);
                    } else {
                      setCustomAmountError("");
                      setAmount(numValue);
                    }
                  }}
                  className={`w-full pl-7 ${customAmountError ? "border-red-500 dark:border-red-400" : ""}`}
                  placeholder="0.00"
                />
              </View>
              {customAmountError && <Text className="text-red-500 dark:text-red-400 text-sm">{customAmountError}</Text>}
            </View>
          )}

          <Button
            onClick={onClickPurchaseAmount}
            isDisabled={amount < 5 || createPaymentRequest.isLoading}
            className="bg-brand hover:bg-brand-dark disabled:bg-brand/50 text-white px-4 py-2 w-full"
          >
            {createPaymentRequest.isLoading ? "Processing..." : `Add $${amount === 0 ? "0.00" : amount.toFixed(2)}`}
          </Button>
        </View>
      </Dialog>
    </View>
  );
};
