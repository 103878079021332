export const getGradientForName = (name: string) => {
  // Generate a simple hash from the name
  const hash = name.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0);

  // Define a set of gradient pairs
  const gradients = [
    { from: "from-indigo-400", to: "to-purple-600", darkFrom: "dark:from-indigo-600", darkTo: "dark:to-purple-800" },
    { from: "from-blue-400", to: "to-teal-600", darkFrom: "dark:from-blue-600", darkTo: "dark:to-teal-800" },
    { from: "from-green-400", to: "to-emerald-600", darkFrom: "dark:from-green-600", darkTo: "dark:to-emerald-800" },
    { from: "from-amber-400", to: "to-orange-600", darkFrom: "dark:from-amber-600", darkTo: "dark:to-orange-800" },
    { from: "from-red-400", to: "to-pink-600", darkFrom: "dark:from-red-600", darkTo: "dark:to-pink-800" },
    { from: "from-purple-400", to: "to-indigo-600", darkFrom: "dark:from-purple-600", darkTo: "dark:to-indigo-800" },
    { from: "from-cyan-400", to: "to-blue-600", darkFrom: "dark:from-cyan-600", darkTo: "dark:to-blue-800" },
  ];

  // Select a gradient based on the hash
  const gradientIndex = hash % gradients.length;
  return gradients[gradientIndex];
};
