import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button } from "../../components/base/Button";
import { Icon } from "../../components/base/Icon";
import { Text } from "../../components/base/Text";
import { View } from "../../components/base/View";
import { useAppDispatch, useAppSelector } from "../../redux";
import { VariablesForm } from "../../components/VariablesForm";
import { EmptyMessage } from "../../components/EmptyMessage";
import { agentEnvironmentActions } from "../../redux/reducers/agentEnvironmentSlice";
import { CreateAgentEnvironmentDialog } from "../../components/agents/CreateAgentEnvironmentDialog";
import { useSearchParam } from "../../hooks/useSearchParam";
import { FieldLabel } from "../../components/base/FieldLabel";
import { featureActions } from "../../redux/reducers/featureSlice";
import { InputField } from "../../components/base/InputField";
import { AgentEnvironmentVariable, agentEnvironmentVariableActions } from "../../redux/reducers/agentEnvironmentVariableSlice";
import { useDebouncedCallback } from "use-debounce";
import { getGradientForName } from "../../utils/gradients";
import { useAuth } from "../../hooks/useAuth";

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.3 },
  },
  exit: {
    opacity: 0,
    y: -20,
    transition: { duration: 0.2 },
  },
};

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 0.3 },
  },
};

export const AgentEnvironmentsPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { agentId } = useParams<{ agentId: string }>();
  const { isAdmin } = useAuth();
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const environmentId = useSearchParam("e");
  const agentEnvironmentsFormData = useAppSelector((state) => state.feature.agentEnvironmentsFormData);
  const agentEnvironmentVariables = useAppSelector((state) =>
    Object.values(state.agentEnvironmentVariable.entities).filter((v) => v.agentEnvironmentId === environmentId),
  );

  const environments = useAppSelector((state) => Object.values(state.agentEnvironment.entities).filter((env) => env.agentId === agentId));

  const currentEnvironment = useAppSelector((state) => state.agentEnvironment.entities[environmentId]);

  const agentFunctions = useAppSelector((state) => Object.values(state.agentFunction.entities).filter((func) => func.agentId === agentId));

  const onClickDeleteEnvironment = async () => {
    await dispatch(
      agentEnvironmentActions.deleteById({
        agentId,
        id: environmentId,
      }),
    );

    navigate(`/agents/${agentId}/environments`);
  };

  const onChangeEnvironmentHandler = (key: string) => (value: unknown) => {
    dispatch(
      featureActions.setAgentEnvironmentsFormData({
        ...agentEnvironmentsFormData,
        [key]: value,
      }),
    );
  };

  const onChangeEnvironmentVariable = useDebouncedCallback((updatedVariable: Partial<AgentEnvironmentVariable>) => {
    dispatch(
      agentEnvironmentVariableActions.updateById({
        ...updatedVariable,
        agentId,
        agentEnvironmentId: environmentId,
      }),
    );
  }, 1000);

  const onClickAddEnvironmentVariable = () => {
    dispatch(
      agentEnvironmentVariableActions.create({
        agentId,
        agentEnvironmentId: environmentId,
        name: "",
        value: "",
      }),
    );
  };

  const onClickDeleteEnvironmentVariable = async (id: string) => {
    await dispatch(
      agentEnvironmentVariableActions.deleteById({
        agentId,
        agentEnvironmentId: environmentId,
        id,
      }),
    );
  };

  const onConfigurePhone = () => {
    dispatch(
      agentEnvironmentActions.configurePhone({
        id: environmentId,
        agentId,
      }),
    );
  };

  useEffect(() => {
    if (currentEnvironment) {
      dispatch(featureActions.setAgentEnvironmentsFormData(currentEnvironment));
      dispatch(agentEnvironmentVariableActions.get({ agentId: agentId || "", agentEnvironmentId: environmentId }));
    }
  }, [currentEnvironment]);

  const gradient = getGradientForName(agentEnvironmentsFormData?.name || "");
  const currentDeployedFunction = agentFunctions.find((func) => func.id === currentEnvironment?.functionId);

  return (
    <View className="flex flex-col flex-1 py-2 overflow-hidden">
      {environments.length === 0 ? (
        <EmptyMessage
          title="No Environments Found"
          description="Create your first environment to get started. Environments help you manage different configurations for your agent."
          iconName="grid"
          buttonText="Create Your First Environment"
          onClickAdd={() => setShowCreateDialog(true)}
          className="h-full mx-4 my-8"
        />
      ) : environmentId ? (
        <motion.div
          initial="hidden"
          animate="visible"
          variants={containerVariants}
          className="flex flex-col flex-1 space-y-4 overflow-hidden"
        >
          <View className="flex flex-col flex-1 bg-white dark:bg-gray-800 rounded-xl shadow-sm overflow-hidden border border-gray-100 dark:border-gray-700">
            <View className="flex items-center justify-between min-h-[60px] p-2 border-b border-gray-200 dark:border-gray-700 overflow-y-auto">
              <motion.div
                initial={{ x: -60, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.3 }}
                className="flex items-center space-x-2 min-h-11"
              >
                <Button
                  onClick={() => navigate(`/agents/${agentId}/environments`)}
                  type="basic"
                  variant="ghost"
                  className="p-2 !rounded-full"
                >
                  <Icon
                    name="close"
                    size={20}
                    className="text-indigo-500"
                  />
                </Button>
                {agentEnvironmentsFormData?.name && (
                  <View className="flex items-center gap-3">
                    <View
                      className={`h-9 w-9 rounded-full bg-gradient-to-br ${gradient.from} ${gradient.to} ${gradient.darkFrom} ${gradient.darkTo} flex items-center justify-center`}
                    >
                      <Text className="text-xs font-bold text-white">{agentEnvironmentsFormData.name.substring(0, 2).toUpperCase()}</Text>
                    </View>
                    <View className="flex flex-col">
                      <Text className="text-lg font-semibold text-gray-900 dark:text-white">{agentEnvironmentsFormData.name}</Text>
                      <View className="flex items-center gap-2">
                        <Text className="text-xs text-gray-500 dark:text-gray-400">
                          ID: {agentEnvironmentsFormData.name.toLowerCase().replace(/\s+/g, "-")}
                        </Text>
                        {currentDeployedFunction && (
                          <Text className="text-xs font-medium text-indigo-500 dark:text-indigo-400">Function: {currentDeployedFunction.name}</Text>
                        )}
                        {currentEnvironment?.phoneNumber && (
                          <Text className="text-xs font-medium text-green-500 dark:text-green-400">Phone: {currentEnvironment.phoneNumber}</Text>
                        )}
                      </View>
                    </View>
                  </View>
                )}
              </motion.div>
              <View className="flex items-center gap-2">
                {!currentEnvironment?.phoneNumber && (
                  <Button
                    onClick={onConfigurePhone}
                    type="basic"
                    variant="ghost"
                    className="p-2 flex items-center"
                  >
                    <Icon
                      name="phone"
                      size={18}
                      className="mr-1"
                    />
                    Add Phone Number
                  </Button>
                )}
                <Button
                  onClick={onClickDeleteEnvironment}
                  type="basic"
                  variant="ghost"
                  className="p-2 !rounded-full hover:!bg-red-500 transition-colors group"
                >
                  <Icon
                    name="trash-outline"
                    size={20}
                    className="text-red-500 group-hover:text-white transition-colors"
                  />
                </Button>
              </View>
            </View>
            <View className="flex flex-col flex-1 p-4 space-y-4">
              <View className="flex flex-row gap-2">
                <InputField
                  label="Name"
                  value={agentEnvironmentsFormData?.name || ""}
                  onChange={onChangeEnvironmentHandler("name")}
                />
                {isAdmin && (
                  <>
                    <InputField
                      name="sttModel"
                      label="STT Model"
                      placeholder="Enter STT model"
                      value={agentEnvironmentsFormData?.sttModel || ""}
                      onChange={onChangeEnvironmentHandler("sttModel")}
                    />
                    <InputField
                      name="ttsModel"
                      label="TTS Model"
                      placeholder="Enter TTS model"
                      value={agentEnvironmentsFormData?.ttsModel || ""}
                      onChange={onChangeEnvironmentHandler("ttsModel")}
                    />
                  </>
                )}
              </View>
              <FieldLabel
                label="Environment Variables"
                className="flex flex-col flex-1"
              >
                <VariablesForm
                  variables={agentEnvironmentVariables}
                  onChange={onChangeEnvironmentVariable}
                  onClickAdd={onClickAddEnvironmentVariable}
                  onClickDelete={onClickDeleteEnvironmentVariable}
                />
              </FieldLabel>
            </View>
          </View>
        </motion.div>
      ) : (
        <motion.div
          initial="hidden"
          animate="visible"
          variants={containerVariants}
          className="space-y-4 w-full"
        >
          {environments.map((environment) => {
            const gradient = getGradientForName(environment.name);
            const deployedFunction = agentFunctions.find((func) => func.id === environment.functionId);
            return (
              <motion.div
                key={environment.id}
                variants={itemVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                className="w-full px-4 py-2 flex items-center justify-between bg-white dark:bg-gray-800 rounded-xl shadow-sm overflow-hidden border border-gray-100 dark:border-gray-700"
              >
                <View className="flex items-center gap-3">
                  <View
                    className={`h-9 w-9 rounded-full bg-gradient-to-br ${gradient.from} ${gradient.to} ${gradient.darkFrom} ${gradient.darkTo} flex items-center justify-center`}
                  >
                    <Text className="text-xs font-bold text-white">{environment.name.substring(0, 2).toUpperCase()}</Text>
                  </View>
                  <View className="flex flex-col">
                    <Text className="text-lg font-semibold text-gray-900 dark:text-white">{environment.name}</Text>
                    <View className="flex items-center gap-2">
                      <Text className="text-xs text-gray-500 dark:text-gray-400">ID: {environment?.name?.toLowerCase().replace(/\s+/g, "-")}</Text>
                      {deployedFunction && <Text className="text-xs font-medium text-indigo-500 dark:text-indigo-400">Function: {deployedFunction.name}</Text>}
                      {environment.phoneNumber && (
                        <Text className="text-xs font-medium text-green-500 dark:text-green-400">Phone: {environment.phoneNumber}</Text>
                      )}
                    </View>
                  </View>
                </View>
                <Button
                  onClick={() => navigate(`/agents/${agentId}/environments?e=${environment.id}`)}
                  type="basic"
                  variant="ghost"
                  className="p-2"
                >
                  <Icon
                    name="edit"
                    size={18}
                    className="mr-1"
                  />
                  Edit
                </Button>
              </motion.div>
            );
          })}
        </motion.div>
      )}
      <CreateAgentEnvironmentDialog
        agentId={agentId || ""}
        isOpen={showCreateDialog}
        onClose={() => setShowCreateDialog(false)}
      />
    </View>
  );
};
