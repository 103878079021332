import { createRestSlice } from "./restSlice";

export type ApiKey = {
  id: string;
  token?: string;
  description: string | null;
  lastUsedAt: string | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

export type Organization = {
  id: string;
  name: string;
  ownerId: string;
  agentsEnabled: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

export type OrganizationMember = {
  id: string;
  userId: string;
  organizationId: string;
  role: "owner" | "admin" | "member";
  email: string;
  name: string;
  createdAt: string;
  updatedAt: string;
};

export type User = {
  id: string;
  email: string;
  name: string;
  isAdmin: boolean;
  balance: number;
  currentOrganizationId: string;
  organizations: Organization[];
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

export type Voice = {
  id: string;
  userId: string;
  name: string;
  sampleUrl: string;
};

const userSlice = createRestSlice<User>("user", "/v1/users");

export const userReducer = userSlice.reducer;
export const userActions = userSlice.actions;
