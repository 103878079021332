import { FC, useEffect, useRef, useState } from "react";
import { View } from "../base/View";
import { Text } from "../base/Text";
import { PlayButton } from "../base/PlayButton";
import { Tooltip } from "../base/Tooltip";
import { Voice } from "../../types";
import clsx from "clsx";

import { ReactComponent as MaleAdultSvg } from "../../assets/svg/male-adult.svg";
import { ReactComponent as FemaleAdultSvg } from "../../assets/svg/female-adult.svg";
import { ReactComponent as FemaleYoungAdultSvg } from "../../assets/svg/female-young-adult.svg";
import { ReactComponent as FemaleChildSvg } from "../../assets/svg/female-child.svg";
import { ReactComponent as MaleChildSvg } from "../../assets/svg/male-child.svg";
import { ReactComponent as MaleYoungAdultSvg } from "../../assets/svg/male-young-adult.svg";
import { ReactComponent as NongenderedSvg } from "../../assets/svg/nongendered.svg";
import { ReactComponent as HiddenSvg } from "../../assets/svg/hidden.svg";

type MinimalVoiceCardProps = {
  voice: Voice;
  index: number;
  onClickPlay: () => void;
  isPlaying: boolean;
  audioElement?: HTMLAudioElement | null;
};

const gradients = [
  "linear-gradient(45deg, #4B5563, #6B7280)", // Subtle gray gradient
  "linear-gradient(45deg, #7C3AED, #C084FC)", // Purple gradient
  "linear-gradient(45deg, #2563EB, #60A5FA)", // Blue gradient
  "linear-gradient(45deg, #059669, #34D399)", // Green gradient
  "linear-gradient(45deg, #DC2626, #FB7185)", // Red gradient
  "linear-gradient(45deg, #D97706, #FBBF24)", // Yellow gradient
  "linear-gradient(45deg, #0891B2, #22D3EE)", // Cyan gradient
  "linear-gradient(45deg, #7C3AED, #F472B6)", // Purple-Pink gradient
  "linear-gradient(45deg, #4F46E5, #818CF8)", // Indigo gradient
];

const getGradientColors = (gradient: string) => {
  const match = gradient.match(/linear-gradient\(45deg,\s*([^,]+),\s*([^)]+)\)/);
  return match ? [match[1].trim(), match[2].trim()] : ["#4B5563", "#6B7280"];
};

const StyledFaceIcon = ({ gradient, gender, age }: { gradient: string; gender?: string; age?: string }) => {
  const [startColor, endColor] = getGradientColors(gradient);
  const lowerGender = gender?.toLowerCase() || "";
  const lowerAge = age?.toLowerCase() || "";

  // Function to determine which icon to use based on gender and age
  const getFaceIcon = () => {
    // Male scenarios
    if (lowerGender.includes("male") && !lowerGender.includes("female")) {
      if (lowerAge.includes("mature") || lowerAge.includes("elder")) {
        return MaleAdultSvg; // Male - Mature Adult
      }
      if (lowerAge.includes("adult") && !lowerAge.includes("young")) {
        return MaleAdultSvg; // Male - Adult
      }
      if (lowerAge.includes("young")) {
        return MaleYoungAdultSvg; // Male - Young Adult
      }
      if (lowerAge.includes("child")) {
        return MaleChildSvg; // Male - Child
      }
      return MaleAdultSvg; // Default male
    }

    // Female scenarios
    if (lowerGender.includes("female")) {
      if (lowerAge.includes("mature") || lowerAge.includes("elder")) {
        return FemaleAdultSvg; // Female - Mature Adult
      }
      if (lowerAge.includes("adult") && !lowerAge.includes("young")) {
        return FemaleAdultSvg; // Female - Adult
      }
      if (lowerAge.includes("young")) {
        return FemaleYoungAdultSvg; // Female - Young Adult
      }
      if (lowerAge.includes("child")) {
        return FemaleChildSvg; // Female - Child
      }
      return FemaleYoungAdultSvg; // Default female
    }

    // Non-binary/Other scenario
    if (lowerGender.includes("non")) {
      return NongenderedSvg; // Gender: Non
    }

    // Default case
    return HiddenSvg;
  };

  const FaceIcon = getFaceIcon();
  const gradientId = `face-gradient-${startColor.replace("#", "")}`; // Create unique ID for gradient

  return (
    <View className="relative w-full h-full">
      <svg
        width="0"
        height="0"
      >
        <defs>
          <linearGradient
            id={gradientId}
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%"
          >
            <stop
              offset="20%"
              style={{ stopColor: startColor }}
            />
            <stop
              offset="80%"
              style={{ stopColor: endColor }}
            />
          </linearGradient>
        </defs>
      </svg>
      <FaceIcon
        width="100%"
        height="100%"
        style={{
          fill: `url(#${gradientId})`,
        }}
      />
    </View>
  );
};

const GradientBadge = ({ children, gradient }: { children: React.ReactNode; gradient: string }) => (
  <View className={clsx("px-2 py-0.5 rounded-full", "bg-gradient-to-r", gradient, "flex items-center justify-center")}>
    <Text className="text-xs font-medium mt-1 mb-1">{children}</Text>
  </View>
);

export const MinimalVoiceCard: FC<MinimalVoiceCardProps> = ({ voice, index, onClickPlay, isPlaying, audioElement }) => {
  const cardRef = useRef<HTMLDivElement>(null);

  // Add early return after all hooks are declared
  if (!voice) {
    return null;
  }

  // Safely access properties with optional chaining and default values
  const {
    name = "",
    userDescription = "",
    userGenderDescription = "",
    userAgeDescription = "",
    userImageUploadThumbnail,
    flashCheckpointUrl,
    isCloned = false,
    isBase = false,
  } = voice;

  const gradient = gradients[index % gradients.length];

  return (
    <View
      ref={cardRef}
      className={clsx(
        "relative w-full rounded-lg bg-gray-50 dark:bg-gray-700/50",
        "border border-gray-200 dark:border-gray-600",
        "hover:border-brand dark:hover:border-brand-light",
        "transition-all duration-200 shadow-sm hover:shadow-md",
        "flex flex-row items-center justify-between p-3 gap-3",
      )}
    >
      {/* Avatar */}
      <View className="w-10 h-10 rounded-lg flex-shrink-0 overflow-hidden">
        {userImageUploadThumbnail ? (
          <img
            src={userImageUploadThumbnail}
            alt={`${name} thumbnail`}
            className="w-full h-full object-cover"
          />
        ) : (
          <StyledFaceIcon
            gradient={gradient}
            gender={userGenderDescription}
            age={userAgeDescription}
          />
        )}
      </View>

      {/* Name and Description */}
      <View className="flex-1 min-w-0 overflow-hidden">
        <Tooltip content={name}>
          <View className="overflow-hidden">
            <Text
              variant="body"
              className="font-semibold text-lg truncate block"
            >
              {name}
            </Text>
          </View>
        </Tooltip>
        {userDescription && (
          <Tooltip content={userDescription}>
            <Text
              variant="muted"
              className="text-xs text-gray-500 dark:text-gray-400 truncate block mt-0.5"
            >
              {userDescription}
            </Text>
          </Tooltip>
        )}
      </View>

      {/* Tags and play button */}
      <View className="flex items-center gap-2">
        {/* Status tags */}
        <View className="hidden md:flex gap-1.5">
          {!isBase && (
            <>
              {isCloned ? (
                <GradientBadge gradient="from-blue-500/50 to-indigo-800/50">Cloned</GradientBadge>
              ) : (
                <GradientBadge gradient="from-pink-500/50 to-purple-800/50">Designed</GradientBadge>
              )}
            </>
          )}
          {flashCheckpointUrl && <GradientBadge gradient="from-orange-600/50 to-yellow-400/50">Flash</GradientBadge>}
        </View>

        {/* Play button */}
        <PlayButton
          isPlaying={isPlaying}
          onPlayPause={onClickPlay}
          audioUrl={voice.preferredPreviewUrl}
          audioElement={audioElement}
        />
      </View>
    </View>
  );
};
