import React from "react";
import { motion } from "framer-motion";
import { Icon } from "./base/Icon";
import { Text } from "./base/Text";
import { View } from "./base/View";
import clsx from "clsx";

interface EmptyMessageProps {
  className?: string;
  title?: string;
  description?: string;
  iconName?: string;
  buttonText?: string;
  onClickAdd?: (data?: any) => void;
  actionData?: any;
}

export const EmptyMessage = ({
  className,
  title = "Not Found",
  description = "Add your first to get started.",
  iconName = "list",
  buttonText = "Add",
  onClickAdd = () => {},
}: EmptyMessageProps) => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className={clsx(
        "flex flex-col items-center justify-center py-20 px-4 bg-gray-50 dark:bg-gray-800/30 rounded-xl border-2 border-dashed border-gray-200 dark:border-gray-700",
        className,
      )}
    >
      <motion.div
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        className="w-24 h-24 mb-6 flex items-center justify-center rounded-full bg-indigo-50 dark:bg-indigo-900/20"
      >
        <Icon
          name={iconName}
          size={48}
          className="text-indigo-400 dark:text-indigo-300"
        />
      </motion.div>
      <Text className="text-2xl font-medium text-gray-700 dark:text-gray-300 mb-2">{title}</Text>
      {description.split("\n").map((line, index) => (
        <Text
          key={index}
          className="text-gray-500 dark:text-gray-400 text-center max-w-md"
        >
          {line}
        </Text>
      ))}
      <motion.div
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={onClickAdd}
        className="cursor-pointer mt-8"
      >
        <View className="flex items-center gap-2 bg-gradient-to-r from-indigo-500 to-purple-600 hover:from-indigo-600 hover:to-purple-700 px-6 py-3 text-white rounded-lg shadow-md">
          <Icon
            name="plus"
            size={18}
          />
          <Text className="text-white">{buttonText}</Text>
        </View>
      </motion.div>
    </motion.div>
  );
};
