import { Outlet } from "react-router-dom";

import { View } from "../components/base/View";
import { RequireAuth } from "../components/auth/RequireAuth";
import { BaseHeader } from "../components/BaseHeader";
import { useAppSelector } from "../redux";
import { BaseHeaderButton } from "../components/BaseHeaderButton";

export const CreatorLayout = () => {
  const organization = useAppSelector((state) => state.organization.currentOrganization);

  return (
    <RequireAuth>
      <View className="relative flex flex-col w-full h-full overflow-scroll bg-gradient-to-b from-white dark:from-gray-900 to-gray-50 dark:to-gray-800">
        <BaseHeader
          title="Creators"
          links={[
            { label: "Speech", to: "/create" },
            { label: "Voices", to: "/voices" },
          ]}
          rightContent={
            organization?.agentsEnabled && (
              <BaseHeaderButton
                to="/agents"
                icon="account-tree"
                label="Agent Portal"
              />
            )
          }
        />

        <View className="flex flex-1 flex-grow">
          <Outlet />
        </View>
      </View>
    </RequireAuth>
  );
};
