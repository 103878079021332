import React, { useEffect, useRef, useState } from "react";
import { Button } from "../base/Button";
import { Text } from "../base/Text";
import { View } from "../base/View";
import GenerationCard from "./GenerationCard";
import clsx from "clsx";
import { ProfileBaseCard } from "./ProfileBaseCard";
import { useAppDispatch, useAppSelector } from "../../redux";
import { Generation, generationActions } from "../../redux/reducers/generationSlice";
import { voiceActions } from "../../redux/reducers/voiceSlice";

type GenerationWithDisplay = Omit<Generation, "createdAt"> & {
  voiceName: string;
  model: string;
  createdAt: string;
};

type GenerationListProps = {
  onCreateNew: () => void;
  onDownload: (audioUrl: string) => void;
  onGenerateWithConfig: (generation: GenerationWithDisplay) => void;
  className?: string;
  maxDisplayCount?: number;
  variant?: "list" | "grid";
  title?: string;
};

export const GenerationList = ({
  onCreateNew,
  onDownload,
  onGenerateWithConfig,
  className,
  maxDisplayCount = 10,
  variant = "list",
  title,
}: GenerationListProps) => {
  const dispatch = useAppDispatch();
  const [isPlayingGenerationId, setIsPlayingGenerationId] = useState<string>("");
  const [globalAudio, setGlobalAudio] = useState<HTMLAudioElement | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  // Get generations and voices from Redux store
  const generationsFromStore = useAppSelector((state) => Object.values(state.generation.entities));
  const voices = useAppSelector((state) => state.voice.entities);

  // Handle playing/pausing generations
  const handlePlayGeneration = (generationId: string, audioUrl: string) => {
    if (isPlayingGenerationId === generationId) {
      // If clicking the same generation, just pause it
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setIsPlayingGenerationId("");
        setGlobalAudio(null);
      }
    } else {
      // Stop any currently playing audio
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }

      // Create and play the new audio
      const newAudio = new Audio(audioUrl);
      audioRef.current = newAudio;
      setGlobalAudio(newAudio);
      setIsPlayingGenerationId(generationId);

      newAudio.play().catch((error) => {
        console.error("Audio playback error:", error);
        setIsPlayingGenerationId("");
        audioRef.current = null;
        setGlobalAudio(null);
      });
    }
  };

  // Add event listener for audio end
  useEffect(() => {
    if (!audioRef.current || !isPlayingGenerationId) return;

    const audio = audioRef.current;
    const handleEnded = () => {
      setIsPlayingGenerationId("");
      setGlobalAudio(null);
    };
    const handlePause = () => {
      setIsPlayingGenerationId("");
      setGlobalAudio(null);
    };
    const handleError = () => {
      setIsPlayingGenerationId("");
      audioRef.current = null;
      setGlobalAudio(null);
    };

    audio.addEventListener("ended", handleEnded);
    audio.addEventListener("pause", handlePause);
    audio.addEventListener("error", handleError);

    return () => {
      audio.removeEventListener("ended", handleEnded);
      audio.removeEventListener("pause", handlePause);
      audio.removeEventListener("error", handleError);
    };
  }, [isPlayingGenerationId]);

  // Cleanup audio on unmount
  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
        setGlobalAudio(null);
        setIsPlayingGenerationId("");
      }
    };
  }, []);

  useEffect(() => {
    dispatch(generationActions.get({}));
    dispatch(voiceActions.get({}));

    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [dispatch]);

  // Prepare the list with additional fields from voices
  const sortedGenerations = [...generationsFromStore]
    .sort((a, b) => {
      const dateA = a.createdAt instanceof Date ? a.createdAt : new Date(a.createdAt);
      const dateB = b.createdAt instanceof Date ? b.createdAt : new Date(b.createdAt);
      return dateB.getTime() - dateA.getTime();
    })
    .slice(0, maxDisplayCount)
    .map(
      (generation): GenerationWithDisplay => ({
        ...generation,
        createdAt: generation.createdAt instanceof Date ? generation.createdAt.toISOString() : new Date(generation.createdAt).toISOString(),
        voiceName: voices[generation.voiceId]?.name || "Unknown Voice",
        model: generation.quality === "high" ? "Studio" : generation.quality === "voice" || generation.quality === "voicestyle" ? "Voice Changer" : "Flash",
      }),
    );

  return (
    <ProfileBaseCard
      title={title || "Generations"}
      className={className}
    >
      {!sortedGenerations.length ? (
        isLoading ? (
          <Text
            variant="muted"
            className="text-center py-8"
          >
            Loading...
          </Text>
        ) : (
          <Text
            variant="muted"
            className="text-center py-8"
          >
            No generations yet
          </Text>
        )
      ) : (
        <View className={clsx(variant === "grid" ? "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4" : "flex flex-col gap-4")}>
          {sortedGenerations.map((generation) => (
            <GenerationCard
              key={generation.id}
              id={generation.id}
              text={generation.text}
              audioUrl={generation.audioUrl}
              createdAt={generation.createdAt}
              voiceName={generation.voiceName}
              model={generation.model}
              notes={generation.notes}
              isPlaying={isPlayingGenerationId === generation.id}
              onPlay={handlePlayGeneration}
              onDownload={onDownload}
              onGenerateWithConfig={() => onGenerateWithConfig(generation)}
              audioElement={globalAudio}
            />
          ))}
        </View>
      )}
      {/* Hidden audio element for playback */}
      <audio ref={audioRef} />
    </ProfileBaseCard>
  );
};
