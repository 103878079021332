import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import clsx from "clsx";

import { agentActions } from "../redux/reducers/agentSlice";
import { AgentDebugger } from "../components/agents/AgentDebugger";
import { agentEnvironmentActions } from "../redux/reducers/agentEnvironmentSlice";
import { agentFunctionActions } from "../redux/reducers/agentFunctionSlice";
import { Button } from "../components/base/Button";
import { CreateAgentEnvironmentDialog } from "../components/agents/CreateAgentEnvironmentDialog";
import { Icon } from "../components/base/Icon";
import { Tabs } from "../components/base/Tabs";
import { useAppDispatch, useAppSelector } from "../redux";
import { useKeyboardShortcut } from "../hooks/ussKeyboardShortcut";
import { useSearchParam } from "../hooks/useSearchParam";
import { View } from "../components/base/View";
import { DropdownField } from "../components/base/DropdownField";
import { Text } from "../components/base/Text";
import { MenuField } from "../components/base/MenuField";
import { getGradientForName } from "../utils/gradients";
import { Dialog } from "../components/base/Dialog";

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.4 },
  },
};

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 0.5 },
  },
};

export const AgentDetailLayout = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { agentId } = useParams<{ agentId: string }>();
  const [isDebuggerOpen, setIsDebuggerOpen] = useState(false);
  const agent = useAppSelector((state) => agentId && state.agent.entities[agentId]);
  const agentEnvironments = useAppSelector((state) => Object.values(state.agentEnvironment.entities).filter((env) => env.agentId === agentId));
  const agentFunctions = useAppSelector((state) => Object.values(state.agentFunction.entities).filter((env) => env.agentId === agentId));
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [showDeployDialog, setShowDeployDialog] = useState(false);
  const [deployEnvironmentId, setDeployEnvironmentId] = useState<string>("");
  const isDetailsPage = pathname.includes("/details");
  const isFunctionPage = pathname.includes("/function");
  const isEnvironmentsPage = pathname.includes("/environments");
  const functionId = useSearchParam("f");
  const environmentId = useSearchParam("e");
  const isLoadingDetails = useAppSelector((state) => state.agent.isLoading);
  const isLoadingEnvironments = useAppSelector((state) => state.agentEnvironment.isLoading);
  const isLoadingFunction = useAppSelector((state) => state.agentFunction.isLoading || state.agentFunction.isLoadingCreate);
  const agentDetailsFormData = useAppSelector((state) => state.feature.agentDetailsFormData);
  const agentEnvironmentsFormData = useAppSelector((state) => state.feature.agentEnvironmentsFormData);
  const agentFunctionFormData = useAppSelector((state) => state.feature.agentFunctionFormData);

  const onChangeFunction = (newFunctionId: string) => {
    navigate(`/agents/${agentId}/function?f=${newFunctionId}`);
  };

  const onChangeDetailsMenu = async (menuItem: string) => {
    if (menuItem === "delete") {
      await dispatch(agentActions.deleteById({ id: agentId }));
      navigate("/agents");
    }
  };

  const onChangeFunctionMenu = async (menuItem: string) => {
    if (menuItem === "new") {
      const newFunction = await dispatch(
        agentFunctionActions.create({
          ...agentFunctionFormData,
          id: undefined,
          name: undefined,
          agentId,
        }),
      ).unwrap();

      navigate(`/agents/${agentId}/function?f=${newFunction.id}`);
    } else if (menuItem === "deploy") {
      setShowDeployDialog(true);
      if (agentEnvironments.length > 0) {
        setDeployEnvironmentId(agentEnvironments[0].id);
      }
    } else if (menuItem === "delete") {
      await dispatch(agentFunctionActions.deleteById({ id: functionId, agentId }));

      navigate(`/agents/${agentId}/function`);
    }
  };

  const onClickFunctionSave = async () => {
    dispatch(
      agentFunctionActions.updateById({
        ...agentFunctionFormData,
        id: functionId,
        agentId,
      }),
    );
  };

  const onClickDetailsSave = () => {
    dispatch(agentActions.updateById({ id: agentId, ...agentDetailsFormData }));
  };

  const onClickEnvironmentSave = () => {
    dispatch(agentEnvironmentActions.updateById({ id: environmentId, ...agentEnvironmentsFormData }));
  };

  const onDeployFunction = async () => {
    if (deployEnvironmentId && functionId) {
      await dispatch(
        agentEnvironmentActions.updateById({
          id: deployEnvironmentId,
          functionId: functionId,
          agentId,
        }),
      );
      setShowDeployDialog(false);
    }
  };

  const onSaveShortcut = () => {
    if (isDetailsPage) {
      onClickDetailsSave();
    } else if (isEnvironmentsPage) {
      onClickEnvironmentSave();
    } else if (isFunctionPage) {
      onClickFunctionSave();
    }
  };

  useKeyboardShortcut("CMD+S", onSaveShortcut);
  useKeyboardShortcut("CTRL+S", onSaveShortcut);

  useEffect(() => {
    if (agentId) {
      dispatch(agentActions.getById({ id: agentId }));
      dispatch(agentEnvironmentActions.get({ agentId }));
      dispatch(agentFunctionActions.get({ agentId }));
    }
  }, [agentId]);

  if (!agent) {
    return (
      <View className="min-h-screen bg-white dark:bg-gray-900 flex flex-col items-center justify-center">
        <View className="text-xl font-semibold mb-4">Agent not found</View>
        <View
          className="cursor-pointer bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md"
          onClick={() => navigate("/agents")}
        >
          Return to Agents
        </View>
      </View>
    );
  }

  return (
    <View className="relative flex flex-row h-full overflow-hidden">
      <View className="flex flex-col flex-1 px-4 h-full overflow-hidden">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={containerVariants}
          className="mb-2 border-b border-gray-200 dark:border-gray-800"
        >
          <View className="grid grid-cols-3 w-full">
            <View className="flex items-center justify-start space-x-4">
              <Link
                to="/agents"
                className="text-indigo-600 dark:text-indigo-400 hover:underline flex items-center"
              >
                <Icon
                  name="chevron-left"
                  size={16}
                  className="mr-1"
                />
                Agents
              </Link>
            </View>
            <View className="flex items-center justify-center h-14">
              <Tabs
                tabs={[
                  { id: `/agents/${agentId}/details`, label: "Details" },
                  { id: `/agents/${agentId}/environments`, label: "Environments" },
                  { id: `/agents/${agentId}/function`, label: "Function" },
                ]}
                activeTab={pathname}
                onChange={(activeTab: string) => navigate(activeTab)}
                isDisabled={isDebuggerOpen}
                className={clsx("transition-opacity duration-300", isDebuggerOpen ? "opacity-0" : "opacity-1", isFunctionPage ? "pl-12" : "")}
              />
            </View>
            <View className="flex items-center justify-end">
              {isDetailsPage && (
                <>
                  <Button
                    onClick={onClickDetailsSave}
                    className="!h-10 !shadow-none mr-2 bg-gradient-to-r from-indigo-500 to-purple-600 hover:from-indigo-600 hover:to-purple-700 hover:scale-105"
                    isLoading={isLoadingDetails}
                  >
                    Save
                  </Button>
                  <MenuField
                    className=""
                    placeholder="Select version"
                    onChange={onChangeDetailsMenu}
                    value={functionId}
                    options={[{ label: "Delete Agent", value: "delete", textColor: "text-red-500" }]}
                  />
                </>
              )}
              {isEnvironmentsPage && agentEnvironments.length > 0 && !environmentId && (
                <Button
                  onClick={() => setShowCreateDialog(true)}
                  className="!h-10 !shadow-none bg-gradient-to-r from-indigo-500 to-purple-600 hover:from-indigo-600 hover:to-purple-700 hover:scale-105"
                  isLoading={isLoadingEnvironments}
                >
                  <Icon
                    name="add"
                    size={20}
                    className="mr-1 mt-[1px]"
                  />
                  Environment
                </Button>
              )}
              {isEnvironmentsPage && environmentId && (
                <Button
                  onClick={onClickEnvironmentSave}
                  className="!h-10 !shadow-none bg-gradient-to-r from-indigo-500 to-purple-600 hover:from-indigo-600 hover:to-purple-700 hover:scale-105"
                  isLoading={isLoadingEnvironments}
                >
                  Save
                </Button>
              )}
              {isFunctionPage && (
                <>
                  <DropdownField
                    className="w-48 min-w-48 max-w-48 mr-4"
                    placeholder="Select version"
                    onChange={onChangeFunction}
                    value={functionId}
                    options={agentFunctions
                      ?.map((version) => {
                        const environmentsUsingVersion = agentEnvironments.filter((env) => env.functionId === version.id);
                        return {
                          label: (
                            <View className="flex items-center gap-2">
                              {environmentsUsingVersion.length > 0 && (
                                <View className="flex items-center gap-1">
                                  {environmentsUsingVersion.map((env) => {
                                    const gradient = getGradientForName(env.name);
                                    return (
                                      <View
                                        key={env.id}
                                        className={`h-5 w-5 rounded-full bg-gradient-to-br ${gradient.from} ${gradient.to} ${gradient.darkFrom} ${gradient.darkTo} flex items-center justify-center`}
                                        title={env.name}
                                      >
                                        <Text className="text-xs font-bold text-white">{env.name.substring(0, 1).toUpperCase()}</Text>
                                      </View>
                                    );
                                  })}
                                </View>
                              )}
                              <Text>{version.id === "new" ? "New Version" : version.name}</Text>
                            </View>
                          ),
                          value: version.id,
                          sortOrder: environmentsUsingVersion.length > 0 ? 0 : 1,
                        };
                      })
                      .sort((a, b) => a.sortOrder - b.sortOrder)}
                  />
                  <Button
                    onClick={onClickFunctionSave}
                    className="!h-10 !shadow-none mr-2 bg-gradient-to-r from-indigo-500 to-purple-600 hover:from-indigo-600 hover:to-purple-700 hover:scale-105"
                    isLoading={isLoadingFunction}
                  >
                    Save
                  </Button>
                  <MenuField
                    className=""
                    placeholder="Select version"
                    onChange={onChangeFunctionMenu}
                    value={functionId}
                    options={[
                      { label: "New Version", value: "new" },
                      { label: "Deploy Version", value: "deploy" },
                      { label: "Delete Version", value: "delete", textColor: "text-red-500" },
                    ]}
                  />
                </>
              )}
            </View>
          </View>
        </motion.div>

        <motion.div
          initial="hidden"
          animate="visible"
          className="flex flex-col flex-1 h-full overflow-hidden"
          variants={itemVariants}
        >
          <Outlet />
        </motion.div>
      </View>
      {isEnvironmentsPage && (
        <CreateAgentEnvironmentDialog
          agentId={agentId || ""}
          isOpen={showCreateDialog}
          onClose={() => setShowCreateDialog(false)}
        />
      )}
      {isFunctionPage && (
        <AgentDebugger
          agentId={agentId || ""}
          functionId={functionId || ""}
          isOpen={isDebuggerOpen}
          onClick={() => setIsDebuggerOpen(!isDebuggerOpen)}
        />
      )}
      <Dialog
        isOpen={showDeployDialog}
        onClose={() => setShowDeployDialog(false)}
        title="Deploy Function"
        actions={
          <>
            <Button
              variant="secondary"
              onClick={() => setShowDeployDialog(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={onDeployFunction}
              isDisabled={!deployEnvironmentId}
              className="bg-gradient-to-r from-indigo-500 to-purple-600 hover:from-indigo-600 hover:to-purple-700"
            >
              Deploy
            </Button>
          </>
        }
      >
        <View className="flex flex-col gap-4 p-4">
          <Text>Select an environment to deploy this function version to:</Text>
          <DropdownField
            className="w-full"
            placeholder="Select environment"
            value={deployEnvironmentId}
            onChange={setDeployEnvironmentId}
            options={agentEnvironments.map((env) => {
              const gradient = getGradientForName(env.name);
              return {
                label: (
                  <View className="flex items-center gap-2">
                    <View
                      className={`h-5 w-5 rounded-full bg-gradient-to-br ${gradient.from} ${gradient.to} ${gradient.darkFrom} ${gradient.darkTo} flex items-center justify-center`}
                    >
                      <Text className="text-xs font-bold text-white">{env.name.substring(0, 1).toUpperCase()}</Text>
                    </View>
                    <Text>{env.name}</Text>
                  </View>
                ),
                value: env.id,
              };
            })}
          />
        </View>
      </Dialog>
    </View>
  );
};
