import { usePrimVoices, DebugMessage } from "primvoices-react";
import { motion } from "framer-motion";
import clsx from "clsx";
import { useState } from "react";

import { View } from "../base/View";
import { Text } from "../base/Text";
import { Icon } from "../base/Icon";

const getEventIcon = (name: string, data?: Record<string, any>) => {
  if (name === "text" && data?.source) {
    return data.source === "speech" ? "mic" : "keyboard";
  }

  switch (name) {
    case "start":
      return "play";
    case "stop":
      return "stop";
    case "interrupt":
      return "pause";
    case "end":
      return "check";
    case "timeout":
      return "clock";
    case "text":
      return "keyboard";
    case "text_to_speech":
      return "volume-up";
    case "audio":
      return "music";
    case "silence":
      return "mute";
    case "transfer_call":
      return "phone-forward";
    case "merge_call":
      return "phone-merge";
    case "context":
      return "database";
    case "error":
      return "error";
    default:
      return "info";
  }
};

const getEventColor = (name: string, type: string) => {
  const baseColor = (() => {
    switch (name) {
      case "start":
        return "text-green-500";
      case "stop":
        return "text-red-500";
      case "interrupt":
        return "text-yellow-500";
      case "end":
        return "text-blue-500";
      case "timeout":
        return "text-orange-500";
      case "text":
        return "text-purple-500";
      case "text_to_speech":
        return "text-indigo-500";
      case "audio":
        return "text-pink-500";
      case "silence":
        return "text-gray-500";
      case "transfer_call":
        return "text-teal-500";
      case "merge_call":
        return "text-cyan-500";
      case "context":
        return "text-emerald-500";
      case "error":
        return "text-red-600";
      default:
        return "text-gray-500";
    }
  })();

  return type === "input" ? baseColor : "text-gray-500";
};

const formatEventData = (message: DebugMessage) => {
  if (!message.data) return null;

  // Display all message data as JSON
  return JSON.stringify(message.data, null, 2);
};

export const AgentMonitoring = () => {
  const { debugMessages } = usePrimVoices();
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const [collapsedTurns, setCollapsedTurns] = useState<Record<string | number, boolean>>({});

  // Group messages by turn
  const groupedMessages = debugMessages?.reduce<Record<string | number, DebugMessage[]>>(
    (acc, message) => {
      const turn = `${message.turn ?? "unknown"}`;

      if (!acc[turn]) {
        acc[turn] = [];
      }

      acc[turn].push(message);
      return acc;
    },
    {} as Record<string, DebugMessage[]>,
  );

  const toggleTurn = (turn: string | number) => {
    setCollapsedTurns((prev) => ({
      ...prev,
      [turn]: !prev[turn],
    }));
  };

  return (
    <View className="flex-1 overflow-y-auto">
      {groupedMessages &&
        Object.entries(groupedMessages).map(([turn, messages], turnIndex) => (
          <View key={turn}>
            <View
              className="sticky top-0 z-10 flex items-center bg-indigo-100 dark:bg-gray-800 cursor-pointer border-b border-gray-300 dark:border-gray-700"
              onClick={() => toggleTurn(turn)}
            >
              <View className="px-2 py-1 w-full bg-indigo-100 dark:bg-brand/20 flex justify-between items-center border-l-4 border-brand">
                <View className="flex items-center justify-start">
                  <Icon
                    name={collapsedTurns[turn] ? "chevron-right" : "chevron-down"}
                    size={16}
                    className="text-indigo-700 dark:text-indigo-200 mr-2"
                  />
                  <Text className="text-xs font-medium text-indigo-700 dark:text-indigo-200">Turn {turn === "unknown" ? "unknown" : turnIndex + 1}</Text>
                </View>
                <Text className="whitespace-nowrap text-xs font-medium text-gray-600 dark:text-gray-400 opacity-60">750 ms</Text>
              </View>
            </View>

            {!collapsedTurns[turn] &&
              messages.map((message, index) => {
                const globalIndex = debugMessages?.indexOf(message) ?? index;

                return (
                  <motion.div
                    key={globalIndex}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    className={clsx(
                      "border-b border-gray-200 dark:border-gray-700",
                      message.name === "error"
                        ? "bg-red-50 dark:bg-red-900/20"
                        : message.type === "input"
                          ? "bg-blue-50 dark:bg-blue-900/20"
                          : "bg-gray-50 dark:bg-gray-800/50",
                    )}
                  >
                    <View
                      className={clsx(
                        "w-full text-left p-3 cursor-pointer",
                        expandedIndex === globalIndex && "bg-gray-100 dark:bg-gray-700",
                        message.name === "error"
                          ? "border-l-4 border-l-red-600"
                          : message.type === "input"
                            ? "border-l-4 border-l-indigo-300 dark:border-l-indigo-400"
                            : "border-l-4 border-l-gray-300 dark:border-l-gray-600",
                      )}
                      onClick={() => setExpandedIndex(expandedIndex === globalIndex ? null : globalIndex)}
                    >
                      <View className="flex items-center gap-3">
                        <View className="flex items-center gap-2">
                          <Icon
                            name={expandedIndex === globalIndex ? "chevron-down" : "chevron-right"}
                            size={16}
                            className="text-gray-400"
                          />
                          <Icon
                            name={getEventIcon(message.name, message.data)}
                            size={20}
                            className={getEventColor(message.name, message.type)}
                          />
                        </View>
                        <Text className={clsx("font-medium capitalize", message.name === "error" && "text-red-600 font-bold")}>
                          {message.name.replace(/_/g, " ")}
                          {message.name === "text" && (
                            <span className="ml-2 text-xs text-gray-500 dark:text-gray-400">
                              via {message.data.source === "speech" ? "speech" : "text input"}
                            </span>
                          )}
                        </Text>
                      </View>
                    </View>

                    {expandedIndex === globalIndex && (
                      <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: "auto", opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className="overflow-hidden"
                      >
                        <View className="p-3 border-t border-gray-200 dark:border-gray-700">
                          <Text className="text-sm font-mono whitespace-pre-wrap">{formatEventData(message)}</Text>
                        </View>
                      </motion.div>
                    )}
                  </motion.div>
                );
              })}
          </View>
        ))}
    </View>
  );
};
