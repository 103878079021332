import { View } from "../base/View";
import { Text } from "../base/Text";
import { Link } from "../base/Link";
import { useLocation } from "react-router-dom";
import clsx from "clsx";

type DocsSidebarProps = {
  className?: string;
};

export const DocsSidebar = ({ className }: DocsSidebarProps) => {
  const { pathname } = useLocation();

  const sections = [
    {
      title: "Events",
      items: [
        { label: "Start Event", to: "/docs/api-reference#start-event" },
        { label: "Stop Event", to: "/docs/api-reference#stop-event" },
        { label: "Interrupt Event", to: "/docs/api-reference#interrupt-event" },
        { label: "End Call Event", to: "/docs/api-reference#end-call-event" },
        { label: "Timeout Event", to: "/docs/api-reference#timeout-event" },
        { label: "Text Event", to: "/docs/api-reference#text-event" },
        { label: "Text to Speech Event", to: "/docs/api-reference#text-to-speech-event" },
        { label: "Audio Event", to: "/docs/api-reference#audio-event" },
        { label: "Silence Event", to: "/docs/api-reference#silence-event" },
        { label: "Transfer Call Event", to: "/docs/api-reference#transfer-call-event" },
        { label: "Merge Call Event", to: "/docs/api-reference#merge-call-event" },
        { label: "Context Update Event", to: "/docs/api-reference#context-update-event" },
      ],
    },
  ];

  return (
    <View className={clsx("w-64 flex-shrink-0 pr-8", className)}>
      {sections.map((section) => (
        <View
          key={section.title}
          className="mb-8"
        >
          <Text
            variant="brand"
            className="text-sm font-semibold mb-4"
          >
            {section.title}
          </Text>
          <View className="space-y-2">
            {section.items.map((item) => (
              <Link
                key={item.to}
                to={item.to}
                className={clsx(
                  "block text-sm py-1 px-2 rounded-md transition-colors",
                  pathname === item.to
                    ? "text-brand dark:text-brand-light bg-brand/10 dark:bg-brand-light/10"
                    : "text-gray-600 dark:text-gray-300 hover:text-brand dark:hover:text-brand-light hover:bg-gray-50 dark:hover:bg-gray-800",
                )}
              >
                {item.label}
              </Link>
            ))}
          </View>
        </View>
      ))}
    </View>
  );
};
