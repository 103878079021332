import { useState } from "react";
import { OrganizationMember } from "../../redux/reducers/userSlice";
import { Button } from "../base/Button";
import { Dialog } from "../base/Dialog";
import { InputField } from "../base/InputField";
import { Text } from "../base/Text";
import { View } from "../base/View";
import { ProfileBaseCard } from "./ProfileBaseCard";
import { OrganizationInvite } from "../../redux/reducers/organizationSlice";
import clsx from "clsx";
import { Icon } from "../base/Icon";
import { useAuth } from "../../hooks/useAuth";

type OrganizationCardProps = {
  members: OrganizationMember[];
  invites: OrganizationInvite[];
  onInviteUser: (email: string, role: "admin" | "member") => void;
  onCancelInvite: (inviteId: string) => void;
  onUpdateMemberRole: (memberId: string, role: "admin" | "member") => void;
  onRemoveMember: (memberId: string) => void;
  className?: string;
};

export const OrganizationCard = ({ members, invites, onInviteUser, onCancelInvite, onUpdateMemberRole, onRemoveMember, className }: OrganizationCardProps) => {
  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState<"admin" | "member">("member");
  const [emailError, setEmailError] = useState("");
  const { user } = useAuth();
  const currentMember = members.find((member) => member.id === user?.id);

  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleInvite = () => {
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
      return;
    }
    onInviteUser(email, role);
    setShowInviteDialog(false);
    setEmail("");
    setRole("member");
    setEmailError("");
  };

  return (
    <ProfileBaseCard
      title="Organization"
      className={className}
      actionButton={
        currentMember?.role === "admin"
          ? {
              label: "Invite User",
              onClick: () => setShowInviteDialog(true),
              variant: "default",
              className: "whitespace-nowrap",
            }
          : undefined
      }
    >
      {/* Members List */}
      <View className="grid grid-cols-1 gap-4">
        {members.map((member) => (
          <View
            key={member.id}
            className={clsx(
              "p-4 rounded-lg",
              "bg-gray-50 dark:bg-gray-700/50",
              "border border-gray-200 dark:border-gray-600",
              "flex justify-between items-center",
            )}
          >
            <View className="flex items-center space-x-3">
              <View className="flex-shrink-0 h-10 w-10 bg-gray-100 dark:bg-gray-700 rounded-full flex items-center justify-center">
                <Text className="text-gray-600 dark:text-gray-300 font-medium">
                  {member.name?.charAt(0)?.toUpperCase() || member.email.charAt(0).toUpperCase()}
                </Text>
              </View>
              <View className="flex flex-col">
                <Text
                  variant="body"
                  className="font-semibold"
                >
                  {member.name || "Unknown"}
                </Text>
                <Text
                  variant="muted"
                  className="text-sm"
                >
                  {member.email}
                </Text>
              </View>
            </View>
            <View className="flex items-center space-x-2">
              {member.role !== "owner" && (
                <View className="flex space-x-2">
                  <Button
                    variant="ghost"
                    onClick={() => onUpdateMemberRole(member.id, member.role === "admin" ? "member" : "admin")}
                  >
                    {member.role === "admin" ? "Demote to Member" : "Promote to Admin"}
                  </Button>
                  <Button
                    variant="ghost"
                    className="text-red-500 dark:text-red-400 hover:text-red-600 dark:hover:text-red-300"
                    onClick={() => onRemoveMember(member.id)}
                  >
                    Remove
                  </Button>
                </View>
              )}
              <Text
                variant="body"
                className={`px-2 py-1 !mx-4 rounded-full text-xs font-medium ${member.role === "owner" ? "bg-brand text-white" : "bg-gray-100 dark:bg-gray-700"}`}
              >
                {member.role.charAt(0).toUpperCase() + member.role.slice(1)}
              </Text>
            </View>
          </View>
        ))}
        {invites?.map((invite) => (
          <View
            key={invite.id}
            className={clsx(
              "p-4 rounded-lg",
              "bg-gray-50 dark:bg-gray-700/50",
              "border border-gray-200 dark:border-gray-600",
              "flex justify-between items-center",
            )}
          >
            <View className="flex items-center space-x-3">
              <View className="flex-shrink-0 h-10 w-10 bg-gray-100 dark:bg-gray-700 rounded-full flex items-center justify-center">
                <Icon
                  name="invite"
                  size={16}
                />
              </View>
              <View className="flex flex-col">
                <Text
                  variant="body"
                  className="font-semibold"
                >
                  {invite.email}
                </Text>
                <Text
                  variant="muted"
                  className="text-sm"
                >
                  Invited as {invite.role.charAt(0).toUpperCase() + invite.role.slice(1)}
                </Text>
              </View>
            </View>
            {currentMember?.role === "admin" && (
              <Button
                variant="ghost"
                className="text-red-500 dark:text-red-400 hover:text-red-600 dark:hover:text-red-300"
                onClick={() => onCancelInvite(invite.id)}
              >
                Cancel Invite
              </Button>
            )}
          </View>
        ))}
      </View>

      {/* Invite User Dialog */}
      <Dialog
        isOpen={showInviteDialog}
        onClose={() => {
          setShowInviteDialog(false);
          setEmail("");
          setRole("member");
          setEmailError("");
        }}
        title="Invite User"
      >
        <View className="p-4 space-y-4">
          <InputField
            label="Email"
            placeholder="Enter email address"
            value={email}
            onChange={(value: string) => {
              setEmail(value);
              setEmailError("");
            }}
            error={emailError}
          />

          <View className="space-y-2">
            <Text
              variant="body"
              className="font-medium"
            >
              Role
            </Text>
            <View className="flex items-center space-x-4">
              <View className="flex items-center space-x-2">
                <input
                  type="radio"
                  id="member"
                  name="role"
                  checked={role === "member"}
                  onChange={() => setRole("member")}
                  className="h-4 w-4 text-brand focus:ring-brand"
                />
                <label
                  htmlFor="member"
                  className="text-sm font-medium text-gray-700 dark:text-gray-300"
                >
                  Member
                </label>
              </View>
              <View className="flex items-center space-x-2">
                <input
                  type="radio"
                  id="admin"
                  name="role"
                  checked={role === "admin"}
                  onChange={() => setRole("admin")}
                  className="h-4 w-4 text-brand focus:ring-brand"
                />
                <label
                  htmlFor="admin"
                  className="text-sm font-medium text-gray-700 dark:text-gray-300"
                >
                  Admin
                </label>
              </View>
            </View>
          </View>

          <View className="flex space-x-3 pt-2">
            <Button
              variant="default"
              onClick={handleInvite}
              isDisabled={!email}
              className="w-full"
            >
              Send Invite
            </Button>
            <Button
              variant="ghost"
              onClick={() => {
                setShowInviteDialog(false);
                setEmail("");
                setRole("member");
                setEmailError("");
              }}
              className="w-full"
            >
              Cancel
            </Button>
          </View>
        </View>
      </Dialog>
    </ProfileBaseCard>
  );
};
