import { Outlet } from "react-router-dom";
import { useEffect } from "react";

import { sessionActions } from "../redux/reducers/sessionSlice";
import { useAppDispatch } from "../redux";
import { View } from "../components/base/View";
import { organizationActions } from "../redux/reducers/organizationSlice";

export const AppLayout = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(sessionActions.getSession());
    dispatch(organizationActions.getCurrentOrganization());
  }, []);

  return (
    <View className="w-full h-full bg-white dark:bg-gray-900 overflow-auto">
      <Outlet />
    </View>
  );
};
