import React from "react";
import { BaseVoiceCard, type BaseVoiceCardProps } from ".";

export const SmallFeatureVoiceCard: React.FC<BaseVoiceCardProps> = (props) => {
  return (
    <BaseVoiceCard
      {...props}
      titleClassName="text-base"
      descriptionClassName="text-xs"
      playButtonClassName="!w-8 !h-8"
      hideVerifiedBadge={true}
      hidePublicBadge={true}
      hideMetadata={true}
      hideTags={true}
      descriptionLineClamp={3}
    />
  );
};
