import { View } from "../../components/base/View";
import { Text } from "../../components/base/Text";
import { BaseHeader } from "../../components/BaseHeader";
import { Link } from "../../components/base/Link";
import { Icon } from "../../components/base/Icon";

type GettingStartedPageProps = {
  className?: string;
};

export const GettingStartedPage = ({ className }: GettingStartedPageProps) => {
  const links = [
    { label: "Getting Started", to: "/docs/getting-started" },
    { label: "API Reference", to: "/docs/api-reference" },
    { label: "Features", to: "/docs/features" },
    { label: "FAQ", to: "/docs/faq" },
  ];

  return (
    <View className={`min-h-screen bg-white dark:bg-gray-900 ${className}`}>
      <BaseHeader
        title="Documentation"
        links={links}
        showBrand={true}
      />
      <View className="w-full max-w-5xl mx-auto p-8">
        <View className="space-y-8">
          <Text
            variant="brand"
            className="text-3xl font-bold mb-4"
          >
            Getting Started
          </Text>
          <Text
            variant="body"
            className="text-lg mb-4"
          >
            Welcome to the PrimVoices documentation. Here you'll find comprehensive guides and documentation to help you start working with PrimVoices as
            quickly as possible.
          </Text>
          <View className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6">
            <Link
              to="/create"
              className="flex items-center p-4 rounded-lg border border-gray-200 dark:border-gray-700 hover:border-brand dark:hover:border-brand-light transition-colors"
            >
              <Icon
                name="sparkles"
                size={24}
                className="mr-3 text-brand dark:text-brand-light"
              />
              <View>
                <Text className="font-medium">Create Your First Voice</Text>
                <Text
                  variant="muted"
                  className="text-sm"
                >
                  Get started by creating your first AI voice
                </Text>
              </View>
            </Link>
            <Link
              to="/voices"
              className="flex items-center p-4 rounded-lg border border-gray-200 dark:border-gray-700 hover:border-brand dark:hover:border-brand-light transition-colors"
            >
              <Icon
                name="record_voice_over"
                size={24}
                className="mr-3 text-brand dark:text-brand-light"
              />
              <View>
                <Text className="font-medium">Browse Voices</Text>
                <Text
                  variant="muted"
                  className="text-sm"
                >
                  Explore our collection of AI voices
                </Text>
              </View>
            </Link>
          </View>
        </View>
      </View>
    </View>
  );
};
