import clsx from "clsx";
import { useState, useEffect, useRef, ReactNode } from "react";

import { FieldLabel } from "./FieldLabel";
import { View } from "./View";
import { Icon } from "./Icon";

type MenuFieldProps = {
  name?: string;
  options: Array<{
    label: string | ReactNode;
    value: string;
    textColor?: string;
  }>;
  value?: string;
  onChange?: (value: string) => void;
  className?: string;
  label?: string;
  placeholder?: string;
  error?: string;
};

export const MenuField = ({ name, options = [], value, onChange = () => {}, className, label, placeholder, error }: MenuFieldProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const onClickOptionHandler = (value: string) => {
    onChange(value);
    setIsOpen(false);
  };

  return (
    <FieldLabel
      label={label}
      className={clsx("!w-10 !h-10", className)}
      error={error}
    >
      <View
        className={clsx("relative w-10 h-10", error && "animate-shake")}
        ref={menuRef}
      >
        <View
          className={clsx(
            "flex items-center justify-center w-10 h-10 rounded-full",
            "text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200",
            "hover:bg-gray-100 dark:hover:bg-gray-700",
            "cursor-pointer transition-colors duration-150",
          )}
          onClick={() => setIsOpen(!isOpen)}
        >
          <Icon
            name="more-vertical"
            size={20}
          />
        </View>

        {isOpen && (
          <View
            className={clsx(
              "absolute z-10 right-0 mt-2 bg-white dark:bg-gray-800 border rounded-lg shadow-lg overflow-y-auto max-h-60",
              error ? "border-red-500 dark:border-red-400" : "border-gray-200 dark:border-gray-700",
            )}
          >
            {options.length === 0 ? (
              <View className="p-3 text-sm text-gray-500 dark:text-gray-400">No options found</View>
            ) : (
              options.map((option) => (
                <View
                  key={option.value}
                  onClick={() => onClickOptionHandler(option.value)}
                  className={clsx(
                    "w-full pl-4 pr-2 py-2 text-right whitespace-nowrap",
                    "cursor-pointer transition-colors duration-150",
                    "hover:bg-gray-50 dark:hover:bg-gray-800/50",
                    value === option.value && "bg-gray-100 dark:bg-gray-800",
                    (option.textColor && option.textColor) || "text-gray-900 dark:text-gray-200",
                  )}
                >
                  {option.label}
                </View>
              ))
            )}
          </View>
        )}
      </View>
    </FieldLabel>
  );
};
