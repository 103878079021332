import React, { useRef, useState, useEffect } from "react";
import { motion } from "framer-motion";
import { View } from "../base/View";
import { Icon } from "../base/Icon";
import { BasicVoiceCard } from "./BasicVoiceCard";
import { CarouselProps } from "./types";

export const HorizontalCarousel: React.FC<CarouselProps> = ({ voices, onClickPlay, isPlayingVoiceId, audioElement }) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [hasOverflow, setHasOverflow] = useState(false);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  // Check for overflow and scroll position
  const checkScrollPosition = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      setHasOverflow(scrollWidth > clientWidth);
      setIsAtStart(scrollLeft <= 0);
      setIsAtEnd(Math.ceil(scrollLeft + clientWidth) >= scrollWidth);
    }
  };

  // Initial check and resize handler
  useEffect(() => {
    checkScrollPosition();
    const handleResize = () => {
      checkScrollPosition();
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const scroll = (direction: "left" | "right") => {
    if (scrollRef.current) {
      const scrollAmount = direction === "left" ? -400 : 400;
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
      // Update scroll position after animation
      setTimeout(checkScrollPosition, 500);
    }
  };

  return (
    <View className="relative">
      {/* Left Arrow */}
      {hasOverflow && !isAtStart && (
        <button
          onClick={() => scroll("left")}
          className="absolute left-0 top-1/2 -translate-y-1/2 z-10 bg-black/20 hover:bg-black/30 p-2 rounded-r-lg"
        >
          <Icon
            name="chevron-left"
            className="w-6 h-6 text-white"
          />
        </button>
      )}

      <View
        ref={scrollRef}
        className="flex gap-6 overflow-x-auto pb-4 px-8 scroll-smooth hide-scrollbar"
        onScroll={checkScrollPosition}
      >
        {voices.map((voice, index: number) => (
          <motion.div
            key={voice.id}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: index * 0.05 }}
            className="flex-shrink-0 w-[300px] transform hover:scale-[1.02] transition-transform"
          >
            <BasicVoiceCard
              voice={voice}
              index={index}
              onClickPlay={onClickPlay(voice.id)}
              isPlaying={isPlayingVoiceId === voice.id}
              audioElement={audioElement}
            />
          </motion.div>
        ))}
      </View>

      {/* Right Arrow */}
      {hasOverflow && !isAtEnd && (
        <button
          onClick={() => scroll("right")}
          className="absolute right-0 top-1/2 -translate-y-1/2 z-10 bg-black/20 hover:bg-black/30 p-2 rounded-l-lg"
        >
          <Icon
            name="chevron-right"
            className="w-6 h-6 text-white"
          />
        </button>
      )}
    </View>
  );
};
