import React, { useState } from "react";
import { clsx } from "clsx";
import { Button } from "../base/Button";
import { Icon } from "../base/Icon";
import { Text } from "../base/Text";
import { View } from "../base/View";
import { PlayButton } from "../base/PlayButton";
import { ProfileBaseCard } from "./ProfileBaseCard";

type GenerationCardProps = {
  id: string;
  text: string;
  audioUrl: string;
  createdAt: string;
  voiceName: string;
  model?: string;
  notes?: string;
  isPlaying?: boolean;
  onPlay: (generationId: string, audioUrl: string) => void;
  onDownload: (audioUrl: string) => void;
  onGenerateWithConfig: () => void;
  audioElement?: HTMLAudioElement | null;
  className?: string;
  variant?: string;
};

export default function GenerationCard({
  id,
  text,
  audioUrl,
  createdAt,
  voiceName,
  model,
  notes,
  isPlaying = false,
  onPlay,
  onDownload,
  onGenerateWithConfig,
  audioElement,
  className = "",
  variant = "default",
}: GenerationCardProps) {
  const [refreshing, setRefreshing] = useState(false);
  const [showNotes, setShowNotes] = useState(false);

  const handlePlayPause = () => {
    onPlay(id, audioUrl);
  };

  const isListVariant = variant === "list";
  const buttonSize = isListVariant ? "!w-6 !h-6" : "!w-7 !h-7";
  const iconSize = isListVariant ? 14 : 16;

  return (
    <ProfileBaseCard className={clsx("relative group", isListVariant ? "p-3 !mb-3" : "p-4", className)}>
      {/* Header section with date and controls */}
      <View className="flex flex-row justify-between items-start mb-1">
        <View className="flex-1">
          <Text className={clsx("text-gray-400 mb-1", isListVariant ? "text-xs" : "text-xs")}>
            {new Date(createdAt).toLocaleString(undefined, {
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </Text>

          <View className="flex flex-row items-center mb-1">
            <Text className={clsx("font-medium truncate mr-1.5", isListVariant ? "text-xs" : "text-sm")}>{voiceName || "Unnamed Voice"}</Text>

            {model && <Text className={clsx("text-gray-500 truncate", isListVariant ? "text-[10px]" : "text-xs")}>{`(${model})`}</Text>}
          </View>
        </View>
      </View>

      {/* Text content */}
      <Text className={clsx("mb-2 break-words whitespace-pre-line overflow-hidden text-ellipsis line-clamp-3", isListVariant ? "text-xs" : "text-sm")}>
        {text}
      </Text>

      {/* Notes section */}
      {notes && (
        <>
          <Button
            type="basic"
            variant="ghost"
            className="!p-0 !text-xs text-gray-500 hover:!bg-transparent underline"
            onClick={() => setShowNotes(!showNotes)}
          >
            {showNotes ? "Hide notes" : "Show notes"}
          </Button>

          {showNotes && <Text className={clsx("mt-1 text-gray-500 whitespace-pre-line break-words", isListVariant ? "text-[10px]" : "text-xs")}>{notes}</Text>}
        </>
      )}

      {/* Action buttons */}
      {onGenerateWithConfig && (
        <View className="flex flex-row justify-between items-center mt-2">
          <Button
            type="primary"
            variant="outline"
            className={clsx("!text-xs !p-1 !px-2", isListVariant && "!text-[10px] !py-0.5 !px-1.5")}
            onClick={onGenerateWithConfig}
          >
            Reuse these settings
          </Button>
          <View className="flex flex-row gap-1 ml-auto">
            <PlayButton
              isPlaying={isPlaying}
              onPlayPause={handlePlayPause}
              audioElement={audioElement}
              audioUrl={audioUrl}
              className={clsx(buttonSize, "min-w-0 min-h-0")}
            />
            <Button
              type="basic"
              variant="ghost"
              className={clsx("!p-1 hover:!bg-gray-100 dark:hover:!bg-gray-700 min-w-0 min-h-0", buttonSize)}
              onClick={() => onDownload(audioUrl)}
              isDisabled={refreshing || !audioUrl}
            >
              <Icon
                name="download"
                size={iconSize}
              />
            </Button>
          </View>
        </View>
      )}
    </ProfileBaseCard>
  );
}
