import clsx from "clsx";

import { FieldLabel } from "./FieldLabel";
import { Style } from "../../utils/types";
import { memoize } from "../../utils/performance";
import { View } from "./View";
import { Icon } from "./Icon";

type SelectFieldProps = {
  name?: string;
  options: Array<{ label: string; value: string }>;
  value?: string;
  onChange?: (value: string) => void;
  className?: string;
  labelClassName?: string;
  label?: string;
  placeholder?: string;
  style?: Style;
  error?: string;
};

export const SelectField = memoize(
  ({ className, labelClassName, label, name, onChange = () => {}, options, placeholder, style, value, error }: SelectFieldProps) => {
    return (
      <FieldLabel
        label={label}
        error={error}
        className={labelClassName}
      >
        <View className="relative w-full">
          <select
            className={clsx(
              "w-full h-10 pl-4 pr-10 rounded-lg appearance-none",
              "transition-all duration-200",
              "border",
              {
                "border-gray-300 dark:border-gray-600": !error,
                "border-red-500 dark:border-red-400": error,
              },
              "text-gray-900 dark:text-gray-100",
              "bg-white dark:bg-gray-800",
              "placeholder:text-gray-400 dark:placeholder:text-gray-500",
              "focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2 dark:focus:ring-offset-gray-900",
              !value && "text-gray-400 dark:text-gray-500",
              !error && "hover:border-brand dark:hover:border-brand-light",
              error && "hover:border-red-400 dark:hover:border-red-300",
              className,
            )}
            onChange={(e) => onChange(e.target.value)}
            value={value || ""}
            name={name}
            style={style}
          >
            {placeholder && (
              <option
                value=""
                disabled
              >
                {placeholder}
              </option>
            )}
            {options.map((option) => (
              <option
                key={option.value}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
          </select>
          <View className="absolute right-2 top-2 pointer-events-none">
            <Icon
              name="chevron-down"
              size={20}
              className={clsx("transition-colors duration-200", error ? "text-red-500 dark:text-red-400" : "text-gray-400 dark:text-gray-500")}
            />
          </View>
        </View>
      </FieldLabel>
    );
  },
);
