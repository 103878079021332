import React, { useState, useRef } from "react";

import { View } from "../components/base/View";
import { Text } from "../components/base/Text";
import { Button } from "../components/base/Button";
import { makeRequest } from "../utils/request";

interface Voice {
  id: string;
  name: string;
  color: string;
  angle: number;
}

interface VoiceWeights {
  [key: string]: number;
}

export const VoiceMixerPage = () => {
  const containerSize = 400;
  const radius = containerSize / 2;

  const containerRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [ttsText, setTtsText] = useState("Hello, I'm your new voice, ready to take on any task.");
  const [audioUrl, setAudioUrl] = useState<string | null>(null);

  const [voices, setVoices] = useState<Voice[]>([
    { id: "ashen", name: "Ashen", color: "#6B46C1", angle: 0 },
    { id: "dapple", name: "Dapple", color: "#F6AD55", angle: 90 },
    { id: "skyforge", name: "Skyforge", color: "#4299E1", angle: 180 },
    { id: "trill", name: "Trill", color: "#F687B3", angle: 270 },
  ]);

  const [newVoiceName, setNewVoiceName] = useState("");
  const [newVoiceColor, setNewVoiceColor] = useState("#000000");

  const hexToRgb = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : { r: 0, g: 0, b: 0 };
  };

  const blendColors = (colors: string[], weights: number[]): string => {
    const rgbColors = colors.map(hexToRgb);
    const blended = rgbColors.reduce(
      (acc, color, i) => ({
        r: acc.r + color.r * weights[i],
        g: acc.g + color.g * weights[i],
        b: acc.b + color.b * weights[i],
      }),
      { r: 0, g: 0, b: 0 },
    );

    return `rgb(${Math.round(blended.r)}, ${Math.round(blended.g)}, ${Math.round(blended.b)})`;
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!isDragging || !containerRef.current) return;
    const rect = containerRef.current.getBoundingClientRect();
    const centerX = rect.left + radius;
    const centerY = rect.top + radius;
    let newX = e.clientX - centerX;
    let newY = e.clientY - centerY;

    const distance = Math.sqrt(newX * newX + newY * newY);
    if (distance > radius) {
      const angle = Math.atan2(newY, newX);
      newX = radius * Math.cos(angle);
      newY = radius * Math.sin(angle);
    }

    setPosition({ x: newX, y: newY });
  };

  const handleMouseUp = () => {
    if (isDragging) {
      setIsDragging(false);
    }
  };

  React.useEffect(() => {
    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    } else {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    }
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  const handleAddVoice = () => {
    if (newVoiceName.trim() === "") return;

    const newVoice: Voice = {
      id: newVoiceName.toLowerCase().replace(/\s+/g, "-"),
      name: newVoiceName,
      color: newVoiceColor,
      angle: 0,
    };

    setVoices([...voices, newVoice]);
    setNewVoiceName("");
    setNewVoiceColor("#000000");
  };

  const handleRemoveVoice = (voiceId: string) => {
    if (voices.length <= 2) {
      alert("Cannot remove voice. Minimum 2 voices required.");
      return;
    }
    setVoices(voices.filter((voice) => voice.id !== voiceId));
    setPosition({ x: 0, y: 0 });
  };

  const calculateProportions = (): VoiceWeights => {
    const distances = voices.map((voice) => {
      const angleRad = (voice.angle * Math.PI) / 180;
      const voiceX = radius * Math.sin(angleRad);
      const voiceY = -radius * Math.cos(angleRad);

      const dx = position.x - voiceX;
      const dy = position.y - voiceY;
      return { voice, distance: Math.sqrt(dx * dx + dy * dy) };
    });

    const minDistance = Math.min(...distances.map((d) => d.distance));
    const THRESHOLD = radius * 0.12;

    if (minDistance < THRESHOLD) {
      const fullWeightVoice = distances.find((d) => d.distance === minDistance)!.voice;
      return voices.reduce(
        (acc, voice) => ({
          ...acc,
          [voice.id]: voice.id === fullWeightVoice.id ? 1 : 0,
        }),
        {},
      );
    }

    const weights = distances.map(({ voice, distance }) => ({
      voice,
      weight: Math.exp((-3 * distance) / radius),
    }));

    const weightSum = weights.reduce((sum, { weight }) => sum + weight, 0);
    const normalizedWeights = weights.map(({ voice, weight }) => ({
      voice,
      weight: weight / weightSum,
    }));

    return normalizedWeights.reduce(
      (acc, { voice, weight }) => ({
        ...acc,
        [voice.id]: parseFloat(weight.toFixed(2)),
      }),
      {},
    );
  };

  const proportions = calculateProportions();
  const blendedColor = blendColors(
    voices.map((voice) => voice.color),
    voices.map((voice) => proportions[voice.id] || 0),
  );

  const handleTtsSubmit = async () => {
    try {
      const response = await makeRequest<Blob>("/v1/tts", {
        method: "POST",
        body: {
          text: ttsText,
          voice: "multi",
          voice_weights: proportions,
        },
        blob: true,
      });

      const url = URL.createObjectURL(response);

      setAudioUrl(url);
    } catch (error) {
      console.error("TTS request failed:", error);
      setAudioUrl(null);
    }
  };

  React.useEffect(() => {
    return () => {
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
      }
    };
  }, [audioUrl]);

  return (
    <View className="min-h-screen bg-white dark:bg-gray-900">
      <View className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <Text className="text-3xl font-bold text-gray-900 dark:text-white mb-8">Voice Mixer</Text>

        {/* Voice Management Section */}
        <View className="bg-gray-50 dark:bg-gray-800 rounded-lg p-6 mb-8">
          <Text className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Manage Voices</Text>

          <View className="flex gap-4 mb-6">
            <input
              type="text"
              placeholder="Voice Name"
              value={newVoiceName}
              onChange={(e) => setNewVoiceName(e.target.value)}
              className="flex-1 px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="color"
              value={newVoiceColor}
              onChange={(e) => setNewVoiceColor(e.target.value)}
              className="w-16 h-10 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700"
            />
            <Button
              onClick={handleAddVoice}
              className="px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg"
            >
              Add Voice
            </Button>
          </View>

          <View className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {voices.map((voice) => (
              <View
                key={voice.id}
                className="flex items-center justify-between p-3 bg-white dark:bg-gray-700 rounded-lg"
              >
                <View className="flex items-center gap-3">
                  <View
                    className="w-6 h-6 rounded-full"
                    style={{ backgroundColor: voice.color }}
                  />
                  <Text className="text-gray-900 dark:text-white">{voice.name}</Text>
                </View>
                <Button
                  onClick={() => handleRemoveVoice(voice.id)}
                  className="p-1 text-red-600 hover:text-red-700"
                >
                  ×
                </Button>
              </View>
            ))}
          </View>
        </View>

        {/* Interactive Circle and Proportions Section */}
        <View className="bg-gray-50 dark:bg-gray-800 rounded-lg p-6 mb-8">
          <Text className="text-xl font-semibold text-gray-900 dark:text-white mb-8">Voice Mixer</Text>

          <View className="flex flex-col lg:flex-row gap-8 items-center justify-center">
            {/* Circle */}
            <View
              ref={containerRef}
              className="relative rounded-full shadow-lg overflow-hidden flex-shrink-0"
              style={{
                width: containerSize,
                height: containerSize,
                background: blendedColor,
              }}
            >
              {/* Guide lines */}
              {voices.map((voice) => (
                <View
                  key={voice.id}
                  className="absolute top-1/2 left-1/2 w-px h-full bg-white/20"
                  style={{
                    transform: `translateY(-50%) rotate(${voice.angle}deg)`,
                  }}
                />
              ))}

              {/* Labels */}
              {voices.map((voice) => {
                const angleRad = (voice.angle * Math.PI) / 180;
                const x = radius + (radius + 40) * Math.sin(angleRad);
                const y = radius - (radius + 40) * Math.cos(angleRad);
                return (
                  <View
                    key={voice.id}
                    className="absolute px-4 py-2 rounded-full text-white text-sm font-semibold transform -translate-x-1/2 -translate-y-1/2 shadow-lg"
                    style={{
                      left: x,
                      top: y,
                      background: `linear-gradient(135deg, ${voice.color}CC, ${voice.color})`,
                    }}
                  >
                    {voice.name}
                  </View>
                );
              })}

              {/* Draggable Dot */}
              <View
                onMouseDown={handleMouseDown}
                className="absolute w-10 h-10 rounded-full bg-white cursor-grab shadow-lg transform -translate-x-1/2 -translate-y-1/2 transition-transform duration-200"
                style={{
                  left: position.x + radius,
                  top: position.y + radius,
                  transform: `translate(-50%, -50%) scale(${isDragging ? 1.1 : 1})`,
                  boxShadow: `0 4px 8px rgba(0,0,0,0.2), 0 0 0 3px ${blendedColor}`,
                }}
              >
                <View
                  className="absolute top-1/2 left-1/2 w-5 h-5 rounded-full transform -translate-x-1/2 -translate-y-1/2 opacity-50"
                  style={{ background: blendedColor }}
                />
              </View>
            </View>

            {/* Voice Proportions */}
            <View className="lg:w-auto">
              <View className="grid auto-cols-[minmax(0,300px)] grid-flow-col gap-8">
                {/* Create columns of 4 items */}
                {Array.from({ length: Math.ceil(voices.length / 4) }).map((_, colIndex) => (
                  <View
                    key={colIndex}
                    className="space-y-4"
                  >
                    {voices.slice(colIndex * 4, (colIndex + 1) * 4).map((voice) => (
                      <View
                        key={voice.id}
                        className="flex items-center gap-4 bg-white dark:bg-gray-700 rounded-lg p-4"
                      >
                        <View className="flex items-center gap-3 flex-1 min-w-0">
                          <View
                            className="w-6 h-6 rounded-full flex-shrink-0"
                            style={{ backgroundColor: voice.color }}
                          />
                          <Text
                            className="font-semibold truncate"
                            style={{ color: voice.color }}
                          >
                            {voice.name}
                          </Text>
                        </View>
                        <input
                          type="number"
                          min="0"
                          max="1"
                          step="0.01"
                          value={proportions[voice.id]}
                          onChange={(e) => {
                            const newValue = Math.min(1, Math.max(0, parseFloat(e.target.value) || 0));
                            const otherVoices = voices.filter((v) => v.id !== voice.id);
                            const remainingWeight = 1 - newValue;
                            const currentOtherWeights = otherVoices.reduce((sum, v) => sum + (proportions[v.id] || 0), 0);

                            const newProportions = { ...proportions };
                            newProportions[voice.id] = newValue;

                            if (currentOtherWeights > 0) {
                              otherVoices.forEach((v) => {
                                newProportions[v.id] = (proportions[v.id] / currentOtherWeights) * remainingWeight;
                              });
                            } else {
                              otherVoices.forEach((v) => {
                                newProportions[v.id] = remainingWeight / otherVoices.length;
                              });
                            }

                            const angleRad = (voice.angle * Math.PI) / 180;
                            const distance = radius * (1 - newValue);
                            const newX = distance * Math.sin(angleRad);
                            const newY = -distance * Math.cos(angleRad);
                            setPosition({ x: newX, y: newY });
                          }}
                          className="w-24 px-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-2 focus:ring-blue-500"
                        />
                      </View>
                    ))}
                  </View>
                ))}
              </View>
            </View>
          </View>
        </View>

        {/* TTS Section */}
        <View className="bg-gray-50 dark:bg-gray-800 rounded-lg p-6">
          <Text className="text-xl font-semibold text-gray-900 dark:text-white mb-4">Text-to-Speech</Text>
          <View className="flex gap-4 mb-4">
            <input
              type="text"
              placeholder="Enter text to speak"
              value={ttsText}
              onChange={(e) => setTtsText(e.target.value)}
              className="flex-1 px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-2 focus:ring-blue-500"
            />
            <Button
              onClick={handleTtsSubmit}
              className="px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg"
            >
              Generate Speech
            </Button>
          </View>
          {audioUrl && (
            <View className="bg-white dark:bg-gray-700 rounded-lg p-4">
              <audio
                controls
                src={audioUrl}
                className="w-full"
              >
                Your browser does not support the audio element.
              </audio>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

export default VoiceMixerPage;
