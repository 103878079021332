import { createRestSlice } from "./restSlice";

export type AgentEnvironmentVariable = {
  id: string;
  agentId: string;
  agentEnvironmentId: string;
  name: string;
  value: string;
  masked: boolean;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
};

const agentEnvironmentVariableSlice = createRestSlice<AgentEnvironmentVariable>(
  "agentEnvironmentVariables",
  "/v1/agents/:agentId/environments/:agentEnvironmentId/variables",
);

export const agentEnvironmentVariableReducer = agentEnvironmentVariableSlice.reducer;
export const agentEnvironmentVariableActions = agentEnvironmentVariableSlice.actions;
