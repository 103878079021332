import { createRestSlice } from "./restSlice";

export type AgentFunction = {
  id: string;
  agentId: string;
  name: string;
  code: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
};

const agentFunctionSlice = createRestSlice<AgentFunction>("agentFunctions", "/v1/agents/:agentId/functions");

export const agentFunctionReducer = agentFunctionSlice.reducer;
export const agentFunctionActions = agentFunctionSlice.actions;
