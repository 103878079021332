import clsx from "clsx";

import { View } from "./View";

export type Tab = {
  id: string;
  label: string;
  icon?: string;
};

type TabsProps = {
  tabs: Tab[];
  activeTab: string;
  onChange: (tabId: string) => void;
  className?: string;
  isDisabled?: boolean;
};

export const Tabs = ({ tabs, activeTab, onChange, className, isDisabled }: TabsProps) => {
  return (
    <View className={className}>
      <View className="flex space-x-8">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => !isDisabled && onChange(tab.id)}
            className={clsx(
              "py-4 px-1 border-b-2 font-medium text-sm focus:outline-none whitespace-nowrap transition-colors",
              isDisabled && "cursor-default",
              activeTab === tab.id
                ? "border-brand dark:border-brand-light text-brand dark:text-brand-light"
                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300 dark:hover:border-gray-600",
            )}
          >
            {tab.label}
          </button>
        ))}
      </View>
    </View>
  );
};
