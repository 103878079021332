import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";

import { FieldLabel } from "./FieldLabel";
import { Icon } from "./Icon";
import { Button } from "./Button";
import { View } from "./View";

type InputFieldProps = {
  className?: string;
  label?: string;
  name?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  value?: string;
  type?: string;
  readOnly?: boolean;
  autoGrow?: boolean;
  labelClassName?: string;
  showError?: boolean;
  error?: string;
  suffix?: React.ReactNode;
  onKeyDown?: (event: React.KeyboardEvent) => void;
};

export const InputField = ({
  className,
  label,
  name,
  onChange = () => {},
  placeholder,
  value,
  type = "text",
  readOnly,
  autoGrow = true,
  labelClassName,
  showError,
  error,
  suffix,
  onKeyDown,
}: InputFieldProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [showPassword, setShowPassword] = useState(false);

  const onChangeInternal = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(event.target.value);
  };

  useEffect(() => {
    if (textareaRef.current && autoGrow) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value, autoGrow]);

  const inputClasses = clsx(
    "w-full px-4 py-2 rounded-lg border transition-colors duration-200",
    "text-gray-900 dark:text-gray-100 bg-white dark:bg-gray-800",
    "placeholder:text-gray-400 dark:placeholder:text-gray-500",
    "focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2 dark:focus:ring-offset-gray-900",
    {
      "border-gray-300 dark:border-gray-600 hover:border-brand/50 dark:hover:border-brand/50": !showError,
      "border-red-500 dark:border-red-500": showError,
      "bg-gray-100 dark:bg-gray-600": readOnly,
    },
    className,
  );

  return (
    <FieldLabel
      label={label}
      className={clsx("w-full", labelClassName)}
      error={error}
    >
      <View className="relative w-full h-full">
        {type === "textarea" ? (
          <textarea
            ref={textareaRef}
            className={clsx(inputClasses, "h-full min-h-full w-full resize-none")}
            onChange={onChangeInternal}
            name={name}
            placeholder={placeholder}
            value={value || ""}
            disabled={readOnly}
            onKeyDown={onKeyDown}
          />
        ) : (
          <input
            className={clsx(inputClasses, "h-10 w-full")}
            type={showPassword ? "text" : type}
            onChange={onChangeInternal}
            name={name}
            placeholder={placeholder}
            value={value || ""}
            disabled={readOnly}
            onKeyDown={onKeyDown}
          />
        )}
        <View className="absolute right-3 top-1/2 -translate-y-1/2 flex items-center space-x-2">
          {type === "password" && (
            <Button
              type="basic"
              variant="ghost"
              className="!p-1"
              onClick={() => setShowPassword(!showPassword)}
            >
              <Icon
                name={showPassword ? "eye-outline" : "eye-slash-outline"}
                size={20}
                className="text-gray-400 hover:text-brand dark:text-gray-500 dark:hover:text-brand-light"
              />
            </Button>
          )}
          {showError && (
            <Icon
              name="info-outline"
              size={20}
              className="text-red-500"
            />
          )}
        </View>
        {suffix && <View className="absolute bottom-2 right-2 pointer-events-none">{suffix}</View>}
      </View>
    </FieldLabel>
  );
};
