import { Outlet } from "react-router-dom";

import { View } from "../components/base/View";
import { RequireAuth } from "../components/auth/RequireAuth";
import { BaseHeader } from "../components/BaseHeader";

export const AdminLayout = () => {
  return (
    <RequireAuth requireAdmin>
      <View className="min-h-screen bg-gray-50 dark:bg-gray-900">
        <BaseHeader
          title="Admin"
          showBrand={true}
          links={[
            { label: "Users", to: "/admin/users" },
            { label: "Organizations", to: "/admin/organizations" },
            { label: "Public Voices", to: "/admin/publicVoices" },
            { label: "Dataset Bases", to: "/admin/datasetBases" },
            { label: "Datasets", to: "/admin/datasets" },
            { label: "Training Jobs", to: "/admin/trainingJobs" },
          ]}
        />
        <View className="flex-1 min-h-[calc(100vh-73px)] bg-gray-50 dark:bg-gray-900">
          <Outlet />
        </View>
      </View>
    </RequireAuth>
  );
};
