import { createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { makeRequest } from "../../utils/request";
import { createRestSlice, ItemState } from "./restSlice";

export type AgentEnvironment = {
  id: string;
  agentId: string;
  name: string;
  functionId: string;
  phoneNumber: string;
  sttModel?: string;
  ttsModel?: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
};

const configurePhone = createAsyncThunk("agentEnvironment/configurePhone", async ({ agentId, id }: Partial<AgentEnvironment>) => {
  const { data } = await makeRequest<{ data: AgentEnvironment }>(`/v1/agents/${agentId}/environments/${id}/configure-phone`, {
    method: "POST",
  });

  return data;
});

const agentEnvironmentSlice = createRestSlice<AgentEnvironment>("agentEnvironments", "/v1/agents/:agentId/environments", {
  extraReducers: (builder) => {
    builder.addCase(configurePhone.pending, (state: ItemState<AgentEnvironment>) => {
      state.isLoading = true;
    });

    builder.addCase(configurePhone.fulfilled, (state: ItemState<AgentEnvironment>, action: PayloadAction<AgentEnvironment>) => {
      state.isLoading = false;
      state.entities[action.payload.id] = action.payload;
    });

    builder.addCase(configurePhone.rejected, (state: ItemState<AgentEnvironment>) => {
      state.isLoading = false;
    });
  },
});

export const agentEnvironmentReducer = agentEnvironmentSlice.reducer;
export const agentEnvironmentActions = {
  ...agentEnvironmentSlice.actions,
  configurePhone,
};
