import { AddCircleOutline, CloseOutlined, DeleteOutline, EditOutlined, RefreshOutlined } from "@mui/icons-material";
import { Modal } from "antd";
import { useSearchParams } from "react-router-dom";
import clsx from "clsx";
import React, { useEffect, useState } from "react";

import { Button } from "../base/Button";
import { capitalize, generate } from "../../utils/strings";
import { InputField } from "../base/InputField";
import { EntityState, getActionsByName, useAppDispatch, useAppSelector } from "../../redux";
import { Item, ItemState } from "../../redux/reducers/restSlice";
import { CheckboxField } from "../base/CheckboxField";
import { FieldLabel } from "../base/FieldLabel";
import { useApiRequest } from "../../hooks/useApiRequest";
import { View } from "../base/View";
import { Spinner } from "../base/Spinner";
import { useDebounce } from "react-use";
import { FileField } from "../base/FileField";
import { Text } from "../base/Text";
import { SelectField } from "../base/SelectField";

type RestListFieldsProps<T> = {
  fields: Field<T>[];
  selectedEntityId: string;
  formData: T;
  isCreate: boolean;
  onChangeFieldHandler: (key: string) => (value: string) => void;
  onChangeFieldAudioHandler: (key: string) => (files: FileList) => Promise<void>;
};

const RestListFields = <T extends {}>({
  fields,
  selectedEntityId,
  formData,
  isCreate,
  onChangeFieldHandler,
  onChangeFieldAudioHandler,
}: RestListFieldsProps<T>) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<string>("");

  const onClickOpenModalHandler = (fieldName: string) => () => {
    setModalContent(formData?.[fieldName as keyof T] as string);
    setIsModalOpen(true);
  };

  const onClickConfirmModalHandler = (fieldName: string) => () => {
    onChangeFieldHandler(fieldName)(modalContent);

    setIsModalOpen(false);
    setModalContent("");
  };

  const onClickCancelModal = () => {
    setIsModalOpen(false);
    setModalContent("");
  };

  const onChangeModalContent = (value: string) => {
    setModalContent(value);
  };

  return (
    <>
      {fields
        .filter((field) => !field.button && (isCreate ? !field.readOnly : true))
        .map((field) =>
          field.component ? (
            React.createElement(field.component, {
              key: field.name,
              id: selectedEntityId,
              formData,
              value: formData?.[field.name as keyof T] as string,
              onChange: onChangeFieldHandler(field.name),
            })
          ) : field.type === "checkbox" ? (
            <CheckboxField
              key={field.name}
              className="rounded border-2 border-purple-500/50 bg-transparent checked:bg-purple-600 checked:border-purple-600 focus:ring-0 focus:ring-offset-0 focus:border-purple-500/50 cursor-pointer"
              label={field.label}
              value={formData?.[field.name as keyof T] as boolean}
              onChange={onChangeFieldHandler(field.name) as unknown as (value: boolean) => void}
              readOnly={field.readOnly}
            />
          ) : field.type === "select" ? (
            <SelectField
              className="mb-4"
              key={field.name}
              label={field.label}
              value={formData?.[field.name as keyof T] as string}
              placeholder="Select an option"
              options={field.options || []}
              onChange={onChangeFieldHandler(field.name)}
            />
          ) : field.type === "textarea_modal" ? (
            <FieldLabel
              key={field.name}
              label={field.label}
              className="mb-4"
            >
              <Button
                className="mr-1 !h-[50px] !shadow-none"
                onClick={onClickOpenModalHandler(field.name)}
                type="basic"
                variant="outline"
              >
                <EditOutlined />
              </Button>
              <Modal
                open={isModalOpen}
                onCancel={onClickCancelModal}
                onOk={onClickConfirmModalHandler(field.name)}
                className="!w-[80vh]"
              >
                <InputField
                  className="border-[1px] border-gray-300 shadow-none w-full !text-black !text-base min-h-[60vh] max-h-[60vh] !indent-0"
                  placeholder={field.label}
                  label={field.label}
                  value={modalContent}
                  onChange={onChangeModalContent}
                  type="textarea"
                />
              </Modal>
            </FieldLabel>
          ) : field.type === "audio" ? (
            <FieldLabel
              label={field.label}
              className="mb-4"
            >
              <FileField
                key={field.name}
                className="!border-gray-300 shadow-none !indent-0 px-4 max-h-[100px]"
                onChange={onChangeFieldAudioHandler(field.name)}
                value={formData?.[field.name as keyof T] as FileList}
                size="sm"
              />
              {formData?.[field.name as keyof T] && (
                <audio
                  className="w-full mt-2"
                  src={formData?.[field.name as keyof T] as string}
                  controls
                />
              )}
            </FieldLabel>
          ) : (
            <InputField
              key={field.name}
              className="border-[1px] border-gray-300 shadow-none !indent-0 px-4 mb-4"
              placeholder={field.label}
              label={field.label}
              value={formData?.[field.name as keyof T] as string}
              onChange={onChangeFieldHandler(field.name)}
              type={field.type}
              readOnly={field.readOnly}
            />
          ),
        )}
    </>
  );
};

export type RestListButtonProps<T> = {
  id: string;
  formData: T;
};

export type RestListComponentProps<T> = {
  id: string;
  formData: T;
  value: string;
  onChange: (value: string) => void;
};

export type Field<T> = {
  name: string;
  label: string;
  options?: { label: string; value: string }[];
  value?: (value: string) => string;
  type?: string;
  visible?: boolean;
  readOnly?: boolean;
  button?: React.JSXElementConstructor<RestListButtonProps<T>>;
  component?: React.JSXElementConstructor<RestListComponentProps<T>>;
};

export type RestListProps<T> = {
  className?: string;
  children?: React.ReactNode;
  entityName: keyof EntityState;
  search?: (query: string, filters?: Record<string, any>) => Promise<string[]>;
  filters?: Record<string, any>;
  fields: Field<T>[];
  onSelect?: () => void;
  sort?: (ids: string[], entities: Record<string, T>) => string[];
  transform?: (data: T) => T;
  editModal?: boolean;
  onSave?: () => void;
  onClose?: () => void;
  onChange?: (formData: T) => void;
  skipInitialFetch?: boolean;
};

export const RestList = <T extends Item>({
  children,
  className,
  entityName,
  fields,
  search,
  filters,
  onSelect = () => {},
  sort = (ids) => ids,
  transform = (data: T) => data,
  editModal = false,
  onSave = () => {},
  onClose = () => {},
  onChange = () => {},
  skipInitialFetch = false,
}: RestListProps<T>) => {
  const dispatch = useAppDispatch();
  const actions = getActionsByName(entityName);
  const entityIds = useAppSelector((state) => (state[entityName] as unknown as ItemState<T>).ids);
  const entityMap = useAppSelector((state) => (state[entityName] as unknown as ItemState<T>).entities);
  const isLoading = useAppSelector((state) => (state[entityName] as unknown as ItemState<T>).isLoading);
  const isLoadingCreate = useAppSelector((state) => (state[entityName] as unknown as ItemState<T>).isLoadingCreate);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedEntityId, setSelectedEntityId] = useState<string | null>(null);
  const [formData, setFormData] = useState<T>({} as T);
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);
  const [filteredEntityIds, setFilteredEntityIds] = useState<string[]>([]);
  const visibleFields = fields.filter((field) => field.visible !== false);
  const visibleButtons = visibleFields.filter((field) => field.button).length || 0;
  const headerColumns = `${generate("1fr", visibleFields.filter((field) => !field.button).length, " ")} ${generate("64px", visibleButtons, " ")} 64px 64px`;
  const columns = `${generate("1fr", visibleFields.filter((field) => !field.button).length, " ")} ${generate("64px", visibleButtons, " ")} 64px 64px`;
  const apiRequest = useApiRequest<{ data: string }>();

  useDebounce(
    async () => {
      if (search) {
        console.log("search", searchQuery, filters);
        setIsLoadingSearch(true);

        const ids = await search(searchQuery, filters);
        setFilteredEntityIds(ids);

        setIsLoadingSearch(false);
      }
    },
    300,
    [searchQuery, filters],
  );

  const onClickRowHandler = (id: string) => () => {
    setSearchParams({ id });
    onSelect();
  };

  const onClickCloseModal = () => {
    setIsOpenConfirmDelete(false);
    setSelectedDeleteId(null);
    setSearchParams({});
  };

  const onClickConfirmDelete = () => {
    if (selectedDeleteId) {
      // @ts-ignore
      dispatch(actions.deleteById({ id: selectedDeleteId }));
    }

    onClickCloseModal();
  };

  const onClickDeleteHandler = (id: string) => (event: React.MouseEvent) => {
    event.stopPropagation();

    setIsOpenConfirmDelete(true);
    setSelectedDeleteId(id);
  };

  const onClickCreate = async () => {
    // @ts-ignore
    await dispatch(actions.create(formData));

    setSearchParams({});
    onSave();
  };

  const onClickCreateModal = () => {
    onClickCreate();
    onClickClose();
    onClose();
  };

  const onClickUpdate = async () => {
    if (selectedEntityId) {
      const filteredFormData: Record<string, string> = {};

      Object.entries(formData).forEach(([key, value]) => {
        const field = fields.find((field) => field.name === key);

        if (field && !field.readOnly) {
          filteredFormData[key] = value;
        }
      });

      // @ts-ignore
      await dispatch(actions.updateById({ id: selectedEntityId, ...filteredFormData }));

      onSave();
    }
  };

  const onClickUpdateModal = () => {
    onClickUpdate();
    onClickClose();
    onClose();
  };

  const onChangeFieldHandler = (key: string) => (value: string) => {
    const newFormData = transform({
      ...formData,
      [key]: value,
    });

    setFormData(newFormData);
    onChange(newFormData);
  };

  const onChangeFieldAudioHandler = (key: string) => async (files: FileList) => {
    const file = files?.[0];

    if (file) {
      const response = await apiRequest.fetch("/v1/files/audio", {
        method: "POST",
        file,
      });

      setFormData({
        ...formData,
        [key]: response.data?.data || "",
      });
    }
  };

  const onClickAdd = () => {
    setSearchParams({ id: "new" });
  };

  const onClickRefresh = () => {
    // @ts-ignore
    dispatch(actions.get({ shallow: "true" }));
  };

  const onClickClose = () => {
    setSearchParams({});
    onClose();
  };

  const onSearchChange = (value: string) => {
    setSearchQuery(value);
  };

  useEffect(() => {
    const id = searchParams.get("id");
    if (!id || id === "new") {
      setFormData({} as T);
      onChange({} as T);
    } else {
      setFormData(entityMap[id] as T);
      onChange(entityMap[id] as T);
    }

    setSelectedEntityId(id);
  }, [searchParams, entityMap]);

  useEffect(() => {
    const id = searchParams.get("id");

    if (id && id !== "new") {
      // @ts-ignore
      dispatch(actions.getById({ id }));
    }
  }, [searchParams]);

  useEffect(() => {
    if (!skipInitialFetch) {
      // @ts-ignore
      dispatch(actions.get({ shallow: "true" }));
    }
  }, []);

  return (
    <div className={clsx("flex flex-col items-center w-full h-full", className)}>
      <div className="flex flex-row w-full h-[calc(100vh-73px)]">
        <div className="w-full flex flex-col overflow-hidden">
          {search && (
            <View className="relative w-full mt-8 mb-4 px-4">
              <InputField
                placeholder="Search users..."
                value={searchQuery}
                onChange={onSearchChange}
              />
              {isLoadingSearch && <Spinner className="absolute right-8 top-3.5" />}
            </View>
          )}
          <div
            className="grid border-b-2 border-b-gray-300 dark:border-b-gray-700 pl-4"
            style={{ gridTemplateColumns: headerColumns }}
          >
            {visibleFields.map((field) =>
              field.button ? (
                <div key={field.name} />
              ) : (
                <span
                  key={field.name}
                  className="self-center px-2 py-4 mr-2 font-semibold text-gray-900 dark:text-gray-100"
                >
                  {field.label}
                </span>
              ),
            )}
            <Button
              className="h-full !shadow-none"
              type="basic"
              variant="ghost"
              onClick={onClickAdd}
            >
              <AddCircleOutline className="text-brand group-hover:text-brand-dark dark:text-brand-light dark:group-hover:text-brand" />
            </Button>
            <Button
              className="h-full !shadow-none"
              type="basic"
              variant="ghost"
              onClick={onClickRefresh}
            >
              <RefreshOutlined className="text-brand group-hover:text-brand-dark dark:text-brand-light dark:group-hover:text-brand" />
            </Button>
          </div>
          <div className="flex flex-col flex-1 overflow-y-auto w-full">
            {sort(entityIds, entityMap)
              .filter((id) => (search ? filteredEntityIds.includes(id) : true))
              .map((id) => {
                const entity = entityMap[id];

                return (
                  <div
                    key={id}
                    className={clsx(
                      "grid border-b-[1px] border-b-gray-300 dark:border-b-gray-700 pl-4 hover:bg-gray-100 dark:hover:bg-gray-800 active:bg-gray-200 dark:active:bg-gray-700",
                      selectedEntityId === id && "bg-gray-100 dark:bg-gray-800",
                    )}
                    style={{ gridTemplateColumns: columns }}
                    onClick={onClickRowHandler(entity.id)}
                  >
                    {visibleFields.map((field) =>
                      field.button ? (
                        React.createElement(field.button, {
                          key: field.name,
                          formData: entity,
                          id,
                        })
                      ) : field.type === "dropdown" ? (
                        <div
                          key={field.name}
                          className="self-center cursor-pointer truncate select-none mr-2 p-2 rounded-lg text-gray-900 dark:text-gray-100"
                        >
                          {String(field.options?.find((option) => option.value === entity?.[field.name as keyof typeof entity])?.label)}
                        </div>
                      ) : field.type === "checkbox" ? (
                        <div
                          key={field.name}
                          className="self-center cursor-pointer truncate select-none mr-2 p-2 rounded-lg text-gray-900 dark:text-gray-100"
                        >
                          {!!entity?.[field.name as keyof typeof entity] ? "✅" : "❌"}
                        </div>
                      ) : (
                        <div
                          key={field.name}
                          className="self-center cursor-pointer truncate select-none mr-2 p-2 rounded-lg text-gray-900 dark:text-gray-100"
                        >
                          {String(entity?.[field.name as keyof typeof entity])}
                        </div>
                      ),
                    )}
                    <Button
                      className="h-full !shadow-none"
                      onClick={onClickDeleteHandler(entity.id)}
                      type="basic"
                      variant="ghost"
                    >
                      <DeleteOutline className="text-brand group-hover:text-brand-dark dark:text-brand-light dark:group-hover:text-brand" />
                    </Button>
                  </div>
                );
              })}
          </div>
        </div>
        {selectedEntityId && (
          <>
            {!editModal ? (
              <div className="border-l border-l-gray-300 dark:border-l-gray-700 h-[calc(100vh-73px)] fixed right-0 top-[73px]">
                {children || (
                  <div className="flex flex-col w-[400px] min-w-[400px] h-full bg-white dark:bg-gray-800">
                    <div className="flex flex-row w-full items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700">
                      <Text
                        variant="brand"
                        className="text-xl font-semibold"
                      >
                        {selectedEntityId === "new" ? `Add ${capitalize(entityName)}` : `Edit ${capitalize(entityName)}`}
                      </Text>
                      <Button
                        className="!w-8 !h-8 !rounded-full"
                        onClick={onClickClose}
                        type="basic"
                        variant="ghost"
                      >
                        <CloseOutlined className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200" />
                      </Button>
                    </div>
                    <div className="flex-1 p-6 overflow-y-auto">
                      <RestListFields<T>
                        fields={fields}
                        selectedEntityId={selectedEntityId}
                        formData={formData}
                        onChangeFieldHandler={onChangeFieldHandler}
                        onChangeFieldAudioHandler={onChangeFieldAudioHandler}
                        isCreate={selectedEntityId === "new"}
                      />
                    </div>
                    <div className="w-full p-4 border-t border-gray-200 dark:border-gray-700">
                      {selectedEntityId === "new" ? (
                        <Button
                          className="w-full"
                          onClick={onClickCreate}
                          isLoading={isLoadingCreate}
                          variant="default"
                        >
                          Create
                        </Button>
                      ) : (
                        <Button
                          className="w-full"
                          onClick={onClickUpdate}
                          isLoading={isLoading}
                          variant="default"
                        >
                          Update
                        </Button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <Modal
                onCancel={onClickClose}
                onClose={onClickClose}
                onOk={selectedEntityId === "new" ? onClickCreateModal : onClickUpdateModal}
                confirmLoading={isLoading}
                width="80vw"
                bodyProps={{
                  style: {
                    height: "70vh",
                    overflow: "hidden",
                  },
                }}
                open
              >
                <div className="p-4 flex flex-col h-full">
                  <RestListFields<T>
                    fields={fields}
                    selectedEntityId={selectedEntityId}
                    formData={formData}
                    onChangeFieldHandler={onChangeFieldHandler}
                    onChangeFieldAudioHandler={onChangeFieldAudioHandler}
                    isCreate={selectedEntityId === "new"}
                  />
                </div>
              </Modal>
            )}
          </>
        )}
      </div>
      <Modal
        open={isOpenConfirmDelete}
        onCancel={onClickCloseModal}
        onClose={onClickCloseModal}
        onOk={onClickConfirmDelete}
        centered
      >
        <span>Are you sure you want to delete this item?</span>
      </Modal>
    </div>
  );
};
