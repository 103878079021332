import React from "react";
import { Button } from "../base/Button";
import { Text } from "../base/Text";
import { View } from "../base/View";
import { ProfileBaseCard } from "./ProfileBaseCard";

type BalanceCardProps = {
  balance: number;
  onClickAddFunds: () => void;
  className?: string;
};

export const BalanceCard = ({ balance, onClickAddFunds, className }: BalanceCardProps) => {
  return (
    <ProfileBaseCard
      title="Balance"
      actionButton={{
        label: "Add Funds",
        onClick: onClickAddFunds,
      }}
      className={className}
    >
      <View className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
        <Text
          variant="body"
          className="text-3xl font-bold mb-4 sm:mb-0"
        >
          ${(balance / 1000000).toFixed(2)} available
        </Text>
      </View>
    </ProfileBaseCard>
  );
};
