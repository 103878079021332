import { createRestSlice } from "./restSlice";

export type Dataset = {
  id: string;
  userId: string;
  name: string;
  description: string;
  datasetBaseId: string;
  referenceUrl: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
};

const datasetSlice = createRestSlice<Dataset>("dataset", "/v1/datasets");

export const datasetReducer = datasetSlice.reducer;
export const datasetActions = datasetSlice.actions;
