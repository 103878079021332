import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { AgentRecipe, agentRecipeActions } from "../../redux/reducers/agentRecipeSlice";
import { RecipeListItem } from "../../components/recipes/RecipeListItem";
import { SearchableList } from "../../components/SearchableList";
import { useAppDispatch, useAppSelector } from "../../redux";
import { View } from "../../components/base/View";

export const AgentRecipesPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const recipeEntities = useAppSelector((state) => state.agentRecipe.entities);
  const isLoading = useAppSelector((state) => state.agentRecipe.isLoading);
  const recipes = Object.values(recipeEntities);

  const onClickItem = (id: string) => {
    navigate(`/agents/recipes/${id}`);
  };

  const onClickCreate = async () => {
    try {
      // Dispatch the create action and wait for it to complete
      const resultAction = await dispatch(agentRecipeActions.create({})).unwrap();

      // Navigate to the newly created recipe's details page
      if (resultAction?.id) {
        navigate(`/agents/recipes/${resultAction.id}`);
      }
    } catch (error) {
      console.error("Failed to create new recipe:", error);
    }
  };

  const filterRecipes = (searchQuery: string, item: Record<string, unknown>) => {
    const recipe = item as AgentRecipe;
    const recipeName = recipe.name?.toLowerCase() || "";
    const recipeDescription = recipe.description?.toLowerCase() || "";

    return recipeName.includes(searchQuery.toLowerCase()) || recipeDescription.includes(searchQuery.toLowerCase());
  };

  useEffect(() => {
    dispatch(agentRecipeActions.get({}));
  }, [dispatch]);

  return (
    <View className="bg-white dark:bg-gray-900">
      <SearchableList
        entityName="recipe"
        emptyMessage={`Create your first recipe to get started.\nRecipes help you define reusable workflows for your agents.`}
        filter={filterRecipes}
        isLoading={isLoading}
        onClickCreate={onClickCreate}
        items={recipes}
        itemComponent={({ item, variant, isEmpty }) => {
          const recipe = item as AgentRecipe;

          const onClick = () => {
            if (isEmpty) {
              onClickCreate();
            } else {
              onClickItem(recipe.id);
            }
          };

          return (
            <RecipeListItem
              id={recipe.id}
              name={recipe.name}
              description={recipe.description}
              onClick={onClick}
              variant={variant}
              isEmpty={isEmpty}
            />
          );
        }}
      />
    </View>
  );
};
