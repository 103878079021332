import { motion } from "framer-motion";
import { useState, useEffect } from "react";

import { AgentFunctionEditor } from "../../components/agents/AgentFunctionEditor";
import { useAppDispatch, useAppSelector } from "../../redux";
import { useSearchParam } from "../../hooks/useSearchParam";
import { AgentFunction } from "../../redux/reducers/agentFunctionSlice";
import { featureActions } from "../../redux/reducers/featureSlice";
import { useNavigate, useParams } from "react-router-dom";

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.4 },
  },
};

export const AgentFunctionPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { agentId } = useParams();
  const functionId = useSearchParam("f");
  const agentFunction = useAppSelector((state) => (functionId ? state.agentFunction.entities[functionId] : null));
  const agentFunctions = useAppSelector((state) => Object.values(state.agentFunction.entities));
  const agentFunctionFormData = useAppSelector((state) => state.feature.agentFunctionFormData);
  const agentEnvironments = useAppSelector((state) => Object.values(state.agentEnvironment.entities).filter((env) => env.agentId === agentId));

  useEffect(() => {
    if (agentFunction) {
      dispatch(featureActions.setAgentFunctionFormData(agentFunction));
    }
  }, [agentFunction]);

  useEffect(() => {
    if (!functionId && agentFunctions.length > 0) {
      navigate(`/agents/${agentId}/function?f=${agentFunctions[0].id}`);
    }
  }, [functionId, agentFunctions]);

  const onChange = (value: string) => {
    dispatch(featureActions.setAgentFunctionFormData({ ...agentFunctionFormData, code: value }));
  };

  return (
    <motion.div
      className="flex flex-col flex-1 h-full overflow-hidden mb-4"
      variants={itemVariants}
    >
      <AgentFunctionEditor
        onChange={onChange}
        value={agentFunctionFormData.code || ""}
        readOnly={agentEnvironments.some((env) => env.functionId === functionId)}
      />
    </motion.div>
  );
};
