import { RestList, RestListComponentProps } from "../../components/shared/RestList";
import { View } from "../../components/base/View";
import { useSearchParams } from "react-router-dom";
import { Button } from "../../components/base/Button";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Dataset } from "../../redux/reducers/datasetSlice";
import { useAppSelector } from "../../redux";

export const AdminDatasetPage = () => {
  const [searchParams] = useSearchParams();
  const isPanelOpen = searchParams.has("id");
  const datasetBases = useAppSelector((state) => state.datasetBase.entities);

  return (
    <View className="h-full bg-gray-50 dark:bg-gray-900">
      <div className={`h-full relative ${isPanelOpen ? "pr-[400px]" : ""} transition-all duration-300`}>
        <RestList
          className="h-full"
          entityName="dataset"
          fields={[
            {
              name: "id",
              label: "ID",
              readOnly: true,
            },
            {
              name: "createdAt",
              label: "Created At",
              readOnly: true,
            },
            {
              name: "name",
              label: "Name",
            },
            {
              name: "description",
              label: "Description",
              type: "textarea",
            },
            {
              name: "datasetBaseId",
              label: "Base Voice",
              type: "select",
              options: Object.values(datasetBases).map((item) => ({
                label: item.name,
                value: item.id,
              })),
              visible: false,
            },
            {
              name: "referenceUrl",
              label: "Reference",
              type: "audio",
            },
            {
              name: "",
              label: "",
              type: "component",
              component: ({ formData }: RestListComponentProps<Dataset>) => {
                const generatePreviewApiRequest = useApiRequest<{ data: { audioUrl: string } }>();

                const onClickGeneratePreview = () => {
                  generatePreviewApiRequest.fetch("/v1/datasets/generate-preview", {
                    method: "POST",
                    body: {
                      name: formData.name,
                      datasetBaseId: formData.datasetBaseId,
                      referenceUrl: formData.referenceUrl,
                    },
                  });
                };

                return (
                  <View className="flex flex-col gap-2 w-full">
                    <Button
                      variant="outline"
                      onClick={onClickGeneratePreview}
                      className="w-full"
                      isLoading={generatePreviewApiRequest.isLoading}
                    >
                      Generate Preview
                    </Button>
                    {generatePreviewApiRequest.data?.data.audioUrl && (
                      <audio
                        src={generatePreviewApiRequest.data?.data.audioUrl}
                        controls
                        className="w-full"
                      />
                    )}
                  </View>
                );
              },
            },
          ]}
        />
      </div>
    </View>
  );
};
