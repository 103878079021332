import { View } from "./base/View";
import { Text } from "./base/Text";
import { Button } from "./base/Button";
import { Icon } from "./base/Icon";
import { PlayButton } from "./base/PlayButton";
import { useEffect, useState, useRef } from "react";
import { clsx } from "clsx";
import { useAppSelector } from "../redux";

interface TutorialStep {
  targetRef?: React.RefObject<HTMLDivElement>;
  message: string;
  voiceId: string;
  audioUrl?: string;
}

interface TutorialOverlayProps {
  isVisible: boolean;
  onClose: () => void;
  currentStep: number;
  onNextStep: () => void;
  onPreviousStep: () => void;
  steps: TutorialStep[];
}

export const TutorialOverlay = ({ isVisible, onClose, currentStep, onNextStep, onPreviousStep, steps }: TutorialOverlayProps) => {
  const [highlightPosition, setHighlightPosition] = useState({ top: 0, left: 0, width: 0, height: 0 });
  const [isMobile, setIsMobile] = useState(false);
  const [isMessageTransitioning, setIsMessageTransitioning] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  // Get voices from Redux store
  const voices = useAppSelector((state) => state.voice.entities);
  const currentVoice = steps[currentStep]?.voiceId ? voices[steps[currentStep].voiceId] : undefined;

  // Initialize audio element when URL changes
  useEffect(() => {
    const audioUrl = steps[currentStep]?.audioUrl;
    if (audioUrl) {
      // If there is an existing audio element, pause and reset it
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }

      audioRef.current = new Audio(audioUrl);
      // Ensure the new audio starts from 0
      audioRef.current.currentTime = 0;

      // Add event listeners
      const handleEnded = () => setIsPlaying(false);
      const handlePause = () => setIsPlaying(false);
      const handleError = () => setIsPlaying(false);

      audioRef.current.addEventListener("ended", handleEnded);
      audioRef.current.addEventListener("pause", handlePause);
      audioRef.current.addEventListener("error", handleError);

      return () => {
        if (audioRef.current) {
          audioRef.current.removeEventListener("ended", handleEnded);
          audioRef.current.removeEventListener("pause", handlePause);
          audioRef.current.removeEventListener("error", handleError);
          audioRef.current.pause();
          audioRef.current = null;
          setIsPlaying(false);
        }
      };
    }
  }, [steps, currentStep]);

  const handlePlayPause = () => {
    if (!audioRef.current) return;

    if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  // Add window resize handler
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768); // matches our md: breakpoint
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  useEffect(() => {
    if (isVisible && steps[currentStep]?.targetRef?.current) {
      const element = steps[currentStep]?.targetRef?.current;
      if (element) {
        // First scroll element into view at top
        element.scrollIntoView({ behavior: "smooth", block: "center" });

        // Wait for scroll to complete before setting positions
        setTimeout(() => {
          const rect = element.getBoundingClientRect();

          // Set highlight position
          if (isMobile && currentStep < 3) {
            const switchOptions = document.querySelectorAll(".md\\:hidden button");
            let targetOption;

            switch (currentStep) {
              case 0:
                targetOption = switchOptions[0]; // Prim Studio
                break;
              case 1:
                targetOption = switchOptions[1]; // Prim Flash
                break;
              case 2:
                targetOption = switchOptions[2]; // Voice Changer
                break;
            }

            if (targetOption) {
              const rect = targetOption.getBoundingClientRect();
              setHighlightPosition({
                top: rect.top,
                left: rect.left,
                width: rect.width,
                height: rect.height,
              });
            }
          } else {
            setHighlightPosition({
              top: rect.top,
              left: rect.left,
              width: rect.width,
              height: rect.height,
            });
          }
        }, 300); // Wait for scroll animation
      }
    }
  }, [isVisible, currentStep, steps, isMobile]);

  useEffect(() => {
    // Trigger transition animation when step changes
    setIsMessageTransitioning(true);
    const timer = setTimeout(() => setIsMessageTransitioning(false), 50);
    return () => clearTimeout(timer);
  }, [currentStep]);

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  if (!isVisible) return null;

  // Hide on mobile screens
  if (isMobile) return null;

  const padding = 2;

  return (
    <View
      className="fixed inset-0 z-[1000] hidden md:block"
      onClick={handleOverlayClick}
    >
      {/* Overlay with hole effect - only create hole if targetRef exists */}
      <View
        className="absolute inset-0 bg-black/30 dark:bg-black/50 transition-all duration-500 ease-in-out"
        style={{
          opacity: 0.8,
          ...(steps[currentStep]?.targetRef && {
            clipPath: `polygon(
              0% 0%,
              100% 0%,
              100% 100%,
              0% 100%,
              0% ${highlightPosition.top - padding}px,
              ${highlightPosition.left - padding}px ${highlightPosition.top - padding}px,
              ${highlightPosition.left - padding}px ${highlightPosition.top + highlightPosition.height + padding}px,
              ${highlightPosition.left + highlightPosition.width + padding}px ${highlightPosition.top + highlightPosition.height + padding}px,
              ${highlightPosition.left + highlightPosition.width + padding}px ${highlightPosition.top - padding}px,
              0% ${highlightPosition.top - padding}px
            )`,
          }),
        }}
      />

      {/* Highlight border - only show if targetRef exists */}
      {steps[currentStep]?.targetRef && (
        <View
          className="absolute border-2 border-gray-200 dark:border-white rounded-lg transition-all duration-500 ease-in-out"
          style={{
            top: highlightPosition.top - padding,
            left: highlightPosition.left - padding,
            width: highlightPosition.width + padding * 2,
            height: highlightPosition.height + padding * 2,
          }}
        />
      )}

      {/* Bottom Right Corner Message Box */}
      <View className="fixed bottom-6 right-6 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-xl shadow-lg transition-all duration-500 max-w-lg">
        <View className="p-6">
          {/* Title section with close button and profile picture */}
          <View className="w-full pb-4 border-b border-gray-200 dark:border-gray-700 mb-6">
            <View className="flex items-center justify-between gap-4">
              <View className="flex items-center gap-3 flex-1 min-w-0">
                <View className="w-10 h-10 rounded-lg flex-shrink-0 overflow-hidden">
                  {currentVoice?.userImageUploadThumbnail ? (
                    <img
                      src={currentVoice.userImageUploadThumbnail}
                      alt={`${currentVoice.name} thumbnail`}
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <View className="w-full h-full bg-gray-200 dark:bg-gray-700" />
                  )}
                </View>
                <View className="flex-1 min-w-0 flex items-center gap-4">
                  <Text
                    className={clsx(
                      "text-gray-900 dark:text-white text-xl font-bold inline-block transition-opacity duration-500 truncate",
                      isMessageTransitioning ? "opacity-0" : "opacity-100",
                    )}
                  >
                    {currentVoice?.name || "Unknown Voice"}
                  </Text>
                  {steps[currentStep].audioUrl && (
                    <PlayButton
                      isPlaying={isPlaying}
                      onPlayPause={handlePlayPause}
                      audioElement={audioRef.current}
                      className="flex-shrink-0 animate-[pulse_1.5s_ease-in-out_infinite] hover:animate-none transition-transform hover:scale-105 shadow-lg hover:shadow-xl"
                      audioUrl={steps[currentStep]?.audioUrl}
                    />
                  )}
                </View>
              </View>
              <Button
                onClick={onClose}
                className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-white !w-7 h-7 !shadow-none flex-shrink-0"
                aria-label="Close dialog"
                type="basic"
              >
                <Icon
                  name="close"
                  size={24}
                />
              </Button>
            </View>
          </View>

          {/* Message */}
          <View>
            <Text
              className={clsx(
                "text-gray-900 dark:text-white text-lg text-left inline-block transition-opacity duration-500",
                isMessageTransitioning ? "opacity-0" : "opacity-100",
              )}
            >
              {steps[currentStep].message}
            </Text>
          </View>

          {/* Buttons */}
          <View
            className={clsx(
              "grid grid-cols-2 gap-2 border-t border-gray-200 dark:border-gray-700 pt-4 mt-6",
              "transition-opacity duration-500",
              isMessageTransitioning ? "opacity-0" : "opacity-100",
            )}
          >
            <Button
              onClick={currentStep === 0 ? undefined : onPreviousStep}
              variant="ghost"
              type="basic"
              className={clsx(
                "w-full text-gray-500 dark:text-gray-400 transition-colors duration-200 !shadow-none",
                currentStep === 0 ? "opacity-50 cursor-not-allowed" : "hover:text-gray-700 dark:hover:text-gray-300",
              )}
            >
              Back
            </Button>
            <Button
              onClick={currentStep === steps.length - 1 ? onClose : onNextStep}
              variant="default"
              className="w-full bg-brand hover:bg-brand-dark dark:bg-brand-light dark:hover:bg-brand text-white px-6 py-2 rounded-lg transition-all duration-200"
            >
              {currentStep === steps.length - 1 ? "Got it!" : "Next"}
            </Button>
          </View>
        </View>
      </View>
    </View>
  );
};
