import { createSlice } from "@reduxjs/toolkit";

import { Agent } from "./agentSlice";
import { AgentEnvironment } from "./agentEnvironmentSlice";
import { AgentFunction } from "./agentFunctionSlice";

export type SessionState = {
  agentDetailsFormData: Partial<Agent>;
  agentEnvironmentsFormData: Partial<AgentEnvironment>;
  agentFunctionFormData: Partial<AgentFunction>;
};

const initialState: SessionState = {
  agentDetailsFormData: {},
  agentEnvironmentsFormData: {},
  agentFunctionFormData: {},
};

const featureSlice = createSlice({
  name: "feature",
  initialState,
  reducers: {
    setAgentDetailsFormData: (state, action) => {
      state.agentDetailsFormData = action.payload;
    },
    setAgentEnvironmentsFormData: (state, action) => {
      state.agentEnvironmentsFormData = action.payload;
    },
    setAgentFunctionFormData: (state, action) => {
      state.agentFunctionFormData = action.payload;
    },
  },
});

export const featureActions = featureSlice.actions;
export const featureReducer = featureSlice.reducer;
