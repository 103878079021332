import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createRestSlice } from "./restSlice";
import { Organization, OrganizationMember } from "./userSlice";
import { makeRequest } from "../../utils/request";

export type OrganizationInvite = {
  id: string;
  email: string;
  organizationId: string;
  status: "pending" | "accepted" | "rejected";
  role: "admin" | "member";
  createdAt: string;
  updatedAt: string;
  expiresAt: string;
};

export type CreateInvitePayload = {
  email: string;
  role: "admin" | "member";
};

export interface OrganizationState {
  currentOrganization: Organization | null;
  members: OrganizationMember[];
  invites: OrganizationInvite[];
  isLoading: boolean;
  error: string | null;
}

const initialState: OrganizationState = {
  currentOrganization: null,
  members: [],
  invites: [],
  isLoading: false,
  error: null,
};

// Organizations base actions
const organizationBaseSlice = createRestSlice<Organization>("organization", "/v1/organizations");

// Organization specific actions
const getCurrentOrganization = createAsyncThunk("organization/getCurrent", async () => {
  const response = await makeRequest<{ data: Organization }>("/v1/organizations/current");
  return response.data;
});

const getOrganizationMembers = createAsyncThunk("organization/getMembers", async (organizationId: string) => {
  const response = await makeRequest<{ data: OrganizationMember[] }>(`/v1/organizations/${organizationId}/members`);
  return response.data;
});

const getOrganizationInvites = createAsyncThunk("organization/getInvites", async (organizationId: string) => {
  const response = await makeRequest<{ data: OrganizationInvite[] }>(`/v1/organizations/${organizationId}/invites`);
  return response.data;
});

const createInvite = createAsyncThunk(
  "organization/createInvite",
  async ({ organizationId, payload }: { organizationId: string; payload: CreateInvitePayload }) => {
    const response = await makeRequest<{ data: OrganizationInvite }>(`/v1/organizations/${organizationId}/invites`, {
      method: "POST",
      body: payload,
    });
    return response.data;
  },
);

const cancelInvite = createAsyncThunk("organization/cancelInvite", async ({ organizationId, inviteId }: { organizationId: string; inviteId: string }) => {
  await makeRequest(`/v1/organizations/${organizationId}/invites/${inviteId}`, {
    method: "DELETE",
  });
  return inviteId;
});

const updateMemberRole = createAsyncThunk(
  "organization/updateMemberRole",
  async ({ organizationId, memberId, role }: { organizationId: string; memberId: string; role: "admin" | "member" }) => {
    const response = await makeRequest<{ data: OrganizationMember }>(`/v1/organizations/${organizationId}/members/${memberId}`, {
      method: "PATCH",
      body: { role },
    });
    return response.data;
  },
);

const removeMember = createAsyncThunk("organization/removeMember", async ({ organizationId, memberId }: { organizationId: string; memberId: string }) => {
  await makeRequest(`/v1/organizations/${organizationId}/members/${memberId}`, {
    method: "DELETE",
  });
  return memberId;
});

const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get current organization
    builder.addCase(getCurrentOrganization.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getCurrentOrganization.fulfilled, (state, action) => {
      state.isLoading = false;
      state.currentOrganization = action.payload;
    });
    builder.addCase(getCurrentOrganization.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message || "Failed to fetch organization";
    });

    // Get organization members
    builder.addCase(getOrganizationMembers.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getOrganizationMembers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.members = action.payload;
    });
    builder.addCase(getOrganizationMembers.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message || "Failed to fetch organization members";
    });

    // Get organization invites
    builder.addCase(getOrganizationInvites.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getOrganizationInvites.fulfilled, (state, action) => {
      state.isLoading = false;
      state.invites = action.payload;
    });
    builder.addCase(getOrganizationInvites.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message || "Failed to fetch organization invites";
    });

    // Create invite
    builder.addCase(createInvite.fulfilled, (state, action) => {
      state.invites.push(action.payload);
    });

    // Cancel invite
    builder.addCase(cancelInvite.fulfilled, (state, action) => {
      state.invites = state.invites.filter((invite) => invite.id !== action.payload);
    });

    // Update member role
    builder.addCase(updateMemberRole.fulfilled, (state, action) => {
      const index = state.members.findIndex((member) => member.id === action.payload.id);
      if (index !== -1) {
        state.members[index] = action.payload;
      }
    });

    // Remove member
    builder.addCase(removeMember.fulfilled, (state, action) => {
      state.members = state.members.filter((member) => member.id !== action.payload);
    });
  },
});

const organizationRestSlice = createRestSlice<Organization>("organization", "/v1/organizations");

export const organizationRestReducer = organizationRestSlice.reducer;
export const organizationReducer = organizationSlice.reducer;

export const organizationRestActions = organizationRestSlice.actions;
export const organizationActions = {
  ...organizationBaseSlice.actions,
  getCurrentOrganization,
  getOrganizationMembers,
  getOrganizationInvites,
  createInvite,
  cancelInvite,
  updateMemberRole,
  removeMember,
};
