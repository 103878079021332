import React from "react";
import { BaseVoiceCard, type BaseVoiceCardProps } from ".";

export const HeroVoiceCard: React.FC<BaseVoiceCardProps> = (props) => {
  return (
    <BaseVoiceCard
      {...props}
      titleClassName="text-3xl"
      descriptionClassName="text-lg"
      playButtonClassName="!w-14 !h-14"
      hideVerifiedBadge={true}
      hidePublicBadge={true}
      hideMetadata={true}
      hideTags={false}
      descriptionLineClamp={2}
    />
  );
};
